import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Dialog,
  Button,
  Card,
  AppBar,
  Container,
  Toolbar,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
} from "@mui/material";
import { json, useNavigate, redirect } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import UserLoginMobile from "./UserLoginMobile";
import "./login.css";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function UserLogin() {
  const navigate = useNavigate();

  const vertical = "top";
  const horizontal = "center";
  const maxWidth = "sm";

  const [showCaptcha, setShowCaptcha] = useState("none");

  const [showOTP, setShowOTP] = useState("none");
  const [mobileNumber, setMobileNumber] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [pickOTP, setPickOTP] = useState("");
  const [showPhone, setShowPhone] = useState("block");
  const [open, setOpen] = useState(false);
  const [openOTP, setOpenOTP] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState("");

  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");

  const [captchaToken, setCaptchaToken] = useState();

  const [errorMsg, setErrorMsg] = useState("");

  const [countryCode, setCountryCode] = useState("");
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleChange = (value) => {
    if (value.length != 10) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
      setMobileNumber(value);
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const getOTP = async (captchaToken) => {
    setBtnLoading(true);
    setBtnDisabled(true);

    const response = await fetch("https://ndd.logicarts.in/pickup/getOTP", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobile: mobileNumber,
        captchaToken: captchaToken,
      }),
    });

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.success == true) {
      setShowOTP("block");
      setShowPhone("none");
      setShowCaptcha("none");
      setBtnDisabled(false);
      setBtnLoading(false);
    } else {
      setErrorMsg(responseJson.data);
      setOpen(true);
      setBtnDisabled(false);
      setBtnLoading(false);
    }
  };

  const verifyOTP = async () => {
    setBtnLoading(true);
    setBtnDisabled(true);

    const response = await fetch("https://ndd.logicarts.in/pickup/verifyOTP", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobile: mobileNumber,
        otp: pickOTP,
      }),
    });
    const responseJson = await response.json();
    if (responseJson.success == true) {
      if (responseJson.data[0].isnewCx == 1) {
        setOpenDialog(true);
        localStorage.setItem(
          "userDetails",
          JSON.stringify(responseJson.data[0])
        );
        localStorage.setItem("token", responseJson.data[0].token);
      } else {
        localStorage.setItem(
          "userDetails",
          JSON.stringify(responseJson.data[0])
        );
        localStorage.setItem("token", responseJson.data[0].token);
        navigate("/express/order-history");
      }
      setBtnLoading(false);
      setBtnDisabled(false);
    } else {
      setErrorMsg(responseJson.data);
      setOpenOTP(true);
      setBtnLoading(false);
      setBtnDisabled(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseOTP = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenOTP(false);
  };

  const handleCloseRegister = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenRegister(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };

  const cxManageCX = async () => {
    const userToken = localStorage.getItem("token");

    const response = await fetch("https://ndd.logicarts.in/pickup/cxManageCX", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${userToken}`,
      },

      body: JSON.stringify({
        cxID: "",
        mobile: mobileNumber,
        jdata: {
          FromAddresses: [
            { Description: "", Address: "", Contact01: "", IsDefault: "" },
            { Description: "", Address: "", Contact01: "", IsDefault: "" },
          ],
          ToAddresses: [
            { Description: "", Address: "", Contact01: "" },
            { Description: "", Address: "", Contact01: "" },
          ],
          firstname: firstName,
          lastname: lastName,
          email: email,
        },
      }),
    });

    const responseJson = await response.json();

    if (responseJson.success == true) {
      setOpenDialog(false);
      navigate("/express/order-history");
    } else {
      setErrorMsg(responseJson.data);
      setOpenRegister(true);
    }
  };

  const handleChangeSelect = (event) => {
    setCountryCode(event.target.value);
  };

  return (
    <React.Fragment>
      <Box className="desktop-create-order">
        <Grid container>
          <Grid item xs={6}>
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
              }}
              pagination={{
                dynamicBullets : true
              }}
              modules={[Autoplay, Pagination]}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <img
                  src={require("./assests/images/Login-Banner.jpg")}
                  style={{
                    width: "100%",
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("./assests/images/Login-Banner2.png")}
                  style={{
                    width: "100%",
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("./assests/images/Login-Banner3.png")}
                  style={{
                    width: "100%",
                  }}
                />
              </SwiperSlide>
            </Swiper>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                marginTop: "40%",
              }}
            >
              <Card
                sx={{
                  boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.1)",
                  borderRadius: "16px",
                  width: "80%",
                  padding: "5%",
                  display: "block",
                  margin: "auto",
                }}
              >
                <Box
                  sx={{
                    display: showPhone,
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "#182035",
                      fontWeight: "700",
                      textTransform: "uppercase",
                    }}
                  >
                    Verify your phone number
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      marginTop: "2%",
                      color: "#777A82",
                    }}
                  >
                    We will send you an{" "}
                    <span
                      style={{
                        color: "#606268",
                        fontWeight: "600",
                      }}
                    >
                      One Time Password (OTP)
                    </span>{" "}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "#777A82",
                    }}
                  >
                    on this mobile number.
                  </Typography>

                  <Box
                    sx={{
                      padding: "5%",
                    }}
                  >
                    <Typography>
                      Enter mobile no.
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        marginTop: "1%",
                      }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="+91"
                        value={countryCode}
                        onChange={handleChangeSelect}
                      >
                        <MenuItem value={10} selected={true}>
                          +91
                        </MenuItem>
                      </Select>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        placeholder="Mobile Number"
                        variant="outlined"
                        type="number"
                        onChange={(e) => handleChange(e.target.value)}
                      />
                    </Stack>
                    <Box
                      sx={{
                        marginTop: "3%",
                      }}
                    >
                      <LoadingButton
                        variant="contained"
                        disabled={btnDisabled}
                        loading={btnLoading}
                        sx={{
                          display: "block",
                          margin: "auto",
                          backgroundColor: "#669AFF",
                        }}
                        onClick={() => {
                          setShowCaptcha("block");
                          setShowPhone("none");
                        }}
                      >
                        GET OTP
                      </LoadingButton>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    padding: "10%",
                    display: showCaptcha,
                  }}
                >
                  <Typography
                    sx={{
                      marginTop: "5%",
                      textAlign: "center",
                    }}
                  >
                    Please verify that you are a human not a robot
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10%",
                    }}
                  >
                    <ReCAPTCHA
                      sitekey="6LcbsGwmAAAAAHwiEd2s4AVSrVc8OY9QFGu-BlZl"
                      onChange={(value) => {
                        getOTP(value);
                        setCaptchaToken(value);
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: showOTP,
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "#182035",
                      fontWeight: "700",
                      textTransform: "uppercase",
                    }}
                  >
                    Verify your phone number
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      marginTop: "2%",
                      color: "#777A82",
                    }}
                  >
                    We have send you an{" "}
                    <span
                      style={{
                        color: "#606268",
                        fontWeight: "600",
                      }}
                    >
                      One Time Password (OTP)
                    </span>{" "}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "#777A82",
                    }}
                  >
                    on this mobile number.
                  </Typography>
                  <Box
                    sx={{
                      marginTop: "5%",
                    }}
                  >
                    <Typography>
                      Enter mobile no.
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        marginTop: "1%",
                      }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="+91"
                        value={countryCode}
                        onChange={handleChangeSelect}
                      >
                        <MenuItem value={10} selected>
                          +91
                        </MenuItem>
                      </Select>
                      <TextField
                        disabled
                        fullWidth
                        id="outlined-basic"
                        placeholder={mobileNumber}
                        variant="outlined"
                        type="number"
                        onChange={(e) => handleChange(e.target.value)}
                      />
                    </Stack>

                    <Box
                      sx={{
                        marginLeft: "5%",
                        marginTop: "5%",
                      }}
                    >
                      <OTPInput
                        value={pickOTP}
                        onChange={setPickOTP}
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                        secure
                        style={{
                          justifyContent: "space-evenly",
                        }}
                        inputStyles={{
                          borderColor: "#fff",
                          borderLeftColor: "#fff0",
                          borderTopColor: "#fff0",
                          borderBottomColor: "#fff0",
                          borderRadius: "5px",
                          height: "50px",
                          width: "50px",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        marginTop: "3%",
                      }}
                    >
                      I didn't receive any code.{" "}
                      <span
                        style={{
                          color: "#6099DC",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowOTP("none");
                          setShowCaptcha("none");
                          setShowPhone("block");
                        }}
                      >
                        RESEND
                      </span>
                    </Typography>
                    <Box
                      sx={{
                        marginTop: "2%",
                      }}
                    >
                      <LoadingButton
                        disabled={btnDisabled}
                        loading={btnLoading}
                        variant="contained"
                        sx={{
                          display: "block",
                          margin: "auto",
                          backgroundColor: "#669AFF",
                        }}
                        onClick={() => verifyOTP()}
                      >
                        Verify & Proceed
                      </LoadingButton>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="mobile-login">
        <UserLoginMobile />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openOTP}
        autoHideDuration={6000}
        onClose={handleCloseOTP}
      >
        <Alert onClose={handleCloseOTP} severity="error" sx={{ width: "100%" }}>
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openRegister}
        autoHideDuration={6000}
        onClose={handleCloseRegister}
      >
        <Alert
          onClose={handleCloseRegister}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Dialog
        fullWidth
        maxWidth={maxWidth}
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <Box
          sx={{
            padding: "2%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}
              >
                Enter Your Details
              </Typography>
            </Box>
            <Box>
              <Button onClick={handleCloseDialog}>Close</Button>
            </Box>
          </Box>

          <Box
            sx={{
              padding: "5%",
            }}
          >
            <TextField
              error={firstNameError}
              fullWidth
              label="First Name"
              variant="standard"
              helperText={firstNameErrorMsg}
              sx={{
                marginBottom: "3%",
              }}
              onChange={(e) => {
                setFirstNameError(false);
                setFirstNameErrorMsg("");
                setFirstName(e.target.value);
              }}
            />
            <TextField
              error={lastNameError}
              fullWidth
              label="Last Name"
              variant="standard"
              helperText={lastNameErrorMsg}
              sx={{
                marginBottom: "3%",
              }}
              onChange={(e) => {
                setLastNameError(false);
                setLastNameErrorMsg("");
                setLastName(e.target.value);
              }}
            />
            <TextField
              error={emailError}
              fullWidth
              label="Email Address"
              variant="standard"
              helperText={emailErrorMsg}
              sx={{
                marginBottom: "3%",
              }}
              onChange={(e) => {
                setEmailError(false);
                setEmailErrorMsg("");
                setEmail(e.target.value);
              }}
            />

            <LoadingButton
              fullWidth
              variant="contained"
              sx={{
                marginTop: "5%",
              }}
              onClick={() => {
                if (firstName == "") {
                  setFirstNameError(true);
                  setFirstNameErrorMsg("Please provide your first name.");
                } else if (lastName == "") {
                  setLastNameError(true);
                  setLastNameErrorMsg("Please provide your last name.");
                } else if (email == "") {
                  setEmailError(true);
                  setEmailErrorMsg("Please provide your email address.");
                } else {
                  cxManageCX();
                }
              }}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

export default UserLogin;
