import React, { Fragment, useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  MarkerF,
  useLoadScript,
  DistanceMatrixService,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useNavigate, useLocation } from "react-router-dom";

import { Box, Button, Card, Stack, TextField, Typography } from "@mui/material";

import MyLocationRoundedIcon from "@mui/icons-material/MyLocationRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LoadingButton from "@mui/lab/LoadingButton";
import "./mapAddress.css";

import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function MapAddress() {
  const navigate = useNavigate();
  const location = useLocation();

  const [mapCenter, setMapCenter] = useState({
    lat: 19.0829084,
    lng: 72.8707019
  });

  const [completeAddress, setCompleteAdress] = useState("");
  const [loading, setLoading] = useState(false);
  const [openFromDrawer, setOpenFromDrawer] = useState(false);

  const [Description, setDescription] = useState("");

  const [Coordinates, setCoordinates] = useState("");
  const [Address, setAddress] = useState("");
  const [Building, setBuilding] = useState("");
  const [Name, setName] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Pincode, setPincode] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Landmark, setLandmark] = useState("");


  const [btnLoading, setBtnLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [nameErrorText, setNameErrorText] = useState("");

  const [buildingError, setBuildingError] = useState(false);
  const [buildingErrorText, setBuildingErrorText] = useState("");

  const [mobileError, setMobileError] = useState(false);
  const [mobileErrorText, setMobileErrorText] = useState("");

  const [DescriptionError, setDescriptionError] = useState(false);
  const [DescriptionErrorText, setDescriptionErrorText] = useState("");

  const [showGiveName, setShowGiveName] = useState("none");

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("refresh")) {
        localStorage.removeItem("refresh");
        window.location.reload();
      }
      if (
        !localStorage.getItem("searchLat") &&
        !localStorage.getItem("searchLng")
      ) {
        await position();
      } else {
        await getSearchedAddress();
        console.log(Number(localStorage.getItem("searchLat")));
        console.log(localStorage.getItem("searchLng"));
      }
      await verifyToken();
    })();
  }, []);

  const verifyToken = async () => {
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    const responseJson = await response.json();
    console.log(responseJson, "verify");
    if (responseJson.success == true) {
      console.log("authenticated");
    } else {
      navigate("/express/user-login");
    }
  };

  const handleOnClick = () => {
    window.location.href = `/express/search-address-mobile?location=${location.state.type}`
  };

  const position = async () => {
    setLoading(true);
    await navigator.geolocation.getCurrentPosition(
      async (position) => {
        setMapCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        await getAddress(position.coords.latitude, position.coords.longitude);
      },
      async (err) => {
        if (err) {
          setLoading(false);
          // setMapCenter({
          //   lat: 12.9889434,
          //   lng:77.6867527
          // });

          await getAddress(19.0829084, 72.8707019);
        }
      }
    );
  };

  const onMarkerDragEnd = async (coord, index) => {
    setLoading(true);
    const { latLng } = await coord;
    const lat = await latLng.lat();
    const lng = await latLng.lng();

    await getAddress(lat, lng);
  };

  Geocode.setApiKey("AIzaSyDJ6m7dujpOLQYOVDL6B5QJdE6cr0sEcuw");
  Geocode.setLanguage("en");
  Geocode.setRegion("in");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();

  const getAddress = async (latitude, longitude) => {
    console.log("qwertyui");
    await Geocode.fromLatLng(`${latitude}`, `${longitude}`).then(
      async (response) => {
        const address = await response.results[0].formatted_address;
        setCompleteAdress(address);
        setLoading(false);
        setCoordinates(`${latitude}, ${longitude}`);
        setAddress(address);
        const filterPostalCode =
          await response.results[0].address_components.filter(
            (value) => value.types[0] == "postal_code"
          );
        const filterCity = await response.results[0].address_components.filter(
          (value) => value.types[0] == "administrative_area_level_3"
        );
        const filterState = await response.results[0].address_components.filter(
          (value) => value.types[0] == "administrative_area_level_1"
        );
        setPincode(filterPostalCode[0].long_name);
        setCity(filterCity[0].long_name);
        setState(filterState[0].long_name);
      },
      (error) => {
        console.error(error);
        if (error) {
          setLoading(true);
          Geocode.fromAddress(localStorage.getItem("searchAddress")).then(
            async (response) => {
              console.log(response);
              const address = await response.results[0].formatted_address;
              setCompleteAdress(address);
              setLoading(false);
              setCoordinates(`${latitude}, ${longitude}`);
              setAddress(address);
              const filterPostalCode =
                await response.results[0].address_components.filter(
                  (value) => value.types[0] == "postal_code"
                );
              const filterCity =
                await response.results[0].address_components.filter(
                  (value) => value.types[0] == "administrative_area_level_3"
                );
              const filterState =
                await response.results[0].address_components.filter(
                  (value) => value.types[0] == "administrative_area_level_1"
                );
              setPincode(filterPostalCode[0].long_name);
              setCity(filterCity[0].long_name);
              setState(filterState[0].long_name);
              setLoading(false);
            },
            (err) => {
              console.log(err);
              setLoading(false);
            }
          );
        }
        setLoading(false);
      }
    );
    // await Geocode.fromAddress("Marathahalli, Bengaluru, Karnataka, India").then(
    //   (response) => {
    //     // const { lat, lng } = response.results[0].geometry.location;
    //     console.log(response);
    //     setLoading(false)
    //   },
    //   (error) => {
    //     console.error(error);
    //     setLoading(false);
    //     alert("Unable to fetch try again later");
    //   }
    // );
  };

  const getSearchedAddress = async () => {
    setLoading(true);
    setMapCenter({
      lat: Number(localStorage.getItem("searchLat")),
      lng: Number(localStorage.getItem("searchLng")),
    });
    await getAddress(
      Number(localStorage.getItem("searchLat")),
      Number(localStorage.getItem("searchLng"))
    );
  };

  const manageAddress = async () => {
    setBtnLoading(true);
    setBtnDisabled(true);
    localStorage.setItem("isFromMap", true);
    const userDetails = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDetails);

    if (!parseData.cxID) {
      setErrorMsg("Session expired! Please login again");
      setOpenErrorAlert(true);
      localStorage.removeItem("userDetails");
      window.location.reload();
      setBtnLoading(false);
      setBtnDisabled(false);
    } else {
      const response = await fetch(
        "https://ndd.logicarts.in/pickup/manageAddress",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({
            cxID: `${parseData.cxID}`,
            cxAdID: "",
            jdata: {
              Description: Description,
              Pincode: Pincode,
              isDefault: "",
              categoryID: "",
              Coordinates: Coordinates,
              Address: Address,
              Building: Building,
              Name: Name,
              Mobile: Mobile,
              City: City,
              State: State,
              Landmark: Landmark,
            },
          }),
        }
      );

      const responseJson = await response.json();
      console.log(responseJson);
      if (responseJson.success == true) {
        setBtnLoading(false);
        setBtnDisabled(false);
        window.location.href = '/express/create-order';

      } else {

        setBtnLoading(false);
        setBtnDisabled(false);
        setErrorMsg("Unable to save your address");
        setOpenErrorAlert(true)
      }
    }
  };

  const vertical = "top";
  const horizontal = "center";

  return (
    <Fragment>
      <Box className="mobile-map">
        <Box
          sx={{
            backgroundColor: "#669AFF",
            display: "flex",
            justifyContent: "space-between",
            padding: "3%",
          }}
        >
          <Typography
            sx={{
              marginTop: "2%",
            }}
            onClick={() => window.location.href = "/express/create-order"}
          >
            <ArrowBackRoundedIcon
              sx={{
                color: "#FFFFFF",
              }}
            />
          </Typography>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontWeight: "600",
              marginTop: "1%",
              fontSize: "1.2rem",
            }}
          >
            Search Location
          </Typography>
          <Typography></Typography>
        </Box>

        <Box>
          <LoadScript googleMapsApiKey="AIzaSyDJ6m7dujpOLQYOVDL6B5QJdE6cr0sEcuw">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={mapCenter}
              zoom={13}
            >
              <>
                <MarkerF
                  position={mapCenter}
                  draggable
                  onDragEnd={async (coord) => await onMarkerDragEnd(coord)}
                />
              </>
            </GoogleMap>
          </LoadScript>
        </Box>
        <Box
          sx={{
            padding: "5%",
          }}
        >
          <Button
            sx={{
              float: "right",
            }}
            onClick={handleOnClick}
          >
            Change
          </Button>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              marginTop: "10%",
            }}
          >
            <Typography>
              <FmdGoodIcon />
            </Typography>
            <Typography className="complete-address">
              {completeAddress}
            </Typography>
          </Stack>

          <LoadingButton
            fullWidth
            variant="contained"
            sx={{
              marginTop: "5%",
            }}
            loading={btnLoading}
            disabled={btnDisabled}
            onClick={() => setOpenFromDrawer(true)}
          >
            Confirm Location
          </LoadingButton>
        </Box>

        <Modal
          open={loading}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              border: "1px soild grey",
              p: 4,
            }}
          >
            <Box
              sx={{
                padding: "10%",
              }}
            >
              <CircularProgress
                sx={{
                  display: "block",
                  margin: "auto",
                }}
              />
            </Box>
          </Box>
        </Modal>

        <Drawer
          anchor="bottom"
          open={openFromDrawer}
          onClose={() => setOpenFromDrawer(false)}
        >
          <Box
            sx={{
              padding: "3%",
            }}
          >
            <Stack direction="row" spacing={2}>
              <Typography>
                <LocationOnIcon />
              </Typography>
              <Typography>{completeAddress}</Typography>
            </Stack>
            <Box
              sx={{
                marginTop: "5%",
                padding: "2%",
              }}
            >
              <TextField
                fullWidth
                variant="standard"
                placeholder="House/Flat/Block No."
                sx={{
                  marginBottom: "5%",
                }}
                onChange={(e) => {
                  setBuildingError(false);
                  setBuildingErrorText("");
                  setBuilding(e.target.value);
                }}
                error={buildingError}
                helperText={buildingErrorText}
              />

              <TextField
                fullWidth
                variant="standard"
                placeholder="Name"
                sx={{
                  marginBottom: "5%",
                }}
                onChange={(e) => {
                  setNameError(false);
                  setNameErrorText("");
                  setName(e.target.value);
                }}
                error={nameError}
                helperText={nameErrorText}
              />
              <TextField
                fullWidth
                variant="standard"
                placeholder="Phone Number"
                sx={{
                  marginBottom: "5%",
                }}
                onChange={(e) => {
                  setMobileError(false);
                  setMobileErrorText("");
                  setMobile(e.target.value);
                }}
                error={mobileError}
                helperText={mobileErrorText}
              />

              <TextField
                fullWidth
                variant="standard"
                placeholder="Landmark (optional)"
                sx={{
                  marginBottom: "5%",
                }}
                onChange={(e) => {
                  setLandmark(e.target.value);
                }}
              />

              <FormControl
                fullWidth
                error={DescriptionError}
                helperText={DescriptionErrorText}
              >
                <InputLabel id="demo-simple-select-label">Save As</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={Description}
                  label="Save As"
                  onChange={(event) => {
                    setDescription(event.target.value);
                    if (event.target.value == "Other") {
                      setShowGiveName("block");
                    } else {
                      setShowGiveName("none");
                    }
                  }}
                >
                  <MenuItem value={"Home"}>Home</MenuItem>
                  <MenuItem value={"Friends/Family"}>Friends/Family</MenuItem>
                  <MenuItem value={"Work"}>Work</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>

              <TextField
                fullWidth
                variant="standard"
                placeholder="Give Name"
                sx={{
                  marginBottom: "5%",
                  marginTop: "5%",
                  display: showGiveName,
                }}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />

              <LoadingButton
                fullWidth
                variant="contained"
                sx={{
                  marginTop: "5%",
                }}
                loading={btnLoading}
                disabled={btnDisabled}
                onClick={async () => {
                  if (Building == "") {
                    setBuildingError(true);
                    setBuildingErrorText("please provide the information");
                  } else if (Name == "") {
                    setNameError(true);
                    setNameErrorText("Please provide Name");
                  } else if (Mobile == "") {
                    setMobileError(true);
                    setMobileErrorText("Please provide mobile number");
                  } else if (Mobile.length !== 10) {
                    setMobileError(true);
                    setMobileErrorText("Please provide correct mobile number");
                  } else if (Description == "") {
                    setDescriptionError(true);
                    setDescriptionErrorText(
                      "Please save your location as label"
                    );
                  } else {
                    await manageAddress();
                  }
                }}
              >
                Save Address
              </LoadingButton>
            </Box>
          </Box>
        </Drawer>
      </Box>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={() => setOpenErrorAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export default MapAddress;
