import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Typography, Grid, Box } from "@mui/material";
import {
  LocalMall as LocalMallIcon,
  Warehouse as WarehouseIcon,
  LocalShipping as LocalShippingIcon,
  CheckCircle as CheckCircleIcon,
  PersonOff as PersonOffIcon,
  PhoneDisabled as PhoneDisabledIcon,
  SouthWest as SouthWestIcon,
  History as HistoryIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import axios from "axios";
import "./assests/css/track.css";
import TruckMarker from "./assests/truckMarker.png";

// Styled components
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

// Step icons
const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient(136deg, rgb(62 178 233) 0%, rgb(62 178 233) 50%, rgb(62 178 233) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(136deg, rgb(62 178 233) 0%, rgb(62 178 233) 50%, rgb(62 178 233) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon, isNotProcessed } = props;

  const iconMap = {
    CheckCirclelIcon: <CheckCircleIcon />,
    LocalMallIcon: <LocalMallIcon />,
    WarehouseIcon: <WarehouseIcon />,
    LocalShippingIcon: <LocalShippingIcon />,
    PersonOffIcon: <PersonOffIcon />,
    PhoneDisabledIcon: <PhoneDisabledIcon />,
    SouthWestIcon: <SouthWestIcon />,
    HistoryIcon: <HistoryIcon />,
    CancelIcon: <CancelIcon />,
  };

  const iconComponent = iconMap[icon] || (
    <div className="QontoStepIcon-circle" style={{
      width: "50px !important",
      height: "32px !important",
    }}
    />
  );

  const backgroundColor = isNotProcessed
    ? "#ccc" // Gray background if not processed
    : active
      ? "linear-gradient(136deg, rgb(62 178 233) 0%, rgb(62 178 233) 50%, rgb(62 178 233) 100%)" // Active state gradient
      : "#eaeaf0"; // Default background

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      style={{
        backgroundColor: backgroundColor,
        width: "50px !important",
        height: "32px !important",
      }}
    >
      {iconComponent}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
  isNotProcessed: PropTypes.bool, // Add isNotProcessed prop
};

function TrackOrder(props) {
  const [statusCount, setStatusCount] = useState(0);
  const [trackingData, setTrackingData] = useState(props.details);
  const [count, setCount] = useState(1);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [map, setMap] = useState(/** @type google.maps.Googlemap */(null));
  const [directions, setDirections] = useState("");
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    const lastStatus = trackingData?.docketStatus;
    if (lastStatus === "Trip Ended") {
      setStatusCount(3);
    }

    if (lastStatus === "Trip Started") {
      setStatusCount(2);
    }

    if (lastStatus === "Yet To Start") {
      setStatusCount(1);
    }
  }, [trackingData]);

  const center = {
    lat: trackingData?.trip?.vehicle?.liveVehicle?.latitude || 0,
    lng: trackingData?.trip?.vehicle?.liveVehicle?.longitude || 0,
  };

  return (
    <>
      <Box className="track-desktop">
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Grid container spacing={4}>
            <Grid item xs={6} md={3}>
              <Grid container spacing={2}>
                {trackingData?.comments?.map((comment, index) => (
                  <Grid item key={index} mb={2} xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={2} >
                        <div style={{
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          textAlign: "center",
                          // backgroundImage: "linear-gradient(136deg, rgb(62 178 233) 0%, rgb(62 178 233) 50%, rgb(62 178 233) 100%)",
                          boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
                          backgroundColor: comment?.isNotProcessed ? "#ccc" : "#3fb1e9",
                        }}>
                          {
                            comment.docketStatus.icon == 'CheckCircleIcon' && (
                              <CheckCircleIcon style={{
                                width: "40px",
                                height: "40px",
                                top: '5px',
                                color: "#fff",
                              }} />
                            )
                          }
                          {
                            comment.docketStatus.icon == 'PhoneDisabledIcon' && (
                              <PhoneDisabledIcon style={{
                                width: "40px",
                                height: "40px",
                                top: '5px',
                                color: "#fff",
                              }} />
                            )
                          }

                          {
                            comment.docketStatus.icon == 'CancelIcon' && (
                              <CancelIcon style={{
                                width: "40px",
                                height: "40px",
                                top: '5px',
                                color: "#fff",
                              }} />
                            )
                          }



                          {
                            comment.docketStatus.icon == 'LocalMallIcon' && (
                              <LocalMallIcon style={{
                                width: "40px",
                                height: "40px",
                                top: '5px',
                                color: "#fff",
                              }} />
                            )
                          }

                          {
                            comment.docketStatus.icon == 'PersonOffIcon' && (
                              <PersonOffIcon style={{
                                width: "40px",
                                height: "40px",
                                top: '5px',
                                color: "#fff",
                              }} />
                            )
                          }

                          {
                            comment.docketStatus.icon == 'WarehouseIcon' && (
                              <WarehouseIcon style={{
                                width: "40px",
                                height: "40px",
                                top: '5px',
                                color: "#fff",
                              }} />
                            )
                          }


                          {
                            comment.docketStatus.icon == 'LocalShippingIcon' && (
                              <LocalShippingIcon style={{
                                width: "40px",
                                height: "40px",
                                top: '5px',
                                color: "#fff",
                              }} />
                            )
                          }

                          {
                            comment.docketStatus.icon == 'HistoryIcon' && (
                              <HistoryIcon style={{
                                width: "40px",
                                height: "40px",
                                top: '5px',
                                color: "#fff",
                              }} />
                            )
                          }


                          {
                            comment.docketStatus.icon == 'SouthWestIcon' && (
                              <SouthWestIcon style={{
                                width: "40px",
                                height: "40px",
                                top: '5px',
                                color: "#fff",
                              }} />
                            )
                          }



                        </div>
                      </Grid>
                      <Grid item xs={9} ml={1}>
                        <Typography>
                          {comment.docketStatus.docketStatusName}
                        </Typography>
                        {comment.description && (
                          <Typography variant="body2" color="text.secondary">
                            {comment.description}
                          </Typography>
                        )}
                        {comment.createdAt && (
                          <Typography variant="caption" color="text.secondary">
                            {new Date(comment.createdAt).toLocaleString()}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={6} md={9}>
              <Grid container direction="column" spacing={1}>
                {/* Address Section */}
                <Grid item xs={12}>
                  <b>Delivery Address:</b> <br />
                  {trackingData?.address}
                </Grid>

                {/* Google Map Section */}
                <Grid item xs={12}>
                  {isLoaded && (
                    <GoogleMap
                      zoom={12}
                      center={{
                        lat:
                          trackingData?.trip?.vehicle?.liveVehicle?.latitude ||
                          12.9539974,
                        lng:
                          trackingData?.trip?.vehicle?.liveVehicle?.longitude ||
                          77.6309395,
                      }}
                      mapContainerStyle={{
                        width: "95%",
                        height: "50vh",
                        padding: "1px",
                      }}
                      options={{
                        zoomControl: true,
                        mapTypeId: "roadmap",
                        mapTypeControl: true,
                        fullscreenControl: true,
                        smoothScroll: true, // added smooth scroll
                      }}
                      onLoad={(map) => setMap(map)}
                    >
                      {/* {directions && (
                        <DirectionsRenderer
                          directions={directions}
                          options={{
                            polylineOptions: {
                              zIndex: 50,
                              strokeColor: "#1976D2",
                              strokeWeight: 5,
                            },
                          }}
                        />
                      )} */}
                      {trackingData?.trip?.vehicle?.liveVehicle?.latitude &&
                        trackingData?.trip?.vehicle?.liveVehicle?.longitude && (
                          <Marker
                            position={{
                              lat:
                                trackingData?.trip?.vehicle?.liveVehicle
                                  ?.latitude || 12.9539974,
                              lng:
                                trackingData?.trip?.vehicle?.liveVehicle
                                  ?.longitude || 77.6309395,
                            }}
                            options={{
                              icon: {
                                url: TruckMarker,
                                scaledSize: new window.google.maps.Size(65, 65),
                              },
                            }}
                          />
                        )}
                    </GoogleMap>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      {/* <Box className="track-mobile-view">
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={statusCount}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </Box> */}
    </>
  );
}

export default TrackOrder;
