import React from "react";
import { Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function NontFound() {
  return (
    <Container>
      <img
        src={require("./assets/232.jpg")}
        style={{
          width: "100%",
        }}
      />
      <Link to="/">
        <Typography
          sx={{
            textAlign: "center",
            color: "blue",
            cursor: "pointer",
          }}
        >
          Go To Home Page
        </Typography>
      </Link>
    </Container>
  );
}

export default NontFound;
