import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Card, Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import MUIDataTable from "mui-datatables";
import Dialog from "@mui/material/Dialog";
import OrderDetails from "../OrderDetails/OrderDetails";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import Stack from "@mui/material/Stack";
import CallIcon from "@mui/icons-material/Call";
import CheckIcon from "@mui/icons-material/Check";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MobileOrderHistory from "./MobileOrderHistory";
import "./orderHistory.css";
import Drawer from "@mui/material/Drawer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import AccountCircle from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import TimelineIcon from "@mui/icons-material/Timeline";
import dateFormat from "dateformat";

import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function OrderHistory() {
  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [maxWidth, setMaxWidth] = React.useState("xs");
  const [maxWidthTrack, setMaxWidthTrack] = React.useState("sm");
  const [value, setValue] = useState("1");

  const [openProfile, setOpenProfile] = useState(false);
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [open, setOpen] = useState(false);
  const [openTrack, setOpenTrack] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);

  const [openEditProfile, setOpenEditProfile] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorText, setfirstNameErrorText] = useState("");

  const [lastnameError, setLastNameError] = useState(false);
  const [lastnameErrorText, setlastnameErrorText] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");

  const [enteredOrderID, setEnteredOrderID] = useState("");

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const vertical = "top";
  const horizontal = "center";

  useEffect(() => {
    (async () => {
      await verifyToken();


      await getAllOrders();
      await getData();
      await dunzoAuthentication();
    })();
  }, []);

  const searchOrder = () => {
    const filter = orderData.filter(
      (value) => JSON.parse(value.jdata).orderID === enteredOrderID
    );
    console.log(filter);
    setOrderData(filter);
  };

  const getData = async () => {
    const userDetails = localStorage.getItem("userDetails");
    const parseData = await JSON.parse(userDetails);
    const parseJdata = await JSON.parse(parseData.jdata);

    setUserName(parseData.firstname + " " + parseData.lastname);
    setUserMobile(parseData.mobile);
    setUserEmail(parseJdata.email);
  };

  const verifyToken = async () => {
    const userToken = localStorage.getItem("token");
    console.log(userToken);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    const responseJson = await response.json();
    console.log(responseJson, "verify");
    if (responseJson.success == true) {
      console.log("authenticated");
    } else {
      navigate("/express/user-login");
    }
  };

  const columns = [
    {
      name: "",
      label: "Order ID",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const parseID = JSON.parse(orderData[dataIndex].jdata);
          return <Typography>{parseID.orderID}</Typography>;
        },
      },
    },
    {
      name: "fromAddress",
      label: "From Address",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const parseAddress = JSON.parse(orderData[dataIndex].jdata);

          return (
            <>
              <Typography
                sx={{
                  fontWeight: "600",
                }}
              >
                {parseAddress.senderName}
              </Typography>
              <Typography>
                {parseAddress.senderAddressLine1},{" "}
                {parseAddress.senderAddressLine2}
              </Typography>
            </>
          );
        },
      },
    },
    {
      name: "toAddress",
      label: "To Address",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const parseAddress = JSON.parse(orderData[dataIndex].jdata);

          return (
            <>
              <Typography
                sx={{
                  fontWeight: "600",
                }}
              >
                {parseAddress.receiverName}
              </Typography>
              <Typography>
                {parseAddress.receiverAddressLine1},{" "}
                {parseAddress.receiverAddressLine1}
              </Typography>
            </>
          );
        },
      },
    },
    {
      name: "createddate",
      label: "Date & Time",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Typography>
              {dateFormat(
                orderData[dataIndex].createddate,
                "dd mmm yyyy HH : MM"
              )}
            </Typography>
          );
        },
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                onClick={() =>
                  navigate("/express/order-details", {
                    state: {
                      orderDetails: orderData[dataIndex],
                      pID: orderData[dataIndex].PID,
                    },
                  })
                }
              >
                View
              </Button>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "multiselect",
    selectableRows: "none",
    draggableColumns: { enabled: true },
    pagination: true,
    fixedHeader: false,
  };

  const handleClickOpen = async (order) => {
    console.log(order);
    setOrderDetails(order);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenTrack = () => {
    setOpenTrack(true);
  };

  const handleCloseTrack = () => {
    setOpenTrack(false);
  };

  const getAllOrders = async () => {
    const userDetails = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDetails);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/getAllOrders",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          mobile: parseData.mobile,
        }),
      }
    );

    const responseJson = await response.json();
    if (responseJson.success == true) {
      // console.log(responseJson);
      setOrderData(responseJson.data);
    } else {
      // setErrorMsg("Unable to fetch the orders.");
      // setOpenErrorAlert(true);
    }
  };

  const dunzoAuthentication = async () => {
    const response = await fetch("https://apis-staging.dunzo.in/api/v1/token", {
      headers: {
        "Content-Type": "application/json",
        "client-id": "cb703831-234a-4329-a8b7-f1f897d55046",
        "client-secret": "8b2434c5-1b35-45d5-a0ae-12591ed54e4b",
      },
    });

    const responseJson = await response.json();
    console.log(responseJson.token, "dunzo-auth");
    localStorage.setItem("dunzo-auth", responseJson.token);
  };

  const logOut = async () => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("token");
    window.location.reload();
  };

  const updateProfile = async () => {
    const userDeatils = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDeatils);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          cxID: parseData.cxID,
          mobile: parseData.mobile,
          jdata: {
            FromAddresses: [
              { Description: "", Address: "", Contact01: "", IsDefault: "" },
              { Description: "", Address: "", Contact01: "", IsDefault: "" },
            ],
            ToAddresses: [
              { Description: "", Address: "", Contact01: "" },
              { Description: "", Address: "", Contact01: "" },
            ],
            firstname: firstName,
            lastname: lastname,
            email: email,
          },
        }),
      }
    );

    const responseJson = await response.json();
    if (responseJson.success == true) {
      alert(
        "Profile update successfully. Kindly logout and login again to see the changes."
      );
      setOpenEditProfile(false);
    } else {
      setErrorMsg("Unable to update the profile");
      setOpenErrorAlert(true);
    }
  };

  return (
    <React.Fragment>
      <Box className="desktop-order-history">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            sx={{
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
            }}
          >
            <Toolbar>
              <img
                src={require("./assests/images/logo_new1.png")}
                style={{
                  width: "10%",
                }}
              />

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <IconButton
                  size="large"
                  onClick={() => navigate("/express/create-order")}
                >
                  <Box>
                    <AddIcon />
                    <Typography>Create Pickup</Typography>
                  </Box>
                </IconButton>
                <IconButton
                  size="large"
                  onClick={() => navigate("/express/order-history")}
                >
                  <Box>
                    <TimelineIcon
                      sx={{
                        color: "#2287D9",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "#2287D9",
                      }}
                    >
                      Track Order
                    </Typography>
                  </Box>
                </IconButton>
                <IconButton
                  size="large"
                  edge="end"
                  aria-haspopup="true"
                  onClick={() => setOpenProfile(true)}
                >
                  <Box>
                    <AccountCircle />
                    <Typography>Profile</Typography>
                  </Box>
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
        <Box
          sx={{
            padding: "2%",
          }}
        >
          <Card
            sx={{
              backgroundColor: "#1A8FD1",
              boxShadow: "4px 8px 24px rgba(52, 25, 31, 0.2)",
              borderRadius: "6px",
              padding: "2%",
            }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: "2rem",
                fontWeight: "600",
              }}
            >
              Can’t Find Your Delivery ?
            </Typography>
            <Typography
              gutterBottom
              sx={{
                color: "#FFFFFF",
              }}
            >
              Track your delivery using Tracking ID
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  sx={{
                    marginTop: "2%",
                    backgroundColor: "#fff",
                    borderRadius: 2.2,
                  }}
                  placeholder="Enter Your Docket No."
                  onChange={(e) => setEnteredOrderID(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: "13%",
                  }}
                  onClick={() => searchOrder()}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Card>
          <Box
            sx={{
              marginTop: "2%",
            }}
          >
            {orderData.length != 0 ? (
              <MUIDataTable
                title="Order History"
                data={orderData}
                columns={columns}
                options={options}
              />
            ) : (
              <>
                <img
                  src={require("./assests/images/Empty-pana.png")}
                  style={{
                    width: "20%",
                    display: "block",
                    margin: "auto",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "3rem",
                    textAlign: "center",
                  }}
                >
                  No Orders Found
                </Typography>
              </>
            )}
          </Box>
        </Box>

        <Dialog
          open={open}
          maxWidth={maxWidth}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {orderDetails.length == 0 ? (
            <Typography>No Order Details Hvae Found</Typography>
          ) : (
            <Box
              sx={{
                padding: 2,
                backgroundColor: "#f8f8f8",
              }}
            >
              <Card
                sx={{
                  borderRadius: "8px",
                  padding: "3%",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  marginBottom: "15%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "2%",
                  }}
                >
                  <Box>
                    <Stack direction="row" spacing={2}>
                      <Typography
                        sx={{
                          color: "grey",
                        }}
                      >
                        <CheckCircleRoundedIcon
                          sx={{
                            color: "green",
                          }}
                        />
                      </Typography>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                          }}
                        >
                          Successfully Delivered
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "0.8rem",
                            color: "grey",
                          }}
                        >
                          Delivered by Nagarjun{" "}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                  <Box>
                    <Chip
                      icon={
                        <CallIcon
                          sx={{
                            fontSize: "1rem",
                          }}
                        />
                      }
                      label="Call"
                      color="primary"
                      sx={{
                        padding: "2px",
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    marginTop: "8%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      marginBottom: "2%",
                    }}
                  >
                    Address Details
                  </Typography>
                  <Box
                    sx={{
                      border: ".5px solid #C6C6C6",
                    }}
                  />
                </Box>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    marginTop: "4%",
                  }}
                >
                  <Typography>
                    <ArrowCircleUpRoundedIcon />
                  </Typography>
                  <Box>
                    <Typography>
                      {JSON.parse(orderDetails.jdata).senderName}
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        fontSize: "0.8rem",
                        color: "grey",
                      }}
                    >
                      {JSON.parse(orderDetails.jdata).sendermobileNumber}
                    </Typography>
                    <Typography
                      sx={{
                        color: "grey",
                      }}
                    >
                      {JSON.parse(orderDetails.jdata).senderAddressLine1}
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    marginTop: "4%",
                  }}
                >
                  <Typography>
                    <ArrowCircleDownRoundedIcon />
                  </Typography>
                  <Box>
                    <Typography>
                      {JSON.parse(orderDetails.jdata).receiverName}
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        fontSize: "0.8rem",
                        color: "grey",
                      }}
                    >
                      {JSON.parse(orderDetails.jdata).receivermobileNumber}
                    </Typography>
                    <Typography
                      sx={{
                        color: "grey",
                      }}
                    >
                      {JSON.parse(orderDetails.jdata).receiverAddressLine1}
                    </Typography>
                  </Box>
                </Stack>

                <Box
                  sx={{
                    marginTop: "8%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      marginBottom: "2%",
                    }}
                  >
                    Item Details
                  </Typography>
                  <Box
                    sx={{
                      border: ".5px solid #C6C6C6",
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "2%",
                  }}
                >
                  <Box>
                    <Typography gutterBottom>Item :</Typography>
                    <Typography gutterBottom>Actual Weight :</Typography>
                    <Typography gutterBottom>Value :</Typography>
                    <Typography gutterBottom>Is Fragile :</Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "grey",
                      }}
                      gutterBottom
                    >
                      {JSON.parse(orderDetails.jdata).Description}
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "grey",
                      }}
                    >
                      {JSON.parse(orderDetails.jdata).actualWeight}
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "grey",
                      }}
                    >
                      {JSON.parse(orderDetails.jdata).goodsValue}
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "grey",
                      }}
                    >
                      {JSON.parse(orderDetails.jdata).isFragile == "false"
                        ? "No"
                        : "Yes"}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    marginTop: "8%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      marginBottom: "2%",
                    }}
                  >
                    Other Details
                  </Typography>
                  <Box
                    sx={{
                      border: ".5px solid #C6C6C6",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "2%",
                  }}
                >
                  <Box>
                    <Typography gutterBottom>Payment Type :</Typography>
                    <Typography gutterBottom>Created Date :</Typography>
                    <Typography gutterBottom>Pickup Date :</Typography>
                  </Box>
                  <Box>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "grey",
                      }}
                    >
                      {JSON.parse(orderDetails.jdata).paymentType}
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "grey",
                      }}
                    >
                      {dateFormat(orderDetails.createddate, "dd mmm yyyy")}
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "grey",
                      }}
                    >
                      {dateFormat(orderDetails.pickupdate, "dd mmm yyyy")}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Box>
          )}
        </Dialog>
        <Dialog
          fullWidth
          open={openTrack}
          maxWidth={maxWidthTrack}
          onClose={handleCloseTrack}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box
            sx={{
              padding: "5%",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
              gutterBottom
            >
              ETA : 15 mins
            </Typography>

            <Box>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color="success">
                      <CheckIcon
                        sx={{
                          fontSize: "1rem",
                        }}
                      />
                    </TimelineDot>
                    <TimelineConnector
                      sx={{
                        backgroundColor: "#2e7d32",
                      }}
                    />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      Picked Up
                    </Typography>
                    <Typography
                      sx={{
                        color: "grey",
                      }}
                    >
                      09:00 AM
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color="success">
                      <CheckIcon
                        sx={{
                          fontSize: "1rem",
                        }}
                      />
                    </TimelineDot>
                    <TimelineConnector
                      sx={{
                        backgroundColor: "#2e7d32",
                      }}
                    />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      Reaching To HUB
                    </Typography>
                    <Typography
                      sx={{
                        color: "grey",
                      }}
                    >
                      09:30 AM
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color="success">
                      <CheckIcon
                        sx={{
                          fontSize: "1rem",
                        }}
                      />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      In-Transit
                    </Typography>
                    <Typography
                      sx={{
                        color: "grey",
                      }}
                    >
                      10:30 AM
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot>
                      <CheckIcon
                        sx={{
                          fontSize: "1rem",
                        }}
                      />
                    </TimelineDot>
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      Out for Delivery
                    </Typography>
                    <Typography
                      sx={{
                        color: "grey",
                      }}
                    >
                      -- : --
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Box>
            <Card
              sx={{
                marginTop: "5%",
                padding: "5%",
                borderRadius: 2.6,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt="Remy Sharp"
                      src={require("./assests/images/pexels-andrea-piacquadio-762020.jpg")}
                      sx={{
                        objectFit: "cover",
                      }}
                    />
                    <Box>
                      <Typography
                        sx={{
                          color: "grey",
                        }}
                      >
                        Delivery Agent
                      </Typography>
                      <Typography>Nagarjun</Typography>
                    </Box>
                  </Stack>
                </Box>
                <Box>
                  <Chip
                    icon={<CallIcon />}
                    label="Call"
                    color="primary"
                    sx={{
                      padding: "10px",
                    }}
                  />
                </Box>
              </Box>
            </Card>
          </Box>
        </Dialog>

        <Drawer
          anchor="right"
          open={openProfile}
          onClose={() => setOpenProfile(false)}
          PaperProps={{
            sx: { width: "30%" },
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5%",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "1.2rem",
                    textAlign: "center",
                    marginTop: "3%",
                    fontWeight: "600",
                  }}
                >
                  User Profile
                </Typography>
              </Box>
              <Box>
                <Button onClick={() => setOpenEditProfile(true)}>Edit</Button>
              </Box>
            </Box>

            <Box
              sx={{
                padding: "5%",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                <AccountCircleIcon
                  sx={{
                    fontSize: "8rem",
                    color: "grey",
                  }}
                />
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {userName}
                </Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "5%",
                padding: "5%",
                overflow: "hidden",
              }}
            >
              <Card
                sx={{
                  padding: "3%",
                  marginBottom: "5%",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <Typography
                  sx={{
                    color: "#807d7d",
                  }}
                >
                  {userMobile}
                </Typography>
              </Card>
              <Card
                sx={{
                  padding: "3%",
                  marginBottom: "5%",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <Typography
                  sx={{
                    color: "#807d7d",
                  }}
                >
                  {userEmail}
                </Typography>
              </Card>
              <Card
                sx={{
                  padding: "3%",
                  marginBottom: "5%",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <Typography
                  sx={{
                    color: "#807d7d",
                  }}
                >
                  Manage Address
                </Typography>
              </Card>
              <Card
                sx={{
                  padding: "3%",
                  marginBottom: "5%",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <Typography
                  sx={{
                    color: "#807d7d",
                  }}
                >
                  Customer Support
                </Typography>
              </Card>

              <Button variant="contained" fullWidth onClick={() => logOut()}>
                Log Out
              </Button>
            </Box>
          </Box>
        </Drawer>

        <Drawer
          anchor="right"
          open={openEditProfile}
          onClose={() => setOpenEditProfile(false)}
          PaperProps={{
            sx: { width: "30%" },
          }}
        >
          <Box
            sx={{
              padding: "5%",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              Update Your Details
            </Typography>

            <TextField
              fullWidth
              placeholder="First Name"
              sx={{
                marginTop: "4%",
              }}
              onChange={(e) => {
                setFirstNameError(false);
                setfirstNameErrorText("");
                setFirstName(e.target.value);
              }}
              error={firstNameError}
              helperText={firstNameErrorText}
            />
            <TextField
              fullWidth
              placeholder="Last Name"
              sx={{
                marginTop: "4%",
              }}
              onChange={(e) => {
                setLastNameError(false);
                setlastnameErrorText("");
                setLastName(e.target.value);
              }}
              error={lastnameError}
              helperText={lastnameErrorText}
            />

            <TextField
              fullWidth
              placeholder="Email Address"
              sx={{
                marginTop: "4%",
              }}
              onChange={(e) => {
                setEmailError(false);
                setEmailErrorText("");
                setEmail(e.target.value);
              }}
              error={emailError}
              helperText={emailErrorText}
            />

            <Button
              fullWidth
              variant="contained"
              sx={{
                marginTop: "5%",
              }}
              onClick={async () => {
                if (firstName == "") {
                  setFirstNameError(true);
                  setfirstNameErrorText("Please provide the first name");
                } else if (lastname == "") {
                  setLastNameError(true);
                  setlastnameErrorText("Please provide the last name");
                } else if (email == "") {
                  setEmailError(true);
                  setEmailErrorText("Please provide the email address");
                } else {
                  await updateProfile();
                }
              }}
            >
              Update Details
            </Button>
          </Box>
        </Drawer>
      </Box>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={() => setOpenErrorAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Box className="mobile-order-history">
        <MobileOrderHistory />
      </Box>
    </React.Fragment>
  );
}

export default OrderHistory;
