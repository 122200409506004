import React, { Fragment, useState, useEffect } from "react";
import {
  Typography,
  Box,
  Stack,
  Avatar,
  Card,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { useNavigate } from "react-router-dom";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function MobileOrderHistory() {
  const navigate = useNavigate();

  const [value, setValue] = useState("1");
  const [valueBottom, setValueBottom] = useState(0);
  const [userName, setUserName] = useState("");
  const [allOrders, setAllOrders] = useState([]);
  const [contacts, setContacts] = useState("");
  const [loading, setLoading] = useState(false);

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const vertical = "top";
  const horizontal = "center";


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeBottom = (event, newValue) => {
    setValueBottom(newValue);
    if (newValue === 1) navigate("/express/create-order");
    if (newValue === 2) navigate("/express/user-profile");
  };

  useEffect(() => {
    (async () => {
      await verifyToken();
      await getAllOrders();
      await getData();
    })();
  }, []);

  const verifyToken = async () => {
    const userToken = localStorage.getItem("token");
    console.log(userToken);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    const responseJson = await response.json();
    console.log(responseJson, "verify");
    if (responseJson.success == true) {
      console.log("authenticated");
    } else {
      navigate("/express/user-login");
    }
  };

  const getData = async () => {
    const userDetails = localStorage.getItem("userDetails");
    const parseData = await JSON.parse(userDetails);
    setUserName(parseData.firstname + " " + parseData.lastname);
    console.log(parseData);
  };

  const getAllOrders = async () => {
    setLoading(true);
    const userDetails = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDetails);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/getAllOrders",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          mobile: parseData.mobile,
        }),
      }
    );

    const responseJson = await response.json();
    console.log('test');
    if (responseJson.success == true) {
      const reverseData = await responseJson.data.reverse();
      setAllOrders(reverseData);
      setLoading(false);
    } else {
      // setErrorMsg("unable to find your orders.");
      // setOpenErrorAlert(true)
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Box>
        <Box
          sx={{
            padding: "5%",
            marginTop: "3%",
          }}
        >
          <Stack direction="row" spacing={2}>
            <Box>
              <AccountCircleIcon
                sx={{
                  fontSize: "3rem",
                  color: "grey",
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#212121",
                  fontWeight: "600",
                  fontSize: "1.3rem",
                }}
              >
                Hi, {!userName ? "User" : userName}
              </Typography>
              <Typography
                sx={{
                  color: "#828282",
                  fontSize: "0.8rem",
                }}
              >
                Welcome for LC Express
              </Typography>
            </Box>
          </Stack>
          <Box
            sx={{
              marginTop: "3%",
            }}
            onClick={() => navigate("/express/create-order")}
          >
            <img
              src={require("./assests/images/Mobile_banner.png")}
              style={{
                width: "100%",
              }}
            />
          </Box>
        </Box>
        {loading == true ? (
          <Box
            sx={{
              padding: "5%",
              alignItems: "center",
              marginTop: "10%",
            }}
          >
            <CircularProgress
              sx={{
                display: "block",
                margin: "auto",
              }}
            />
            <Typography
              sx={{
                textAlign: "center",
                marginTop: "5%",
              }}
            >
              Getting Data...
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              padding: "5%",
              marginBottom: "30%",
            }}
          >
            {allOrders.length == 0 ? (
              <>
                <img
                  src={require("./assests/images/Empty-pana.png")}
                  style={{
                    width: "60%",
                    display: "block",
                    margin: "auto",
                  }}
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  No Orders Found
                </Typography>
              </>
            ) : (
              <>
                {allOrders.length > 5 ? (
                  <>
                    {allOrders.splice(0, 5).map((order, index) => (
                      <Card
                        sx={{
                          backgroundColor: "#FFFFFF",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          borderRadius: "6px",
                          padding: "3%",
                          marginBottom: "2%",
                        }}
                        onClick={() =>
                          navigate("/express/order-details", {
                            state: {
                              orderDetails: order,
                              pID: order.PID,
                            },
                          })
                        }
                        key={index}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <Stack direction="row" spacing={2}>
                              <Typography>
                                <HourglassBottomIcon
                                  sx={{
                                    color: "#1A8FD1",
                                    fontSize: "2rem",
                                  }}
                                />
                              </Typography>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: "600",
                                  }}
                                >
                                  {JSON.parse(order.jdata).Description}
                                </Typography>

                                <Typography
                                  sx={{
                                    color: "grey",
                                    fontSize: "0.7rem",
                                  }}
                                >
                                  {JSON.parse(order.jdata).receiverName},{" "}
                                  {JSON.parse(order.jdata).receivermobileNumber}
                                </Typography>
                              </Box>
                            </Stack>
                          </Box>
                          <Box>
                            <Typography>
                              <ArrowRightIcon
                                sx={{
                                  fontSize: "2.3rem",
                                }}
                              />
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    ))}
                  </>
                ) : (
                  <>
                    {allOrders.map((order, index) => (
                      <Card
                        sx={{
                          backgroundColor: "#FFFFFF",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          borderRadius: "6px",
                          padding: "3%",
                          marginBottom: "2%",
                        }}
                        onClick={() =>
                          navigate("/express/order-details", {
                            state: {
                              orderDetails: order,
                              pID: order.PID,
                            },
                          })
                        }
                        key={index}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <Stack direction="row" spacing={2}>
                              <Typography>
                                <HourglassBottomIcon
                                  sx={{
                                    color: "#1A8FD1",
                                    fontSize: "2rem",
                                  }}
                                />
                              </Typography>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: "600",
                                  }}
                                >
                                  {JSON.parse(order.jdata).Description}
                                </Typography>

                                <Typography
                                  sx={{
                                    color: "grey",
                                    fontSize: "0.7rem",
                                  }}
                                >
                                  {JSON.parse(order.jdata).receiverName},{" "}
                                  {JSON.parse(order.jdata).receivermobileNumber}
                                </Typography>
                              </Box>
                            </Stack>
                          </Box>
                          <Box>
                            <Typography>
                              <ArrowRightIcon
                                sx={{
                                  fontSize: "2.3rem",
                                }}
                              />
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    ))}
                  </>
                )}

                <Box
                  sx={{
                    marginTop: "5%",
                  }}
                >
                  <Button
                    sx={{
                      display: "block",
                      margin: "auto",
                    }}
                    onClick={() => navigate("/express/all-orders")}
                  >
                    View All Orders
                  </Button>
                </Box>
              </>
            )}
          </Box>
        )}

        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 20,
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <BottomNavigation
            showLabels
            value={valueBottom}
            onChange={handleChangeBottom}
          >
            <BottomNavigationAction label="Home" icon={<HomeRoundedIcon />} />
            <BottomNavigationAction
              label="Create Order"
              icon={<ControlPointRoundedIcon />}
            />
            <BottomNavigationAction
              label="Profile"
              icon={<Person2RoundedIcon />}
            />
          </BottomNavigation>
        </Box>
      </Box>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={() => setOpenErrorAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export default MobileOrderHistory;
