import React, { useEffect, useState, useWindowDimensions } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Card, Grid, Modal } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import CreateOrderMobile from "./CreateOrderMobile";
import "./create.css";

import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from "@mui/icons-material/Inventory";
import SummarizeIcon from "@mui/icons-material/Summarize";
import backImage from "./assests/images/bgImage.png";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import MyLocationIcon from "@mui/icons-material/MyLocation";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import SuccessImage from "./assests/images/96237-success.gif";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

import Drawer from "@mui/material/Drawer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import AccountCircle from "@mui/icons-material/AccountCircle";

import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import AddIcon from "@mui/icons-material/Add";
import TimelineIcon from "@mui/icons-material/Timeline";
import { v4 as uuid } from "uuid";

import CancelIcon from "@mui/icons-material/Cancel";
import MyLocationRoundedIcon from "@mui/icons-material/MyLocationRounded";
import dateFormat from "dateformat";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import Geocode from "react-geocode";
import {
  GoogleMap,
  LoadScript,
  MarkerF,
  useLoadScript,
  DistanceMatrixService,
} from "@react-google-maps/api";
import PointConfetti from "../Animation/PointConfetti";



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "background.paper",
  border: "0.5px solid #000",
  boxShadow: 24,
  p: 4,
};

function CreateOrder(props) {
  const navigate = useNavigate();
  const [showMain, setShowMain] = useState(true);
  const [value, setValue] = useState("1");
  const [anchorElUser, setAnchorElUser] = useState(null);

  // const { height, width } = useWindowDimensions();
  // console.log(window.innerHeight, window.innerWidth);

  const [senderName, setSenderName] = useState("");
  const [senderAddressLine1, setSenderAddressLine1] = useState("");
  const [senderAddressLine2, setSenderAddressLine2] = useState("");
  const [sendermobileNumber, setSendermobileNumber] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverAddressLine1, setReceiverAddressLine1] = useState("");
  const [receiverAddressLine2, setReceiverAddressLine2] = useState("");
  const [receivermobileNumber, setReceivermobileNumber] = useState("");
  const [description, setDescription] = useState("");
  const [actualWeight, setActualWeight] = useState("");
  const [isFragile, setIsFragile] = useState(false);
  const [goodsValue, setGoodsValue] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [mobile, setMobile] = useState("");

  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  const [pickUpAddress, setPickUpAddress] = useState("");
  const [deliveryAddress, setdeliveryAddress] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [time, setTime] = useState("");

  const [packageSize, setPackageSize] = useState("");
  const [smallBorder, setSmallBorder] = useState("0.5px solid #DDDDDD");
  const [mediumBorder, setMediumBorder] = useState("0.5px solid #DDDDDD");
  const [largeBorder, setLargeBorder] = useState("0.5px solid #DDDDDD");
  const [bulkyBorder, setBulkyBorder] = useState("0.5px solid #DDDDDD");

  const [selectedDate, setSelectedDate] = useState("");
  const [date1Border, setDate1Border] = useState("0.5px solid #DDDDDD");
  const [date2Border, setDate2Border] = useState("0.5px solid #DDDDDD");
  const [date3Border, setDate3Border] = useState("0.5px solid #DDDDDD");
  const [date4Border, setDate4Border] = useState("0.5px solid #DDDDDD");

  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [pickupCoords, setPickupCoords] = useState("");
  const [deliveryCoords, setDeliveryCoords] = useState("");

  const [openFromAddress, setOpenFromAddress] = useState(false);
  const [openToAddress, setOpenToAddress] = useState(false);

  const [userAddress, setUserAddress] = useState([]);

  const [senderError, setSenderError] = useState(false);
  const [senderErrorText, setSenderErrorText] = useState("");
  const [receiverError, setReceiverError] = useState(false);
  const [receiverErrorTex, setReceiverErrorText] = useState("");

  const [packageSizeError, setPackageSizeError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [descriptionErrorText, setDescriptionErrorText] = useState("");
  const [goodsValueError, setGoodsValueError] = useState(false);
  const [goodsValueErrorText, setGoodsValueErrorText] = useState("");
  const [schPickError, setSchPickError] = useState(false);
  const [timeError, setTimeError] = useState(false);

  const [openEditProfile, setOpenEditProfile] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorText, setfirstNameErrorText] = useState("");

  const [lastnameError, setLastNameError] = useState(false);
  const [lastnameErrorText, setlastnameErrorText] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [amountPaid, setAmountPaid] = useState("");

  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [distanceOriginLat, setDistanceOriginLat] = useState(0);
  const [distanceOriginLng, setDistanceOriginLng] = useState(0);
  const [distanceDestLat, setDistanceDestLat] = useState(12.9889077);
  const [distanceDestLng, setDistanceDestLng] = useState(77.6869043);
  const [totalDistance, setTotalDistance] = useState("");
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [senderLandmark, setSenderLandmark] = useState("");
  const [senderCity, setSenderCity] = useState("");
  const [senderState, setSenderState] = useState("");
  const [senderPincode, setSenderPincode] = useState("");

  const [senderHouseAddress, setSenderHouseAddress] = useState("");

  const [receiverLandmark, setRecevierLandmark] = useState("");
  const [receiverCity, setRecevierCity] = useState("");
  const [receiverState, setRecevierState] = useState("");
  const [receiverPincode, setRecevierPincode] = useState("");
  const [receiverHouseAddress, setRecevierHouseAddress] = useState("");

  const [openDrawerCoupons, setOpenDrawerCoupons] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [showCelebrate, setShowCelebrate] = useState("none");

  const [allSizes, setAllSizes] = useState([]);
  const [ssID, setssID] = useState(0);
  const [allCoupons, setAllCoupons] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [showUper, setShowUper] = useState("none");
  const [showPaymentTypeError, setShowPaymentTypeError] = useState("none");

  const [completeAddress, setCompleteAdress] = useState("");
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [progress, setProgress] = useState(0);

  const maxWidth = "md";

  useEffect(() => {
    (async () => {
      await position();
      await verifyToken();
      await getData();
      await getAddress();
      await getCoupons();
    })();
  }, []);

  const getOrigin = async (coords) => {
    const originCoordinates = coords.split(",");
    console.log(originCoordinates);
    setDistanceOriginLat(Number(originCoordinates[0]));
    setDistanceOriginLng(Number(originCoordinates[1]));
  };

  const getDestination = async (coords) => {
    const destinationCoordinates = coords.split(",");
    setDistanceDestLat(Number(destinationCoordinates[0]));
    setDistanceDestLng(Number(destinationCoordinates[1]));
  };

  const getData = async () => {
    const userDetails = localStorage.getItem("userDetails");
    const parseData = await JSON.parse(userDetails);
    const parseJdata = await JSON.parse(parseData.jdata);
    setSenderName(parseData.firstname + " " + parseData.lastname);
    setMobile(parseData.mobile);
    setSendermobileNumber(parseData.mobile);
    setUserName(parseData.firstname + " " + parseData.lastname);
    setUserMobile(parseData.mobile);
    setUserEmail(parseJdata.email);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const verifyToken = async () => {
    const userToken = localStorage.getItem("token");
    console.log(userToken);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    const responseJson = await response.json();
    if (responseJson.success == true) {
      console.log("authenticated");
    } else {
      navigate("/express/user-login");
    }
  };

  const date = new Date();

  const date2 = moment().add(1, "days");
  const date3 = moment().add(2, "days");
  const date4 = moment().add(3, "days");

  const getAddress = async () => {
    const userToken = localStorage.getItem("token");
    const userDetails = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDetails);
    console.log(parseData.mobile);
    const response = await fetch("https://ndd.logicarts.in/pickup/getAddress", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${userToken}`,
      },

      body: JSON.stringify({
        cxID: `${parseData.cxID}`,
      }),
    });

    const responseJson = await response.json();
    const filterData = await responseJson.data.filter(
      (data) => data.isactive === true
    );
    const revereseData = filterData.reverse();
    setUserAddress(revereseData);

    if (!localStorage.getItem("isFromMap")) {
      setReceiverName("");
      setReceiverAddressLine1("");
      setReceivermobileNumber("");
      setDeliveryCoords("");
      setRecevierHouseAddress("");
      setRecevierLandmark("");
      setRecevierCity("");
      setRecevierState("");
      setRecevierPincode("");
    } else {
      setReceiverName(JSON.parse(revereseData[0].jdata).Name);
      setReceiverAddressLine1(
        `${JSON.parse(revereseData[0].jdata).Building},${
          JSON.parse(revereseData[0].jdata).Address
        }`
      );
      setReceivermobileNumber(JSON.parse(revereseData[0].jdata).Mobile);
      setDeliveryCoords(JSON.parse(revereseData[0].jdata).Coordinates);
      setRecevierHouseAddress(JSON.parse(revereseData[0].jdata).Building);
      setRecevierLandmark(JSON.parse(revereseData[0].jdata).Landmark);
      setRecevierCity(JSON.parse(revereseData[0].jdata).City);
      setRecevierState(JSON.parse(revereseData[0].jdata).State);
      setRecevierPincode(JSON.parse(revereseData[0].jdata).Pincode);
      await getDestination(JSON.parse(revereseData[0].jdata).Coordinates);
    }
  };

  const createOrder = async () => {
    setBtnDisabled(true);
    setLoading(true);
    const unique_id = uuid();
    const small_id = unique_id.slice(0, 8);
    const userToken = localStorage.getItem("token");
    const pickupCoordinates = pickupCoords.split(",");
    const deliveryCoordinates = deliveryCoords.split(",");

    const response = await fetch("https://ndd.logicarts.in/pickup/create", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${userToken}`,
      },

      body: JSON.stringify({
        pID: "",
        pickupdate: date.toISOString(),
        jdata: {
          orderID: `EPL${small_id}`,
          senderName: senderName,
          senderHouseAddress: senderHouseAddress,
          senderAddressLine1: senderAddressLine1,
          senderAddressLine2: senderAddressLine2,
          senderLandmark: senderLandmark,
          senderCity: senderCity,
          senderState: senderState,
          senderPincode: senderPincode,
          sendermobileNumber: sendermobileNumber,
          senderLat: Number(pickupCoordinates[0]),
          senderLng: Number(pickupCoordinates[1]),
          senderNote: isFragile == true ? "Fragile item, handle with care" : "",
          invoiceNumber: invoiceNumber,

          receiverName: receiverName,
          receiverHouseAddress: receiverHouseAddress,
          receiverAddressLine1: receiverAddressLine1,
          receiverAddressLine2: receiverAddressLine2,
          receiverLandmark: receiverLandmark,
          receiverCity: receiverCity,
          receiverState: receiverState,
          receiverPincode: receiverPincode,
          receivermobileNumber: receivermobileNumber,
          receiverLat: Number(deliveryCoordinates[0]),
          receiverLng: Number(deliveryCoordinates[1]),
          receiverNote:
            isFragile == true ? "Fragile item, handle with care" : "",

          paymentType: paymentType,
          goodsValue: goodsValue,

          Description: description,
          actualWeight: packageSize,
          mobile: mobile,
          isFragile: `${isFragile}`,

          deliveryAent: {},
          is_deliveryAgent: "0",

          paymentStatus: "pending",
          scheduleTime: time,
          scheduleDate: selectedDate,
          couponCode: couponCode,

          totalDistance: totalDistance,
          cxAmountPaid: amountPaid,
          images: [],
          amountToPay: `${amountPaid}00`,
          status: "Cx Amount Not Ready",
        },
      }),
    });

    const responseJson = await response.json();
    console.log(responseJson.data);
    if (responseJson.success == true) {
      setBtnDisabled(false);
      setLoading(false);
      if (paymentType == "Online") {
        navigate("/express/payment", {
          state: {
            paymentID: responseJson.data.id,
            pID: responseJson.data.pID,
            pickupdate: date.toISOString(),
            orderID: `EPL${small_id}`,
            senderName: senderName,
            senderHouseAddress: senderHouseAddress,
            senderAddressLine1: senderAddressLine1,
            senderAddressLine2: senderAddressLine2,
            senderLandmark: senderLandmark,
            senderCity: senderCity,
            senderState: senderState,
            senderPincode: senderPincode,
            sendermobileNumber: sendermobileNumber,
            senderLat: Number(pickupCoordinates[0]),
            senderLng: Number(pickupCoordinates[1]),
            senderNote:
              isFragile == true ? "Fragile item, handle with care" : "",
            invoiceNumber: invoiceNumber,

            receiverName: receiverName,
            receiverHouseAddress: receiverHouseAddress,
            receiverAddressLine1: receiverAddressLine1,
            receiverAddressLine2: receiverAddressLine2,
            receiverLandmark: receiverLandmark,
            receiverCity: receiverCity,
            receiverState: receiverState,
            receiverPincode: receiverPincode,
            receivermobileNumber: receivermobileNumber,
            receiverLat: Number(deliveryCoordinates[0]),
            receiverLng: Number(deliveryCoordinates[1]),
            receiverNote:
              isFragile == true ? "Fragile item, handle with care" : "",

            paymentType: paymentType,
            goodsValue: goodsValue,

            Description: description,
            actualWeight: packageSize,
            mobile: mobile,
            isFragile: `${isFragile}`,

            couponCode: couponCode,

            deliveryAent: {},
            is_deliveryAgent: "0",

            paymentStatus: "pending",
            scheduleTime: time,
            scheduleDate: selectedDate,
            cxAmountPaid: amountPaid,
            totalDistance: totalDistance,
            images: [],
            amountToPay: `${amountPaid}00`,
            status: "Cx Amount Not Ready",
          },
        });
      } else {
        navigate("/express/order-success");
      }
    } else {
      setErrorMsg("Error in creating order.");
      setOpenErrorAlert(true);
      setBtnDisabled(false);
      setLoading(false);
    }
  };

  const dunzogetPrice = async () => {
    setBtnDisabled(true);
    setLoading(true);
    const pickupCoordinates = pickupCoords.split(",");
    const deliveryCoordinates = deliveryCoords.split(",");
    console.log(paymentType);

    const response = await fetch("https://ndd.logicarts.in/dunzo/getQuote", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },

      body: JSON.stringify({
        jdata: {
          pickup_details: [
            {
              lat: Number(pickupCoordinates[0]),
              lng: Number(pickupCoordinates[1]),
              reference_id: "pickup-ref",
            },
          ],
          optimised_route: true,
          drop_details: [
            {
              lat: Number(deliveryCoordinates[0]),
              lng: Number(deliveryCoordinates[1]),
              reference_id: "drop-ref1",
            },
          ],
        },
      }),
    });

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.success == true) {
      await createOrder();
    } else {
      setBtnDisabled(false);
      setLoading(false);
      setErrorMsg("unable to create order");
      setOpenErrorAlert(true)
    }
  };
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#2287D9",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#2287D9",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#2287D9",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#2287D9",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <HomeIcon />,
      2: <InventoryIcon />,
      3: <SummarizeIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const steps = ["Address", "Package", "Summary"];

  const handleChange = (event) => {
    setTime(event.target.value);
  };

  const logOut = async () => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("token");
    window.location.reload();
  };

  const deleteAddress = async (id) => {
    const userDetails = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDetails);

    console.log(id);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/manageAddress",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          cxID: `${parseData.cxID}`,
          cxAdID: id,
          jdata: {
            Description: "",
            Address: "",
            Pincode: "",
            isDefault: "",
            categoryID: "9",
          },
        }),
      }
    );

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.success == true) {
      await getAddress();
    } else {
      setErrorMsg("Unable to delete your address please try after sometime.");
      setOpenErrorAlert(true);
    }
  };

  const shipmentSizeDropDown = async () => {
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/shipmentSizeDropDown",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          jdata: {},
        }),
      }
    );

    const responseJson = await response.json();
    if (responseJson.success == true) {
      setAllSizes(responseJson.data);
    } else {
      setErrorMsg("Unable to get the sizes");
      setOpenErrorAlert(true);
    }
  };

  const ShipmentsizeNpricing = async (code, id) => {
    const replaceKM = totalDistance.replace("km", "");
    console.log(replaceKM);
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/ShipmentsizeNpricing",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          jdata: {
            couponCode: code == 0 ? "" : code,
            distance: replaceKM,
            ssID: !id ? ssID : id,
            senderPincode: senderPincode,
            receiverPincode: receiverPincode,
          },
        }),
      }
    );

    const responseJson = await response.json();
    if (responseJson.success == true) {
      if (ssID == 1 || id == 1) {
        setAmountPaid(responseJson.data[0].price);
      } else if (ssID == 2 || id == 2) {
        setAmountPaid(responseJson.data[1].price);
      } else if (ssID == 3 || id == 3) {
        setAmountPaid(responseJson.data[2].price);
      } else {
        setAmountPaid(responseJson.data[3].price);
      }
    }
    console.log(responseJson);
  };

  const updateProfile = async () => {
    const userDeatils = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDeatils);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          cxID: parseData.cxID,
          mobile: parseData.mobile,
          jdata: {
            FromAddresses: [
              { Description: "", Address: "", Contact01: "", IsDefault: "" },
              { Description: "", Address: "", Contact01: "", IsDefault: "" },
            ],
            ToAddresses: [
              { Description: "", Address: "", Contact01: "" },
              { Description: "", Address: "", Contact01: "" },
            ],
            firstname: firstName,
            lastname: lastname,
            email: email,
          },
        }),
      }
    );

    const responseJson = await response.json();
    if (responseJson.success == true) {
      alert(
        "Profile update successfully. Kindly logout and login again to see the changes."
      );
      setOpenEditProfile(false);
    } else {
      setErrorMsg("Unable to update the profile");
      setOpenErrorAlert(true)
    }
  };


  const vertical = "top";
  const horizontal = "center";

  
  const stepperUI = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <Card
            sx={{
              width: "50%",
              display: "block",
              margin: "auto",
              boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.1)",
              borderRadius: "16px",
              padding: "2%",
            }}
          >
            <Typography
              gutterBottom
              sx={{
                fontWeight: "700",
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              Address Details
            </Typography>
            <Box
              sx={{
                border: ".5px solid #C6C6C6",
              }}
            />

            <Box
              sx={{
                marginTop: "7%",
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  color: "#646262",
                }}
              >
                Add Pickup Details
              </Typography>
              <TextField
                fullWidth
                placeholder="Select pickup Address"
                sx={{
                  border: "0.5px solid #DDDDDD",
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                  borderRadius: "4px",
                }}
                onClick={() => {
                  setSenderError(false);
                  setSenderErrorText("");
                  setOpenFromAddress(true);
                }}
                value={senderAddressLine1}
                defaultValue={senderAddressLine1}
                error={senderError}
                helperText={senderErrorText}
              />
              <Typography
                gutterBottom
                sx={{
                  color: "#646262",
                  marginTop: "5%",
                }}
              >
                Add Delivery Details
              </Typography>
              <TextField
                fullWidth
                placeholder="Select delivery Address"
                sx={{
                  border: "0.5px solid #DDDDDD",
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                  borderRadius: "4px",
                }}
                onClick={() => {
                  setReceiverError(false);
                  setReceiverErrorText("");
                  setOpenToAddress(true);
                }}
                value={receiverAddressLine1}
                defaultValue={receiverAddressLine1}
                error={receiverError}
                helperText={receiverErrorTex}
              />

              <Button
                variant="contained"
                sx={{
                  display: "block",
                  margin: "auto",
                  backgroundColor: "#669AFF",
                  marginTop: "5%",
                }}
                onClick={async () => {
                  if (senderAddressLine1 == "") {
                    setSenderError(true);
                    setSenderErrorText("Please provider the details");
                  } else if (receiverAddressLine1 == "") {
                    setReceiverError(true);
                    setReceiverErrorText("Please provider the details");
                  } else {
                    await shipmentSizeDropDown();
                    setActiveStep(1);
                  }
                }}
              >
                Next
              </Button>
            </Box>
          </Card>
        );
      case 1:
        return (
          <Card
            sx={{
              width: "50%",
              display: "block",
              margin: "auto",
              boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.1)",
              borderRadius: "16px",
              padding: "2%",
            }}
          >
            <Typography
              gutterBottom
              sx={{
                fontWeight: "700",
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              Package Details
            </Typography>
            <Box
              sx={{
                border: ".5px solid #C6C6C6",
              }}
            />
            <Typography
              gutterBottom
              sx={{
                marginTop: "5%",
              }}
            >
              Package Size
            </Typography>
            <Stack direction="row" spacing={6}>
              <Box>
                <Card
                  sx={{
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                    padding: "8%",
                    border: smallBorder,
                    width: "100%",
                  }}
                  onClick={async () => {
                    setPackageSize(
                      `${allSizes[3].description} ${allSizes[3].sWeight}-${allSizes[3].eWeight} Kgs`
                    );
                    setSmallBorder("1px solid #2287D9");
                    setMediumBorder("0.5px solid #DDDDDD");
                    setLargeBorder("0.5px solid #DDDDDD");
                    setBulkyBorder("0.5px solid #DDDDDD");
                    setssID(allSizes[3].ssID);
                    await ShipmentsizeNpricing(0, allSizes[3].ssID);
                  }}
                >
                  <img
                    src={allSizes[3].sizeURL}
                    style={{
                      display: "block",
                      margin: "auto",
                      width: "100%",
                    }}
                  />
                </Card>
                <Typography
                  sx={{
                    color: "#9A9A9A",
                    fontSize: "0.8rem",
                    textAlign: "center",
                  }}
                >
                  {allSizes[3].description} ( {allSizes[3].sWeight} -{" "}
                  {allSizes[3].eWeight} kgs )
                </Typography>
              </Box>
              <Box>
                <Card
                  sx={{
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                    padding: "10%",
                    border: mediumBorder,
                  }}
                  onClick={async () => {
                    setPackageSize(
                      `${allSizes[2].description} ${allSizes[2].sWeight}-${allSizes[2].eWeight}Kgs`
                    );
                    setSmallBorder("0.5px solid #DDDDDD");
                    setMediumBorder("1px solid #2287D9");
                    setLargeBorder("0.5px solid #DDDDDD");
                    setBulkyBorder("0.5px solid #DDDDDD");
                    setssID(allSizes[2].ssID);
                    await ShipmentsizeNpricing(0, allSizes[2].ssID);
                  }}
                >
                  <img
                    src={allSizes[2].sizeURL}
                    style={{
                      display: "block",
                      margin: "auto",
                      width: "100%",
                    }}
                  />
                </Card>
                <Typography
                  sx={{
                    color: "#9A9A9A",
                    fontSize: "0.8rem",
                    textAlign: "center",
                  }}
                >
                  {allSizes[2].description} ( {allSizes[2].sWeight} -{" "}
                  {allSizes[2].eWeight} kgs )
                </Typography>
              </Box>
              <Box>
                <Card
                  sx={{
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                    padding: "10%",
                    border: largeBorder,
                  }}
                  onClick={async () => {
                    setPackageSize(
                      `${allSizes[1].description} ${allSizes[1].sWeight}-${allSizes[1].eWeight}Kgs`
                    );
                    setSmallBorder("0.5px solid #DDDDDD");
                    setMediumBorder("0.5px solid #DDDDDD");
                    setLargeBorder("1px solid #2287D9");
                    setBulkyBorder("0.5px solid #DDDDDD");
                    setssID(allSizes[1].ssID);
                    await ShipmentsizeNpricing(0, allSizes[1].ssID);
                  }}
                >
                  <img
                    src={allSizes[1].sizeURL}
                    style={{
                      display: "block",
                      margin: "auto",
                      width: "100%",
                    }}
                  />
                </Card>
                <Typography
                  sx={{
                    color: "#9A9A9A",
                    fontSize: "0.8rem",
                    textAlign: "center",
                  }}
                >
                  {allSizes[1].description} ( {allSizes[1].sWeight} -{" "}
                  {allSizes[1].eWeight} kgs )
                </Typography>
              </Box>
              <Box>
                <Card
                  sx={{
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                    padding: "10%",
                    border: bulkyBorder,
                  }}
                  onClick={async () => {
                    setPackageSize(
                      `${allSizes[0].description} ${allSizes[0].sWeight}-${allSizes[0].eWeight}Kgs`
                    );
                    setSmallBorder("0.5px solid #DDDDDD");
                    setMediumBorder("0.5px solid #DDDDDD");
                    setLargeBorder("0.5px solid #DDDDDD");
                    setBulkyBorder("1px solid #2287D9");
                    setssID(allSizes[0].ssID);
                    await ShipmentsizeNpricing(0, allSizes[0].ssID);
                  }}
                >
                  <img
                    src={allSizes[0].sizeURL}
                    style={{
                      display: "block",
                      margin: "auto",
                      width: "100%",
                    }}
                  />
                </Card>
                <Typography
                  sx={{
                    color: "#9A9A9A",
                    fontSize: "0.8rem",
                    textAlign: "center",
                  }}
                >
                  {allSizes[0].description} ( {allSizes[0].sWeight} -{" "}
                  {allSizes[0].eWeight} kgs )
                </Typography>
              </Box>
            </Stack>
            <Typography
              sx={{
                fontSize: "0.8rem",
                textAlign: "center",
                color: "red",
                display: packageSizeError === true ? "block" : "none",
              }}
            >
              Please Choose the Package size
            </Typography>
            <Typography gutterBottom>Package Contents</Typography>
            <TextField
              fullWidth
              placeholder="Enter Item Details"
              sx={{
                border: "0.5px solid #DDDDDD",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                borderRadius: "4px",
              }}
              onChange={(e) => {
                setDescriptionError(false);
                setDescriptionErrorText("");
                setDescription(e.target.value);
              }}
              error={descriptionError}
              helperText={descriptionErrorText}
            />

            <Typography
              gutterBottom
              sx={{
                marginTop: "3%",
              }}
            >
              Package Value
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter Item Details"
              sx={{
                border: "0.5px solid #DDDDDD",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                borderRadius: "4px",
              }}
              onChange={(e) => {
                setGoodsValueError(false);
                setGoodsValueErrorText("");
                setGoodsValue(e.target.value);
              }}
              error={goodsValueError}
              helperText={goodsValueErrorText}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="IS FRAGILE"
              sx={{
                marginBottom: "2%",
                marginTop: "1%",
              }}
              onChange={(event) => setIsFragile(event.target.checked)}
            />
            <Typography
              gutterBottom
              sx={{
                marginTop: "3%",
                fontWeight: "600",
              }}
            >
              Schedule Pick up
            </Typography>
            <Box
              sx={{
                border: ".5px solid #C6C6C6",
              }}
            />
            <Stack
              direction="row"
              spacing={2}
              sx={{
                marginTop: "2%",
              }}
            >
              <Card
                sx={{
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                  padding: "3%",
                  border: date1Border,
                }}
                onClick={() => {
                  setSelectedDate(dateFormat(date, "dd mmm yyyy"));
                  setDate1Border("1px solid #2287D9");
                  setDate2Border("0.5px solid #DDDDDD");
                  setDate3Border("0.5px solid #DDDDDD");
                  setDate4Border("0.5px solid #DDDDDD");
                }}
              >
                <Typography
                  sx={{
                    fontSize: "2rem",
                    color: "#9A9A9A",
                    textAlign: "center",
                  }}
                >
                  {dateFormat(date, "dd")}
                </Typography>
                <Typography
                  sx={{
                    color: "#9A9A9A",
                    textAlign: "center",
                  }}
                >
                  {dateFormat(date, "mmm yyyy")}
                </Typography>
              </Card>
              <Card
                sx={{
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                  padding: "3%",
                  border: date2Border,
                }}
                onClick={() => {
                  setSelectedDate(dateFormat(date2._d, "dd mmm yyyy"));
                  setDate1Border("0.5px solid #DDDDDD");
                  setDate2Border("1px solid #2287D9");
                  setDate3Border("0.5px solid #DDDDDD");
                  setDate4Border("0.5px solid #DDDDDD");
                }}
              >
                <Typography
                  sx={{
                    fontSize: "2rem",
                    color: "#9A9A9A",
                    textAlign: "center",
                  }}
                >
                  {dateFormat(date2._d, "dd")}
                </Typography>
                <Typography
                  sx={{
                    color: "#9A9A9A",
                    textAlign: "center",
                  }}
                >
                  {dateFormat(date2._d, "mmm yyyy")}
                </Typography>
              </Card>
              <Card
                sx={{
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                  padding: "3%",
                  border: date3Border,
                }}
                onClick={() => {
                  setSelectedDate(dateFormat(date3._d, "dd mmm yyyy"));
                  setDate1Border("0.5px solid #DDDDDD");
                  setDate2Border("0.5px solid #DDDDDD");
                  setDate3Border("1px solid #2287D9");
                  setDate4Border("0.5px solid #DDDDDD");
                }}
              >
                <Typography
                  sx={{
                    fontSize: "2rem",
                    color: "#9A9A9A",
                    textAlign: "center",
                  }}
                >
                  {dateFormat(date3._d, "dd")}
                </Typography>
                <Typography
                  sx={{
                    color: "#9A9A9A",
                    textAlign: "center",
                  }}
                >
                  {dateFormat(date3._d, "mmm yyyy")}
                </Typography>
              </Card>
              <Card
                sx={{
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                  padding: "3%",
                  border: date4Border,
                }}
                onClick={() => {
                  setSelectedDate(dateFormat(date4._d, "dd mmm yyyy"));
                  setDate1Border("0.5px solid #DDDDDD");
                  setDate2Border("0.5px solid #DDDDDD");
                  setDate3Border("0.5px solid #DDDDDD");
                  setDate4Border("1px solid #2287D9");
                }}
              >
                <Typography
                  sx={{
                    fontSize: "2rem",
                    color: "#9A9A9A",
                    textAlign: "center",
                  }}
                >
                  {dateFormat(date4._d, "dd")}
                </Typography>
                <Typography
                  sx={{
                    color: "#9A9A9A",
                    textAlign: "center",
                  }}
                >
                  {dateFormat(date4._d, "mmm yyyy")}
                </Typography>
              </Card>
            </Stack>
            <Typography
              gutterBottom
              sx={{
                marginTop: "3%",
              }}
            >
              Time
            </Typography>
            <FormControl fullWidth error={timeError}>
              <InputLabel id="demo-simple-select-label">Time</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={time}
                label="Age"
                onChange={handleChange}
              >
                <MenuItem value={"08:00 AM - 10:00 AM"}>
                  08:00 AM - 10:00 AM
                </MenuItem>
                <MenuItem value={"10:00 AM - 12:00 PM"}>
                  10:00 AM - 12:00 PM
                </MenuItem>
                <MenuItem value={"12:00 PM - 02:00 PM"}>
                  12:00 PM - 02:00 PM
                </MenuItem>
              </Select>
            </FormControl>

            <Box
              sx={{
                marginTop: "5%",
              }}
            >
              <Card
                sx={{
                  width: "90%",
                  display: "block",
                  margin: "auto",
                  boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.15)",
                  borderRadius: "8px",
                  padding: "3%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        marginTop: "3%",
                      }}
                    >
                      Apply Coupons
                    </Typography>
                  </Box>
                  <Box>
                    <Button onClick={() => setOpenDrawerCoupons(true)}>
                      Select
                    </Button>
                  </Box>
                </Box>
              </Card>
              <Card
                sx={{
                  width: "90%",
                  display: "block",
                  margin: "auto",
                  boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.15)",
                  borderRadius: "8px",
                  padding: "3%",
                  marginTop: "3%",
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    textTransform: "uppercase",
                  }}
                >
                  Select payment type
                </Typography>

                <Box
                  sx={{
                    border: ".5px solid #C6C6C6",
                  }}
                />

                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Online"
                      control={<Radio />}
                      label="Online (UPI / Credit Card/ Debit Card)"
                      onChange={(e) => {
                        setPackageSizeError("none");
                        setPaymentType(e.target.value);
                      }}
                    />
                    <FormControlLabel
                      value="COD"
                      control={<Radio />}
                      label="Cash On Pickup/Delivery"
                      onChange={(e) => {
                        setPackageSizeError("none");
                        setPaymentType(e.target.value);
                      }}
                    />
                  </RadioGroup>
                </FormControl>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                    color: "red",
                    display: showPaymentTypeError,
                  }}
                >
                  Please select the payment type
                </Typography>
              </Card>
            </Box>

            <Box
              sx={{
                marginTop: "3%",
                backgroundColor: "#fff",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#d5d5d57d",
                  width: "100%",
                  padding: "1%",
                  display: showUper,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    marginLeft: "2%",
                  }}
                >
                  {" "}
                  <span
                    style={{
                      color: "#31AE1D",
                    }}
                  >
                    Who woo !
                  </span>{" "}
                  coupon {couponCode} Applied
                </Typography>
              </Box>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  padding: "5%",
                }}
              >
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: "1.2rem",
                    fontWeight: "600",
                  }}
                >
                  Total : ₹{amountPaid}
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "#669AFF",
                  }}
                  onClick={() => {
                    if (packageSize === "") {
                      setPackageSizeError(true);
                    } else if (description === "") {
                      setDescriptionError(true);
                      setDescriptionErrorText(
                        "Please provide the name of item"
                      );
                    } else if (goodsValue == "") {
                      setGoodsValueError(true);
                      setGoodsValueErrorText("provide the value of item");
                    } else if (selectedDate == "") {
                      setSchPickError(true);
                    } else if (time == "") {
                      setTimeError(true);
                    } else if (paymentType == "") {
                      setShowPaymentTypeError("block");
                    } else {
                      setActiveStep(2);
                    }
                  }}
                >
                  Proceed
                </Button>
              </Stack>
            </Box>
          </Card>
        );
      case 2:
        return (
          <Card
            sx={{
              width: "60%",
              display: "block",
              margin: "auto",
              boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.1)",
              borderRadius: "16px",
              padding: "2%",
            }}
          >
            <Typography
              gutterBottom
              sx={{
                fontWeight: "700",
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              ORDER SUMMARY
            </Typography>
            <Box
              sx={{
                border: ".5px solid #C6C6C6",
              }}
            />
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "2%",
              }}
            >
              <Grid item xs={6}>
                <Card
                  sx={{
                    display: "block",
                    margin: "auto",
                    boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    padding: "5%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      ADDRESS DETAILS
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "#14AFE0",
                      }}
                      onClick={() => setActiveStep(0)}
                    >
                      Update
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      border: ".5px solid #C6C6C6",
                    }}
                  />
                  <Box>
                    <Timeline
                      sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                      }}
                    >
                      <TimelineItem
                        sx={{
                          minHeight: "50px",
                        }}
                      >
                        <TimelineSeparator>
                          <TimelineDot color="success"></TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography
                            sx={{
                              fontWeight: "600",
                            }}
                          >
                            Pickup Address
                          </Typography>
                          <Typography>{senderAddressLine1}</Typography>
                          <Typography
                            sx={{
                              fontSize: "0.8rem",
                              color: "grey",
                            }}
                          >
                            {senderName} | {sendermobileNumber}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem
                        sx={{
                          minHeight: "0px",
                        }}
                      >
                        <TimelineSeparator>
                          <TimelineDot color="error"></TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography
                            sx={{
                              fontWeight: "600",
                            }}
                          >
                            Deilvery Address
                          </Typography>
                          <Typography>{receiverAddressLine1}</Typography>
                          <Typography
                            sx={{
                              fontSize: "0.8rem",
                              color: "grey",
                              marginBottom: "6%",
                            }}
                          >
                            {receiverName} | {receivermobileNumber}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  sx={{
                    display: "block",
                    margin: "auto",
                    boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    padding: "5%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      PACKAGE DETAILS
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "#14AFE0",
                      }}
                      onClick={() => setActiveStep(1)}
                    >
                      Update
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      border: ".5px solid #C6C6C6",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "3%",
                    }}
                  >
                    <Box>
                      <Typography
                        gutterBottom
                        sx={{
                          color: "#646262",
                          marginTop: "3%",
                        }}
                      >
                        Package Size :
                      </Typography>
                      <Typography
                        gutterBottom
                        sx={{
                          color: "#646262",
                          marginTop: "3%",
                        }}
                      >
                        Package Contents :
                      </Typography>
                      <Typography
                        gutterBottom
                        sx={{
                          color: "#646262",
                          marginTop: "3%",
                        }}
                      >
                        Package Value :
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        gutterBottom
                        sx={{
                          color: "#646262",
                          marginTop: "3%",
                        }}
                      >
                        {packageSize}
                      </Typography>
                      <Typography
                        gutterBottom
                        sx={{
                          color: "#646262",
                          marginTop: "3%",
                        }}
                      >
                        {description}
                      </Typography>
                      <Typography
                        gutterBottom
                        sx={{
                          color: "#646262",
                          marginTop: "3%",
                        }}
                      >
                        {`INR ${goodsValue}`}
                      </Typography>
                    </Box>
                  </Box>

                  <Typography
                    sx={{
                      color: "#F45555",
                      textAlign: "center",
                      fontSize: "0.7rem",
                      marginTop: "3%",
                      textTransform: "uppercase",
                      visibility: isFragile === true ? "visible" : "hidden",
                    }}
                  >
                    Package contents IS Fragile
                  </Typography>
                </Card>
                <Card
                  sx={{
                    display: "block",
                    margin: "auto",
                    boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    padding: "5%",
                    marginTop: "3%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      PICKUP SCHEDULE
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "#14AFE0",
                      }}
                      onClick={() => setActiveStep(1)}
                    >
                      Update
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      border: ".5px solid #C6C6C6",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "3%",
                    }}
                  >
                    <Typography
                      gutterBottom
                      sx={{
                        color: "#646262",
                        marginTop: "3%",
                        width: "50%",
                      }}
                    >
                      Date and time :
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "#646262",
                        marginTop: "3%",
                      }}
                    >
                      {`${selectedDate} at ${time}`}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <LoadingButton
              variant="contained"
              loading={loading}
              disabled={btnDisabled}
              sx={{
                display: "block",
                margin: "auto",
                backgroundColor: "#669AFF",
                marginTop: "5%",
              }}
              onClick={() => {
                createOrder();
                localStorage.removeItem("isFromMap");
              }}
            >
              Place Order
            </LoadingButton>
          </Card>
        );

      default:
        break;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getCoupons = async () => {
    const response = await fetch("https://ndd.logicarts.in/pickup/coupons", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        jdata: {},
      }),
    });

    const responseJson = await response.json();
    if (responseJson.success == true) {
      setAllCoupons(responseJson.data);
    } else {
      setErrorMsg("unable to get coupons data");
      setOpenErrorAlert(true);
    }
  };

  Geocode.setApiKey("AIzaSyDJ6m7dujpOLQYOVDL6B5QJdE6cr0sEcuw");
  Geocode.setLanguage("en");
  Geocode.setRegion("in");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();

  const getAddressPosition = async (latitude, longitude) => {
    console.log("qwertyui");
    await Geocode.fromLatLng(`${latitude}`, `${longitude}`).then(
      async (response) => {
        const address = await response.results[0].formatted_address;
        setSenderAddressLine1(address);
        setPickupCoords(`${latitude},${longitude}`);
        const filterPostalCode =
          await response.results[0].address_components.filter(
            (value) => value.types[0] == "postal_code"
          );
        const filterCity = await response.results[0].address_components.filter(
          (value) => value.types[0] == "administrative_area_level_3"
        );
        const filterState = await response.results[0].address_components.filter(
          (value) => value.types[0] == "administrative_area_level_1"
        );
        setSenderPincode(filterPostalCode[0].long_name);
        setSenderCity(filterCity[0].long_name);
        setSenderState(filterState[0].long_name);
        await getOrigin(`${latitude},${longitude}`);
        setOpenLoadingModal(false);
      },
      (error) => {
        setOpenLoadingModal(false);
        console.error(error);
        setErrorMsg("Unable to fetch try again later");
        setOpenErrorAlert(true);
      }
    );
  };

  const position = async () => {
    setOpenLoadingModal(true);
    await navigator.geolocation.getCurrentPosition(
      async (position) => {
        await getAddressPosition(
          position.coords.latitude,
          position.coords.longitude
        );
      },
      async (err) => {
        if (err) {
          setOpenLoadingModal(false);
          setErrorMsg("Please enable your device location");
          setOpenErrorAlert(true);
        }
      }
    );
  };

  function CircularProgressWithLabel(props) {
    return (
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          marginLeft: "45%",
          marginTop: "5%",
          marginBottom: "5%",
        }}
      >
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}s`}
          </Typography>
        </Box>
      </Box>
    );
  }
  
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };

  return (
    <React.Fragment>
      <Box className="desktop-create-order">
        <Box
          sx={{
            backgroundImage: `url(${backImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <AppBar
              position="static"
              sx={{
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Toolbar>
                <img
                  src={require("./assests/images/logo_new1.png")}
                  style={{
                    width: "10%",
                  }}
                />

                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <IconButton
                    size="large"
                    onClick={() => navigate("/express/create-order")}
                  >
                    <Box>
                      <AddIcon
                        sx={{
                          color: "#2287D9",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#2287D9",
                        }}
                      >
                        Create Pickup
                      </Typography>
                    </Box>
                  </IconButton>
                  <IconButton
                    size="large"
                    onClick={() => navigate("/express/order-history")}
                  >
                    <Box>
                      <TimelineIcon />
                      <Typography>Track Order</Typography>
                    </Box>
                  </IconButton>
                  <IconButton
                    size="large"
                    edge="end"
                    aria-haspopup="true"
                    onClick={() => setOpenProfile(true)}
                  >
                    <Box>
                      <AccountCircle />
                      <Typography>Profile</Typography>
                    </Box>
                  </IconButton>
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
          <Box
            sx={{
              marginTop: "2%",
            }}
          >
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Box
            sx={{
              marginTop: "5%",
            }}
          >
            {stepperUI(activeStep)}
          </Box>

          <Dialog
            open={open}
            fullWidth
            maxWidth={maxWidth}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Please Choose Your Address
            </DialogTitle>
            <DialogContent>
              <Box>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    marginBottom: "2%",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/express/map-address")}
                >
                  <Typography
                    sx={{
                      color: "#2287D9",
                    }}
                  >
                    <MyLocationIcon />
                  </Typography>
                  <Typography>Add New Address</Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    marginBottom: "2%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSenderAddressLine1(
                      "BTM layout 2nd stage, Bangalore, Karnataka, 560068"
                    );
                    setSendermobileNumber("9098767890");
                    setSenderName("Ayushman Sharma");
                    handleClose();
                  }}
                >
                  <Box>
                    <Typography>
                      <LocationOnIcon />
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      Ayushman Sharma
                    </Typography>
                    <Typography>+91 9098989090</Typography>
                    <Typography>
                      {" "}
                      91 SpringBoard hub, Dr. Puneet service road, Mahadevpur,
                      Bangalore Karnataka, 560068{" "}
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    marginBottom: "2%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setReceiverName("Ayushman Sharma");
                    setReceiverAddressLine1(
                      "91 SpringBoard Hub Mahadevpura,Bangalore,Karnataka, 560068"
                    );
                    setReceivermobileNumber("9098767890");
                    handleClose();
                  }}
                >
                  <Box>
                    <Typography>
                      <LocationOnIcon />
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      Ayushman Sharma
                    </Typography>
                    <Typography>+91 9098989090</Typography>
                    <Typography>
                      {" "}
                      91 SpringBoard hub, Dr. Puneet service road, Mahadevpur,
                      Bangalore Karnataka, 560068{" "}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </DialogContent>
          </Dialog>
          <Drawer
            anchor="right"
            open={openProfile}
            onClose={() => setOpenProfile(false)}
            PaperProps={{
              sx: { width: "30%" },
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5%",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "1.2rem",
                      textAlign: "center",
                      marginTop: "3%",
                      fontWeight: "600",
                    }}
                  >
                    User Profile
                  </Typography>
                </Box>
                <Box>
                  <Button onClick={() => setOpenEditProfile(true)}>Edit</Button>
                </Box>
              </Box>

              <Box
                sx={{
                  padding: "5%",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <AccountCircleIcon
                    sx={{
                      fontSize: "8rem",
                      color: "grey",
                    }}
                  />
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "5%",
                  padding: "5%",
                  overflow: "hidden",
                }}
              >
                <Card
                  sx={{
                    padding: "3%",
                    marginBottom: "5%",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#807d7d",
                    }}
                  >
                    {userMobile}
                  </Typography>
                </Card>
                <Card
                  sx={{
                    padding: "3%",
                    marginBottom: "5%",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#807d7d",
                    }}
                  >
                    {userEmail}
                  </Typography>
                </Card>
                <Card
                  sx={{
                    padding: "3%",
                    marginBottom: "5%",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#807d7d",
                    }}
                  >
                    Manage Address
                  </Typography>
                </Card>
                <Card
                  sx={{
                    padding: "3%",
                    marginBottom: "5%",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#807d7d",
                    }}
                  >
                    Customer Support
                  </Typography>
                </Card>

                <Button variant="contained" fullWidth onClick={() => logOut()}>
                  Log Out
                </Button>
              </Box>
            </Box>
          </Drawer>
        </Box>
      </Box>

      <Drawer
        anchor="right"
        open={openFromAddress}
        onClose={() => setOpenFromAddress(false)}
        PaperProps={{
          sx: { width: "30%" },
        }}
      >
        <Box
          sx={{
            padding: "5%",
          }}
        >
          <Button
            sx={{
              float: "right",
            }}
            onClick={() => setOpenFromAddress(false)}
          >
            <CancelIcon
              sx={{
                color: "grey",
              }}
            />
          </Button>
          <Typography
            sx={{
              fontWeight: "600",
              marginTop: "10%",
            }}
          >
            Search For Pickup Location
          </Typography>

          <TextField
            fullWidth
            style={{
              border: "0.5px solid #DDDDDD",
              borderRadius: "4px",
              marginTop: "3%",
            }}
            placeholder="Search your address"
            onClick={() =>
              navigate("/express/search-address", {
                state: {
                  type: "from Address",
                },
              })
            }
          />

          <Typography
            sx={{
              color: "gray",
              textAlign: "center",
              marginTop: "3%",
            }}
          >
            Or
          </Typography>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              marginTop: "3%",
            }}
            onClick={() => {
              localStorage.removeItem("searchLat");
              localStorage.removeItem("searchLng");
              navigate("/express/map-address", {
                state: {
                  type: "from Address",
                },
              });
            }}
          >
            <Typography>
              <MyLocationRoundedIcon
                sx={{
                  color: "#2287D9",
                }}
              />
            </Typography>

            <Typography>Select location via map</Typography>
          </Stack>

          <Typography
            sx={{
              marginTop: "5%",
              color: "#959595",
            }}
          >
            Saved Address
          </Typography>
          {userAddress.length == 0 ? (
            <Box
              sx={{
                marginTop: "10%",
                marginBottom: "10%",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Please Save Your Address
              </Typography>
            </Box>
          ) : (
            <>
              {userAddress.map((fromAddress, index) => (
                <Box key={index}>
                  <Card
                    sx={{
                      padding: "3%",
                      marginTop: "2%",
                      borderRadius: "8px",
                      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      border: "0.5px solid silver",
                    }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        onClick={() => deleteAddress(fromAddress.cxAdID)}
                        sx={{
                          float: "right",
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                      <Button
                        sx={{
                          float: "right",
                        }}
                        onClick={() => {
                          localStorage.removeItem("searchLat");
                          localStorage.removeItem("searchLng");
                          navigate("/express/update-address", {
                            state: {
                              type: "from Address",
                              jdata: fromAddress.jdata,
                              cxAdID: fromAddress.cxAdID,
                            },
                          });
                        }}
                      >
                        <EditIcon />
                      </Button>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Box>
                        <Typography>
                          <HomeRoundedIcon />
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "600",
                          }}
                          gutterBottom
                        >
                          {JSON.parse(fromAddress.jdata).Name}
                        </Typography>
                        <Typography>
                          {fromAddress.Address == ""
                            ? "Please save your address details"
                            : ` ${JSON.parse(fromAddress.jdata).Building}, ${
                                JSON.parse(fromAddress.jdata).Address
                              }`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#767676",
                            marginTop: "2%",
                          }}
                        >
                          {JSON.parse(fromAddress.jdata).Mobile == ""
                            ? "No contact number"
                            : JSON.parse(fromAddress.jdata).Mobile}
                        </Typography>
                      </Box>
                    </Stack>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        marginTop: "3%",
                      }}
                      onClick={() => {
                        setSenderAddressLine1(
                          `${JSON.parse(fromAddress.jdata).Building},${
                            JSON.parse(fromAddress.jdata).Address
                          }`
                        );
                        setSendermobileNumber(
                          JSON.parse(fromAddress.jdata).Mobile
                        );
                        setSenderName(JSON.parse(fromAddress.jdata).Name);
                        setPickupCoords(
                          JSON.parse(fromAddress.jdata).Coordinates
                        );
                        setOpenFromAddress(false);
                      }}
                    >
                      Select Address
                    </Button>
                  </Card>
                </Box>
              ))}
            </>
          )}
        </Box>
      </Drawer>

      <Drawer
        anchor="right"
        open={openToAddress}
        onClose={() => setOpenToAddress(false)}
        PaperProps={{
          sx: { width: "30%" },
        }}
      >
        <Box
          sx={{
            padding: "5%",
          }}
        >
          <Button
            sx={{
              float: "right",
            }}
            onClick={() => setOpenToAddress(false)}
          >
            <CancelIcon
              sx={{
                color: "grey",
              }}
            />
          </Button>
          <Typography
            sx={{
              fontWeight: "600",
              marginTop: "10%",
            }}
          >
            Search For Delivery Location
          </Typography>

          <TextField
            fullWidth
            style={{
              border: "0.5px solid #DDDDDD",
              borderRadius: "4px",
              marginTop: "3%",
            }}
            placeholder="Search your address"
            onClick={() =>
              navigate("/express/search-address", {
                state: {
                  type: "to Address",
                },
              })
            }
          />

          <Typography
            sx={{
              color: "gray",
              textAlign: "center",
              marginTop: "3%",
            }}
          >
            Or
          </Typography>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              marginTop: "3%",
            }}
            onClick={() => {
              localStorage.removeItem("searchLat");
              localStorage.removeItem("searchLng");
              navigate("/express/map-address", {
                state: {
                  type: "to Address",
                },
              });
            }}
          >
            <Typography>
              <MyLocationRoundedIcon
                sx={{
                  color: "#2287D9",
                }}
              />
            </Typography>

            <Typography>Select location via map</Typography>
          </Stack>

          <Typography
            sx={{
              marginTop: "5%",
              color: "#959595",
            }}
          >
            Saved Address
          </Typography>

          {userAddress.length == 0 ? (
            <Box
              sx={{
                marginTop: "10%",
                marginBottom: "10%",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Please Save Your Address
              </Typography>
            </Box>
          ) : (
            <>
              {userAddress.map((toAddress, index) => (
                <Box key={index}>
                  <Card
                    sx={{
                      padding: "3%",
                      marginTop: "2%",
                      borderRadius: "8px",
                      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      border: "0.5px solid silver",
                    }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        onClick={() => deleteAddress(toAddress.cxAdID)}
                        sx={{
                          float: "right",
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                      <Button
                        sx={{
                          float: "right",
                        }}
                        onClick={() => {
                          localStorage.removeItem("searchLat");
                          localStorage.removeItem("searchLng");
                          navigate("/express/update-address", {
                            state: {
                              type: "to Address",
                              jdata: toAddress.jdata,
                              cxAdID: toAddress.cxAdID,
                            },
                          });
                        }}
                      >
                        <EditIcon />
                      </Button>
                    </Stack>

                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        marginTop: "3%",
                      }}
                    >
                      <Box>
                        <Typography>
                          <HomeRoundedIcon />
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "600",
                          }}
                          gutterBottom
                        >
                          {JSON.parse(toAddress.jdata).Name}
                        </Typography>
                        <Typography>
                          {!JSON.parse(toAddress.jdata).Address
                            ? "Please save your address details"
                            : ` ${JSON.parse(toAddress.jdata).Building}, ${
                                JSON.parse(toAddress.jdata).Address
                              }`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#767676",
                            marginTop: "2%",
                          }}
                        >
                          {JSON.parse(toAddress.jdata).Mobile == ""
                            ? "No contact number"
                            : JSON.parse(toAddress.jdata).Mobile}
                        </Typography>
                      </Box>
                    </Stack>

                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        marginTop: "3%",
                      }}
                      onClick={() => {
                        setReceiverName(JSON.parse(toAddress.jdata).Name);
                        setReceiverAddressLine1(
                          `${JSON.parse(toAddress.jdata).Building},${
                            JSON.parse(toAddress.jdata).Address
                          }`
                        );
                        setReceivermobileNumber(
                          JSON.parse(toAddress.jdata).Mobile
                        );
                        setDeliveryCoords(
                          JSON.parse(toAddress.jdata).Coordinates
                        );
                        setOpenToAddress(false);
                      }}
                    >
                      Select Address
                    </Button>
                  </Card>
                </Box>
              ))}
            </>
          )}
        </Box>
      </Drawer>

      <Drawer
        anchor="right"
        open={openEditProfile}
        onClose={() => setOpenEditProfile(false)}
        PaperProps={{
          sx: { width: "30%" },
        }}
      >
        <Box
          sx={{
            padding: "5%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
            }}
          >
            Update Your Details
          </Typography>

          <TextField
            fullWidth
            placeholder="First Name"
            sx={{
              marginTop: "4%",
            }}
            onChange={(e) => {
              setFirstNameError(false);
              setfirstNameErrorText("");
              setFirstName(e.target.value);
            }}
            error={firstNameError}
            helperText={firstNameErrorText}
          />
          <TextField
            fullWidth
            placeholder="Last Name"
            sx={{
              marginTop: "4%",
            }}
            onChange={(e) => {
              setLastNameError(false);
              setlastnameErrorText("");
              setLastName(e.target.value);
            }}
            error={lastnameError}
            helperText={lastnameErrorText}
          />

          <TextField
            fullWidth
            placeholder="Email Address"
            sx={{
              marginTop: "4%",
            }}
            onChange={(e) => {
              setEmailError(false);
              setEmailErrorText("");
              setEmail(e.target.value);
            }}
            error={emailError}
            helperText={emailErrorText}
          />

          <Button
            fullWidth
            variant="contained"
            sx={{
              marginTop: "5%",
            }}
            onClick={async () => {
              if (firstName == "") {
                setFirstNameError(true);
                setfirstNameErrorText("Please provide the first name");
              } else if (lastname == "") {
                setLastNameError(true);
                setlastnameErrorText("Please provide the last name");
              } else if (email == "") {
                setEmailError(true);
                setEmailErrorText("Please provide the email address");
              } else {
                await updateProfile();
              }
            }}
          >
            Update Details
          </Button>
        </Box>
      </Drawer>

      <Box
        sx={{
          visibility: "hidden",
        }}
      >
        <LoadScript googleMapsApiKey="AIzaSyDJ6m7dujpOLQYOVDL6B5QJdE6cr0sEcuw">
          <DistanceMatrixService
            options={{
              destinations: [{ lat: distanceDestLat, lng: distanceDestLng }],
              origins: [{ lng: distanceOriginLng, lat: distanceOriginLat }],
              travelMode: "DRIVING",
            }}
            callback={(response) => {
              setTotalDistance(response.rows[0].elements[0].distance.text);
            }}
          />
        </LoadScript>
      </Box>

      <Modal
        open={openLoadingModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{ display: "flex", justifyContent: "center", padding: "5%" }}
          >
            <CircularProgress />
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              marginTop: "3%",
            }}
          >
            Getting Your Location
          </Typography>
        </Box>
      </Modal>

      <Drawer
        anchor="right"
        open={openDrawerCoupons}
        onClose={() => setOpenDrawerCoupons(false)}
      >
        <Box
          sx={{
            padding: "5%",
            marginBottom: "25%",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Coupons
          </Typography>
          {allCoupons.map((coupon) => (
            <Card
              sx={{
                borderRadius: "6px",
                backgroundColor: "#FFF",
                boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.15)",
                padding: "3%",
                marginTop: "3%",
              }}
            >
              <Stack direction="row" spacing={10}>
                <Box>
                  <Typography
                    sx={{
                      color: "#EB9C2C",
                      textTransform: "uppercase",
                    }}
                    gutterBottom
                  >
                    {coupon.couponCode}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: "600",
                    }}
                  >
                    {coupon.description}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    onClick={async () => {
                      setOpenDrawerCoupons(false);
                      setCouponCode(coupon.couponCode);
                      setShowCelebrate("block");
                      setOpenDialog(true);
                      await ShipmentsizeNpricing(coupon.couponCode);
                      setShowUper("block");
                      setTimeout(() => {
                        setShowCelebrate("none");
                        setOpenDialog(false);
                      }, 2500);
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              </Stack>
            </Card>
          ))}
          <Button
            variant="contained"
            fullWidth
            sx={{
              marginTop: "5%",
            }}
            onClick={() => setOpenDrawerCoupons(false)}
          >
            Close
          </Button>
        </Box>
      </Drawer>

      <Dialog
        open={openDialog}
        maxWidth={maxWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box>
          <img
            src={require("./assests/images/13491-pop-new-year.gif")}
            style={{
              width: "50%",
              margin: "auto",
              display: "block",
            }}
          />
        </Box>
        <DialogContent
          sx={{
            marginTop: "-16%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              textAlign: "center",
            }}
            gutterBottom
          >
            {couponCode} Applied
          </Typography>

          <Typography
            sx={{
              fontSize: "0.8rem",
              color: "grey",
            }}
          >
            saved! That feels amazing right?
          </Typography>
        </DialogContent>
        <Button
          variant="contained"
          sx={{
            borderRadius: "0px",
          }}
          onClick={() => {
            setShowCelebrate("none");
            setOpenDialog(false);
          }}
        >
          OK
        </Button>
      </Dialog>

      <Box
        sx={{
          display: showCelebrate,
          position: "absolute",
          top: "60%",
        }}
      >
        <PointConfetti />
      </Box>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={() => setOpenErrorAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Box className="mobile-create-order">
        <CreateOrderMobile />
      </Box>
    </React.Fragment>
  );
}

export default CreateOrder;
