import React, { useState, useEffect } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import LocalMallIcon from "@mui/icons-material/LocalMall";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Grid, Typography } from "@mui/material";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

function TrackOrderMobile(props) {
  const [statusCount, setStatusCount] = useState(0);

  // const consigneeDetails = JSON.parse(props.details[0].consigneeDetails);
  // const consignorDetails = JSON.parse(props.details[0].consignorDetails);

  // const dataToSort = props.details;

  // const sortByDate = (arr) => {
  //   const sorter = (a, b) => {
  //     return (
  //       new Date(a.StatusDatetime).getTime() -
  //       new Date(b.StatusDatetime).getTime()
  //     );
  //   };
  //   arr.sort(sorter);
  // };
  // sortByDate(dataToSort);

  // console.log("datatosort", dataToSort);

  useEffect(() => {
    const lastStatus = props.details?.trip?.tripStatus;

    if (lastStatus == "Trip Ended") {
      setStatusCount(3);
    }

    if (lastStatus == "Trip Started") {
      setStatusCount(2);
    }
    if (lastStatus == "Yet To Start" ) {
      setStatusCount(1);
    }
    // if (lastStatus[0].Status == "Consignment Booked") {
    //   setStatusCount(0);
    // }
  }, []);

  return (
    <Box>
    
    </Box>
  );
}

export default TrackOrderMobile;
