import React, {
  Fragment,
  useState,
  useEffect,
  useSyncExternalStore,
} from "react";
import {
  Box,
  Typography,
  Card,
  Stack,
  Drawer,
  Button,
  TextField,
} from "@mui/material";

import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import { useNavigate } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function UserProfile() {
  const navigate = useNavigate();

  const [valueBottom, setValueBottom] = useState(2);
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorText, setfirstNameErrorText] = useState("");

  const [lastnameError, setLastNameError] = useState(false);
  const [lastnameErrorText, setlastnameErrorText] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const vertical = "top";
  const horizontal = "center";

  const handleChangeBottom = (event, newValue) => {
    setValueBottom(newValue);
    if (newValue === 0) navigate("/express/order-history");
    if (newValue === 1) navigate("/express/create-order");
    if (newValue === 2) navigate("/express/user-profile");
  };

  useEffect(() => {
    (async () => {
      await verifyToken();
      await getData();
    })();
  }, []);

  const getData = async () => {
    const userDetails = localStorage.getItem("userDetails");
    const parseData = await JSON.parse(userDetails);
    const parseJdata = await JSON.parse(parseData.jdata);
    setUserName(parseData.firstname + " " + parseData.lastname);
    setUserMobile(parseData.mobile);
    setUserEmail(parseJdata.email);
  };

  const logOut = async () => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("token");
    window.location.reload();
  };

  const verifyToken = async () => {
    const userToken = localStorage.getItem("token");
    console.log(userToken);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    const responseJson = await response.json();
    console.log(responseJson, "verify");
    if (responseJson.success == true) {
      console.log("authenticated");
    } else {
      navigate("/express/user-login");
    }
  };

  const updateProfile = async () => {
    const userDeatils = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDeatils);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          cxID: parseData.cxID,
          mobile: parseData.mobile,
          jdata: {
            FromAddresses: [
              { Description: "", Address: "", Contact01: "", IsDefault: "" },
              { Description: "", Address: "", Contact01: "", IsDefault: "" },
            ],
            ToAddresses: [
              { Description: "", Address: "", Contact01: "" },
              { Description: "", Address: "", Contact01: "" },
            ],
            firstname: firstName,
            lastname: lastname,
            email: email,
          },
        }),
      }
    );

    const responseJson = await response.json();
    if (responseJson.success == true) {
      alert(
        "Profile update successfully. Kindly logout and login again to see the changes."
      );
      setOpenDrawer(false);
    } else {
      setErrorMsg("Unable to update the profile");
      setOpenErrorAlert(true)
      
    }
  };

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: "#669AFF",
          display: "flex",
          justifyContent: "space-between",
          padding: "3%",
        }}
      >
        <Typography
          sx={{
            marginTop: "2%",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackRoundedIcon
            sx={{
              color: "#FFFFFF",
            }}
          />
        </Typography>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: "600",
            marginTop: "1%",
            fontSize: "1.2rem",
          }}
        >
          User Profile
        </Typography>
        <Typography></Typography>
      </Box>
      <Box
        sx={{
          padding: "15%",
          backgroundColor: "#eef8ff",
        }}
      >
        <img
          src={require("./assets/images/7309681.jpg")}
          style={{
            width: "50%",
            borderRadius: "50%",
            display: "block",
            margin: "auto",
            boxShadow: "#9ad4ff 0px 5px 15px",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "3%",
            fontWeight: "600",
          }}
        >
          {userName}
        </Typography>
      </Box>

      <Box
        sx={{
          marginTop: "5%",
          padding: "5%",
          marginBottom: "30%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              color: "#807d7d",
              fontSize: "1.1rem",
            }}
          >
            {userMobile}
          </Typography>
          <Typography>
            <ChevronRightRoundedIcon
              sx={{
                color: "#807d7d",
              }}
            />
          </Typography>
        </Box>

        <Box
          sx={{
            border: ".5px solid #C6C6C6",
            marginBottom: "5%",
          }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              color: "#807d7d",
              fontSize: "1.1rem",
            }}
          >
            {userEmail}
          </Typography>
          <Typography>
            <ChevronRightRoundedIcon
              sx={{
                color: "#807d7d",
              }}
            />
          </Typography>
        </Box>

        <Box
          sx={{
            border: ".5px solid #C6C6C6",
            marginBottom: "5%",
          }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2%",
          }}
          onClick={() => navigate("/express/saved-address")}
        >
          <Typography
            sx={{
              fontWeight: "600",
              color: "#807d7d",
              fontSize: "1.1rem",
            }}
          >
            Manage Address
          </Typography>
          <Typography>
            <ChevronRightRoundedIcon
              sx={{
                color: "#807d7d",
              }}
            />
          </Typography>
        </Box>

        <Box
          sx={{
            border: ".5px solid #C6C6C6",
            marginBottom: "5%",
          }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              color: "#807d7d",
              fontSize: "1.1rem",
            }}
          >
            Customer Support
          </Typography>
          <Typography>
            <ChevronRightRoundedIcon
              sx={{
                color: "#807d7d",
              }}
            />
          </Typography>
        </Box>

        <Box
          sx={{
            border: ".5px solid #C6C6C6",
            marginBottom: "5%",
          }}
        />

        <Button
          variant="contained"
          fullWidth
          onClick={() => setOpenDrawer(true)}
        >
          Edit Profile
        </Button>

        <Button
          variant="contained"
          fullWidth
          onClick={() => logOut()}
          sx={{
            marginTop: "3%",
            backgroundColor: "#da0505",
          }}
        >
          Log OUt
        </Button>
      </Box>

      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 20,
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <BottomNavigation
          showLabels
          value={valueBottom}
          onChange={handleChangeBottom}
        >
          <BottomNavigationAction label="Home" icon={<HomeRoundedIcon />} />
          <BottomNavigationAction
            label="Create Order"
            icon={<ControlPointRoundedIcon />}
          />
          <BottomNavigationAction
            label="Profile"
            icon={<Person2RoundedIcon />}
          />
        </BottomNavigation>
      </Box>

      <Drawer
        anchor="bottom"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box
          sx={{
            padding: "5%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
            }}
          >
            Update Your Details
          </Typography>

          <TextField
            fullWidth
            placeholder="First Name"
            sx={{
              marginTop: "4%",
            }}
            onChange={(e) => {
              setFirstNameError(false);
              setfirstNameErrorText("");
              setFirstName(e.target.value);
            }}
            error={firstNameError}
            helperText={firstNameErrorText}
          />
          <TextField
            fullWidth
            placeholder="Last Name"
            sx={{
              marginTop: "4%",
            }}
            onChange={(e) => {
              setLastNameError(false);
              setlastnameErrorText("");
              setLastName(e.target.value);
            }}
            error={lastnameError}
            helperText={lastnameErrorText}
          />

          <TextField
            fullWidth
            placeholder="Email Address"
            sx={{
              marginTop: "4%",
            }}
            onChange={(e) => {
              setEmailError(false);
              setEmailErrorText("");
              setEmail(e.target.value);
            }}
            error={emailError}
            helperText={emailErrorText}
          />

          <Button
            fullWidth
            variant="contained"
            sx={{
              marginTop: "5%",
            }}
            onClick={async () => {
              if (firstName == "") {
                setFirstNameError(true);
                setfirstNameErrorText("Please provide the first name");
              } else if (lastname == "") {
                setLastNameError(true);
                setlastnameErrorText("Please provide the last name");
              } else if (email == "") {
                setEmailError(true);
                setEmailErrorText("Please provide the email address");
              } else {
                await updateProfile();
              }
            }}
          >
            Update Details
          </Button>
        </Box>
      </Drawer>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={() => setOpenErrorAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export default UserProfile;
