import React, { Fragment, useState, useEffect } from "react";
import {
  Box,
  Card,
  Stack,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Drawer,
  Radio,
  RadioGroup,
  Grid,
  FormControlLabel,
  Modal,
} from "@mui/material";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import UpgradeOutlinedIcon from "@mui/icons-material/UpgradeOutlined";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "background.paper",
  border: "0.3px solid #000",
  boxShadow: 24,
  p: 4,
};

function Picker() {
  const navigate = useNavigate();

  let url = new URL(window.location.href);
  let search_params = url.searchParams;
  let valuepID = search_params.get("pID");
  let valueMobile = search_params.get("mobileNo");

  const [disabled, setDisabled] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [Description, setDescription] = useState("");
  const [actualWeight, setActualWeight] = useState("");
  const [goodsValue, setGoodsValue] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [isFragile, setIsFragile] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [pickrMobile, setPickrMobile] = useState("");
  const [receiverAddressLine1, setReceiverAddressLine1] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receivermobileNumber, setReceivermobileNumber] = useState("");
  const [senderAddressLine1, setSenderAddressLine1] = useState("");
  const [senderName, setSenderName] = useState("");
  const [sendermobileNumber, setSendermobileNumber] = useState("");

  const [pID, setPID] = useState(0);
  const [mobile, setMobile] = useState("");
  const [pickerName, setPickerName] = useState("");
  const [allStatus, setAllStatus] = useState([]);
  const [statusID, setStatusID] = useState("");
  const [orderID, setOrderID] = useState("");
  const [senderHouseAddress, setSenderHouseAddress] = useState("");
  const [senderAddressLine2, setSenderAddressLine2] = useState("");
  const [senderLandmark, setSenderLandmark] = useState("");
  const [senderCity, setSenderCity] = useState("");
  const [senderState, setSenderState] = useState("");
  const [senderPincode, setSenderPincode] = useState("");
  const [senderLng, setSenderLng] = useState();
  const [senderLat, setSenderLat] = useState();
  const [senderNote, setSenderNote] = useState("");

  const [openDrawerPrice, setOpenDrawerPrice] = useState(false);
  const [allSizes, setAllSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [amountToPay, setAmountToPay] = useState("");
  const [receiverLat, setReceiverLat] = useState();
  const [receiverLng, setReceiverLng] = useState();
  const [receiverHouseAddress, setReceiverHouseAddress] = useState("");
  const [receiverAddressLine2, setReceiverAddressLine2] = useState("");
  const [receiverLandmark, setReceiverLandmark] = useState("");
  const [receiverCity, setReceiverCity] = useState("");
  const [receiverState, setReceiverState] = useState();
  const [receiverPincode, setReceiverPincode] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");
  const [scheduleDate, setScheduleDate] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [totalDistance, setTotalDistance] = useState("");

  const [openDrawerUpdate, setOpenDrawerUpdate] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const [packageSize, setPackageSize] = useState("");
  const [smallBorder, setSmallBorder] = useState("0.5px solid #DDDDDD");
  const [mediumBorder, setMediumBorder] = useState("0.5px solid #DDDDDD");
  const [largeBorder, setLargeBorder] = useState("0.5px solid #DDDDDD");
  const [bulkyBorder, setBulkyBorder] = useState("0.5px solid #DDDDDD");

  const [cxAmountPaid, setcxAmountPaid] = useState();
  const [priceText, setPriceText] = useState("No amount have to collected");

  const [amountToBePayed, setAmountToBePayed] = useState(100);

  const [ssID, setssID] = useState();
  const [openDrawerMap, setOpenDrawerMap] = useState(false);
  const [open, setOpen] = useState(false);

  const [packageSizeError, setPackageSizeError] = useState(false);

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const vertical = "top";
  const horizontal = "center";

  const handleChange = (event) => {
    setSelectedSize(event.target.value.description);
    setssID(event.target.value);
  };

  useEffect(() => {
    (async () => {
      await statusDropdown();
      await getOrderDetails(valuepID, valueMobile);
    })();
  }, []);

  const shipmentSizeDropDown = async () => {
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/shipmentSizeDropDown",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          jdata: {},
        }),
      }
    );

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.success == true) {
      setAllSizes(responseJson.data);
    } else {
      setErrorMsg("Unable to get the sizes");
      setOpenErrorAlert(true)
    }
  };

  const getOrderDetails = async (pID, mobile) => {
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/getOrderDetails",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token":
            "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGljaGVjazEiLCJpYXQiOjE2ODkwMTYzMDUsImV4cCI6MTY4OTAxOTkwNX0.Lgtn6mOK-sFiGxWJV_ZEyjqojTaAd-wVa2dYWL2Lurs",
        },

        body: JSON.stringify({
          mobile: mobile,
          pid: pID,
        }),
      }
    );

    const responseJson = await response.json();
    const parseData = JSON.parse(responseJson.data[0].jdata);
    console.log(responseJson);
    console.log(parseData);
    if (responseJson.success === true) {
      setPID(responseJson.data[0].PID);
      setMobile(responseJson.data[0].mobile);
      setPickerName(responseJson.data[0].pickrName);
      setDescription(parseData.Description);
      setOrderID(parseData.orderID);
      setActualWeight(parseData.actualWeight);
      setGoodsValue(parseData.goodsValue);
      setInvoiceNumber(parseData.invoiceNumber);
      setIsFragile(parseData.isFragile);
      setPaymentType(parseData.paymentType);
      setPickrMobile(parseData.pickrMobile);
      setReceiverAddressLine1(parseData.receiverAddressLine1);
      setReceiverName(parseData.receiverName);
      setReceivermobileNumber(parseData.receivermobileNumber);
      setSenderAddressLine1(parseData.senderAddressLine1);
      setSenderName(parseData.senderName);
      setSendermobileNumber(parseData.sendermobileNumber);
      setSenderHouseAddress(parseData.senderHouseAddress);
      setSenderAddressLine2(parseData.senderAddressLine2);
      setSenderLandmark(parseData.senderLandmark);
      setSenderCity(parseData.senderCity);
      setSenderPincode(parseData.senderCity);
      setSenderState(parseData.senderState);
      setSenderPincode(parseData.senderPincode);
      setSenderLat(parseData.senderLat);
      setSenderLng(parseData.senderLng);
      setSenderNote(parseData.senderNote);
      setAmountToPay(parseData.amountToPay);
      setReceiverLat(parseData.receiverLat);
      setReceiverLng(parseData.receiverLng);
      setReceiverHouseAddress(parseData.receiverHouseAddress);
      setReceiverAddressLine2(parseData.receiverAddressLine2);
      setReceiverLandmark(parseData.receiverLandmark);
      setReceiverCity(parseData.receiverCity);
      setReceiverState(parseData.receiverState);
      setReceiverPincode(parseData.receiverPincode);
      setScheduleTime(parseData.scheduleTime);
      setScheduleDate(parseData.scheduleDate);
      setCouponCode(parseData.couponCode);
      setTotalDistance(parseData.totalDistance);
      setcxAmountPaid(parseData.cxAmountPaid);
    } else {
      setErrorMsg("Unable to fetch the order details");
      setOpenErrorAlert(true)
    }
  };

  const pickerModifyOrder = async () => {
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/pickerModifyOrder",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token":
            "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGljaGVjazEiLCJpYXQiOjE2ODkwMTYzMDUsImV4cCI6MTY4OTAxOTkwNX0.Lgtn6mOK-sFiGxWJV_ZEyjqojTaAd-wVa2dYWL2Lurs",
        },
        body: JSON.stringify({
          pID: !valuepID ? pID : valuepID,
          contentID: "1",
          statusID: "",
          jdata: {
            orderID: orderID,
            senderName: senderName,
            senderHouseAddress: senderHouseAddress,
            senderAddressLine1: senderAddressLine1,
            senderAddressLine2: senderAddressLine2,
            senderLandmark: senderLandmark,
            senderCity: senderCity,
            senderState: senderState,
            senderPincode: senderPincode,
            sendermobileNumber: sendermobileNumber,
            senderLat: senderLat,
            senderLng: senderLng,
            senderNote: senderNote,
            invoiceNumber: invoiceNumber,

            receiverName: receiverName,
            receiverHouseAddress: receiverHouseAddress,
            receiverAddressLine1: receiverAddressLine1,
            receiverAddressLine2: receiverAddressLine2,
            receiverLandmark: receiverLandmark,
            receiverCity: receiverCity,
            receiverState: receiverState,
            receiverPincode: receiverPincode,
            receivermobileNumber: receivermobileNumber,
            receiverLat: receiverLat,
            receiverLng: receiverLng,
            receiverNote: senderNote,

            paymentType: paymentType,
            goodsValue: goodsValue,

            Description: Description,
            actualWeight: actualWeight,
            mobile: mobile,
            isFragile: `${isFragile}`,

            deliveryAent: pickerName,
            is_deliveryAgent: "1",

            paymentStatus: "pending",
            scheduleTime: scheduleTime,
            scheduleDate: scheduleDate,
            couponCode: couponCode,
            totalDistance: totalDistance,
            images: [],
            amountToPay: amountToPay,
            status: "Cx Amount Not Ready",
          },
        }),
      }
    );

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.success == true) {
      setOpenUpdate(true);
      await getOrderDetails(valuepID, valueMobile);
    } else {
      setErrorMsg("Unable to update the order");
      setOpenErrorAlert(true)
    }
  };

  const statusDropdown = async () => {
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/statusDropdown",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token":
            "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGljaGVjazEiLCJpYXQiOjE2ODkwMTYzMDUsImV4cCI6MTY4OTAxOTkwNX0.Lgtn6mOK-sFiGxWJV_ZEyjqojTaAd-wVa2dYWL2Lurs",
        },
        body: JSON.stringify({}),
      }
    );

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.success === true) {
      setAllStatus(responseJson.data);
    } else {
      setErrorMsg("Unable to fetch Status");
      setOpenErrorAlert(true)
    }
  };

  const pickerUpdateStatus = async () => {
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/pickerModifyOrder",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token":
            "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGljaGVjazEiLCJpYXQiOjE2ODkwMTYzMDUsImV4cCI6MTY4OTAxOTkwNX0.Lgtn6mOK-sFiGxWJV_ZEyjqojTaAd-wVa2dYWL2Lurs",
        },
        body: JSON.stringify({
          pID: !valuepID ? pID : valuepID,
          contentID: "2",
          statusID: statusID,
          jdata: {
            orderID: orderID,
            senderName: senderName,
            senderHouseAddress: senderHouseAddress,
            senderAddressLine1: senderAddressLine1,
            senderAddressLine2: senderAddressLine2,
            senderLandmark: senderLandmark,
            senderCity: senderCity,
            senderState: senderState,
            senderPincode: senderPincode,
            sendermobileNumber: sendermobileNumber,
            senderLat: senderLat,
            senderLng: senderLng,
            senderNote: senderNote,
            invoiceNumber: invoiceNumber,

            receiverName: receiverName,
            receiverHouseAddress: receiverHouseAddress,
            receiverAddressLine1: receiverAddressLine1,
            receiverAddressLine2: receiverAddressLine2,
            receiverLandmark: receiverLandmark,
            receiverCity: receiverCity,
            receiverState: receiverState,
            receiverPincode: receiverPincode,
            receivermobileNumber: receivermobileNumber,
            receiverLat: receiverLat,
            receiverLng: receiverLng,
            receiverNote: senderNote,

            paymentType: paymentType,
            goodsValue: goodsValue,

            Description: Description,
            actualWeight: actualWeight,
            mobile: mobile,
            isFragile: `${isFragile}`,

            deliveryAent: pickerName,
            is_deliveryAgent: "1",

            paymentStatus: "pending",
            scheduleTime: scheduleTime,
            scheduleDate: scheduleDate,
            couponCode: couponCode,
            totalDistance: totalDistance,
            images: [],
            amountToPay: amountToPay,
            status: "Cx Amount Not Ready",
          },
        }),
      }
    );

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.success == true) {
      setOpenStatus(true);
    } else {
      setErrorMsg("unable to update the status");
      setOpenErrorAlert(true)
    }
  };

  const ShipmentsizeNpricing = async () => {
    console.log(ssID);
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/ShipmentsizeNpricing",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtb2JpbGUiOiI3ODY5MDc4Nzg3IiwiaWF0IjoxNjg5MDY0NTcyLCJleHAiOjE2OTAyNzQxNzJ9.3Urzj1GffaoZmoOJf8QtbaaO0OO9Nf4bVWlOO6xflcA",
        },
        body: JSON.stringify({
          jdata: {
            couponCode: couponCode,
            distance: totalDistance,
            ssID: ssID,
          },
        }),
      }
    );

    const responseJson = await response.json();
    const filterPrice = responseJson.data.filter((value) => value.ssID == ssID);
    console.log(filterPrice);
    if (Number(filterPrice[0].price) == cxAmountPaid) {
      setPriceText("No amount have to collected");
    } else {
      const diffAmount = Number(filterPrice[0].price) - Number(cxAmountPaid);
      setAmountToBePayed(diffAmount);
      setPriceText(`Extra amount have to collected is : INR ${diffAmount}`);
    }
  };

  const payAmount = async () => {
    console.log(cxAmountPaid, priceText);
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/sendDifferentialAmountNotification",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtb2JpbGUiOiI3ODY5MDc4Nzg3IiwiaWF0IjoxNjg5MDY0NTcyLCJleHAiOjE2OTAyNzQxNzJ9.3Urzj1GffaoZmoOJf8QtbaaO0OO9Nf4bVWlOO6xflcA",
        },
        body: JSON.stringify({
          mobile: sendermobileNumber,
          name: senderName,
          oldAmount: cxAmountPaid,
          amountToBePayed: `${amountToBePayed}00`,
          totalAmount: Number(cxAmountPaid) + Number(amountToBePayed),
          orderID: orderID,
          pID: !valuepID ? pID : valuepID,
          pickerMobile: valueMobile,
        }),
      }
    );

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.success == true) {
      setOpen(true);
    }
  };

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: "#669AFF",
          padding: "3%",
        }}
      >
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: "600",
            marginTop: "1%",
            fontSize: "1.2rem",
            textAlign: "center",
          }}
        >
          Express Picker
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "5%",
          marginBottom: "15%",
        }}
      >
        <Card
          sx={{
            background: "#FFFFFF",
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
            borderRadius: "6px",
            padding: "3%",
            marginBottom: "3%",
          }}
        >
          <Typography
            sx={{
              marginBottom: "2%",
              textTransform: "uppercase",
              fontWeight: "700",
            }}
          >
            Picker Details
          </Typography>

          <Box
            sx={{
              border: ".5px solid #C6C6C6",
            }}
          />
          <Stack
            direction="row"
            spacing={2}
            sx={{
              marginTop: "3%",
            }}
          >
            <Typography>
              <AccountCircleIcon
                sx={{
                  fontSize: "3.5rem",
                  color: "grey",
                }}
              />
            </Typography>
            <Box>
              <Typography
                sx={{
                  fontWeight: "600",
                }}
              >
                {!pickerName ? "Picker" : pickerName},Logicarts
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  color: "#8E8E8E",
                }}
              >
                +91 {pickrMobile}
              </Typography>
            </Box>
          </Stack>
        </Card>
        <Card
          sx={{
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.15)",
            borderRadius: "8px",
            padding: "1%",
            marginBottom: "3%",
          }}
        >
          <Box
            sx={{
              padding: "2%",
            }}
          >
            <Typography
              gutterBottom
              sx={{
                textTransform: "uppercase",
                fontWeight: "700",
              }}
            >
              ADDRESS DETAILS
            </Typography>
            <Box
              sx={{
                border: ".5px solid #C6C6C6",
              }}
            />
          </Box>

          <Box>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem
                sx={{
                  minHeight: "50px",
                }}
              >
                <TimelineSeparator>
                  <TimelineDot color="success"></TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography
                    sx={{
                      fontWeight: "600",
                    }}
                  >
                    Pickup Address
                  </Typography>
                  <Typography>{senderAddressLine1}</Typography>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      color: "grey",
                    }}
                  >
                    {senderName} | {sendermobileNumber}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem
                sx={{
                  minHeight: "0px",
                }}
              >
                <TimelineSeparator>
                  <TimelineDot color="error"></TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Typography
                    sx={{
                      fontWeight: "600",
                    }}
                  >
                    Deilvery Address
                  </Typography>
                  <Typography>{receiverAddressLine1}</Typography>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      color: "grey",
                      marginBottom: "6%",
                    }}
                  >
                    {receiverName} | {receivermobileNumber}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Box>
        </Card>
        <Card
          sx={{
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.15)",
            borderRadius: "8px",
            padding: "3%",
            marginBottom: "3%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                gutterBottom
                sx={{
                  textAlign: "center",
                  textTransform: "uppercase",
                  fontWeight: "700",
                  marginTop: "3%",
                }}
              >
                PACKAGE DETAILS
              </Typography>
            </Box>
            <Box>
              <Button onClick={() => setOpenDrawerUpdate(true)}>Update</Button>
            </Box>
          </Box>
          <Box
            sx={{
              border: ".5px solid #C6C6C6",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "3%",
            }}
          >
            <Box>
              <Typography
                gutterBottom
                sx={{
                  color: "#646262",
                  marginTop: "3%",
                }}
              >
                Package Size :
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  color: "#646262",
                  marginTop: "3%",
                }}
              >
                Package Contents :
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  color: "#646262",
                  marginTop: "3%",
                }}
              >
                Package Value :
              </Typography>
            </Box>
            <Box>
              <Typography
                gutterBottom
                sx={{
                  color: "#646262",
                  marginTop: "3%",
                }}
              >
                {actualWeight}
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  color: "#646262",
                  marginTop: "3%",
                }}
              >
                {Description}
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  color: "#646262",
                  marginTop: "3%",
                }}
              >
                {`INR ${goodsValue}`}
              </Typography>
            </Box>
          </Box>
        </Card>
        <Card
          sx={{
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.15)",
            borderRadius: "8px",
            padding: "3%",
            marginBottom: "3%",
          }}
        >
          <Typography
            gutterBottom
            sx={{
              fontWeight: "700",
              marginBottom: "2%",
            }}
          >
            PAYMENT TYPE :{" "}
            <span
              style={{
                color: "#939393",
              }}
            >
              {paymentType === "COD" ? "COP (cash on pickup)" : "Online"}
            </span>
          </Typography>
          <Box
            sx={{
              border: ".5px solid #C6C6C6",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "2%",
              marginTop: "2%",
            }}
          >
            <Typography>
              {paymentType == "COD" ? "Amount to be collected" : "Amount Paid"}
            </Typography>
            <Typography>INR {cxAmountPaid}</Typography>
          </Box>

          <Button
            variant="contained"
            fullWidth
            onClick={() => payAmount()}
            sx={{
              display: paymentType == "COD" ? "block" : "none",
            }}
          >
            Send Payment Link
          </Button>

          <Button
            variant="contained"
            fullWidth
            sx={{
              marginTop: "5%",
              display: paymentType == "COD" ? "none" : "block",
            }}
            onClick={async () => {
              await shipmentSizeDropDown();
              setOpenDrawerPrice(true);
            }}
          >
            Check Price
          </Button>
        </Card>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#5FAE44",
          }}
        >
          <a href={`tel: ${sendermobileNumber} `}>
            <Button
              startIcon={<CallOutlinedIcon />}
              sx={{
                color: "#fff",
              }}
            >
              Customer
            </Button>
          </a>
        </Box>
        <Box
          sx={{
            backgroundColor: "#F2A851",
          }}
        >
          <Button
            startIcon={<NearMeOutlinedIcon />}
            sx={{
              color: "#fff",
            }}
            onClick={() => setOpenDrawerMap(true)}
          >
            Direction
          </Button>
        </Box>
        <Box
          sx={{
            backgroundColor: "#1A8FD1",
          }}
        >
          <Button
            startIcon={<UpgradeOutlinedIcon />}
            sx={{
              color: "#fff",
            }}
            onClick={() => setOpenDrawer(true)}
          >
            Update Status
          </Button>
        </Box>
      </Box>

      <Drawer anchor="bottom" open={openDrawer}>
        <Box
          sx={{
            padding: "5%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              marginBottom: "5%",
              textAlign: "center",
              fontSize: "1.2rem",
            }}
          >
            Update The Status
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={(e) => console.log(e.target.value)}
            >
              {allStatus.map((status) => (
                <FormControlLabel
                  value={status.Statusid}
                  control={<Radio />}
                  label={status.Status}
                  onChange={(e) => setStatusID(e.target.value)}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Button
            variant="contained"
            fullWidth
            sx={{
              marginTop: "3%",
            }}
            onClick={async () => {
              setOpenDrawer(false);
              await pickerUpdateStatus();
            }}
          >
            Update
          </Button>
        </Box>
      </Drawer>

      <Drawer
        anchor="bottom"
        open={openDrawerPrice}
        onClose={() => setOpenDrawerPrice(false)}
      >
        <Box
          sx={{
            padding: "5%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              marginBottom: "5%",
              textAlign: "center",
              fontSize: "1.2rem",
            }}
          >
            Check Price
          </Typography>

          {allSizes.length == 0 ? (
            <Typography>Loading sizes</Typography>
          ) : (
            <>
              <Grid
                container
                spacing={2}
                sx={{
                  marginBottom: "2%",
                  marginTop: "2%",
                }}
              >
                <Grid item xs={6}>
                  <Box>
                    <Card
                      sx={{
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                        border: smallBorder,
                        width: "100%",
                      }}
                      onClick={async () => {
                        setPackageSize(
                          `${allSizes[3].description} ${allSizes[3].sWeight}-${allSizes[3].eWeight} Kgs`
                        );
                        setSmallBorder("1px solid #2287D9");
                        setMediumBorder("0.5px solid #DDDDDD");
                        setLargeBorder("0.5px solid #DDDDDD");
                        setBulkyBorder("0.5px solid #DDDDDD");
                        setssID(allSizes[3].ssID);
                      }}
                    >
                      <img
                        src={allSizes[3].sizeURL}
                        style={{
                          display: "block",
                          margin: "auto",
                          width: "100%",
                        }}
                      />
                    </Card>
                    <Typography
                      sx={{
                        color: "#9A9A9A",
                        fontSize: "0.8rem",
                        textAlign: "center",
                      }}
                    >
                      {allSizes[3].description} ( {allSizes[3].sWeight} -{" "}
                      {allSizes[3].eWeight} kgs )
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <Card
                      sx={{
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                        border: mediumBorder,
                      }}
                      onClick={async () => {
                        setPackageSize(
                          `${allSizes[2].description} ${allSizes[2].sWeight}-${allSizes[2].eWeight}Kgs`
                        );
                        setSmallBorder("0.5px solid #DDDDDD");
                        setMediumBorder("1px solid #2287D9");
                        setLargeBorder("0.5px solid #DDDDDD");
                        setBulkyBorder("0.5px solid #DDDDDD");
                        setssID(allSizes[2].ssID);
                      }}
                    >
                      <img
                        src={allSizes[2].sizeURL}
                        style={{
                          display: "block",
                          margin: "auto",
                          width: "100%",
                        }}
                      />
                    </Card>
                    <Typography
                      sx={{
                        color: "#9A9A9A",
                        fontSize: "0.8rem",
                        textAlign: "center",
                      }}
                    >
                      {allSizes[2].description} ( {allSizes[2].sWeight} -{" "}
                      {allSizes[2].eWeight} kgs )
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  marginBottom: "5%",
                }}
              >
                <Grid item xs={6}>
                  <Box>
                    <Card
                      sx={{
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                        padding: "10%",
                        border: largeBorder,
                      }}
                      onClick={async () => {
                        setPackageSize(
                          `${allSizes[1].description} ${allSizes[1].sWeight}-${allSizes[1].eWeight}Kgs`
                        );
                        setSmallBorder("0.5px solid #DDDDDD");
                        setMediumBorder("0.5px solid #DDDDDD");
                        setLargeBorder("1px solid #2287D9");
                        setBulkyBorder("0.5px solid #DDDDDD");
                        setssID(allSizes[1].ssID);
                      }}
                    >
                      <img
                        src={allSizes[1].sizeURL}
                        style={{
                          display: "block",
                          margin: "auto",
                          width: "100%",
                        }}
                      />
                    </Card>
                    <Typography
                      sx={{
                        color: "#9A9A9A",
                        fontSize: "0.8rem",
                        textAlign: "center",
                      }}
                    >
                      {allSizes[1].description} ( {allSizes[1].sWeight} -{" "}
                      {allSizes[1].eWeight} kgs )
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <Card
                      sx={{
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                        padding: "10%",
                        border: bulkyBorder,
                      }}
                      onClick={async () => {
                        setPackageSize(
                          `${allSizes[0].description} ${allSizes[0].sWeight}-${allSizes[0].eWeight}Kgs`
                        );
                        setSmallBorder("0.5px solid #DDDDDD");
                        setMediumBorder("0.5px solid #DDDDDD");
                        setLargeBorder("0.5px solid #DDDDDD");
                        setBulkyBorder("1px solid #2287D9");
                        setssID(allSizes[0].ssID);
                      }}
                    >
                      <img
                        src={allSizes[0].sizeURL}
                        style={{
                          display: "block",
                          margin: "auto",
                          width: "100%",
                        }}
                      />
                    </Card>
                    <Typography
                      sx={{
                        color: "#9A9A9A",
                        fontSize: "0.8rem",
                        textAlign: "center",
                      }}
                    >
                      {allSizes[0].description} ( {allSizes[0].sWeight} -{" "}
                      {allSizes[0].eWeight} kgs )
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Typography
                sx={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                  color: "red",
                  display: packageSizeError === true ? "block" : "none",
                }}
              >
                Please Choose the Package size
              </Typography>
            </>
          )}

          <Typography>
            Coupon Code{" "}
            <span
              style={{
                fontWeight: "600",
              }}
            >
              {couponCode}
            </span>{" "}
            has applied
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "3%",
            }}
          >
            <Typography>Total Distance : </Typography>
            <Typography> {totalDistance}</Typography>
          </Box>

          <Typography
            sx={{
              marginTop: "2%",
              marginBottom: "2%",
              textAlign: "center",
              fontWeight: "600",
              color:
                priceText == "No amount have to collected" ? "green" : "red",
            }}
          >
            {priceText}
          </Typography>

          <Button
            fullWidth
            variant="outlined"
            sx={{
              marginTop: "5%",
            }}
            onClick={() => {
              if (packageSize === "") {
                setPackageSizeError(true);
              } else {
                ShipmentsizeNpricing();
              }
            }}
          >
            Check
          </Button>

          <Button
            fullWidth
            variant="contained"
            sx={{
              marginTop: "3%",
              display:
                priceText == "No amount have to collected" ? "block" : "none",
            }}
            onClick={() => setOpenDrawerPrice(false)}
          >
            Close
          </Button>

          <Button
            variant="contained"
            fullWidth
            onClick={() => payAmount()}
            sx={{
              marginTop: "3%",
              display:
                priceText != "No amount have to collected" ? "block" : "none",
            }}
          >
            Send Payment Link
          </Button>
        </Box>
      </Drawer>

      <Drawer
        anchor="bottom"
        open={openDrawerUpdate}
        onClose={() => setOpenDrawerUpdate(false)}
      >
        <Box
          sx={{
            padding: "5%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              marginBottom: "5%",
              textAlign: "center",
              fontSize: "1.2rem",
            }}
          >
            Update Order
          </Typography>

          <TextField
            fullWidth
            placeholder="Package Content"
            onChange={(e) => setDescription(e.target.value)}
          />

          <TextField
            fullWidth
            placeholder="Package Value"
            onChange={(e) => setGoodsValue(e.target.value)}
            sx={{
              marginTop: "3%",
            }}
          />

          <Button
            fullWidth
            variant="outlined"
            sx={{
              marginTop: "5%",
            }}
            onClick={() => pickerModifyOrder()}
          >
            Update
          </Button>
        </Box>
      </Drawer>

      <Drawer
        anchor="bottom"
        open={openDrawerMap}
        onClose={() => setOpenDrawerMap(false)}
      >
        <Box
          sx={{
            padding: "5%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              marginBottom: "5%",
              textAlign: "center",
              fontSize: "1.2rem",
            }}
          >
            Select Direction
          </Typography>

          <Box>
            <a
              href={`http://maps.google.com/maps?q=${senderLat},${senderLng}`}
              target="_blank"
            >
              <Button
                variant="contained"
                fullWidth
                color="success"
                sx={{
                  marginBottom: "5%",
                }}
              >
                To Pickup Location
              </Button>
            </a>
            <a
              href={`http://maps.google.com/maps?q=${receiverLat},${receiverLng}`}
              target="_blank"
            >
              <Button
                variant="contained"
                fullWidth
                color="warning"
                sx={{
                  marginBottom: "5%",
                }}
              >
                To delivery Location
              </Button>
            </a>
          </Box>
        </Box>
      </Drawer>

      <Snackbar
        open={openStatus}
        autoHideDuration={6000}
        onClose={() => setOpenStatus(false)}
      >
        <Alert
          onClose={() => setOpenStatus(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Status has been update!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openUpdate}
        autoHideDuration={6000}
        onClose={() => setOpenUpdate(false)}
      >
        <Alert
          onClose={() => setOpenUpdate(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Order has been update!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={() => setOpenErrorAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              textAlign: "center",
            }}
          >
            <CheckCircleIcon
              color="success"
              sx={{
                fontSize: "4rem",
              }}
            />
          </Typography>
          <Typography id="modal-modal-description" sx={{ textAlign: "center" }}>
            Payment link has shared with customer.
          </Typography>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default Picker;
