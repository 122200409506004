import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Button, Typography, Box, LinearProgress, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Images/MediaSlider.css';

const quotes = {
    images: [
        "The strength of a company lies in its ability to deliver efficiently and effectively. That’s where logistics comes in.",
        "A leader in logistics transforms obstacles into pathways, ensuring every product finds its way home.",
        "In logistics, every financial entry is a checkpoint in the journey of goods from origin to destination.",
        "Great logistics marketing isn't a campaign; it's a commitment to excellence in service and innovation.",
        "Good marketing makes the company look smart. Great logistics makes the customer feel smart.",
        "Successful logistics finance is not just about numbers; it's about aligning financial goals with operational excellence.",
        "In logistics, every financial entry is a checkpoint in the journey of goods from origin to destination.",
        "In the logistics sector, the role of IT teams is paramount. They ensure that every link in the supply chain is connected, efficient, and secure.",
        "Amateurs talk about strategy. Professionals talk about logistics.",
        "The future of logistics is not about moving boxes; it’s about enabling global commerce.",
        "In the world of logistics, teams are the architects of efficiency, building bridges of trust and reliability across every shipment.",
    ],
    videos: [
        "In the world of logistics, teams are the architects of efficiency, building bridges of trust and reliability across every shipment.",
    ]
};

const mediaItems = {
    images: [
        { src: "https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1719218421910_0d5deb25f4a929b7bd69a6cda241b564.jpg", type: "image" },
        { src: "https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1719217959853_WhatsApp%20Image%202024-05-24%20at%206.05.44%20PM.jpeg", type: "image" },
        { src: "https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1719218463050_WhatsApp%20Image%202024-05-24%20at%204.25.45%20PM.jpeg", type: "image" },
        { src: "https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1719218520181_WhatsApp%20Image%202024-05-24%20at%204.25.45%20PM%20%282%29.jpeg", type: "image" },
        { src: "https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1719218562535_967546bc6d11be0ebb43a9eec7a931b5.jpg", type: "image" },
        { src: "https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1719218602384_1badfccbf46612299b5e1042f00f729e.jpg", type: "image" },
        { src: "https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1719218652624_bce6b66c3d1bf97b4be8e367f36c22fb.jpg", type: "image" },
        { src: "https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1719218699272_93ed695c5b9b0a8f6420ea2dc603d9e1.jpg", type: "image" },
        { src: "https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1719218740805_cd2457ff452c96d62c55422f8e4376d2.jpg", type: "image" },
        { src: "https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1719218784859_WhatsApp%20Image%202024-05-24%20at%204.25.46%20PM.jpeg", type: "image" },
        { src: "https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1719218833620_bae86dbe70b74235352bae4bd9beb974.jpg", type: "image" },
    ],
    videos: [
        { src: "https://www.youtube.com/embed/qenovn2a4BU?rel=0&autoplay=1", type: "video" },
    ]
};

const StyledButton = styled(Button)({
    margin: '0 10px',
});

const BlackLinearProgress = styled(LinearProgress)({
    backgroundColor: '#f0f0f0',
    width:'100%',
    height: '2px', 
      marginTop: '10px',
    '& .MuiLinearProgress-bar': {
        backgroundColor: 'black',
      
    },
});

const CharmonmanTypography = styled(Typography)({
    fontFamily: 'Charmonman',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '45.6px',
    letterSpacing: '0.02em',
    textAlign: 'left',
});

const MediaSlider = () => {
    const [mediaType, setMediaType] = useState('images');
    const [currentSlide, setCurrentSlide] = useState(0);
    const [progress, setProgress] = useState(0);
    const sliderRef = React.useRef(null);

    const handleBeforeChange = (oldIndex, newIndex) => {
        setCurrentSlide(newIndex);
        setProgress(0);
    };

    const handleMediaTypeChange = (type) => {
        setMediaType(type);
        setCurrentSlide(0);
        setProgress(0);
    };

    useEffect(() => {
        if (mediaType === 'videos') return;

        const interval = setInterval(() => {
            setProgress(prev => {
                if (prev >= 100) {
                    setCurrentSlide((prevSlide) => (prevSlide + 1) % mediaItems[mediaType].length);
                    sliderRef.current.slickNext();
                    return 0;
                }
                return prev + 1;
            });
        }, 80);

        return () => clearInterval(interval);
    }, [mediaType]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        fade: true,
        cssEase: 'linear',
        beforeChange: handleBeforeChange,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        nextArrow: <ArrowForwardIosIcon style={{ color: '#000' }}/>,
        prevArrow: <ArrowBackIosIcon style={{ color: '#000' }} />,
    };

    return (
        <Box p={2}>
            <Box display="flex" justifyContent="flex-start" marginBottom={2}>
                <StyledButton variant="contained" onClick={() => handleMediaTypeChange('images')}>
                    Photos
                </StyledButton>
                <StyledButton variant="contained" onClick={() => handleMediaTypeChange('videos')}>
                    Video
                </StyledButton>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {mediaType === 'images' ? (
                        <Box position="relative">
                            <Slider ref={sliderRef} {...settings}>
                                {mediaItems[mediaType].map((item, index) => (
                                    <div key={index}>
                                        <img src={item.src} alt={`Slider ${index + 1}`} style={{ width: '100%', height: '400px', objectFit: 'cover' }} />
                                    </div>
                                ))}
                            </Slider>
                            <Box position="absolute" bottom={-10} left={0} right={0} display="flex" justifyContent="space-between" alignItems="center" px={2}>
                                <Box flexGrow={1} mr={2}>
                                    <BlackLinearProgress variant="determinate" value={progress} />
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <IconButton onClick={() => {
                                        setCurrentSlide((currentSlide - 1 + mediaItems[mediaType].length) % mediaItems[mediaType].length);
                                        sliderRef.current.slickPrev();
                                    }}>
                                        <ArrowBackIosIcon style={{ color: '#000' }} />
                                    </IconButton>
                                    <IconButton onClick={() => {
                                        setCurrentSlide((currentSlide + 1) % mediaItems[mediaType].length);
                                        sliderRef.current.slickNext();
                                    }}>
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <div style={{ position: 'relative', width: '100%', height: '400px' }}>
                            <iframe
                                src={mediaItems.videos[0].src}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            />
                        </div>
                    )}
                </Grid>
                <Grid item xs={12} md={6} display="flex" alignItems="center" justifyContent="center">
                    <CharmonmanTypography variant="h6">
                        {quotes[mediaType][currentSlide]}
                    </CharmonmanTypography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MediaSlider;