// eslint-disable-next-line 
const local = {
    hostName: "https://localhost",
    cookie_expiration: 364,
    hostNameHeroku: "http://localhost:2020",
    hybridHostName: "https://hapi.logicarts.in",
    ptlHostName: "https://ndd.logicarts.in",
    apiKey: "AIzaSyDJ6m7dujpOLQYOVDL6B5QJdE6cr0sEcuw",
    geoCodeApiKey: "AIzaSyA1-I3n0iwCXvvhAaoANLQ_EVWNhoQrPo4",
    sandeepApiKey: "AIzaSyAXld-X2r3YqR6ah2-Hgk_r90nZo07ykXM"
};

// eslint-disable-next-line no-unused-vars
const prod = {
    hostName: "https://ndd.logicarts.in",
    adminHostName: "https://dev.logicarts.in",
    hybridHostName: "https://hapi.logicarts.in",
    cookie_expiration: 364,
    apiKey: "AIzaSyDJ6m7dujpOLQYOVDL6B5QJdE6cr0sEcuw",
    geoCodeApiKey: "AIzaSyA1-I3n0iwCXvvhAaoANLQ_EVWNhoQrPo4",
    sandeepApiKey: "AIzaSyAXld-X2r3YqR6ah2-Hgk_r90nZo07ykXM"
};



const config = prod;

export default {
    ...config
};
