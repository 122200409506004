import React, { useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import QrCodeIcon from "@mui/icons-material/QrCode";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import "./assests/css/service.css";
import Triangle from "./assests/images/RectangleTRi.svg";
import platform from "./assests/images/platform.png";
import Warehouse from "./assests/images/a-08.svg";
import Ship from "./assests/images/ship.png";
import Truck from "./assests/images/tr-01.png";
import Package from "./assests/images/a-05.svg";
import DeliveryOffice from "./assests/images/d-01-01.png";
import Delivery from "./assests/images/a-03.svg";
import Tracking from "./assests/images/a-04.svg";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import PinDropIcon from "@mui/icons-material/PinDrop";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import WidgetsIcon from '@mui/icons-material/Widgets';

function Services() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#1e1a34",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <img
        src={Triangle}
        style={{
          width: "100%",
        }}
      />
      <Typography
        className="service-header"
        data-aos="fade-up"
        data-aos-delay="120"
        data-aos-duration="3000"
      >
        One Stop Solution For All Your Needs
      </Typography>
      <Container
        sx={{
          paddingTop: "5%",
          overflow: "hidden",
        }}
      >
        <Box className="desktop-view-service">
          <Grid
            container
            spacing={4}
            sx={{
              overflow: "hidden",
            }}
          >
            <Grid item xs={6}>
              <Grid
                container
                sx={{
                  marginTop: "2%",
                }}
                data-aos="fade-up"
                data-aos-delay="120"
                data-aos-duration="3000"
              >
                <Grid item xs={6}>
                  <WidgetsIcon
                    sx={{
                      fontSize: "2.4rem",
                      color: "#fff",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    marginLeft: "-30%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      color: "#dfddddeb",
                    }}
                  >
                    Last Mile
                  </Typography>
                  <Typography
                    sx={{
                      color: "#dfddddeb",
                      fontSize : "0.8rem"
                    }}
                  >
                    Logicarts offers fast, reliable and efficient
                    last mile delivery solutions to ensure your customers
                    receive their orders on time and in excellent condition.
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{
                  marginTop: "5%",
                }}
                data-aos="fade-up"
                data-aos-delay="120"
                data-aos-duration="3000"
              >
                <Grid item xs={6}>
                  <WarehouseIcon
                    sx={{
                      fontSize: "2.4rem",
                      color: "#fff",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    marginLeft: "-30%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      color: "#dfddddeb",
                    }}
                  >
                    Warehousing
                  </Typography>
                  <Typography
                    sx={{
                      color: "#dfddddeb",
                      fontSize : "0.8rem"

                    }}
                  >
                    Logicarts offers secure and efficient
                    warehousing solutions, from receiving and storing goods to
                    order fulfillment and distribution. We provide a safe and
                    organized environment to ensure the proper handling and
                    storage of your products.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  marginTop: "5%",
                  marginBottom: "10%",
                }}
                data-aos="fade-up"
                data-aos-delay="120"
                data-aos-duration="3000"
              >
                <Grid item xs={6}>
                  <DirectionsBoatFilledIcon
                    sx={{
                      fontSize: "2.4rem",
                      color: "#fff",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    marginLeft: "-30%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      color: "#dfddddeb",
                    }}
                  >
                    CNF & Freight Forwarding
                  </Typography>
                  <Typography
                    sx={{
                      color: "#dfddddeb",
                      fontSize : "0.8rem"
                      
                    }}
                  >
                    CNF (Cost and Freight) and freight forwarding services
                    provided by Logicarts ensure smooth and
                    cost-effective transportation of goods. We manage the
                    logistics and shipping process, ensuring timely and safe
                    delivery to your desired location.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                marginTop: "10%",
              }}
            >
              <Grid container spacing={7} className="image-upper">
                <Grid item xs={4}>
                  <img
                    src={Warehouse}
                    style={{
                      width: "100%",
                    }}
                    className="image-2"
                    data-aos="fade-up"
                    data-aos-delay="450"
                    data-aos-duration="3000"
                  />
                </Grid>
                <Grid item xs={4}>
                  <img
                    src={Package}
                    style={{
                      width: "100%",
                    }}
                    className="image-3"
                    data-aos="fade-up"
                    data-aos-delay="550"
                    data-aos-duration="3000"
                  />
                </Grid>
                <Grid item xs={4} className="image-4-truck">
                  <img
                    src={Ship}
                    style={{
                      width: "100%",
                    }}
                    className="image-4"
                    data-aos="fade-up"
                    data-aos-delay="650"
                    data-aos-duration="3000"
                  />
                </Grid>
              </Grid>

              {/* <img
                    src={ship}
                    style={{
                      width: "7%",
                      position : "absolute",
                      marginLeft : "-3%"
                    }}
                    data-aos="fade-up"
                    data-aos-delay="450"
                    data-aos-duration="3000"
                  /> */}

              <img
                src={platform}
                className="middle-image"
                data-aos="zoom-in-up"
                data-aos-delay="200"
                data-aos-duration="1000"
              />

              <Grid container spacing={7} className="bottom-images">
                <Grid item xs={4}>
                  <img
                    src={Truck}
                    style={{
                      width: "100%",
                    }}
                    data-aos="fade-up"
                    data-aos-delay="750"
                    data-aos-duration="3000"
                  />
                </Grid>
                <Grid item xs={4}>
                  <img
                    src={Delivery}
                    style={{
                      width: "100%",
                    }}
                    className="image-5"
                    data-aos="fade-up"
                    data-aos-delay="850"
                    data-aos-duration="3000"
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    marginTop: "-18%",
                    marginLeft: "-14%",
                  }}
                >
                  <img
                    src={Tracking}
                    style={{
                      width: "70%",
                    }}
                    className="image-6"
                    data-aos="fade-up"
                    data-aos-delay="950"
                    data-aos-duration="3000"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box className="tab-view-service">
          <Box>
            <Grid container>
              <Grid item xs={6}>
                <Grid
                  container
                  sx={{
                    marginTop: "5%",
                  }}
                  data-aos="fade-up"
                  data-aos-delay="120"
                  data-aos-duration="3000"
                >
                  <Grid item xs={2}>
                    <WidgetsIcon className="icon-service" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography className="service-head">Last Mile</Typography>
                    <Typography
                      sx={{
                        color: "#dfddddeb",
                      }}
                    >
                      DC to customer, DC to retail store.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  data-aos="fade-up"
                  data-aos-delay="120"
                  data-aos-duration="3000"
                  sx={{
                    marginBottom: "20%",
                  }}
                >
                  <Grid item xs={2}>
                    <WarehouseIcon className="icon-service" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography className="service-head">
                      Warehousing
                    </Typography>
                    <Typography
                      sx={{
                        color: "#dfddddeb",
                      }}
                    >
                      End to end warehousing service to fulfil the demands.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  data-aos="fade-up"
                  data-aos-delay="120"
                  data-aos-duration="3000"
                  sx={{
                    marginBottom: "20%",
                  }}
                >
                  <Grid item xs={2}>
                    <WarehouseIcon className="icon-service" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography className="service-head">
                      Warehousing
                    </Typography>
                    <Typography
                      sx={{
                        color: "#dfddddeb",
                      }}
                    >
                      End to end warehousing service to fulfil the demands.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              paddingLeft: "20%",
              paddingRight: "20%",
              paddingTop: "10%",
            }}
          >
            <Grid container spacing={7} className="image-upper">
              <Grid item xs={4}>
                <img
                  src={Warehouse}
                  style={{
                    width: "100%",
                  }}
                  className="image-2"
                  data-aos="fade-up"
                  data-aos-delay="450"
                  data-aos-duration="3000"
                />
              </Grid>
              <Grid item xs={4}>
                <img
                  src={Package}
                  style={{
                    width: "100%",
                  }}
                  className="image-3"
                  data-aos="fade-up"
                  data-aos-delay="550"
                  data-aos-duration="3000"
                />
              </Grid>
              <Grid item xs={4} className="image-4-truck">
                <img
                  src={Truck}
                  style={{
                    width: "100%",
                  }}
                  className="image-4"
                  data-aos="fade-up"
                  data-aos-delay="650"
                  data-aos-duration="3000"
                />
              </Grid>
            </Grid>

            <img
              src={platform}
              className="middle-image"
              data-aos="zoom-in-up"
              data-aos-delay="200"
              data-aos-duration="1000"
            />

            <Grid container spacing={7} className="bottom-images">
              <Grid item xs={4}>
                <img
                  src={Ship}
                  style={{
                    width: "100%",
                  }}
                  data-aos="fade-up"
                  data-aos-delay="750"
                  data-aos-duration="3000"
                />
              </Grid>
              <Grid item xs={4}>
                <img
                  src={Delivery}
                  style={{
                    width: "100%",
                  }}
                  className="image-5"
                  data-aos="fade-up"
                  data-aos-delay="850"
                  data-aos-duration="3000"
                />
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  marginTop: "-18%",
                  marginLeft: "-14%",
                }}
              >
                <img
                  src={Tracking}
                  style={{
                    width: "100%",
                  }}
                  className="image-6"
                  data-aos="fade-up"
                  data-aos-delay="950"
                  data-aos-duration="3000"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className="mobile-view-service">
          <Box
            sx={{
              paddingLeft: "20%",
              paddingRight: "20%",
              paddingTop: "10%",
            }}
          >
            {/* <Grid container spacing={7} className="image-upper">
              <Grid item xs={4}>
                <img
                  src={Warehouse}
                  style={{
                    width: "100%",
                  }}
                  className="image-2"
                  data-aos="fade-up"
                  data-aos-delay="450"
                  data-aos-duration="3000"
                />
              </Grid>
              <Grid item xs={4}>
                <img
                  src={Package}
                  style={{
                    width: "100%",
                  }}
                  className="image-3"
                  data-aos="fade-up"
                  data-aos-delay="550"
                  data-aos-duration="3000"
                />
              </Grid>
              <Grid item xs={4} className="image-4-truck">
                <img
                  src={Truck}
                  style={{
                    width: "100%",
                  }}
                  className="image-4"
                  data-aos="fade-up"
                  data-aos-delay="650"
                  data-aos-duration="3000"
                />
              </Grid>
            </Grid> */}

            <img
              src={require("./assests/images/Artboard 1 (2).png")}
              data-aos="zoom-in-up"
              data-aos-delay="200"
              data-aos-duration="1000"
              className="mobile-service"
              style={{
                width: "60%",
              }}
            />

            {/* <Grid container spacing={7} className="bottom-images">
              <Grid item xs={4}>
                <img
                  src={DeliveryOffice}
                  style={{
                    width: "100%",
                  }}
                  data-aos="fade-up"
                  data-aos-delay="750"
                  data-aos-duration="3000"
                />
              </Grid>
              <Grid item xs={4}>
                <img
                  src={Delivery}
                  style={{
                    width: "100%",
                  }}
                  className="image-5"
                  data-aos="fade-up"
                  data-aos-delay="850"
                  data-aos-duration="3000"
                />
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  marginTop: "-18%",
                  marginLeft: "-14%",
                }}
              >
                <img
                  src={Tracking}
                  style={{
                    width: "100%",
                  }}
                  className="image-6"
                  data-aos="fade-up"
                  data-aos-delay="950"
                  data-aos-duration="3000"
                />
              </Grid>
            </Grid> */}
          </Box>
          <Box
            sx={{
              padding: "5%",
            }}
          >
            <Grid
              container
              sx={{
                marginTop: "55%",
              }}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{
                    marginTop: "5%",
                    marginBottom: "10%",

                  }}
                  data-aos="fade-up"
                  data-aos-delay="120"
                  data-aos-duration="3000"
                >
                  <Grid item xs={2}>
                    <WidgetsIcon className="icon-service" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography className="service-head">Last Mile</Typography>
                    <Typography
                      sx={{
                        color: "#dfddddeb",
                      }}
                    >
                      Logicarts offers fast, reliable and efficient
                      last mile delivery solutions to ensure your customers
                      receive their orders on time and in excellent condition.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  sx={{
                    marginTop: "2%",
                    marginBottom: "10%",

                  }}
                  data-aos="fade-up"
                  data-aos-delay="120"
                  data-aos-duration="3000"
                >
                  <Grid item xs={2}>
                    <WarehouseIcon className="icon-service" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography className="service-head">
                      Warehousing
                    </Typography>
                    <Typography
                      sx={{
                        color: "#dfddddeb",
                      }}
                    >
                      Logicarts offers secure and efficient
                      warehousing solutions, from receiving and storing goods to
                      order fulfillment and distribution. We provide a safe and
                      organized environment to ensure the proper handling and
                      storage of your products.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{
                    marginTop: "2%",
                    marginBottom: "10%",
                  }}
                  data-aos="fade-up"
                  data-aos-delay="120"
                  data-aos-duration="3000"
                >
                  <Grid item xs={2}>
                    <DirectionsBoatFilledIcon className="icon-service" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography className="service-head">
                    CNF & Freight Forwarding
                    </Typography>
                    <Typography
                      sx={{
                        color: "#dfddddeb",
                      }}
                    >
                      CNF (Cost and Freight) and freight forwarding services
                      provided by Logicarts ensure smooth and
                      cost-effective transportation of goods. We manage the
                      logistics and shipping process, ensuring timely and safe
                      delivery to your desired location.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Services;
