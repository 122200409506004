import React, { useEffect } from 'react';
import { Typography, Divider, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import './Images/banner.css';
import anime from 'animejs';

const BannerContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '400px',
  width: '100%',
  maxWidth: '100%',
  backgroundImage: `url(https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1718815278660_WhatsApp%20Image%202024-05-24%20at%204.25.46%20PM.jpg)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: '#fff',
  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
  margin: 0,
  padding: 0,
  overflow: 'hidden',
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0))',
    opacity: 0.7,
    zIndex: 1,
  },
  '> *': {
    position: 'relative',
    zIndex: 2,
  },
}));

const BannerText = styled(Typography)(({ theme }) => ({
  fontSize: '3rem',
  fontWeight: 'bold',
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const BannerContent = styled('div')(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  padding: '20px 0',
  marginLeft: '25px',
  [theme.breakpoints.down('md')]: {
    marginLeft: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
  },
}));

const Banner = () => {
  useEffect(() => {
    const textWrapper = document.querySelectorAll('.ml3');
    textWrapper.forEach(wrapper => {
      wrapper.innerHTML = wrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
    });

    anime.timeline({ loop: false })
      .add({
        targets: '.ml3 .letter',
        opacity: [0, 1],
        easing: "easeInOutQuad",
        duration: 500,
        delay: (el, i) => 25 * (i + 1) 
      });
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <BannerContainer className="banner">
      <BannerContent fontFamily="Roboto">
        <Typography
          className="ml3"
          variant="h3"
          component="h1"
          sx={{
            fontSize: isSmallScreen ? '16px' : '20px',
            fontWeight: 'bold',
            marginBottom: '10px',
            marginTop: isSmallScreen ? '40px' : '55px',
          }}
        >
          Logicarts Celebrates 5 Years of Innovation and Excellence!
        </Typography>
        <Divider
          className="ml3"
          sx={{
            bgcolor: 'white',
            my: 2,
            width: isSmallScreen ? '20%' : '10%',
            marginBottom: '5px',
          }}
        />
        <Typography
          className="ml3"
          variant="h6"
          component="p"
          sx={{
            fontWeight: 'bold',
            marginBottom: isSmallScreen ? '65px' : '85px',
            fontSize: isSmallScreen ? '12px' : 'inherit',
          }}
        >
          Join us as we celebrate a significant milestone – Logicart’s 5th anniversary! Over the past five years, we've journeyed through innovation, creativity, and excellence, transforming ideas into reality and setting new standards in the industry. In this special video, we look back at our achievements, share memorable moments, and express our heartfelt gratitude to our incredible team, loyal clients, and supportive community. Thank you for being part of our journey.
        </Typography>
      </BannerContent>
    </BannerContainer>
  );
};

export default Banner;
