import React, { Fragment, useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { Card, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";

import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import backImage from "../CreateOrder/assests/images/bgImage.png";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import Geocode from "react-geocode";

import MyLocationRoundedIcon from "@mui/icons-material/MyLocationRounded";
import Drawer from "@mui/material/Drawer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CircularProgress from "@mui/material/CircularProgress";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import AccountCircle from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import TimelineIcon from "@mui/icons-material/Timeline";
import LoadingButton from "@mui/lab/LoadingButton";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function UpdateAddressDesktop() {
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [completeAddress, setCompleteAdress] = useState("");
  const [loading, setLoading] = useState(false);

  const [openProfile, setOpenProfile] = useState(false);
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [openDrawer, setOpenDrawer] = useState(false);
  const [saveAs, setSaveAs] = useState("");

  const [btnLoading, setBtnLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [nameErrorText, setNameErrorText] = useState("");

  const [buildingError, setBuildingError] = useState(false);
  const [buildingErrorText, setBuildingErrorText] = useState("");

  const [mobileError, setMobileError] = useState(false);
  const [mobileErrorText, setMobileErrorText] = useState("");

  const [DescriptionError, setDescriptionError] = useState(false);
  const [DescriptionErrorText, setDescriptionErrorText] = useState("");

  const [openFromDrawer, setOpenFromDrawer] = useState(false);
  const [openToDrawer, setOpenToDrawer] = useState(false);

  const [Coordinates, setCoordinates] = useState("");
  const [Address, setAddress] = useState("");
  const [Building, setBuilding] = useState("");
  const [Name, setName] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Description, setDescription] = useState("");
  const [Pincode, setPincode] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Landmark, setLandmark] = useState("");

  const [openEditProfile, setOpenEditProfile] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorText, setfirstNameErrorText] = useState("");

  const [lastnameError, setLastNameError] = useState(false);
  const [lastnameErrorText, setlastnameErrorText] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");

  const [showGiveName, setShowGiveName] = useState("none");
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [PincodeError, setPincodeError] = useState(false);
  const [PincodeErrorText, setPincodeErrorText] = useState("");

  const [CityError, setCityError] = useState(false);
  const [CityErrorText, setCityErrorText] = useState("");

  const [StateError, setStateError] = useState(false);
  const [StateErrorText, setStateErrorText] = useState("");

  const vertical = "top";
  const horizontal = "center";

  const containerStyle = {
    width: "100%",
    height: "300px",
    borderRadius: "10px",
  };

  useEffect(() => {
    (async () => {
      const parseData = JSON.parse(location.state.jdata);
      console.log(parseData);
      const parseCoords = parseData.Coordinates.split(",");
      console.log(parseCoords);

      if (
        !localStorage.getItem("searchLat") &&
        !localStorage.getItem("searchLng")
      ) {
        setMapCenter({
          lat: Number(parseCoords[0]),
          lng: Number(parseCoords[1]),
        });
        setBuilding(parseData.Building);
        setName(parseData.Name);
        setMobile(parseData.Mobile);
        await getAddress(
          parseFloat(parseCoords[0]),
          parseFloat(parseCoords[1])
        );
      } else {
        await getSearchedAddress();
        console.log(Number(localStorage.getItem("searchLat")));
        console.log(localStorage.getItem("searchLng"));
      }
      await verifyToken();
      await getData();
    })();
  }, []);


  const getData = async () => {
    const userDetails = localStorage.getItem("userDetails");
    const parseData = await JSON.parse(userDetails);
    const parseJdata = await JSON.parse(parseData.jdata);
    setMobile(parseData.mobile);
    setUserName(parseData.firstname + " " + parseData.lastname);
    setUserMobile(parseData.mobile);
    setUserEmail(parseJdata.email);
  };

  const verifyToken = async () => {
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    const responseJson = await response.json();
    console.log(responseJson, "verify");
    if (responseJson.success == true) {
      console.log("authenticated");
    } else {
      navigate("/express/user-login");
    }
  };

  const onMarkerDragEnd = async (coord, index) => {
    setLoading(true);
    const { latLng } = await coord;
    const lat = await latLng.lat();
    const lng = await latLng.lng();

    await getAddress(lat, lng);
  };

  Geocode.setApiKey("AIzaSyDJ6m7dujpOLQYOVDL6B5QJdE6cr0sEcuw");
  Geocode.setLanguage("en");
  Geocode.setRegion("in");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();

  const getAddress = async (latitude, longitude) => {
    console.log("qwertyui");
    await Geocode.fromLatLng(`${latitude}`, `${longitude}`).then(
      async (response) => {
        const address = await response.results[0].formatted_address;
        setCompleteAdress(address);
        setLoading(false);
        setCoordinates(`${latitude}, ${longitude}`);
        setAddress(address);
        const filterPostalCode =
          await response.results[0].address_components.filter(
            (value) => value.types[0] == "postal_code"
          );
        const filterCity = await response.results[0].address_components.filter(
          (value) => value.types[0] == "administrative_area_level_3"
        );
        const filterState = await response.results[0].address_components.filter(
          (value) => value.types[0] == "administrative_area_level_1"
        );
        setPincode(filterPostalCode[0].long_name);
        setCity(filterCity[0].long_name);
        setState(filterState[0].long_name);
      },
      (error) => {
        console.error(error);
        setLoading(false);
        setErrorMsg("Unable to fetch try again later");
        setOpenErrorAlert(true)
      }
    );
  };

  const getSearchedAddress = async () => {
    setLoading(true);
    setMapCenter({
      lat: Number(localStorage.getItem("searchLat")),
      lng: Number(localStorage.getItem("searchLng")),
    });
    await getAddress(
      Number(localStorage.getItem("searchLat")),
      Number(localStorage.getItem("searchLng"))
    );
  };

  const manageAddress = async () => {
    setBtnLoading(true);
    setBtnDisabled(true);

    const userDetails = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDetails);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/manageAddress",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          cxID: `${parseData.cxID}`,
          cxAdID: location.state.cxAdID,
          jdata: {
            Description: Description,
            Pincode: Pincode,
            isDefault: "",
            categoryID: "",
            Coordinates: Coordinates,
            Address: Address,
            Building: Building,
            Name: Name,
            Mobile: Mobile,
            City: City,
            State: State,
            Landmark: Landmark,
          },
        }),
      }
    );

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.success == true) {
      navigate("/express/create-order");
      setBtnLoading(false);
      setBtnDisabled(false);
    } else {
      setBtnLoading(false);
      setBtnDisabled(false);
      setErrorMsg("Unable to save your address");
      setOpenErrorAlert(true)
    }
  };

  

  const logOut = async () => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("token");
    window.location.reload();
  };

  const updateProfile = async () => {
    const userDeatils = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDeatils);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          cxID: parseData.cxID,
          mobile: parseData.mobile,
          jdata: {
            FromAddresses: [
              { Description: "", Address: "", Contact01: "", IsDefault: "" },
              { Description: "", Address: "", Contact01: "", IsDefault: "" },
            ],
            ToAddresses: [
              { Description: "", Address: "", Contact01: "" },
              { Description: "", Address: "", Contact01: "" },
            ],
            firstname: firstName,
            lastname: lastname,
            email: email,
          },
        }),
      }
    );

    const responseJson = await response.json();
    if (responseJson.success == true) {
      alert(
        "Profile update successfully. Kindly logout and login again to see the changes."
      );
      setOpenEditProfile(false);
    } else {
      setErrorMsg("Unable to update the profile");
      setOpenErrorAlert(true)
    }
  };

  return (
    <Fragment>
      <Box className="desktop-map">
        <Box
          sx={{
            backgroundImage: `url(${backImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <AppBar
              position="static"
              sx={{
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Toolbar>
                <img
                  src={require("../CreateOrder/assests/images/logo_new1.png")}
                  style={{
                    width: "10%",
                  }}
                />

                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <IconButton
                    size="large"
                    onClick={() => navigate("/express/create-order")}
                  >
                    <Box>
                      <AddIcon
                        sx={{
                          color: "#2287D9",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#2287D9",
                        }}
                      >
                        Create Pickup
                      </Typography>
                    </Box>
                  </IconButton>
                  <IconButton
                    size="large"
                    onClick={() => navigate("/express/order-history")}
                  >
                    <Box>
                      <TimelineIcon />
                      <Typography>Track Order</Typography>
                    </Box>
                  </IconButton>
                  <IconButton
                    size="large"
                    edge="end"
                    aria-haspopup="true"
                    onClick={() => setOpenProfile(true)}
                  >
                    <Box>
                      <AccountCircle />
                      <Typography>Profile</Typography>
                    </Box>
                  </IconButton>
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
          <Box
            sx={{
              marginTop: "2%",
            }}
          >
            <Card
              sx={{
                width: "50%",
                display: "block",
                margin: "auto",
                boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.1)",
                borderRadius: "16px",
                padding: "2%",
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  fontWeight: "700",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                Add address
              </Typography>
              <Box
                sx={{
                  border: ".5px solid #C6C6C6",
                }}
              />

              <Box
                sx={{
                  marginTop: "3%",
                }}
              >
                <TextField
                  fullWidth
                  placeholder="Search your location"
                  style={{
                    border: "0.5px solid #DDDDDD",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                    borderRadius: "4px",
                  }}
                  onClick={() =>
                    navigate("/express/search-address", {
                      state: {
                        type: location.state.type,
                      },
                    })
                  }
                />
                <Box
                  sx={{
                    marginTop: "3%",
                    borderRadius: "8px",
                  }}
                >
                  <LoadScript googleMapsApiKey="AIzaSyDJ6m7dujpOLQYOVDL6B5QJdE6cr0sEcuw">
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={mapCenter}
                      zoom={20}
                    >
                      <>
                        <MarkerF
                          position={mapCenter}
                          draggable
                          onDragEnd={async (coord) =>
                            await onMarkerDragEnd(coord)
                          }
                        />
                      </>
                    </GoogleMap>
                  </LoadScript>
                  
                </Box>

                <Typography
                  sx={{
                    marginTop: "5%",
                  }}
                >
                  Address
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Current Address"
                  sx={{
                    border: "0.5px solid #DDDDDD",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                    borderRadius: "4px",
                  }}
                  value={completeAddress}
                  defaultValue={completeAddress}
                />

                <LoadingButton
                  fullWidth
                  variant="contained"
                  sx={{
                    marginTop: "5%",
                  }}
                  loading={btnLoading}
                  disabled={btnDisabled}
                  onClick={() => setOpenFromDrawer(true)}
                >
                  Confirm Location
                </LoadingButton>
              </Box>
            </Card>
          </Box>

          <Modal
            open={loading}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                border: "1px soild grey",
                p: 4,
              }}
            >
              <Box
                sx={{
                  padding: "10%",
                }}
              >
                <CircularProgress
                  sx={{
                    display: "block",
                    margin: "auto",
                  }}
                />
              </Box>
            </Box>
          </Modal>

          <Drawer
            anchor="right"
            open={openProfile}
            onClose={() => setOpenProfile(false)}
            PaperProps={{
              sx: { width: "30%" },
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  textAlign: "center",
                  marginTop: "3%",
                  fontWeight: "600",
                }}
              >
                User Profile
              </Typography>
              <Box
                sx={{
                  padding: "5%",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <AccountCircleIcon
                    sx={{
                      fontSize: "8rem",
                      color: "grey",
                    }}
                  />
                  <Typography sx={{
                textAlign : "center"
              }}>{userName}</Typography>
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "5%",
                  padding: "5%",
                  overflow: "hidden",
                }}
              >
                <Card
                  sx={{
                    padding: "3%",
                    marginBottom: "5%",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#807d7d",
                    }}
                  >
                    {userMobile}
                  </Typography>
                </Card>
                <Card
                  sx={{
                    padding: "3%",
                    marginBottom: "5%",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#807d7d",
                    }}
                  >
                    {userEmail}
                  </Typography>
                </Card>
                <Card
                  sx={{
                    padding: "3%",
                    marginBottom: "5%",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#807d7d",
                    }}
                  >
                    Manage Address
                  </Typography>
                </Card>
                <Card
                  sx={{
                    padding: "3%",
                    marginBottom: "5%",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#807d7d",
                    }}
                  >
                    Customer Support
                  </Typography>
                </Card>

                <Button variant="contained" fullWidth>
                  Edit Profile
                </Button>
              </Box>
            </Box>
          </Drawer>

          <Drawer
            anchor="right"
            open={openProfile}
            onClose={() => setOpenProfile(false)}
            PaperProps={{
              sx: { width: "30%" },
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5%",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "1.2rem",
                      textAlign: "center",
                      marginTop: "3%",
                      fontWeight: "600",
                    }}
                  >
                    User Profile
                  </Typography>
                </Box>
                <Box>
                  <Button onClick={() => setOpenEditProfile(true)}>Edit</Button>
                </Box>
              </Box>

              <Box
                sx={{
                  padding: "5%",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <AccountCircleIcon
                    sx={{
                      fontSize: "8rem",
                      color: "grey",
                    }}
                  />
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "5%",
                  padding: "5%",
                  overflow: "hidden",
                }}
              >
                <Card
                  sx={{
                    padding: "3%",
                    marginBottom: "5%",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#807d7d",
                    }}
                  >
                    {userMobile}
                  </Typography>
                </Card>
                <Card
                  sx={{
                    padding: "3%",
                    marginBottom: "5%",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#807d7d",
                    }}
                  >
                    {userEmail}
                  </Typography>
                </Card>
                <Card
                  sx={{
                    padding: "3%",
                    marginBottom: "5%",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#807d7d",
                    }}
                  >
                    Manage Address
                  </Typography>
                </Card>
                <Card
                  sx={{
                    padding: "3%",
                    marginBottom: "5%",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#807d7d",
                    }}
                  >
                    Customer Support
                  </Typography>
                </Card>

                <Button variant="contained" fullWidth onClick={() => logOut()}>
                  Log Out
                </Button>
              </Box>
            </Box>
          </Drawer>
        </Box>
      </Box>

      <Drawer
          anchor="bottom"
          open={openFromDrawer}
          onClose={() => setOpenFromDrawer(false)}
        >
          <Box
            sx={{
              padding: "3%",
            }}
          >
            <Stack direction="row" spacing={2}>
              <Typography>
                <LocationOnIcon />
              </Typography>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "600",
                  }}
                >
                  {Building}
                </Typography>
                <Typography>{completeAddress}</Typography>
              </Box>
            </Stack>
            <Box
              sx={{
                marginTop: "5%",
                padding: "2%",
              }}
            >
              <TextField
                fullWidth
                variant="standard"
                placeholder="House/Flat/Block No."
                sx={{
                  marginBottom: "5%",
                }}
                value={Building}
                defaultValue={Building}
                onChange={(e) => {
                  setBuildingError(false);
                  setBuildingErrorText("");
                  setBuilding(e.target.value);
                }}
                error={buildingError}
                helperText={buildingErrorText}
              />

              <TextField
                fullWidth
                variant="standard"
                placeholder="Name"
                sx={{
                  marginBottom: "5%",
                }}
                value={Name}
                defaultValue={Name}
                onChange={(e) => {
                  setNameError(false);
                  setNameErrorText("");
                  setName(e.target.value);
                }}
                error={nameError}
                helperText={nameErrorText}
              />
              <TextField
                fullWidth
                variant="standard"
                placeholder="Phone Number"
                value={Mobile}
                defaultValue={Mobile}
                sx={{
                  marginBottom: "5%",
                }}
                onChange={(e) => {
                  setMobileError(false);
                  setMobileErrorText("");
                  setMobile(e.target.value);
                }}
                error={mobileError}
                helperText={mobileErrorText}
              />

              <TextField
                fullWidth
                variant="standard"
                placeholder="Landmark (optional)"
                sx={{
                  marginBottom: "5%",
                }}
                onChange={(e) => {
                  setLandmark(e.target.value);
                }}
              />

              <FormControl
                fullWidth
                error={DescriptionError}
                helperText={DescriptionErrorText}
              >
                <InputLabel id="demo-simple-select-label">Save As</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={Description}
                  label="Save As"
                  onChange={(event) => {
                    setDescription(event.target.value);
                    if (event.target.value == "Other") {
                      setShowGiveName("block");
                    } else {
                      setShowGiveName("none");
                    }
                  }}
                >
                  <MenuItem value={"Home"}>Home</MenuItem>
                  <MenuItem value={"Friends/Family"}>Friends/Family</MenuItem>
                  <MenuItem value={"Work"}>Work</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>

              <TextField
                fullWidth
                variant="standard"
                placeholder="Give Name"
                sx={{
                  marginBottom: "5%",
                  marginTop: "5%",
                  display: showGiveName,
                }}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />


              <LoadingButton
                fullWidth
                variant="contained"
                sx={{
                  marginTop: "5%",
                }}
                loading={btnLoading}
                disabled={btnDisabled}
                onClick={async () => {
                  if (Building == "") {
                    setBuildingError(true);
                    setBuildingErrorText("please provide the information");
                  } else if (Name == "") {
                    setNameError(true);
                    setNameErrorText("Please provide Name");
                  } else if (Mobile == "") {
                    setMobileError(true);
                    setMobileErrorText("Please provide mobile number");
                  } else if (Mobile.length !== 10) {
                    setMobileError(true);
                    setMobileErrorText("Please provide correct mobile number");
                  } else if (Description == "") {
                    setDescriptionError(true);
                    setDescriptionErrorText(
                      "Please save your location as label"
                    );
                  }  else {
                    await manageAddress();
                  }
                }}
              >
                Save Address
              </LoadingButton>
            </Box>
          </Box>
        </Drawer>

      <Drawer
        anchor="right"
        open={openEditProfile}
        onClose={() => setOpenEditProfile(false)}
        PaperProps={{
          sx: { width: "30%" },
        }}
      >
        <Box
          sx={{
            padding: "5%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
            }}
          >
            Update Your Details
          </Typography>

          <TextField
            fullWidth
            placeholder="First Name"
            sx={{
              marginTop: "4%",
            }}
            onChange={(e) => {
              setFirstNameError(false);
              setfirstNameErrorText("");
              setFirstName(e.target.value);
            }}
            error={firstNameError}
            helperText={firstNameErrorText}
          />
          <TextField
            fullWidth
            placeholder="Last Name"
            sx={{
              marginTop: "4%",
            }}
            onChange={(e) => {
              setLastNameError(false);
              setlastnameErrorText("");
              setLastName(e.target.value);
            }}
            error={lastnameError}
            helperText={lastnameErrorText}
          />

          <TextField
            fullWidth
            placeholder="Email Address"
            sx={{
              marginTop: "4%",
            }}
            onChange={(e) => {
              setEmailError(false);
              setEmailErrorText("");
              setEmail(e.target.value);
            }}
            error={emailError}
            helperText={emailErrorText}
          />

          <Button
            fullWidth
            variant="contained"
            sx={{
              marginTop: "5%",
            }}
            onClick={async () => {
              if (firstName == "") {
                setFirstNameError(true);
                setfirstNameErrorText("Please provide the first name");
              } else if (lastname == "") {
                setLastNameError(true);
                setlastnameErrorText("Please provide the last name");
              } else if (email == "") {
                setEmailError(true);
                setEmailErrorText("Please provide the email address");
              } else {
                await updateProfile();
              }
            }}
          >
            Update Details
          </Button>
        </Box>
      </Drawer>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={() => setOpenErrorAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export default UpdateAddressDesktop;
