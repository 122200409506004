import React, { Fragment, useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Card } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import IconButton from "@mui/material/IconButton";
import backImage from "../CreateOrder/assests/images/bgImage.png";

import AccountCircle from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import TimelineIcon from "@mui/icons-material/Timeline";

import Drawer from "@mui/material/Drawer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function SearchAddress() {
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [completeAddress, setCompleteAdress] = useState("");
  const [loading, setLoading] = useState(false);

  const [openProfile, setOpenProfile] = useState(false);
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [openEditProfile, setOpenEditProfile] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorText, setfirstNameErrorText] = useState("");

  const [lastnameError, setLastNameError] = useState(false);
  const [lastnameErrorText, setlastnameErrorText] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const vertical = "top";
  const horizontal = "center";

  useEffect(() => {
    (async () => {
      await verifyToken();
      await getData();
    })();
  }, []);

  const getData = async () => {
    const userDetails = localStorage.getItem("userDetails");
    const parseData = await JSON.parse(userDetails);
    const parseJdata = await JSON.parse(parseData.jdata);
    setUserName(parseData.firstname + " " + parseData.lastname);
    setUserMobile(parseData.mobile);
    setUserEmail(parseJdata.email);
  };

  const verifyToken = async () => {
    const userToken = localStorage.getItem("token");
    console.log(userToken);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    const responseJson = await response.json();
    console.log(responseJson, "verify");
    if (responseJson.success == true) {
      console.log("authenticated");
    } else {
      navigate("/express/user-login");
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logOut = async () => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("token");
    window.location.reload();
  };

  const updateProfile = async () => {
    const userDeatils = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDeatils);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          cxID: parseData.cxID,
          mobile: parseData.mobile,
          jdata: {
            FromAddresses: [
              { Description: "", Address: "", Contact01: "", IsDefault: "" },
              { Description: "", Address: "", Contact01: "", IsDefault: "" },
            ],
            ToAddresses: [
              { Description: "", Address: "", Contact01: "" },
              { Description: "", Address: "", Contact01: "" },
            ],
            firstname: firstName,
            lastname: lastname,
            email: email,
          },
        }),
      }
    );

    const responseJson = await response.json();
    if (responseJson.success == true) {
      alert(
        "Profile update successfully. Kindly logout and login again to see the changes."
      );
      setOpenEditProfile(false);
    } else {
      setErrorMsg("Unable to update the profile");
      setOpenErrorAlert(true)
    }
  };


  return (
    <Fragment>
      <Box
        sx={{
          backgroundImage: `url(${backImage})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            sx={{
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
            }}
          >
            <Toolbar>
              <img
                src={require("../CreateOrder/assests/images/logo_new1.png")}
                style={{
                  width: "10%",
                }}
              />

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <IconButton
                  size="large"
                  onClick={() => navigate("/express/create-order")}
                >
                  <Box>
                    <AddIcon />
                    <Typography>Create Order</Typography>
                  </Box>
                </IconButton>
                <IconButton
                  size="large"
                  onClick={() => navigate("/express/order-history")}
                >
                  <Box>
                    <TimelineIcon />
                    <Typography>Track Order</Typography>
                  </Box>
                </IconButton>
                <IconButton
                  size="large"
                  edge="end"
                  aria-haspopup="true"
                  onClick={() => setOpenProfile(true)}
                >
                  <Box>
                    <AccountCircle />
                    <Typography>Profile</Typography>
                  </Box>
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
        <Box
          sx={{
            marginTop: "15%",
          }}
        >
          <Card
            sx={{
              width: "50%",
              display: "block",
              margin: "auto",
              boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.1)",
              borderRadius: "16px",
              padding: "2%",
              height: "300px",
            }}
          >
            <Typography
              gutterBottom
              sx={{
                fontWeight: "700",
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              Search Address
            </Typography>
            <Box
              sx={{
                border: ".5px solid #C6C6C6",
              }}
            />
            <Box
              sx={{
                marginTop: "5%",
              }}
            >
              <Autocomplete
                apiKey="AIzaSyDJ6m7dujpOLQYOVDL6B5QJdE6cr0sEcuw"
                style={{
                  width: "95%",
                  height: "40px",
                  border: "0.5px solid #DDDDDD",
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                  borderRadius: "4px",
                  paddingLeft: "2%",
                }}
                onPlaceSelected={(place) => {
                  console.log(place);
                  localStorage.setItem(
                    "searchLat",
                    place.geometry.location.lat()
                  );
                  localStorage.setItem(
                    "searchLng",
                    place.geometry.location.lng()
                  );
                  navigate("/express/map-address", {
                    state: {
                      type: location.state.type,
                    },
                  });
                }}
                placeholder="Search your address"
                options={{
                  types: ["(regions)"],
                  componentRestrictions: { country: "in" },
                }}
              />
            </Box>
          </Card>
        </Box>
        <Drawer
          anchor="right"
          open={openProfile}
          onClose={() => setOpenProfile(false)}
          PaperProps={{
            sx: { width: "30%" },
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5%",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "1.2rem",
                    textAlign: "center",
                    marginTop: "3%",
                    fontWeight: "600",
                  }}
                >
                  User Profile
                </Typography>
              </Box>
              <Box>
                <Button>Edit</Button>
              </Box>
            </Box>

            <Box
              sx={{
                padding: "5%",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                <AccountCircleIcon
                  sx={{
                    fontSize: "8rem",
                    color: "grey",
                  }}
                />
                <Typography sx={{
                textAlign : "center"
              }}>{userName}</Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "5%",
                padding: "5%",
                overflow: "hidden",
              }}
            >
              <Card
                sx={{
                  padding: "3%",
                  marginBottom: "5%",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <Typography
                  sx={{
                    color: "#807d7d",
                  }}
                >
                  {userMobile}
                </Typography>
              </Card>
              <Card
                sx={{
                  padding: "3%",
                  marginBottom: "5%",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <Typography
                  sx={{
                    color: "#807d7d",
                  }}
                >
                  {userEmail}
                </Typography>
              </Card>
              <Card
                sx={{
                  padding: "3%",
                  marginBottom: "5%",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <Typography
                  sx={{
                    color: "#807d7d",
                  }}
                >
                  Manage Address
                </Typography>
              </Card>
              <Card
                sx={{
                  padding: "3%",
                  marginBottom: "5%",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <Typography
                  sx={{
                    color: "#807d7d",
                  }}
                >
                  Customer Support
                </Typography>
              </Card>

              <Button variant="contained" fullWidth onClick={() => logOut()}>
                Log Out
              </Button>
            </Box>
          </Box>
        </Drawer>

        <Drawer
          anchor="right"
          open={openEditProfile}
          onClose={() => setOpenEditProfile(false)}
          PaperProps={{
            sx: { width: "30%" },
          }}
        >
          <Box
            sx={{
              padding: "5%",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              Update Your Details
            </Typography>

            <TextField
              fullWidth
              placeholder="First Name"
              sx={{
                marginTop: "4%",
              }}
              onChange={(e) => {
                setFirstNameError(false);
                setfirstNameErrorText("");
                setFirstName(e.target.value);
              }}
              error={firstNameError}
              helperText={firstNameErrorText}
            />
            <TextField
              fullWidth
              placeholder="Last Name"
              sx={{
                marginTop: "4%",
              }}
              onChange={(e) => {
                setLastNameError(false);
                setlastnameErrorText("");
                setLastName(e.target.value);
              }}
              error={lastnameError}
              helperText={lastnameErrorText}
            />

            <TextField
              fullWidth
              placeholder="Email Address"
              sx={{
                marginTop: "4%",
              }}
              onChange={(e) => {
                setEmailError(false);
                setEmailErrorText("");
                setEmail(e.target.value);
              }}
              error={emailError}
              helperText={emailErrorText}
            />

            <Button
              fullWidth
              variant="contained"
              sx={{
                marginTop: "5%",
              }}
              onClick={async () => {
                if (firstName == "") {
                  setFirstNameError(true);
                  setfirstNameErrorText("Please provide the first name");
                } else if (lastname == "") {
                  setLastNameError(true);
                  setlastnameErrorText("Please provide the last name");
                } else if (email == "") {
                  setEmailError(true);
                  setEmailErrorText("Please provide the email address");
                } else {
                  await updateProfile();
                }
              }}
            >
              Update Details
            </Button>
          </Box>
        </Drawer>
      </Box>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={() => setOpenErrorAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export default SearchAddress;
