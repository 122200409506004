import React, { useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import AOS from "aos";
import "aos/dist/aos.css";
import "./assests/css/choose.css";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import ShareLocationOutlinedIcon from "@mui/icons-material/ShareLocationOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

function ChooseUs() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Box>
      <Box className="desktop-view-choose-us">
        <Typography
          sx={{
            fontSize: "2rem",
            textAlign: "center",
            marginTop: "3rem",
            fontWeight: 600,
            color: "#1b8fd2",
          }}
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          Why Choose Us?
        </Typography>
        <Typography
          sx={{
            fontSize: "1.3rem",
            textAlign: "center",
            color: "#1b8fd2",
          }}
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          Find Reason To Choose Us As Your Freight Partner
        </Typography>

        <Grid
          container
          sx={{
            padding: "6%",
          }}
        >
          <Grid
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            item
            xs={6}
          >
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "5%",
              }}
            >
              <Box>
                <AccessTimeIcon
                  sx={{
                    fontSize: "3rem",
                    color: "#1a8fd1",
                  }}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "3%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    lineHeight: "28px",
                  }}
                >
                  On-time delivery
                </Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  Logicarts strives for 100% on-time delivery,
                  ensuring our customers receive their shipments within the
                  agreed timeframe. With advanced technology, experienced staff
                  and efficient processes, we deliver reliable and trustworthy
                  logistics solutions.
                </Typography>
              </Box>
            </Container>
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "5%",
              }}
            >
              <Box>
                <SupportAgentIcon
                  sx={{
                    fontSize: "3rem",
                    color: "#1a8fd1",
                  }}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "3%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    lineHeight: "28px",
                  }}
                >
                  24/7 support
                </Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  Logicarts provides 24/7 support for all your
                  shipping needs. With a dedicated and knowledgeable support
                  team, we're here to answer your questions and resolve any
                  issues, ensuring a smooth and stress-free shipping experience.
                </Typography>
              </Box>
            </Container>
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "5%",
              }}
            >
              <Box>
                <SavingsOutlinedIcon
                  sx={{
                    fontSize: "3rem",
                    color: "#1a8fd1",
                  }}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "3%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    lineHeight: "28px",
                  }}
                >
                  Reduce Costs
                </Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  Reduce shipping costs with Logicarts. We use
                  innovative technology and data-driven processes to optimize
                  routes, streamline operations, and minimize waste, resulting
                  in cost-effective and sustainable logistics solutions for your
                  business.
                </Typography>
              </Box>
            </Container>
          </Grid>
          <Grid
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            item
            xs={6}
          >
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "5%",
              }}
            >
              <Box>
                <ShareLocationOutlinedIcon
                  sx={{
                    fontSize: "3rem",
                    color: "#1a8fd1",
                  }}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "3%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    lineHeight: "28px",
                  }}
                >
                  Tracking
                </Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  Track your shipment in real-time with our advanced tracking
                  system. Logicarts offers seamless and transparent
                  tracking, giving you peace of mind and the ability to stay
                  informed about the location and status of your delivery.
                </Typography>
              </Box>
            </Container>
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "5%",
              }}
            >
              <Box>
                <VisibilityOutlinedIcon
                  sx={{
                    fontSize: "3rem",
                    color: "#1a8fd1",
                  }}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "3%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    lineHeight: "28px",
                  }}
                >
                  Improve Visibility
                </Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  Improve visibility into your shipping operations with our
                  logistics company. We offer real-time tracking and updates,
                  giving you a clear picture of the status and location of your
                  shipments. This improves transparency, increases efficiency,
                  and reduces the risk of disruptions.
                </Typography>
              </Box>
            </Container>
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "5%",
              }}
            >
              <Box>
                <CheckCircleOutlinedIcon
                  sx={{
                    fontSize: "3rem",
                    color: "#1a8fd1",
                  }}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "3%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    lineHeight: "28px",
                  }}
                >
                  Order fulfilment
                </Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  Efficient and reliable order fulfillment is critical for your
                  business success. Logicarts provides end-to-end
                  order fulfillment services, from receiving and processing
                  orders to delivery and returns. With our expertise, we ensure
                  fast and accurate order fulfillment for your customers.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>

      <Box className="mobile-view-choose-us">
        <Typography
          sx={{
            fontSize: "1.5rem",
            textAlign: "center",
            marginTop: "3rem",
            fontWeight: 600,
            color: "#1b8fd2",
          }}
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          Why Choose Us?
        </Typography>
        <Typography
          sx={{
            fontSize: "1rem",
            textAlign: "center",
            color: "#1b8fd2",
          }}
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          Find Reason To Choose Us As Your Freight Partner
        </Typography>

        <Grid
          container
          sx={{
            padding: "6%",
          }}
        >
          <Grid
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            item
            xs={12}
          >
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "5%",
              }}
            >
              <Box>
                <AccessTimeIcon
                  sx={{
                    fontSize: "3rem",
                    color: "#1a8fd1",
                  }}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "3%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    lineHeight: "28px",
                  }}
                >
                  On-time delivery
                </Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  Logicarts strives for 100% on-time delivery,
                  ensuring our customers receive their shipments within the
                  agreed timeframe. With advanced technology, experienced staff
                  and efficient processes, we deliver reliable and trustworthy
                  logistics solutions.
                </Typography>
              </Box>
            </Container>
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "5%",
              }}
            >
              <Box>
                <SupportAgentIcon
                  sx={{
                    fontSize: "3rem",
                    color: "#1a8fd1",
                  }}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "3%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    lineHeight: "28px",
                  }}
                >
                  24/7 support
                </Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  Logicarts provides 24/7 support for all your
                  shipping needs. With a dedicated and knowledgeable support
                  team, we're here to answer your questions and resolve any
                  issues, ensuring a smooth and stress-free shipping experience.
                </Typography>
              </Box>
            </Container>
          </Grid>
          <Grid
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            item
            xs={12}
          >
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "5%",
              }}
            >
              <Box>
                <SavingsOutlinedIcon
                  sx={{
                    fontSize: "3rem",
                    color: "#1a8fd1",
                  }}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "3%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    lineHeight: "28px",
                  }}
                >
                  Reduce Costs
                </Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  Reduce shipping costs with Logicarts. We use
                  innovative technology and data-driven processes to optimize
                  routes, streamline operations, and minimize waste, resulting
                  in cost-effective and sustainable logistics solutions for your
                  business.
                </Typography>
              </Box>
            </Container>
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "5%",
              }}
            >
              <Box>
                <ShareLocationOutlinedIcon
                  sx={{
                    fontSize: "3rem",
                    color: "#1a8fd1",
                  }}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "3%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    lineHeight: "28px",
                  }}
                >
                  Tracking
                </Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  Track your shipment in real-time with our advanced tracking
                  system. Logicarts offers seamless and transparent
                  tracking, giving you peace of mind and the ability to stay
                  informed about the location and status of your delivery.
                </Typography>
              </Box>
            </Container>
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "5%",
              }}
            >
              <Box>
                <VisibilityOutlinedIcon
                  sx={{
                    fontSize: "3rem",
                    color: "#1a8fd1",
                  }}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "3%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    lineHeight: "28px",
                  }}
                >
                  Improve Visibility
                </Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  Improve visibility into your shipping operations with our
                  logistics company. We offer real-time tracking and updates,
                  giving you a clear picture of the status and location of your
                  shipments. This improves transparency, increases efficiency,
                  and reduces the risk of disruptions.
                </Typography>
              </Box>
            </Container>
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "5%",
              }}
            >
              <Box>
                <CheckCircleOutlinedIcon
                  sx={{
                    fontSize: "3rem",
                    color: "#1a8fd1",
                  }}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "3%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    marginBottom: "10px",
                    lineHeight: "28px",
                  }}
                >
                  Order fulfilment
                </Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  Efficient and reliable order fulfillment is critical for your
                  business success. Logicarts provides end-to-end
                  order fulfillment services, from receiving and processing
                  orders to delivery and returns. With our expertise, we ensure
                  fast and accurate order fulfillment for your customers.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ChooseUs;
