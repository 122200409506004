import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Slide,
  DialogContent,
  TextField,
  Grid,
} from "@mui/material";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import TrackOrder from "../../TrackOrder/TrackOrder";
import axios from "axios";
import "../css/header.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import TrackOrderMobile from "../../TrackOrderMobile/TrackOrderMobile";

const drawerWidth = 240;
const resWidth = 1078;
const navItems = ["Home", "Service", "About Us", "Events", "Contact Us"];

let headerBackGround = "transparent";

function ElevationScroll(props) {
  const { children, window } = props;


  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 0 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

function HeaderAbout(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [maxWidth, setMaxWidth] = useState("lg");
  const [openTrack, setOpenTrack] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [docketNo, setDocketNo] = useState("");
  const [vertical, setVertical] = useState("top");
  const [horizontal, setHorizontal] = useState("center");
  const [secondary, setSecondary] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenTrack(false);
  };

  const handleCloseMobile = () => {
    setOpenMobile(false);
    setOpenTrack(false);
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="div" sx={{ my: 2 }}>
        <img
          src={require("../../../assets/logo/logicartsV2Logo.png")}
          // src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1674474652526_logo_new1.d80dfce90a7ce4675a0b.webp"
          style={{
            width: "70%",
            padding: "10px",
          }}
        />
      </Typography>
      <Divider />
      <List>
        <Link
          to="/"
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <ListItem
            disablePadding
            secondaryAction={
              <IconButton edge="end" aria-label="delete">
                <KeyboardArrowRightIcon
                  sx={{
                    color: "#1b8fd2",
                  }}
                />
              </IconButton>
            }
          >
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => navigate("/")}
            >
              <ListItemText
                primary="Home"
                secondary={secondary ? "Secondary text" : null}
              />
            </ListItemButton>
          </ListItem>
        </Link>
        <ListItem
          disablePadding
          secondaryAction={
            <IconButton edge="end" aria-label="delete">
              <KeyboardArrowRightIcon
                sx={{
                  color: "#1b8fd2",
                }}
              />
            </IconButton>
          }
        >
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() =>
              navigate("/services", {
                state: "services",
              })
            }
          >
            <ListItemText
              primary="Service"
              secondary={secondary ? "Secondary text" : null}
            />
          </ListItemButton>
        </ListItem>
        <Link
          to="/about-us"
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <ListItem
            disablePadding
            secondaryAction={
              <IconButton edge="end" aria-label="delete">
                <KeyboardArrowRightIcon
                  sx={{
                    color: "#1b8fd2",
                  }}
                />
              </IconButton>
            }
          >
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => props.func1("about")}
            >
              <ListItemText
                primary="About Us"
                secondary={secondary ? "Secondary text" : null}
              />
            </ListItemButton>
          </ListItem>

         
        </Link>
        <ListItem
            disablePadding
            secondaryAction={
              <IconButton edge="end" aria-label="delete">
                <KeyboardArrowRightIcon
                  sx={{
                    color: "#1b8fd2",
                  }}
                />
              </IconButton>
            }
          >
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => navigate("/events")}
            >
              <ListItemText
                primary="Events"
                secondary={secondary ? "Secondary text" : null}
              />
            </ListItemButton>
          </ListItem>
        {/* <Link
          to="/events"
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <ListItem
            disablePadding
            secondaryAction={
              <IconButton edge="end" aria-label="delete">
                <KeyboardArrowRightIcon
                  sx={{
                    color: "#1b8fd2",
                  }}
                />
              </IconButton>
            }
          >
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => props.func1("about")}
            >
              <ListItemText
                primary="Events"
                secondary={secondary ? "Secondary text" : null}
              />
            </ListItemButton>
          </ListItem>
        </Link> */}

        <ListItem
          disablePadding
          secondaryAction={
            <IconButton edge="end" aria-label="delete">
              <KeyboardArrowRightIcon
                sx={{
                  color: "#1b8fd2",
                }}
              />
            </IconButton>
          }
        >

          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => props.func("contact-us")}
          >
            <ListItemText
              primary="Contact Us"
              secondary={secondary ? "Secondary text" : null}
            />
          </ListItemButton>
        </ListItem>
        <a
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
          href="https://docs.google.com/forms/d/e/1FAIpQLSfrpbGOo7Zo3aRto81VeNVXB0cqXytsDrLKIZ7JXvX0X1lmiA/viewform?usp=sf_link"
          target="_blank"
        >
          <ListItem
            disablePadding
            secondaryAction={
              <IconButton edge="end" aria-label="delete">
                <KeyboardArrowRightIcon
                  sx={{
                    color: "#1b8fd2",
                  }}
                />
              </IconButton>
            }
          >
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText
                primary="Become a Partner"
                secondary={secondary ? "Secondary text" : null}
              />
            </ListItemButton>
          </ListItem>
        </a>

        <ListItem
          disablePadding
          secondaryAction={
            <IconButton edge="end" aria-label="delete">
              <KeyboardArrowRightIcon
                sx={{
                  color: "#1b8fd2",
                }}
              />
            </IconButton>
          }
        >
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => setOpenMobile(true)}
          >
            <ListItemText
              primary="Track Order"
              secondary={secondary ? "Secondary text" : null}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const handleClickScroll = (value) => {
    const element = document.getElementById(value);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const search = async () => {
    await setLoading(true);

    await setLoading(true);
    const data = await axios
      .post(`https://ndd.logicarts.in/misc/getTrackingStatusByAnyParameter`, {
        docketNO: docketNo,
      })
      .then((response) => {
        const stepperData = [];
        const tableData = [];
        if (response.data.data != null) {
          response.data.data.map((eachItem) => {
            if (eachItem.category == 1) {
              stepperData.push(eachItem);
            } else {
              tableData.push(eachItem);
            }
          });

          setData(response.data.data);
          setOpenTrack(true);
          setLoading(false);
          setOpenSnack(false);
        } else {
          setOpenSnack(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <ElevationScroll {...props}>
          <AppBar
            component="nav"
            sx={{
              backgroundColor: "#fff",
            }}
          >
            <Toolbar>
              <IconButton
                color="black"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  mr: 2,
                  display: { md: "none", xl: "none", lg: "none", sm: "block" },
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="div"
                className="mobile-header"
                onClick={() => navigate("/")}
              >
                <img
                  src={require("../../../assets/logo/logicartsV2Logo.png")}
                  // src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1674474652526_logo_new1.d80dfce90a7ce4675a0b.webp"
                  className="mobile-logo-image"
                />
              </Typography>
              <Typography
                component="div"
                className="desktop-header"
                onClick={() => navigate("/")}
              >
                <img
                  src={require("../../../assets/logo/logicartsV2Logo.png")}
                  // src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1674474652526_logo_new1.d80dfce90a7ce4675a0b.webp"
                  className="desktop-logo-image"
                  style={{
                    cursor: "pointer",
                  }}
                />
              </Typography>
              <Box className="desktop-header">
                <Button
                  sx={{ color: "#1a8fd1" }}
                  onClick={() => navigate("/")}
                  className="desktop-menu"
                >
                  Home
                </Button>
                <Button
                  sx={{ color: "#1a8fd1", marginLeft: "20px" }}
                  onClick={() =>
                    navigate("/services", {
                      state: "services",
                    })
                  }
                  className="desktop-menu"
                >
                  Service
                </Button>
                <Button
                  sx={{ color: "#1a8fd1" }}
                  onClick={() => handleClickScroll("about")}
                  className="desktop-menu"
                >
                  About Us
                </Button>
                <Button
                  sx={{ color: "#1a8fd1" }}
                  onClick={() =>
                    navigate("/events", {
                      state: "services",
                    })
                  } className="desktop-menu"
                >
                  Events
                </Button>
                <Button
                  sx={{ color: "#1a8fd1" }}
                  onClick={() => handleClickScroll("contact-us")}
                  className="desktop-menu"
                >
                  Contact Us
                </Button>
                <a
                  style={{
                    textDecoration: "none",
                  }}
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfrpbGOo7Zo3aRto81VeNVXB0cqXytsDrLKIZ7JXvX0X1lmiA/viewform?usp=sf_link"
                  target="_blank"
                >
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      backgroundColor: "#1a8fd1",
                      borderRadius: "20px",
                    }}
                    className="desktop-menu"
                  >
                    Become a Partner
                  </Button>
                </a>

                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    backgroundColor: "#1a8fd1",
                    borderRadius: "20px",
                  }}
                  className="desktop-menu"
                  onClick={() => setOpen(true)}
                >
                  Track Order
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Box component="nav" className="mobile-header">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { md: "block", xl: "none", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        maxWidth={maxWidth}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          width: "100%",
          zIndex: 100000,
        }}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p
                style={{
                  fontWeight: 600,
                }}
              >
                Track Order
              </p>
            </div>
            <div
              style={{
                marginTop: "2%",
              }}
            >
              <Button onClick={handleClose}>
                <HighlightOffOutlinedIcon />
              </Button>
            </div>
          </div>
        </DialogTitle>

        {openTrack == true ? (
          <DialogContent
            sx={{
              width: "100%",
            }}
          >
            <TrackOrder details={data} />
            {/* <TrackOrderMobile details={data} /> */}
            <Box
              sx={{
                marginTop: "3%",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#1a8fd1",
                  color: "#fff",
                  margin: "auto",
                  display: "block",
                }}
                onClick={() => setOpenTrack(false)}
              >
                Search Again
              </Button>
            </Box>
          </DialogContent>
        ) : (
          <DialogContent
            sx={{
              width: "100%",
            }}
          >
            <Grid
              container
              spacing={2}
              className="desktop-track"
              sx={{
                marginBottom: "5%",
              }}
            >
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  label="Tracking Number"
                  id="outlined-error-helper-text"
                  error={openSnack == true ? true : false}
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                  }}
                  value={docketNo}
                  onChange={(e) => (
                    setDocketNo(e.target.value), setOpenSnack(false)
                  )}
                  helperText={
                    openSnack == true
                      ? "Unable to get data! Kindly enter correct docket number"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4} className="track-btn-grid">
                <Button
                  variant="contained"
                  disabled={loading}
                  sx={{
                    width: "100%",
                    backgroundColor: "#1a8fd1",
                    color: "#fff",
                    height: "56px",
                  }}
                  onClick={() => search()}
                >
                  {loading == true ? (
                    <Typography>
                      <HourglassTopIcon className="rotate" /> Loading...
                    </Typography>
                  ) : (
                    <Typography>Track & Trace</Typography>
                  )}
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className="mobile-track"
              sx={{
                marginBottom: "5%",
              }}
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Tracking Number"
                  id="outlined-error-helper-text"
                  error={openSnack == true ? true : false}
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                  }}
                  value={docketNo}
                  onChange={(e) => (
                    setDocketNo(e.target.value), setOpenSnack(false)
                  )}
                  helperText={
                    openSnack == true
                      ? "Unable to get data! Kindly enter correct docket number"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  disabled={loading}
                  sx={{
                    width: "100%",
                    backgroundColor: "#1a8fd1",
                    color: "#fff",
                    height: "56px",
                  }}
                  onClick={() => search()}
                >
                  {loading == true ? (
                    <Typography>
                      <HourglassTopIcon className="rotate" /> Loading...
                    </Typography>
                  ) : (
                    <Typography>Track & Trace</Typography>
                  )}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Dialog>

      <Dialog
        fullScreen
        open={openMobile}
        onClose={handleCloseMobile}
        fullWidth={true}
        maxWidth={maxWidth}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          width: "100%",
          zIndex: 100000,
        }}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p
                style={{
                  fontWeight: 600,
                }}
              >
                Track Order
              </p>
            </div>
            <div
              style={{
                marginTop: "2%",
              }}
            >
              <Button onClick={handleCloseMobile}>
                <HighlightOffOutlinedIcon />
              </Button>
            </div>
          </div>
        </DialogTitle>

        {openTrack == true ? (
          <>
            <DialogContent
              className="desktop-track-order-details"
              sx={{
                width: "100%",
              }}
            >
              <TrackOrderMobile details={data} />
              <Box
                sx={{
                  marginTop: "3%",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#1a8fd1",
                    color: "#fff",
                    margin: "auto",
                    display: "block",
                  }}
                  onClick={() => setOpenTrack(false)}
                >
                  Search Again
                </Button>
              </Box>
            </DialogContent>
          </>
        ) : (
          <DialogContent
            sx={{
              width: "100%",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                marginBottom: "5%",
              }}
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Tracking Number"
                  id="outlined-error-helper-text"
                  error={openSnack == true ? true : false}
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                  }}
                  value={docketNo}
                  onChange={(e) => (
                    setDocketNo(e.target.value), setOpenSnack(false)
                  )}
                  helperText={
                    openSnack == true
                      ? "Unable to get data! Kindly enter correct docket number"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  disabled={loading}
                  sx={{
                    width: "100%",
                    backgroundColor: "#1a8fd1",
                    color: "#fff",
                    height: "56px",
                  }}
                  onClick={() => search()}
                >
                  {loading == true ? (
                    <Typography>
                      <HourglassTopIcon className="rotate" /> Loading...
                    </Typography>
                  ) : (
                    <Typography>Track & Trace</Typography>
                  )}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}

export default HeaderAbout;





// import React, { useState } from "react";
// import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
// import CssBaseline from "@mui/material/CssBaseline";
// import Divider from "@mui/material/Divider";
// import Drawer from "@mui/material/Drawer";
// import IconButton from "@mui/material/IconButton";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemText from "@mui/material/ListItemText";
// import MenuIcon from "@mui/icons-material/Menu";
// import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
// import useScrollTrigger from "@mui/material/useScrollTrigger";
// import HourglassTopIcon from "@mui/icons-material/HourglassTop";
// import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import { Dialog, DialogTitle, DialogContentText, DialogActions, Slide, DialogContent, TextField, Grid } from "@mui/material";
// import PropTypes from "prop-types";
// import { Link, useNavigate } from "react-router-dom";
// import TrackOrder from "../../TrackOrder/TrackOrder";
// import axios from "axios";
// import "../css/header.css";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
// import TrackOrderMobile from "../../TrackOrderMobile/TrackOrderMobile";

// const drawerWidth = 240;
// const resWidth = 1078;
// const navItems = ["Home", "Service", "About Us", "Events", "Contact Us"];

// let headerBackGround = "transparent";

// function ElevationScroll(props) {
//   const { children, window } = props;

//   const trigger = useScrollTrigger({
//     disableHysteresis: true,
//     threshold: 0,
//     target: window ? window() : undefined,
//   });

//   return React.cloneElement(children, {
//     elevation: trigger ? 0 : 0,
//   });
// }

// ElevationScroll.propTypes = {
//   children: PropTypes.element.isRequired,
//   window: PropTypes.func,
// };

// function HeaderAbout(props) {
//   const { window } = props;
//   const [mobileOpen, setMobileOpen] = useState(false);
//   const [open, setOpen] = useState(false);
//   const [openSnack, setOpenSnack] = useState(false);
//   const [maxWidth, setMaxWidth] = useState("lg");
//   const [openTrack, setOpenTrack] = useState(false);
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [docketNo, setDocketNo] = useState("");
//   const [vertical, setVertical] = useState("top");
//   const [horizontal, setHorizontal] = useState("center");
//   const [secondary, setSecondary] = useState(false);
//   const [openMobile, setOpenMobile] = useState(false);

//   const navigate = useNavigate();

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setOpenTrack(false);
//   };

//   const handleCloseMobile = () => {
//     setOpenMobile(false);
//     setOpenTrack(false);
//   };

//   const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
//   });

//   const handleDrawerToggle = () => {
//     setMobileOpen((prevState) => !prevState);
//   };

//   const trigger = useScrollTrigger({
//     disableHysteresis: true,
//     threshold: 0,
//     target: window ? window() : undefined,
//   });

//   const drawer = (
//     <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
//       <Typography variant="div" sx={{ my: 2 }}>
//         <img
//           src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1674474652526_logo_new1.d80dfce90a7ce4675a0b.webp"
//           style={{
//             width: "70%",
//             padding: "10px",
//           }}
//         />
//       </Typography>
//       <Divider />
//       <List>
//         <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
//           <ListItem
//             disablePadding
//             secondaryAction={
//               <IconButton edge="end" aria-label="delete">
//                 <KeyboardArrowRightIcon
//                   sx={{ color: "#1b8fd2" }}
//                 />
//               </IconButton>
//             }
//           >
//             <ListItemButton
//               sx={{ textAlign: "center" }}
//               onClick={() => navigate("/")}
//             >
//               <ListItemText primary="Home" secondary={secondary ? "Secondary text" : null} />
//             </ListItemButton>
//           </ListItem>
//         </Link>
//         <ListItem
//           disablePadding
//           secondaryAction={
//             <IconButton edge="end" aria-label="delete">
//               <KeyboardArrowRightIcon sx={{ color: "#1b8fd2" }} />
//             </IconButton>
//           }
//         >
//           <ListItemButton sx={{ textAlign: "center" }} onClick={() => navigate("/services", { state: "services" })}>
//             <ListItemText primary="Service" secondary={secondary ? "Secondary text" : null} />
//           </ListItemButton>
//         </ListItem>
//         <Link to="/about-us" style={{ color: "inherit", textDecoration: "none" }}>
//           <ListItem
//             disablePadding
//             secondaryAction={
//               <IconButton edge="end" aria-label="delete">
//                 <KeyboardArrowRightIcon sx={{ color: "#1b8fd2" }} />
//               </IconButton>
//             }
//           >
//             <ListItemButton sx={{ textAlign: "center" }} onClick={() => props.func1("about")}>
//               <ListItemText primary="About Us" secondary={secondary ? "Secondary text" : null} />
//             </ListItemButton>
//           </ListItem>
//         </Link>
//         <ListItem
//           disablePadding
//           secondaryAction={
//             <IconButton edge="end" aria-label="delete">
//               <KeyboardArrowRightIcon sx={{ color: "#1b8fd2" }} />
//             </IconButton>
//           }
//         >
//           <ListItemButton sx={{ textAlign: "center" }} onClick={() => navigate("/events")}>
//             <ListItemText primary="Events" secondary={secondary ? "Secondary text" : null} />
//           </ListItemButton>
//         </ListItem>
//         <Link to="/contact-us" style={{ color: "inherit", textDecoration: "none" }}>
//           <ListItem
//             disablePadding
//             secondaryAction={
//               <IconButton edge="end" aria-label="delete">
//                 <KeyboardArrowRightIcon sx={{ color: "#1b8fd2" }} />
//               </IconButton>
//             }
//           >
//             <ListItemButton sx={{ textAlign: "center" }} onClick={() => props.func1("contact")}>
//               <ListItemText primary="Contact Us" secondary={secondary ? "Secondary text" : null} />
//             </ListItemButton>
//           </ListItem>
//         </Link>
//         <Divider />
//         <Box sx={{ textAlign: "center", margin: "20px 0" }}>
//           <Button
//             variant="contained"
//             sx={{ color: "#fff", backgroundColor: "#1a8fd1", padding: "10px 20px" }}
//             onClick={() => setOpenTrack(true)}
//           >
//             Track & Trace
//           </Button>
//         </Box>
//       </List>
//     </Box>
//   );

//   return (
//     <Box sx={{ display: "flex" }}>
//       <CssBaseline />
//       <ElevationScroll {...props}>
//         <AppBar component="nav" sx={{ backgroundColor: headerBackGround, boxShadow: "none", position: "fixed" }}>
//           <Toolbar>
//             <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
//               <MenuIcon />
//             </IconButton>
//             <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
//               <img
//                 src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1674474652526_logo_new1.d80dfce90a7ce4675a0b.webp"
//                 style={{ width: "10vw", padding: "10px" }}
//                 alt="logo"
//               />
//             </Typography>
//             <Box sx={{ display: { xs: "none", sm: "block" } }}>
//               <Button sx={{ color: "#1a8fd1" }} onClick={() => navigate("/")}>Home</Button>
//               <Button sx={{ color: "#1a8fd1" }} onClick={() => navigate("/services")}>Service</Button>
//               <Button sx={{ color: "#1a8fd1" }} onClick={() => props.func1("about")}>About Us</Button>
//               <Button sx={{ color: "#1a8fd1" }} onClick={() => navigate("/events")} className="desktop-menu">Events</Button>
//               <Button sx={{ color: "#1a8fd1" }} onClick={() => props.func1("contact")}>Contact Us</Button>
//               <Button
//                 sx={{ color: "#fff", backgroundColor: "#1a8fd1", padding: "5px 20px", borderRadius: "20px" }}
//                 onClick={handleClickOpen}
//               >
//                 Track & Trace
//               </Button>
//             </Box>
//           </Toolbar>
//         </AppBar>
//       </ElevationScroll>
//       <Box component="nav">
//         <Drawer
//           container={window !== undefined ? () => window().document.body : undefined}
//           variant="temporary"
//           open={mobileOpen}
//           onClose={handleDrawerToggle}
//           ModalProps={{
//             keepMounted: true, // Better open performance on mobile.
//           }}
//           sx={{
//             display: { xs: "block", sm: "none" },
//             "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
//           }}
//         >
//           {drawer}
//         </Drawer>
//       </Box>
//       <TrackOrder open={open} onClose={handleClose} />
//       <Snackbar
//         anchorOrigin={{ vertical, horizontal }}
//         open={openSnack}
//         onClose={() => setOpenSnack(false)}
//         autoHideDuration={3000}
//       >
//         <MuiAlert
//           onClose={() => setOpenSnack(false)}
//           severity="info"
//           sx={{ width: "100%" }}
//         >
//           Order Not Found
//         </MuiAlert>
//       </Snackbar>
//       <TrackOrderMobile open={openMobile} onClose={handleCloseMobile} />
//     </Box>
//   );
// }

// HeaderAbout.propTypes = {
//   window: PropTypes.func,
//   func1: PropTypes.func,
// };

// export default HeaderAbout;