import React, { Fragment, useCallback, useState, useEffect } from "react";
import {
  Box,
  Card,
  Stack,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Drawer,
  Radio,
  RadioGroup,
  Grid,
  FormControlLabel,
  Modal,
} from "@mui/material";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from '@mui/icons-material/Cancel';

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "background.paper",
  border: "0.3px solid #000",
  boxShadow: 24,
  p: 4,
};

function RestAmount() {
  const navigate = useNavigate();

  let url = new URL(window.location.href);
  let search_params = url.searchParams;
  let valuepID = search_params.get("pID");
  let valueMobile = search_params.get("pickerMobile");
  let payID = search_params.get("paymentID");
  let cxMobile = search_params.get("mobile");

  const [openDrawer, setOpenDrawer] = useState(false);

  const [Description, setDescription] = useState("");
  const [actualWeight, setActualWeight] = useState("");
  const [goodsValue, setGoodsValue] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [isFragile, setIsFragile] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [pickrMobile, setPickrMobile] = useState("");
  const [receiverAddressLine1, setReceiverAddressLine1] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receivermobileNumber, setReceivermobileNumber] = useState("");
  const [senderAddressLine1, setSenderAddressLine1] = useState("");
  const [senderName, setSenderName] = useState("");
  const [sendermobileNumber, setSendermobileNumber] = useState("");

  const [pID, setPID] = useState(0);
  const [mobile, setMobile] = useState("");
  const [pickerName, setPickerName] = useState("");
  const [allStatus, setAllStatus] = useState([]);
  const [statusID, setStatusID] = useState("");
  const [orderID, setOrderID] = useState("");
  const [senderHouseAddress, setSenderHouseAddress] = useState("");
  const [senderAddressLine2, setSenderAddressLine2] = useState("");
  const [senderLandmark, setSenderLandmark] = useState("");
  const [senderCity, setSenderCity] = useState("");
  const [senderState, setSenderState] = useState("");
  const [senderPincode, setSenderPincode] = useState("");
  const [senderLng, setSenderLng] = useState();
  const [senderLat, setSenderLat] = useState();
  const [senderNote, setSenderNote] = useState("");

  const [openDrawerPrice, setOpenDrawerPrice] = useState(false);
  const [allSizes, setAllSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [amountToPay, setAmountToPay] = useState("");
  const [receiverLat, setReceiverLat] = useState();
  const [receiverLng, setReceiverLng] = useState();
  const [receiverHouseAddress, setReceiverHouseAddress] = useState("");
  const [receiverAddressLine2, setReceiverAddressLine2] = useState("");
  const [receiverLandmark, setReceiverLandmark] = useState("");
  const [receiverCity, setReceiverCity] = useState("");
  const [receiverState, setReceiverState] = useState();
  const [receiverPincode, setReceiverPincode] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");
  const [scheduleDate, setScheduleDate] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [totalDistance, setTotalDistance] = useState("");

  const [openDrawerUpdate, setOpenDrawerUpdate] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const [packageSize, setPackageSize] = useState("");
  const [smallBorder, setSmallBorder] = useState("0.5px solid #DDDDDD");
  const [mediumBorder, setMediumBorder] = useState("0.5px solid #DDDDDD");
  const [largeBorder, setLargeBorder] = useState("0.5px solid #DDDDDD");
  const [bulkyBorder, setBulkyBorder] = useState("0.5px solid #DDDDDD");

  const [cxAmountPaid, setcxAmountPaid] = useState();
  const [priceText, setPriceText] = useState("");

  const [amountToBePayed, setAmountToBePayed] = useState(100);

  const [ssID, setssID] = useState();
  const [openDrawerMap, setOpenDrawerMap] = useState(false);
  const [open, setOpen] = useState(false);
  const [openFailed, setOpenFailed] = useState(false)

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const vertical = "top";
  const horizontal = "center";

  useEffect(() => {
    (async () => {
      await getOrderDetails(valuepID, valueMobile);
    })();
  }, []);

  const Razorpay = useRazorpay();

  const handlePayment = useCallback(async () => {
    const options = {
      key: "rzp_live_FkMwaM2QQXnydI",
      amount: "100",
      currency: "INR",
      name: "Logicarts",
      description: "Logicarts pickup create order payment transaction",
      image:
        "https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1681457671979_logo_blue.png",
      order_id: payID,
      handler: async (res) => {
        console.log(res);
        if (res) {
          const response = await fetch(
            "https://ndd.logicarts.in/pickup/pickerModifyOrder",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                "x-access-token":
                  "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGljaGVjazEiLCJpYXQiOjE2ODkwMTYzMDUsImV4cCI6MTY4OTAxOTkwNX0.Lgtn6mOK-sFiGxWJV_ZEyjqojTaAd-wVa2dYWL2Lurs",
              },

              body: JSON.stringify({
                pID: !valuepID ? pID : valuepID,
                contentID: "1",
                statusID: "",
                jdata: {
                  orderID: orderID,
                  senderName: senderName,
                  senderHouseAddress: senderHouseAddress,
                  senderAddressLine1: senderAddressLine1,
                  senderAddressLine2: senderAddressLine2,
                  senderLandmark: senderLandmark,
                  senderCity: senderCity,
                  senderState: senderState,
                  senderPincode: senderPincode,
                  sendermobileNumber: sendermobileNumber,
                  senderLat: senderLat,
                  senderLng: senderLng,
                  senderNote: senderNote,
                  invoiceNumber: invoiceNumber,

                  receiverName: receiverName,
                  receiverHouseAddress: receiverHouseAddress,
                  receiverAddressLine1: receiverAddressLine1,
                  receiverAddressLine2: receiverAddressLine2,
                  receiverLandmark: receiverLandmark,
                  receiverCity: receiverCity,
                  receiverState: receiverState,
                  receiverPincode: receiverPincode,
                  receivermobileNumber: receivermobileNumber,
                  receiverLat: receiverLat,
                  receiverLng: receiverLng,
                  receiverNote: senderNote,

                  paymentType: paymentType,
                  goodsValue: goodsValue,

                  Description: Description,
                  actualWeight: actualWeight,
                  mobile: mobile,
                  isFragile: `${isFragile}`,

                  deliveryAent: pickerName,
                  is_deliveryAgent: "1",

                  paymentStatus: "Successful",
                  scheduleTime: scheduleTime,
                  scheduleDate: scheduleDate,
                  couponCode: couponCode,
                  totalDistance: totalDistance,
                  images: [],
                  amountToPay: amountToPay,
                  status: "Cx Amount Not Ready",
                },
              }),
            }
          );

          const responseJson = await response.json();
          console.log(responseJson);
          if (responseJson.success == true) {
            setOpen(true);
          } else {
            setErrorMsg("Unable to update the payment status");
            setOpenErrorAlert(true)
          }
        } else {
          const response = await fetch(
            "https://ndd.logicarts.in/pickup/pickerModifyOrder",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                "x-access-token":
                  "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGljaGVjazEiLCJpYXQiOjE2ODkwMTYzMDUsImV4cCI6MTY4OTAxOTkwNX0.Lgtn6mOK-sFiGxWJV_ZEyjqojTaAd-wVa2dYWL2Lurs",
              },

              body: JSON.stringify({
                pID: !valuepID ? pID : valuepID,
                contentID: "1",
                statusID: "",
                jdata: {
                  orderID: orderID,
                  senderName: senderName,
                  senderHouseAddress: senderHouseAddress,
                  senderAddressLine1: senderAddressLine1,
                  senderAddressLine2: senderAddressLine2,
                  senderLandmark: senderLandmark,
                  senderCity: senderCity,
                  senderState: senderState,
                  senderPincode: senderPincode,
                  sendermobileNumber: sendermobileNumber,
                  senderLat: senderLat,
                  senderLng: senderLng,
                  senderNote: senderNote,
                  invoiceNumber: invoiceNumber,

                  receiverName: receiverName,
                  receiverHouseAddress: receiverHouseAddress,
                  receiverAddressLine1: receiverAddressLine1,
                  receiverAddressLine2: receiverAddressLine2,
                  receiverLandmark: receiverLandmark,
                  receiverCity: receiverCity,
                  receiverState: receiverState,
                  receiverPincode: receiverPincode,
                  receivermobileNumber: receivermobileNumber,
                  receiverLat: receiverLat,
                  receiverLng: receiverLng,
                  receiverNote: senderNote,

                  paymentType: paymentType,
                  goodsValue: goodsValue,

                  Description: Description,
                  actualWeight: actualWeight,
                  mobile: mobile,
                  isFragile: `${isFragile}`,

                  deliveryAent: pickerName,
                  is_deliveryAgent: "1",

                  paymentStatus: "Failed",
                  scheduleTime: scheduleTime,
                  scheduleDate: scheduleDate,
                  couponCode: couponCode,
                  totalDistance: totalDistance,
                  images: [],
                  amountToPay: amountToPay,
                  status: "Cx Amount Not Ready",
                },
              }),
            }
          );

          const responseJson = await response.json();
          console.log(responseJson);
          if (responseJson.success == true) {
            navigate("/express/order-history");
          } else {
            setErrorMsg("Unable to update the payment status");
            setOpenErrorAlert(true)
            navigate("/express/order-history");
          }
        }
      },
      prefill: {
        name: "",
        email: "",
        contact: "",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);

  const getOrderDetails = async (pID, mobile) => {
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/getOrderDetails",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token":
            "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGljaGVjazEiLCJpYXQiOjE2ODkwMTYzMDUsImV4cCI6MTY4OTAxOTkwNX0.Lgtn6mOK-sFiGxWJV_ZEyjqojTaAd-wVa2dYWL2Lurs",
        },

        body: JSON.stringify({
          mobile: mobile,
          pid: pID,
        }),
      }
    );

    const responseJson = await response.json();
    const parseData = JSON.parse(responseJson.data[0].jdata);
    console.log(responseJson);
    console.log(parseData);
    if (responseJson.success === true) {
      setPID(responseJson.data[0].PID);
      setMobile(responseJson.data[0].mobile);
      setPickerName(responseJson.data[0].pickrName);
      setDescription(parseData.Description);
      setOrderID(parseData.orderID);
      setActualWeight(parseData.actualWeight);
      setGoodsValue(parseData.goodsValue);
      setInvoiceNumber(parseData.invoiceNumber);
      setIsFragile(parseData.isFragile);
      setPaymentType(parseData.paymentType);
      setPickrMobile(parseData.pickrMobile);
      setReceiverAddressLine1(parseData.receiverAddressLine1);
      setReceiverName(parseData.receiverName);
      setReceivermobileNumber(parseData.receivermobileNumber);
      setSenderAddressLine1(parseData.senderAddressLine1);
      setSenderName(parseData.senderName);
      setSendermobileNumber(parseData.sendermobileNumber);
      setSenderHouseAddress(parseData.senderHouseAddress);
      setSenderAddressLine2(parseData.senderAddressLine2);
      setSenderLandmark(parseData.senderLandmark);
      setSenderCity(parseData.senderCity);
      setSenderPincode(parseData.senderCity);
      setSenderState(parseData.senderState);
      setSenderPincode(parseData.senderPincode);
      setSenderLat(parseData.senderLat);
      setSenderLng(parseData.senderLng);
      setSenderNote(parseData.senderNote);
      setAmountToPay(parseData.amountToPay);
      setReceiverLat(parseData.receiverLat);
      setReceiverLng(parseData.receiverLng);
      setReceiverHouseAddress(parseData.receiverHouseAddress);
      setReceiverAddressLine2(parseData.receiverAddressLine2);
      setReceiverLandmark(parseData.receiverLandmark);
      setReceiverCity(parseData.receiverCity);
      setReceiverState(parseData.receiverState);
      setReceiverPincode(parseData.receiverPincode);
      setScheduleTime(parseData.scheduleTime);
      setScheduleDate(parseData.scheduleDate);
      setCouponCode(parseData.couponCode);
      setTotalDistance(parseData.totalDistance);
      setcxAmountPaid(parseData.cxAmountPaid);
    } else {
      setErrorMsg("Unable to fetch the order details");
      setOpenErrorAlert(true)
    }
  };

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: "#669AFF",
          padding: "3%",
        }}
      >
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: "600",
            marginTop: "1%",
            fontSize: "1.2rem",
            textAlign: "center",
          }}
        >
          Pay Amount
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "5%",
          marginTop: "30%",
        }}
      >
        <img
          src={require("../CreateOrder/assests/images/logo_new1.png")}
          style={{
            width: "70%",
            display: "block",
            margin: "auto",
          }}
        />
        <Typography
          sx={{
            marginTop: "5%",
          }}
        >
          Click on{" "}
          <span
            style={{
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            PAY NOW
          </span>{" "}
          to complete your transaction.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              marginTop: "10%",
            }}
            onClick={handlePayment}
          >
            Pay now
          </Button>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              textAlign: "center",
            }}
          >
            <CheckCircleIcon
              color="success"
              sx={{
                fontSize: "4rem",
              }}
            />
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ textAlign: "center", fontWeight: "600" }}
          >
            Transaction Successful
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openFailed}
        onClose={() => setOpenFailed(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              textAlign: "center",
            }}
          >
            <CancelIcon
              color="error"
              sx={{
                fontSize: "4rem",
              }}
            />
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ textAlign: "center", fontWeight: "600" }}
          >
            Transaction Failed!
          </Typography>
        </Box>
      </Modal>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={() => setOpenErrorAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export default RestAmount;
