import "./App.css";
import Home from "./components/HomePage/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutUs from "./components/AboutUs/AboutUs";
import Events from "./components/Events/Events";
import CreateOrder from "./components/CreateOrder/CreateOrder";
import OrderSuccess from "./components/CreateOrder/OrderSuccess";
import Payment from "./components/Payment/Payment";
import OrderDetails from "./components/OrderDetails/OrderDetails";
import TrackPickUpOrder from "./components/TrackPickOrder/TrackPickUpOrder";
import UserLogin from "./components/UserLogin/UserLogin";
import UserOTP from "./components/UserLogin/UserOTP";
import OrderHistory from "./components/OrderHistory/OrderHistory";
import NontFound from "./components/NotFound/NontFound";
import UserProfile from "./components/Profile/UserProfile";
import MapAddress from "./components/MapAddress/MapAddress";
import SearchAddress from "./components/SearchAddress/SearchAddress";
import MapAddressDesktop from "./components/MapAddress/MapAddressDesktop";
import SearchAddressMobile from "./components/SearchAddress/SearchAddressMobile";
import AllOrdersMobile from "./components/All Orders/AllOrdersMobile";
import Picker from "./components/Picker/Picker";
import UpdateAddressMobile from "./components/ChangeAddressMap/UpdateAddressMobile";
import UpdateAddressDesktop from "./components/ChangeAddressMap/UpdateAddressDesktop";
import SavedAddress from "./components/SavedAddress/SavedAddress";
import RestAmount from "./components/RestAmount/RestAmount";
import VendorService from "./components/VendorService/Home";

// import ModalVideo from "react-modal-video";
import ReactPlayer from "react-player";

function App() {
  return (
    <div>
    {/* <ModalVideo /> */}
    {/* <ReactPlayer url={"https://youtu.be/qenovn2a4BU?si=QokX5UvhwYoLWWCT"}/> */}

      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path ="/events" element={<Events />} />
          <Route exact path ="/vendor/services" element={<VendorService />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/services" element={<Home />} />
          <Route exact path="/contact-us" element={<Home />} />
          <Route exact path="/tracking" element={<Home />} />
          <Route exact path="/express/create-order" element={<CreateOrder />} />
          <Route exact path="/express/user-login" element={<UserLogin />} />
          <Route exact path="/express/user-otp" element={<UserOTP />} />
          <Route
            exact
            path="/express/order-details"
            element={<OrderDetails />}
          />
          <Route
            exact
            path="/express/order-history"
            element={<OrderHistory />}
          />
          <Route
            exact
            path="/express/all-orders"
            element={<AllOrdersMobile />}
          />
          <Route
            exact
            path="/express/track-pickup-order"
            element={<TrackPickUpOrder />}
          />
          <Route
            exactx
            path="/express/order-success"
            element={<OrderSuccess />}
          />
          <Route exact path="/express/payment" element={<Payment />} />
          <Route exact path="/express/user-profile" element={<UserProfile />} />
          <Route
            exact
            path="/express/map-address"
            element={<MapAddressDesktop />}
          />
          <Route
            exact
            path="/express/map-address-mobile"
            element={<MapAddress />}
          />
          <Route
            exact
            path="/express/search-address"
            element={<SearchAddress />}
          />
          <Route
            exact
            path="/express/search-address-mobile"
            element={<SearchAddressMobile />}
          />
          <Route exact path="/express/picker" element={<Picker />} />
          <Route
            exact
            path="/express/update-address-mobile"
            element={<UpdateAddressMobile />}
          />
          <Route
            exact
            path="/express/update-address"
            element={<UpdateAddressDesktop />}
          />
          <Route
            exact
            path="/express/saved-address"
            element={<SavedAddress />}
          />
          <Route
            exact
            path="/express/pay-rest-amount"
            element={<RestAmount />}
          />

          <Route exact path="*" element={<NontFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
