import React, { Component, Fragment } from "react";
import { Box, Typography } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Autocomplete from "react-google-autocomplete";

class MyClassComponent extends Component {
  handlePlaceSelected = (place) => {
    console.log(place);
    localStorage.setItem("searchLat", place.geometry.location.lat());
    localStorage.setItem("searchLng", place.geometry.location.lng());
    localStorage.setItem("searchAddress", place.formatted_address);
    localStorage.setItem("refresh", true);
    this.props.onGo();
  };

  render() {
    return (
      <Fragment>
        <Box
          sx={{
            backgroundColor: "#669AFF",
            display: "flex",
            justifyContent: "space-between",
            padding: "3%",
          }}
        >
          <Typography
            sx={{
              marginTop: "2%",
            }}
            onClick={this.props.onGoBack}
          >
            <ArrowBackRoundedIcon
              sx={{
                color: "#FFFFFF",
              }}
            />
          </Typography>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontWeight: "600",
              marginTop: "1%",
              fontSize: "1.2rem",
            }}
          >
            Search Location 
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "5%",
            padding: "3%",
          }}
        >
          <Autocomplete
            apiKey="AIzaSyDJ6m7dujpOLQYOVDL6B5QJdE6cr0sEcuw"
            style={{
              width: "95%",
              height: "40px",
              border: "0.5px solid #DDDDDD",
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
              borderRadius: "4px",
              paddingLeft: "2%",
            }}
            onPlaceSelected={this.handlePlaceSelected}
            placeholder="Search your address"
            options={{
              types: ["(regions)"],
              componentRestrictions: { country: "in" },
            }}
          />
        </Box>
      </Fragment>
    );
  }
}

export default MyClassComponent;
