import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Card,
  Typography,
  Container,
  Drawer,
  Button,
  TextField,
  Modal,
  CircularProgress,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import CheckIcon from "@mui/icons-material/Check";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import CallIcon from "@mui/icons-material/Call";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import dateFormat from "dateformat";
import backImage from "../CreateOrder/assests/images/bgImage.png";

import AddIcon from "@mui/icons-material/Add";
import TimelineIcon from "@mui/icons-material/Timeline";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import "./trackPickup.css";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  border: "0.3px solid grey",
};

function TrackPickUpOrder() {
  const navigate = useNavigate();
  const location = useLocation();

  const [orderID, setOrderID] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [statusHistory, setStatusHistory] = useState([]);
  const [openProfile, setOpenProfile] = useState(false);

  const [openEditProfile, setOpenEditProfile] = useState(false);

  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorText, setfirstNameErrorText] = useState("");

  const [lastnameError, setLastNameError] = useState(false);
  const [lastnameErrorText, setlastnameErrorText] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");

  const [orderData, setOrderData] = useState([]);
  const [status, setStatus] = useState("");
  const [statusUpdate, setStatusUpdate] = useState("");

  const [loading, setLoading] = useState(true);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const vertical = "top";
  const horizontal = "center";

  let url = new URL(window.location.href);
  let search_params = url.searchParams;
  let valuepID = search_params.get("pID");

  useEffect(() => {
    (async () => {
      await verifyToken();
      await TrackShipment(valuepID);
      await getData();
    })();
  }, []);

  const getData = async () => {
    const userDetails = localStorage.getItem("userDetails");
    const parseData = await JSON.parse(userDetails);
    const parseJdata = await JSON.parse(parseData.jdata);

    setUserName(parseData.firstname + " " + parseData.lastname);
    setUserMobile(parseData.mobile);
    setUserEmail(parseJdata.email);
  };

  const verifyToken = async () => {
    const userToken = localStorage.getItem("token");
    console.log(userToken);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    const responseJson = await response.json();
    console.log(responseJson, "verify");
    if (responseJson.success == true) {
      console.log("authenticated");
    } else {
      navigate("/express/user-login");
    }
  };

  const TrackShipment = async (valuepID) => {
    setLoading(true);
    const response = await fetch(
      "https://ndd.logicarts.in/shipmentTracking/trackShipmentStatus",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          pID: !valuepID ? location.state.pID : valuepID,
        }),
      }
    );

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.success == true) {
      const parseData = JSON.parse(responseJson.data[0].jdata);
      const parseHistory = JSON.parse(responseJson.data[0].statusHistory_WS);
      console.log(parseHistory);
      setOrderID(parseData.orderID);
      setCreatedDate(responseJson.data[0].createddate);
      setDeliveryAddress(parseData.receiverAddressLine1);
      setStatusHistory(parseHistory);
      setLoading(false);
    }
  };

  const updateProfile = async () => {
    const userDeatils = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDeatils);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          cxID: parseData.cxID,
          mobile: parseData.mobile,
          jdata: {
            FromAddresses: [
              { Description: "", Address: "", Contact01: "", IsDefault: "" },
              { Description: "", Address: "", Contact01: "", IsDefault: "" },
            ],
            ToAddresses: [
              { Description: "", Address: "", Contact01: "" },
              { Description: "", Address: "", Contact01: "" },
            ],
            firstname: firstName,
            lastname: lastname,
            email: email,
          },
        }),
      }
    );

    const responseJson = await response.json();
    if (responseJson.success == true) {
      alert(
        "Profile update successfully. Kindly logout and login again to see the changes."
      );
      setOpenEditProfile(false);
    } else {
      setErrorMsg("Unable to update the profile");
      setOpenErrorAlert(true);
    }
  };

  const logOut = async () => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("token");
    window.location.reload();
  };

  return (
    <Fragment>
      <Box className="track-pickup-mobile">
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              backgroundColor: "#669AFF",
              display: "flex",
              justifyContent: "space-between",
              padding: "3%",
            }}
          >
            <Typography
              sx={{
                marginTop: "2%",
              }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackRoundedIcon
                sx={{
                  color: "#FFFFFF",
                }}
              />
            </Typography>
            <Typography
              sx={{
                color: "#FFFFFF",
                fontWeight: "600",
                marginTop: "1%",
                fontSize: "1.2rem",
              }}
            >
              Track Order
            </Typography>
            <Typography></Typography>
          </Box>
        </Box>
        <Box
          sx={{
            padding: 2,
            backgroundColor: "#f8f8f8",
          }}
        >
          <Card
            sx={{
              padding: "5%",
              borderRadius: 2.5,
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "1.1rem",
              }}
            >
              Order ID : {orderID}
            </Typography>
            <Typography
              sx={{
                color: "grey",
              }}
            >
              {dateFormat(createdDate, "dd mmm yyyy")}
            </Typography>
          </Card>
          <Card
            sx={{
              padding: "5%",
              borderRadius: 2.5,
              marginTop: "5%",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
              gutterBottom
            >
              Order Status
            </Typography>

            <Box>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                {statusHistory.map((status) => (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot color="success">
                        <CheckIcon
                          sx={{
                            fontSize: "1rem",
                          }}
                        />
                      </TimelineDot>
                      <TimelineConnector
                        sx={{
                          backgroundColor: "#2e7d32",
                        }}
                      />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        sx={{
                          fontWeight: "600",
                        }}
                      >
                        {status.Status}
                      </Typography>
                      <Typography
                        sx={{
                          color: "grey",
                        }}
                      >
                        {dateFormat(status.updatedDate, " dd-mm-yyyy, HH:MM")}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Box>
          </Card>
          <Card
            sx={{
              padding: "5%",
              borderRadius: 2.5,
              marginTop: "5%",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
              gutterBottom
            >
              Delivery Address :
            </Typography>
            <Typography
              sx={{
                color: "grey",
              }}
            >
              {deliveryAddress}
            </Typography>
          </Card>
        </Box>
      </Box>
      <Box className="track-pickup-desktop">
        <Box
          sx={{
            backgroundImage: `url(${backImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <AppBar
              position="static"
              sx={{
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Toolbar>
                <img
                  src={require("../CreateOrder/assests/images/logo_new1.png")}
                  style={{
                    width: "10%",
                  }}
                />

                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <IconButton
                    size="large"
                    onClick={() => navigate("/express/create-order")}
                  >
                    <Box>
                      <AddIcon />
                      <Typography>Create Pickup</Typography>
                    </Box>
                  </IconButton>
                  <IconButton
                    size="large"
                    onClick={() => navigate("/express/order-history")}
                  >
                    <Box>
                      <TimelineIcon />
                      <Typography>Track Order</Typography>
                    </Box>
                  </IconButton>
                  <IconButton
                    size="large"
                    edge="end"
                    aria-haspopup="true"
                    onClick={() => setOpenProfile(true)}
                  >
                    <Box>
                      <AccountCircle />
                      <Typography>Profile</Typography>
                    </Box>
                  </IconButton>
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
          <Box
            sx={{
              marginTop: "5%",
            }}
          >
            <Container
              sx={{
                marginBottom: "2%",
              }}
            >
              <Card
                sx={{
                  borderRadius: "8px",
                  padding: "3%",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  width: "50%",
                  margin: "auto",
                  display: "block",
                }}
              >
                <Card
                  sx={{
                    padding: "5%",
                    borderRadius: 2.5,
                    marginTop: "2%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "1.1rem",
                    }}
                  >
                    Order ID : {orderID}
                  </Typography>
                  <Typography
                    sx={{
                      color: "grey",
                    }}
                  >
                    {dateFormat(createdDate, "dd mmm yyyy")}
                  </Typography>
                </Card>
                <Card
                  sx={{
                    padding: "5%",
                    borderRadius: 2.5,
                    marginTop: "2%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                    }}
                    gutterBottom
                  >
                    Order Status
                  </Typography>

                  <Box>
                    <Timeline
                      sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                      }}
                    >
                      {statusHistory.map((status) => (
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot color="success">
                              <CheckIcon
                                sx={{
                                  fontSize: "1rem",
                                }}
                              />
                            </TimelineDot>
                            <TimelineConnector
                              sx={{
                                backgroundColor: "#2e7d32",
                              }}
                            />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography
                              sx={{
                                fontWeight: "600",
                              }}
                            >
                              {status.Status}
                            </Typography>
                            <Typography
                              sx={{
                                color: "grey",
                              }}
                            >
                              {dateFormat(
                                status.updatedDate,
                                " dd-mm-yyyy, HH:MM"
                              )}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                    </Timeline>
                  </Box>
                </Card>
                <Card
                  sx={{
                    padding: "5%",
                    borderRadius: 2.5,
                    marginTop: "2%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                    }}
                    gutterBottom
                  >
                    Delivery Address :
                  </Typography>
                  <Typography
                    sx={{
                      color: "grey",
                    }}
                  >
                    {deliveryAddress}
                  </Typography>
                </Card>
              </Card>
            </Container>
          </Box>
        </Box>
      </Box>

      <Drawer
        anchor="right"
        open={openProfile}
        onClose={() => setOpenProfile(false)}
        PaperProps={{
          sx: { width: "30%" },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5%",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  textAlign: "center",
                  marginTop: "3%",
                  fontWeight: "600",
                }}
              >
                User Profile
              </Typography>
            </Box>
            <Box>
              <Button onClick={() => setOpenEditProfile(true)}>Edit</Button>
            </Box>
          </Box>

          <Box
            sx={{
              padding: "5%",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
              }}
            >
              <AccountCircleIcon
                sx={{
                  fontSize: "8rem",
                  color: "grey",
                }}
              />
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
              }}
            >
              {userName}
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "5%",
              padding: "5%",
              overflow: "hidden",
            }}
          >
            <Card
              sx={{
                padding: "3%",
                marginBottom: "5%",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <Typography
                sx={{
                  color: "#807d7d",
                }}
              >
                {userMobile}
              </Typography>
            </Card>
            <Card
              sx={{
                padding: "3%",
                marginBottom: "5%",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <Typography
                sx={{
                  color: "#807d7d",
                }}
              >
                {userEmail}
              </Typography>
            </Card>
            <Card
              sx={{
                padding: "3%",
                marginBottom: "5%",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <Typography
                sx={{
                  color: "#807d7d",
                }}
              >
                Manage Address
              </Typography>
            </Card>
            <Card
              sx={{
                padding: "3%",
                marginBottom: "5%",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <Typography
                sx={{
                  color: "#807d7d",
                }}
              >
                Customer Support
              </Typography>
            </Card>

            <Button variant="contained" fullWidth onClick={() => logOut()}>
              Log Out
            </Button>
          </Box>
        </Box>
      </Drawer>

      <Drawer
        anchor="right"
        open={openEditProfile}
        onClose={() => setOpenEditProfile(false)}
        PaperProps={{
          sx: { width: "30%" },
        }}
      >
        <Box
          sx={{
            padding: "5%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
            }}
          >
            Update Your Details
          </Typography>

          <TextField
            fullWidth
            placeholder="First Name"
            sx={{
              marginTop: "4%",
            }}
            onChange={(e) => {
              setFirstNameError(false);
              setfirstNameErrorText("");
              setFirstName(e.target.value);
            }}
            error={firstNameError}
            helperText={firstNameErrorText}
          />
          <TextField
            fullWidth
            placeholder="Last Name"
            sx={{
              marginTop: "4%",
            }}
            onChange={(e) => {
              setLastNameError(false);
              setlastnameErrorText("");
              setLastName(e.target.value);
            }}
            error={lastnameError}
            helperText={lastnameErrorText}
          />

          <TextField
            fullWidth
            placeholder="Email Address"
            sx={{
              marginTop: "4%",
            }}
            onChange={(e) => {
              setEmailError(false);
              setEmailErrorText("");
              setEmail(e.target.value);
            }}
            error={emailError}
            helperText={emailErrorText}
          />

          <Button
            fullWidth
            variant="contained"
            sx={{
              marginTop: "5%",
            }}
            onClick={async () => {
              if (firstName == "") {
                setFirstNameError(true);
                setfirstNameErrorText("Please provide the first name");
              } else if (lastname == "") {
                setLastNameError(true);
                setlastnameErrorText("Please provide the last name");
              } else if (email == "") {
                setEmailError(true);
                setEmailErrorText("Please provide the email address");
              } else {
                await updateProfile();
              }
            }}
          >
            Update Details
          </Button>
        </Box>
      </Drawer>

      <Modal
        open={loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CircularProgress />
        </Box>
      </Modal>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={() => setOpenErrorAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export default TrackPickUpOrder;
