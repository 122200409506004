import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Card, Stack, Typography, TextField } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";

import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import SearchIcon from "@mui/icons-material/Search";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AllOrdersMobile() {
  const navigate = useNavigate();

  const [allOrders, setAllOrders] = useState([]);
  const [enteredOrderID, setEnteredOrderID] = useState(" ");
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const vertical = "top";
  const horizontal = "center";

  useEffect(() => {
    (async () => {
      await getAllOrders();
    })();
  }, []);

  const getAllOrders = async () => {
    const userDetails = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDetails);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/getAllOrders",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          mobile: parseData.mobile,
        }),
      }
    );

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.success == true) {
      setAllOrders(responseJson.data);
    } else {
      setErrorMsg("unable to find your orders.");
      setOpenErrorAlert(true)
    }
  };

  const searchOrder = () => {
    const filter = allOrders.filter(
      (value) => JSON.parse(value.jdata).orderID == enteredOrderID
    );
    setAllOrders(filter)
  };

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: "#669AFF",
          display: "flex",
          justifyContent: "space-between",
          padding: "3%",
        }}
      >
        <Typography
          sx={{
            marginTop: "2%",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackRoundedIcon
            sx={{
              color: "#FFFFFF",
            }}
          />
        </Typography>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: "600",
            marginTop: "1%",
            fontSize: "1.2rem",
          }}
        >
          All Orders
        </Typography>
        <Typography></Typography>
      </Box>

      <Box
        sx={{
          padding: "3%",
        }}
      >
        <Stack direction="row" spacing={2}>
          <TextField
            placeholder="Search"
            sx={{
              width: "100%",
            }}
            onChange={(e) => setEnteredOrderID(e.target.value)}
          />
          <Button variant="contained" onClick={() => searchOrder()}>
            <SearchIcon />
          </Button>
        </Stack>
      </Box>

      <Box
        sx={{
          padding: "3%",
        }}
      >
        {allOrders.map((order) => (
          <Card
            sx={{
              backgroundColor: "#FFFFFF",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              borderRadius: "8px",
              padding: "5%",
              border: "0.5px solid silver",
              marginBottom: "3%",
            }}
          >
            <Stack direction="row" spacing={2}>
              <Typography>
                <LocalShippingIcon />
              </Typography>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "600",
                  }}
                >
                  {JSON.parse(order.jdata).Description}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "grey",
                  }}
                >
                  {dateFormat(order.createddate, "dd,mmm,yyyy")}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                marginTop: "4%",
              }}
            >
              <Typography>
                <ArrowCircleUpRoundedIcon />
              </Typography>
              <Box>
                <Typography>{JSON.parse(order.jdata).senderName}</Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  {JSON.parse(order.jdata).senderAddressLine1}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                marginTop: "4%",
              }}
            >
              <Typography>
                <ArrowCircleDownRoundedIcon />
              </Typography>
              <Box>
                <Typography>{JSON.parse(order.jdata).receiverName}</Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  {JSON.parse(order.jdata).receiverAddressLine1}
                </Typography>
              </Box>
            </Stack>
            <Button
              variant="contained"
              fullWidth
              sx={{
                marginTop: "3%",
              }}
              onClick={() =>
                navigate("/express/order-details", {
                  state: {
                    orderDetails: order,
                    pID: order.PID,
                  },
                })
              }
            >
              View Order
            </Button>
          </Card>
        ))}
      </Box>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={() => setOpenErrorAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export default AllOrdersMobile;
