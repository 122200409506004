import React, { useEffect, useRef } from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import BG from "./assests/images/ghnjm.png";
import StarIcon from "@mui/icons-material/Star";
import AOS from "aos";
import "aos/dist/aos.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "swiper/css";
import "swiper/css/navigation";
import "./assests/css/clients.css";
import YouTube from "react-youtube";

function Clients() {
  useEffect(() => {
    AOS.init();
  }, []);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const opts = {
    height: "280px",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <Box>
      <Box
        className="desktop-client"
        sx={{
          backgroundImage: `url(${BG})`,
          height: "500px",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#f9f9fb",
        }}
      >
        <Grid
          container
          sx={{
            zIndex: 10,
            position: "relative",
            marginBottom: "60%",
          }}
        >
          <Grid
            item
            xs={6}
            sx={{
              marginTop: "-5%",
            }}
          >
            <Grid container>
              <Grid item xs={6} className="image-1-client">
                <img
                  data-aos="zoom-in"
                  data-aos-delay="120"
                  data-aos-duration="3000"
                  src={require("./assests/images/client-01.png")}
                />
              </Grid>
              <Grid item xs={6} className="image-2-client">
                <img
                  data-aos="zoom-in"
                  data-aos-delay="160"
                  data-aos-duration="3000"
                  src={require("./assests/images/client-02.png")}
                />
              </Grid>
            </Grid>

            <Box
            className="client-image-box"
              
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  width: "400px",
                  height: "400px",

                  borderRadius: "50%",
                }}
              ></Box>
            </Box>
            <Grid container>
              <Grid item xs={4} className="image-3-client">
                <img
                  data-aos="zoom-in"
                  data-aos-delay="200"
                  data-aos-duration="3000"
                  src={require("./assests/images/client-03.png")}
                />
              </Grid>
              <Grid item xs={5} className="image-4-client">
                <img
                  data-aos="zoom-in"
                  data-aos-delay="240"
                  data-aos-duration="3000"
                  src={require("./assests/images/client-04.png")}
                />
              </Grid>
            </Grid>
            <Grid container className="image-5-client">
              <Grid item xs={5}>
                <img
                  data-aos="zoom-in"
                  data-aos-delay="280"
                  data-aos-duration="3000"
                  src={require("./assests/images/client-05.png")}
                />
              </Grid>
              <Grid item xs={7} className="image-6-client">
                <img
                  data-aos="zoom-in"
                  data-aos-delay="320"
                  data-aos-duration="3000"
                  src={require("./assests/images/client-06.png")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              padding: "2%",
            }}
          >
            <Typography
              sx={{
                fontSize: "3rem",
                fontWeight: 600,
              }}
            >
              What Client Say?
            </Typography>
            <Grid container spacing={2} >
              <Grid item xs={6}>
                <Card
                  data-aos="flip-right"
                  data-aos-delay="380"
                  data-aos-duration="3000"
                  sx={{
                    marginTop: "5%",
                    
                    backgroundColor: "#edededb8",
                  }}
                >
                  <CardContent>
                    <YouTube
                      videoId="eqrJEtB_CSU"
                      opts={opts}
                      onReady={_onReady}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  data-aos="flip-right"
                  data-aos-delay="380"
                  data-aos-duration="3000"
                  sx={{
                    marginTop: "5%",
                    backgroundColor: "#edededb8",
                  }}
                >
                  <CardContent>
                    <YouTube
                      videoId="Q05aXMxDeyo"
                      opts={opts}
                      onReady={_onReady}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        className="mobile-client"
        sx={{
          padding: "5%",
          marginBottom : "10%"
        }}
      >
        <Typography
          sx={{
            fontSize: "1.8rem",
            fontWeight: 600,
            textAlign: "center",
            marginBottom : "10%"
          }}
        >
          Our Trusted Clients
        </Typography>
        <Swiper
          spaceBetween={20}
          slidesPerView={4}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          breakpoints={{
            200: {
              // width: 576,
              slidesPerView: 1,
            },
            330: {
              // width: 576,
              slidesPerView: 2.7,
            },
            600: {
              // width: 576,
              slidesPerView: 2.4,
            },
            669: {
              // width: 576,
              slidesPerView: 2.5,
            },
            768: {
              // width: 768,
              slidesPerView: 3,
            },
            800: {
              // width: 768,
              slidesPerView: 3.5,
            },
          }}
          modules={[Autoplay, Navigation]}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <img
              data-aos="zoom-in"
              data-aos-delay="120"
              data-aos-duration="3000"
              src={require("./assests/images/client-01.png")}
              className="clientimage client-image-top"
              
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              data-aos="zoom-in"
              data-aos-delay="160"
              data-aos-duration="3000"
              src={require("./assests/images/client-02.png")}
              className="clientimage client-image-top"
              
            />
          </SwiperSlide>

          <SwiperSlide>
            <img
              data-aos="zoom-in"
              data-aos-delay="200"
              data-aos-duration="3000"
              src={require("./assests/images/client-03.png")}
              className="clientimage"
              
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              data-aos="zoom-in"
              data-aos-delay="240"
              data-aos-duration="3000"
              src={require("./assests/images/client-04.png")}
              className="clientimage"
              
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              data-aos="zoom-in"
              data-aos-delay="280"
              data-aos-duration="3000"
              src={require("./assests/images/client-05.png")}
              className="clientimage"
              
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              data-aos="zoom-in"
              data-aos-delay="320"
              data-aos-duration="3000"
              src={require("./assests/images/client-06.png")}
              className="clientimage"
              
            />
          </SwiperSlide>
        </Swiper>
        {/* <Box className="client-buttons">
          <Grid container>
            <Grid item xs={4}>
              <Box className="client-btn-left" ref={prevRef}>
                <KeyboardArrowLeftIcon
                  sx={{
                    fontSize: "2rem",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className="client-btn-right" ref={nextRef}>
                <KeyboardArrowRightIcon
                  sx={{
                    fontSize: "2rem",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box> */}
      </Box>
    </Box>
  );
}

export default Clients;
