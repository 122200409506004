import React, { useEffect, useState } from "react";
import ChooseUs from "../ChooseUs/ChooseUs";
import Clients from "../Clients/Clients";
import JoinUs from "../JoinUs/JoinUs";
import Section2 from "../section2/Section2";
import SectionThird from "../Section3/SectionThird";
import Services from "../Services/Services";
import VideoSectionSecond from "../VideoSection2/VideoSectionSecond";
import "./css/home.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useLocation } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, Button, Typography } from "@mui/material";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ModalVideo from "react-modal-video";
// import ReactPlayer from "react-player";

function Home() {
  const [startCount, setStartCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [maxWidth, setMaxWidth] = useState("md");

  const location = useLocation();
  localStorage.setItem("scrollService", location.state);

  useEffect(() => {
    // setOpen(true)

    if (window.location.href == "https://logicarts.in/" || window.location.href == "http://localhost:3000/" ) {
      setOpen(true);
    } else {
      setOpen(false)
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });

    if (typeof window !== "undefined") {
      const handleScroll = (e) => {
        e.preventDefault();
        const position = window.scrollY;
        console.log(position);

        if (position >= 4900) {
          setStartCount(1);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  useEffect(() => {
    const element = document.getElementById(location.state);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  // useEffect(() => {
  //   if (scrollContact == true) {
  //     window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
  //   }

  // }, []);

  const handleClickScroll = (e) => {
    window.scrollTo({
      left: 0,
      top: document.body.scrollHeight,
      behavior: "smooth",
    });

    console.log("scroll");
  };

  const handleClickScrollService = (e) => {
    window.scrollTo({ left: 0, top: 741, behavior: "smooth" });
  };

  const handleClickScrollHome = (e) => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleMoveToLink = () => {
    window.location.href =
      "https://yourstory.com/2023/04/logicarts-handles-last-mile-delivery-bulky-shipment";
  };

  return (
    <>
    <ModalVideo />
      <Header
        func={handleClickScroll}
        func1={handleClickScrollService}
        func2={handleClickScrollHome}
      />
      <div
        style={{
          overflow: "hidden",
        }}
      >
        <section className="_1A98vVyC" id="home">
          {/* <ReactPlayer url={"https://youtu.be/qenovn2a4BU?si=QokX5UvhwYoLWWCT"}/> */}


          <VideoSectionSecond />
        </section>
        <section id="services" className="services-section">
          <Services />
        </section>
        <section>
          <Section2 />
        </section>
        <section>
          <SectionThird countStart={startCount} />
        </section>

        <section>
          <ChooseUs />
        </section>
        <section id="join-us">
          <JoinUs />
        </section>
        <section>
          <Clients />
        </section>
        <section id="contact-us">
          <Footer />
        </section>
      </div>

      {/* <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          zIndex: 100000,
        }}
      >
        <Box>
          <img
            src="https://images.yourstory.com/assets/logos/logo_yourstory_new.svg"
            style={{
              width: "20%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "2%",
              marginBottom: "2%",
            }}
          />
          <Button className="story-close-btn" onClick={handleClose}>
            <HighlightOffRoundedIcon className="close-story-icon" />
          </Button>
        </Box>
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={handleMoveToLink}
        >
          <img
            src={require("./images/WhatsApp.jpeg")}
            style={{
              width: "100%",
            }}
          />
        </Box>
        <Box>
          <Typography className="story-text" onClick={handleMoveToLink}>
            The New Face of Last Mile Deliveries for Bulky Shipments on Time.
          </Typography>
          <Button
            variant="outlined"
            className="read-more-story-btn"
            sx={{
              display: "block",
              margin: "auto",
              marginBottom: "3%",
              cursor: "pointer",
            }}
            onClick={handleMoveToLink}
          >
            Read More
          </Button>
        </Box>
      </Dialog> */}
    </>
  );
}

export default Home;
