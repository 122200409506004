
import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Box,
} from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const VideoModal = () => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    sessionStorage.setItem("homeVedioModalClose", true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="video-modal-title"
    
    >
      <DialogContent sx={{ p: 0, position: "relative", height: "65vh",  }}> 
        <IconButton
          edge="end"
          aria-label="close"
          sx={{ position: "absolute", top: 10, right: 8, color: "#fff", zIndex: 3 }}
          onClick={handleClose}
        >
          <HighlightOffOutlinedIcon />
        </IconButton>
        <Box sx={{ height: "100%" }}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/qenovn2a4BU?rel=0&autoplay=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ border: 0 }}
            autoPlay
           />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VideoModal;