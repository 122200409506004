import React from "react";
import MyClassComponent from "./MyClassComponent";
import { useNavigate, useLocation } from "react-router-dom";

function WrapperComponent(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    window.location.href = "/express/create-order";
    
  };

  const onGo = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};

    for (const [param, value] of urlParams.entries()) {
      params[param] = value;
    }
    
    navigate("/express/map-address-mobile", {
      state: {
        type: params.location,
      },
    });
  }

  return <MyClassComponent {...props} onGo={onGo} onGoBack={handleGoBack} />;
}

export default WrapperComponent;
