import React, { useState } from "react";
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import Config from './../../configprod';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function UserOTP() {
  const vertical = "top";
  const horizontal = "center";

  const navigate = useNavigate();
  const location = useLocation();

  const [pickOTP, setPickOTP] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openOTP, setOpenOTP] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState("");

  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");

  const [errorMsg, setErrorMsg] = useState("");

  const verifyOTP = async () => {
    setBtnLoading(true);
    setBtnDisabled(true);

    const response = await fetch("https://ndd.logicarts.in/pickup/verifyOTP", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobile: location.state.mobileNumber,
        otp: pickOTP,
      }),
    });
    const responseJson = await response.json();
    if (responseJson.success == true) {
      // console.log(responseJson);

      if (responseJson.data[0].isnewCx == 1) {
        setOpenDrawer(true);
        // localStorage.setItem(
        //   "userDetails",
        //   JSON.stringify(responseJson.data[0])
        // );
        localStorage.setItem("token", responseJson.data[0].token);
      } else {
        getUserDetails();
        localStorage.setItem(
          "userDetails",
          JSON.stringify(responseJson.data[0])
        );
        localStorage.setItem("token", responseJson.data[0].token);
        navigate("/express/order-history");

        

      }
      setBtnLoading(false);
      setBtnDisabled(false);
    } else {
      setErrorMsg(responseJson.data);
      setOpenOTP(true);
      setBtnLoading(false);
      setBtnDisabled(false);
    }
  };

  const handleCloseOTP = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenOTP(false);
  };

  const handleCloseRegister = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenRegister(false);
  };

  const delay = async (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
  }


  const cxManageCX = async () => {
    const userToken = localStorage.getItem("token");

    const response = await fetch("https://ndd.logicarts.in/pickup/cxManageCX", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${userToken}`,
      },

      body: JSON.stringify({
        cxID: "",
        mobile: location.state.mobileNumber,
        jdata: {
          FromAddresses: [
            { Description: "", Address: "", Contact01: "", IsDefault: "" },
            { Description: "", Address: "", Contact01: "", IsDefault: "" },
          ],
          ToAddresses: [
            { Description: "", Address: "", Contact01: "" },
            { Description: "", Address: "", Contact01: "" },
          ],
          firstname: firstName,
          lastname: lastName,
          email: email,
        },
      }),
    });

    const responseJson = await response.json();

    if (responseJson.success == true) {

      const response = await fetch(
        "https://ndd.logicarts.in/pickup/user/details",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({
            mobile: location.state.mobileNumber,
          }),
        }
      );
      const responseJson = response.json();
      console.log(responseJson, 'rohan response');

      await axios.post(
        `${Config.hostName}/pickup/user/details`,
        {
          mobile: location.state.mobileNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token"),
          },
        }
      ).then(async (res) => {
        console.log(res.data.data[0]);
        const data2 = res.data.data[0];
        localStorage.setItem(
          "userDetails",
          JSON.stringify(data2)
        );


        await delay(2000);
        
        navigate("/express/order-history");
        setOpenDrawer(false);
      })

    } else {
      setErrorMsg(responseJson.data);
      setOpenRegister(true);
    }
  };

  const getUserDetails = async () => {
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/user/details",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          mobile: location.state.mobileNumber,
        }),
      }
    );
    const responseJson = response.json();
    // console.log(responseJson);

    if (responseJson.success == true) {
      await localStorage.setItem(
        "userDetails",
        JSON.stringify(responseJson.data[0])
      );

    }

  };

  const handleClose = () => {
    setOpenDrawer(false);
    navigate("/express/user-login");
  };

  return (
    <Box
      sx={{
        padding: "5%",
      }}
    >
      <img
        src={require("./assests/images/Expresslogonew.png")}
        style={{
          width: "100%",
          marginTop: "30%",
          marginBottom: "20%",
        }}
      />

      <Typography
        sx={{
          fontSize: "1.3rem",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        OTP Verification
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
        }}
      >
        Enter the OTP send to <strong>+91 {location.state.mobileNumber}</strong>
      </Typography>

      <Box
        sx={{
          marginLeft: "5%",
          marginTop: "15%",
        }}
      >
        <OTPInput
          value={pickOTP}
          onChange={setPickOTP}
          OTPLength={4}
          otpType="number"
          disabled={false}
          secure
          style={{
            justifyContent: "space-evenly",
          }}
          inputStyles={{
            width: "100%",
            borderColor: "#fff",
            borderLeftColor: "#fff0",
            borderTopColor: "#fff0",
            borderBottomColor: "grey",
          }}
        />
      </Box>
      <Typography
        sx={{
          textAlign: "center",
          marginTop: "10%",
        }}
      >
        Didn't Received?{" "}
        <span
          style={{
            color: "#6ec0ff",
            fontWeight: "600",
          }}
          onClick={() => navigate("/express/user-login")}
        >
          RESEND OTP
        </span>
      </Typography>
      <LoadingButton
        fullWidth
        disabled={btnDisabled}
        loading={btnLoading}
        variant="contained"
        sx={{
          marginTop: "15%",
        }}
        onClick={() => verifyOTP()}
      >
        Verify & Proceed
      </LoadingButton>

      <Drawer anchor="bottom" open={openDrawer} onClose={handleClose}>
        <Box
          sx={{
            padding: "5%",
          }}
        >
          <Typography
            sx={{
              marginBottom: "5%",
              fontWeight: "600",
            }}
          >
            Provide us your details
          </Typography>
          <Typography
            sx={{
              marginTop: "5%",
              marginBottom: "3%",
            }}
          >
            First Name
          </Typography>
          <TextField
            error={firstNameError}
            helperText={firstNameErrorMsg}
            fullWidth
            variant="standard"
            placeholder="Enter Your First Name"
            onChange={(e) => {
              setFirstNameError(false);
              setFirstNameErrorMsg("");
              setFirstName(e.target.value);
            }}
          />
          <Typography
            sx={{
              marginTop: "5%",
              marginBottom: "3%",
            }}
          >
            Last Name
          </Typography>
          <TextField
            error={lastNameError}
            helperText={lastNameErrorMsg}
            fullWidth
            variant="standard"
            placeholder="Enter Your Last Name"
            onChange={(e) => {
              setLastNameError(false);
              setLastNameErrorMsg("");
              setLastName(e.target.value);
            }}
          />
          <Typography
            sx={{
              marginTop: "5%",
              marginBottom: "3%",
            }}
          >
            Email Address
          </Typography>
          <TextField
            error={emailError}
            helperText={emailErrorMsg}
            fullWidth
            variant="standard"
            placeholder="Enter Your Email Address"
            onChange={(e) => {
              setEmailError(false);
              setEmailErrorMsg("");
              setEmail(e.target.value);
            }}
          />

          <LoadingButton
            fullWidth
            variant="contained"
            sx={{
              marginTop: "5%",
            }}
            onClick={() => {
              if (firstName == "") {
                setFirstNameError(true);
                setFirstNameErrorMsg("Please provide your first name.");
              } else if (lastName == "") {
                setLastNameError(true);
                setLastNameErrorMsg("Please provide your last name.");
              } else if (email == "") {
                setEmailError(true);
                setEmailErrorMsg("Please provide your email address.");
              } else {
                cxManageCX();
              }
            }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Drawer>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openOTP}
        autoHideDuration={6000}
        onClose={handleCloseOTP}
      >
        <Alert onClose={handleCloseOTP} severity="error" sx={{ width: "100%" }}>
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openRegister}
        autoHideDuration={6000}
        onClose={handleCloseRegister}
      >
        <Alert
          onClose={handleCloseRegister}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default UserOTP;
