import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import SuccessImage from "./assests/images/96237-success.gif";
import { useNavigate, useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";


function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function CircularProgressWithLabel(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        marginLeft: "45%",
        marginTop: "5%",
        marginBottom: "5%",
      }}
    >
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}s`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function OrderSuccess(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [openDrawer, setOPenDrawer] = useState(true);
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");


  const vertical = "top";
  const horizontal = "center";

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 30
          ? navigate("/express/order-history")
          : prevProgress + 2
      );
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const cancelOrder = async () => {
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/pickerModifyOrder",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token":
            "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGljaGVjazEiLCJpYXQiOjE2ODkwMTYzMDUsImV4cCI6MTY4OTAxOTkwNX0.Lgtn6mOK-sFiGxWJV_ZEyjqojTaAd-wVa2dYWL2Lurs",
        },
        body: JSON.stringify({
          pID: location.state.pID,
          contentID: "2",
          statusID: "19",
          jdata: {
            orderID: location.state.orderID,
            senderName: location.state.senderName,
            senderHouseAddress: location.state.senderHouseAddress,
            senderAddressLine1: location.state.senderAddressLine1,
            senderAddressLine2: location.state.senderAddressLine2,
            senderLandmark: location.state.senderLandmark,
            senderCity: location.state.senderCity,
            senderState: location.state.senderState,
            senderPincode: location.state.senderPincode,
            sendermobileNumber: location.state.sendermobileNumber,
            senderLat: location.state.senderLat,
            senderLng: location.state.senderLng,
            senderNote: location.state.senderNote,
            receiverName: location.state.receiverName,
            receiverHouseAddress: location.state.receiverHouseAddress,
            receiverAddressLine1: location.state.receiverAddressLine1,
            receiverAddressLine2: location.state.receiverAddressLine2,
            receiverLandmark: location.state.receiverLandmark,
            receiverCity: location.state.receiverCity,
            receiverState: location.state.receiverState,
            receiverPincode: location.state.receiverPincode,
            receiverMobileNumber: location.state.receivermobileNumber,
            receiverLat: location.state.receiverLat,
            receiverLng: location.state.receiverLng,
            receiverNote: location.state.receiverNote,
            paymentType: location.state.paymentType,
            goodsValue: Number(location.state.goodsValue),
            Description: location.state.Description,
            actualWeight: location.state.actualWeight,
            isFragile: location.state.isFragile,
            mobile: location.state.mobile,
            amount: "200",
            deliveryAent: {},
            is_deliveryAgent: "0",
            couponCode: location.state.couponCode,
            images: [],
            amountToPay: location.state.amountToPay,
            status: "Cx Order Cancelled",
          },
        }),
      }
    );
    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.success == true) {
      setOpen(true);
      setTimeout(() => {
        setOPenDrawer(false);
        navigate("/express/order-history");
      }, 2000);
    } else {
      setErrorMsg("Unable to cancel your order");
      setOpenErrorAlert(true)
      navigate("/express/order-history");
    }
  };

  return (
    <React.Fragment>
      <Box className="mobile-order-success">
        <Container>
          <Box sx={{ my: 2 }}>
            <img
              src={SuccessImage}
              style={{
                width: "50%",
                margin: "auto",
                display: "block",
                marginBottom: "10%",
              }}
            />
            <Typography
              sx={{
                color: "#48B867",
                fontWeight: "700",
                textAlign: "center",
                fontSize: "2rem",
                lineHeight: 1,
              }}
            >
              Order Successfully Placed.
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                color: "#A1A1A1",
                marginTop: "5%",
              }}
            >
              Congratulations, your order has been successfully create.Thank you
              for choosing us.
            </Typography>
            <Button
              variant="contained"
              sx={{
                width: "50%",
                margin: "auto",
                display: "block",
                marginTop: "5%",
              }}
              onClick={() => navigate("/express/order-history")}
            >
              Done
            </Button>
            <Typography
              sx={{
                color: "#C2C2C2",
                marginTop: "3%",
                textAlign: "center",
              }}
              onClick={() => navigate("/express/create-order")}
            >
              Book New Order
            </Typography>
          </Box>

          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Box
              sx={{
                padding: "5%",
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  textAlign: "center",
                  marginBottom: "5%",
                }}
              >
                If you placed the order by mistake then you can cancel your
                order{" "}
              </Typography>

              <CircularProgressWithLabel value={progress} />

              <Button
                variant="contained"
                color="error"
                sx={{
                  margin: "auto",
                  display: "block",
                }}
                onClick={() => cancelOrder()}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className="desktop-order-success">
        <Container>
          <Box sx={{ my: 2, marginTop: "10%" }}>
            <img
              src={SuccessImage}
              style={{
                width: "20%",
                margin: "auto",
                display: "block",
                marginBottom: "5%",
              }}
            />
            <Typography
              sx={{
                color: "#48B867",
                fontWeight: "700",
                textAlign: "center",
                fontSize: "2rem",
                lineHeight: 1,
              }}
            >
              Order Successfully Placed.
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                color: "#A1A1A1",
                marginTop: "2%",
              }}
            >
              Congratulations, your order has been successfully create.Thank you
              for choosing us.
            </Typography>
            <Button
              variant="contained"
              sx={{
                width: "20%",
                margin: "auto",
                display: "block",
                marginTop: "2%",
              }}
              onClick={() => navigate("/express/order-history")}
            >
              Done
            </Button>
            <Typography
              sx={{
                color: "#C2C2C2",
                marginTop: "1.5%",
                textAlign: "center",
              }}
              onClick={() => navigate("/express/create-order")}
            >
              Book New Order
            </Typography>
          </Box>

          <Box>
            <Box
              sx={{
                padding: "5%",
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  textAlign: "center",
                }}
              >
                If you placed the order by mistake then you can cancel your
                order{" "}
              </Typography>

              <CircularProgressWithLabel value={progress} />

              <Button
                variant="contained"
                color="error"
                sx={{
                  margin: "auto",
                  display: "block",
                }}
                onClick={() => cancelOrder()}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      <Snackbar open={open} autoHideDuration={6000}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Your order has been cancelled!
        </Alert>
      </Snackbar>


      <Snackbar
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={() => setOpenErrorAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default OrderSuccess;
