import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  Box,
  Typography,
  Button,
  Container,
  Tooltip,
  Menu,
  MenuItem,
  TextField,
  Grid,
  Modal,
  CircularProgress,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import CallIcon from "@mui/icons-material/Call";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";
import dateFormat from "dateformat";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import backImage from "../CreateOrder/assests/images/bgImage.png";

import AddIcon from "@mui/icons-material/Add";
import TimelineIcon from "@mui/icons-material/Timeline";
import AccountCircle from "@mui/icons-material/AccountCircle";

import Drawer from "@mui/material/Drawer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import "./orderDetails.css";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  border: "0.3px solid grey",
};

function OrderDetails() {
  const navigate = useNavigate();
  const location = useLocation();

  // console.log(location.state.orderDetails);

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);

  const [openEditProfile, setOpenEditProfile] = useState(false);

  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorText, setfirstNameErrorText] = useState("");

  const [lastnameError, setLastNameError] = useState(false);
  const [lastnameErrorText, setlastnameErrorText] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");

  const [orderData, setOrderData] = useState([]);
  const [status, setStatus] = useState("");
  const [statusUpdate, setStatusUpdate] = useState("");

  const [loading, setLoading] = useState(true);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  let url = new URL(window.location.href);
  let search_params = url.searchParams;
  let valuepID = search_params.get("pID");

  useEffect(() => {
    (async () => {
      await verifyToken();
      await getOrderDetails(valuepID);
      await getData();
    })();
  }, []);


  const vertical = "top";
  const horizontal = "center";

  const getData = async () => {
    const userDetails = localStorage.getItem("userDetails");
    const parseData = await JSON.parse(userDetails);
    const parseJdata = await JSON.parse(parseData.jdata);
    setUserName(parseData.firstname + " " + parseData.lastname);
    setUserMobile(parseData.mobile);
    setUserEmail(parseJdata.email);
  };

  const verifyToken = async () => {
    const userToken = localStorage.getItem("token");
    console.log(userToken);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    const responseJson = await response.json();
    console.log(responseJson, "verify");
    if (responseJson.success == true) {
      console.log("authenticated");
    } else {
      navigate("/express/user-login");
    }
  };

  const getOrderDetails = async (valuepID) => {
    setLoading(true);
    const userDetails = localStorage.getItem("userDetails");
    const parseData = await JSON.parse(userDetails);
    console.log(valuepID);

    const response = await fetch(
      "https://ndd.logicarts.in/shipmentTracking/trackShipmentStatus",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          pID: !valuepID ? location.state.pID : valuepID,
        }),
      }
    );

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.success == true) {
      const parseData = JSON.parse(responseJson.data[0].jdata);
      setOrderData(parseData);
      setStatus(responseJson.data[0].status);
      setStatusUpdate(responseJson.data[0].updatedDate);
      setLoading(false);
    }
  };

  const updateProfile = async () => {
    const userDeatils = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDeatils);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          cxID: parseData.cxID,
          mobile: parseData.mobile,
          jdata: {
            FromAddresses: [
              { Description: "", Address: "", Contact01: "", IsDefault: "" },
              { Description: "", Address: "", Contact01: "", IsDefault: "" },
            ],
            ToAddresses: [
              { Description: "", Address: "", Contact01: "" },
              { Description: "", Address: "", Contact01: "" },
            ],
            firstname: firstName,
            lastname: lastname,
            email: email,
          },
        }),
      }
    );

    const responseJson = await response.json();
    if (responseJson.success == true) {
      alert(
        "Profile update successfully. Kindly logout and login again to see the changes."
      );
      setOpenEditProfile(false);
    } else {
      setErrorMsg("Unable to update the profile");
      setOpenErrorAlert(true)
    }
  };

  const logOut = async () => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("token");
    window.location.reload();
  };

  return (
    <Fragment>
      <Box className="mobile-orders-details">
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              backgroundColor: "#669AFF",
              display: "flex",
              justifyContent: "space-between",
              padding: "3%",
            }}
          >
            <Typography
              sx={{
                marginTop: "2%",
              }}
              onClick={() => navigate("/express/order-history")}
            >
              <ArrowBackRoundedIcon
                sx={{
                  color: "#FFFFFF",
                }}
              />
            </Typography>
            <Typography
              sx={{
                color: "#FFFFFF",
                fontWeight: "600",
                marginTop: "1%",
                fontSize: "1.2rem",
              }}
            >
              Order Details
            </Typography>
            <Typography></Typography>
          </Box>
        </Box>
        <Box
          sx={{
            padding: 2,
            backgroundColor: "#f8f8f8",
          }}
        >
          <Card
            sx={{
              borderRadius: "8px",
              padding: "3%",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              marginBottom: "15%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2%",
              }}
            >
              <Box>
                <Stack direction="row" spacing={2}>
                  <Typography
                    sx={{
                      color: "grey",
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        color: "green",
                      }}
                    />
                  </Typography>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      {status}
                    </Typography>
                    <Typography
                      sx={{
                        color: "grey",
                      }}
                    >
                      {dateFormat(statusUpdate, "dd-mm-yyyy, HH:MM")}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>

            <Box
              sx={{
                marginTop: "5%",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  marginBottom: "2%",
                }}
              >
                Address Details
              </Typography>
              <Box
                sx={{
                  border: ".5px solid #C6C6C6",
                }}
              />
            </Box>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                marginTop: "4%",
              }}
            >
              <Typography>
                <ArrowCircleUpRoundedIcon />
              </Typography>
              <Box>
                <Typography>{orderData.senderName}</Typography>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: "0.8rem",
                    color: "grey",
                  }}
                >
                  {orderData.sendermobileNumber}
                </Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  {orderData.senderAddressLine1}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                marginTop: "4%",
              }}
            >
              <Typography>
                <ArrowCircleDownRoundedIcon />
              </Typography>
              <Box>
                <Typography>{orderData.receiverName}</Typography>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: "0.8rem",
                    color: "grey",
                  }}
                >
                  {orderData.receivermobileNumber}
                </Typography>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                >
                  {orderData.receiverAddressLine1}
                </Typography>
              </Box>
            </Stack>

            <Box
              sx={{
                marginTop: "8%",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  marginBottom: "2%",
                }}
              >
                Item Details
              </Typography>
              <Box
                sx={{
                  border: ".5px solid #C6C6C6",
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "2%",
              }}
            >
              <Box>
                <Typography gutterBottom>Item :</Typography>
                <Typography gutterBottom>Actual Weight :</Typography>
                <Typography gutterBottom>Value :</Typography>
                <Typography gutterBottom>Is Fragile :</Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "grey",
                  }}
                  gutterBottom
                >
                  {orderData.Description}
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    color: "grey",
                  }}
                >
                  {orderData.actualWeight}
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    color: "grey",
                  }}
                >
                  {orderData.goodsValue}
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    color: "grey",
                  }}
                >
                  {orderData.isFragile == "false" ? "No" : "Yes"}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                marginTop: "8%",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  marginBottom: "2%",
                }}
              >
                Other Details
              </Typography>
              <Box
                sx={{
                  border: ".5px solid #C6C6C6",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "2%",
              }}
            >
              <Box>
                <Typography gutterBottom>Payment Type :</Typography>
                <Typography gutterBottom>Created Date :</Typography>
                <Typography gutterBottom>Pickup Date :</Typography>
              </Box>
              <Box>
                <Typography
                  gutterBottom
                  sx={{
                    color: "grey",
                  }}
                >
                  {orderData.paymentType}
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    color: "grey",
                  }}
                >
                  {dateFormat(orderData.createddate, "dd mmm yyyy")}
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    color: "grey",
                  }}
                >
                  {dateFormat(orderData.pickupdate, "dd mmm yyyy")}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: "3%",
              }}
            ></Box>
          </Card>

          <Button
            startIcon={<ShareLocationIcon />}
            variant="contained"
            fullWidth
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
            }}
            onClick={() =>
              navigate("/express/track-pickup-order", {
                state: {
                  pID: !valuepID ? location.state.pID : valuepID,
                },
              })
            }
          >
            Track Order
          </Button>
        </Box>
      </Box>

      <Box className="desktop-order-details">
        <Box
          sx={{
            backgroundImage: `url(${backImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <AppBar
              position="static"
              sx={{
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Toolbar>
                <img
                  src={require("./assests/images/logo_new1.png")}
                  style={{
                    width: "10%",
                  }}
                />

                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <IconButton
                    size="large"
                    onClick={() => navigate("/express/create-order")}
                  >
                    <Box>
                      <AddIcon />
                      <Typography>Create Pickup</Typography>
                    </Box>
                  </IconButton>
                  <IconButton
                    size="large"
                    onClick={() => navigate("/express/order-history")}
                  >
                    <Box>
                      <TimelineIcon />
                      <Typography>Track Order</Typography>
                    </Box>
                  </IconButton>
                  <IconButton
                    size="large"
                    edge="end"
                    aria-haspopup="true"
                    onClick={() => setOpenProfile(true)}
                  >
                    <Box>
                      <AccountCircle />
                      <Typography>Profile</Typography>
                    </Box>
                  </IconButton>
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
          <Box
            sx={{
              marginTop: "5%",
            }}
          >
            <Container
              sx={{
                marginBottom: "2%",
              }}
            >
              <Card
                sx={{
                  borderRadius: "8px",
                  padding: "3%",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  width: "50%",
                  margin: "auto",
                  display: "block",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "2%",
                  }}
                >
                  <Box>
                    <Stack direction="row" spacing={2}>
                      <Typography
                        sx={{
                          color: "grey",
                        }}
                      >
                        <CheckCircleRoundedIcon
                          sx={{
                            color: "green",
                          }}
                        />
                      </Typography>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                          }}
                        >
                          {status}
                        </Typography>
                        <Typography
                          sx={{
                            color: "grey",
                          }}
                        >
                          {dateFormat(statusUpdate, "dd-mm-yyyy, HH:MM")}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                  <Box>
                    <Chip
                      icon={
                        <CallIcon
                          sx={{
                            fontSize: "1rem",
                          }}
                        />
                      }
                      label="Call"
                      color="primary"
                      sx={{
                        padding: "2px",
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    marginTop: "8%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      marginBottom: "2%",
                    }}
                  >
                    Address Details
                  </Typography>
                  <Box
                    sx={{
                      border: ".5px solid #C6C6C6",
                    }}
                  />
                </Box>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    marginTop: "4%",
                  }}
                >
                  <Typography>
                    <ArrowCircleUpRoundedIcon />
                  </Typography>
                  <Box>
                    <Typography>
                      {JSON.parse(location.state.orderDetails.jdata).senderName}
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        fontSize: "0.8rem",
                        color: "grey",
                      }}
                    >
                      {
                        JSON.parse(location.state.orderDetails.jdata)
                          .sendermobileNumber
                      }
                    </Typography>
                    <Typography
                      sx={{
                        color: "grey",
                      }}
                    >
                      {
                        JSON.parse(location.state.orderDetails.jdata)
                          .senderAddressLine1
                      }
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    marginTop: "4%",
                  }}
                >
                  <Typography>
                    <ArrowCircleDownRoundedIcon />
                  </Typography>
                  <Box>
                    <Typography>
                      {
                        JSON.parse(location.state.orderDetails.jdata)
                          .receiverName
                      }
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        fontSize: "0.8rem",
                        color: "grey",
                      }}
                    >
                      {
                        JSON.parse(location.state.orderDetails.jdata)
                          .receivermobileNumber
                      }
                    </Typography>
                    <Typography
                      sx={{
                        color: "grey",
                      }}
                    >
                      {
                        JSON.parse(location.state.orderDetails.jdata)
                          .receiverAddressLine1
                      }
                    </Typography>
                  </Box>
                </Stack>

                <Box
                  sx={{
                    marginTop: "8%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      marginBottom: "2%",
                    }}
                  >
                    Item Details
                  </Typography>
                  <Box
                    sx={{
                      border: ".5px solid #C6C6C6",
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "2%",
                  }}
                >
                  <Box>
                    <Typography gutterBottom>Item :</Typography>
                    <Typography gutterBottom>Actual Weight :</Typography>
                    <Typography gutterBottom>Value :</Typography>
                    <Typography gutterBottom>Is Fragile :</Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "grey",
                      }}
                      gutterBottom
                    >
                      {
                        JSON.parse(location.state.orderDetails.jdata)
                          .Description
                      }
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "grey",
                      }}
                    >
                      {
                        JSON.parse(location.state.orderDetails.jdata)
                          .actualWeight
                      }
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "grey",
                      }}
                    >
                      {JSON.parse(location.state.orderDetails.jdata).goodsValue}
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "grey",
                      }}
                    >
                      {JSON.parse(location.state.orderDetails.jdata)
                        .isFragile == "false"
                        ? "No"
                        : "Yes"}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    marginTop: "8%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      marginBottom: "2%",
                    }}
                  >
                    Other Details
                  </Typography>
                  <Box
                    sx={{
                      border: ".5px solid #C6C6C6",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "2%",
                  }}
                >
                  <Box>
                    <Typography gutterBottom>Payment Type :</Typography>
                    <Typography gutterBottom>Created Date :</Typography>
                    <Typography gutterBottom>Pickup Date :</Typography>
                  </Box>
                  <Box>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "grey",
                      }}
                    >
                      {
                        JSON.parse(location.state.orderDetails.jdata)
                          .paymentType
                      }
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "grey",
                      }}
                    >
                      {dateFormat(
                        location.state.orderDetails.createddate,
                        "dd mmm yyyy"
                      )}
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "grey",
                      }}
                    >
                      {dateFormat(
                        location.state.orderDetails.pickupdate,
                        "dd mmm yyyy"
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "3%",
                  }}
                ></Box>
                <Button
                  startIcon={<ShareLocationIcon />}
                  variant="contained"
                  fullWidth
                  onClick={() =>
                    navigate("/express/track-pickup-order", {
                      state: {
                        pID: !valuepID ? location.state.pID : valuepID,
                      },
                    })
                  }
                >
                  Track Order
                </Button>
              </Card>
            </Container>
          </Box>
        </Box>
      </Box>

      <Drawer
        anchor="right"
        open={openProfile}
        onClose={() => setOpenProfile(false)}
        PaperProps={{
          sx: { width: "30%" },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5%",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  textAlign: "center",
                  marginTop: "3%",
                  fontWeight: "600",
                }}
              >
                User Profile
              </Typography>
            </Box>
            <Box>
              <Button onClick={() => setOpenEditProfile(true)}>Edit</Button>
            </Box>
          </Box>

          <Box
            sx={{
              padding: "5%",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
              }}
            >
              <AccountCircleIcon
                sx={{
                  fontSize: "8rem",
                  color: "grey",
                }}
              />
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
              }}
            >
              {userName}
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "5%",
              padding: "5%",
              overflow: "hidden",
            }}
          >
            <Card
              sx={{
                padding: "3%",
                marginBottom: "5%",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <Typography
                sx={{
                  color: "#807d7d",
                }}
              >
                {userMobile}
              </Typography>
            </Card>
            <Card
              sx={{
                padding: "3%",
                marginBottom: "5%",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <Typography
                sx={{
                  color: "#807d7d",
                }}
              >
                {userEmail}
              </Typography>
            </Card>
            <Card
              sx={{
                padding: "3%",
                marginBottom: "5%",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <Typography
                sx={{
                  color: "#807d7d",
                }}
              >
                Manage Address
              </Typography>
            </Card>
            <Card
              sx={{
                padding: "3%",
                marginBottom: "5%",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <Typography
                sx={{
                  color: "#807d7d",
                }}
              >
                Customer Support
              </Typography>
            </Card>

            <Button variant="contained" fullWidth onClick={() => logOut()}>
              Log Out
            </Button>
          </Box>
        </Box>
      </Drawer>

      <Drawer
        anchor="right"
        open={openEditProfile}
        onClose={() => setOpenEditProfile(false)}
        PaperProps={{
          sx: { width: "30%" },
        }}
      >
        <Box
          sx={{
            padding: "5%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
            }}
          >
            Update Your Details
          </Typography>

          <TextField
            fullWidth
            placeholder="First Name"
            sx={{
              marginTop: "4%",
            }}
            onChange={(e) => {
              setFirstNameError(false);
              setfirstNameErrorText("");
              setFirstName(e.target.value);
            }}
            error={firstNameError}
            helperText={firstNameErrorText}
          />
          <TextField
            fullWidth
            placeholder="Last Name"
            sx={{
              marginTop: "4%",
            }}
            onChange={(e) => {
              setLastNameError(false);
              setlastnameErrorText("");
              setLastName(e.target.value);
            }}
            error={lastnameError}
            helperText={lastnameErrorText}
          />

          <TextField
            fullWidth
            placeholder="Email Address"
            sx={{
              marginTop: "4%",
            }}
            onChange={(e) => {
              setEmailError(false);
              setEmailErrorText("");
              setEmail(e.target.value);
            }}
            error={emailError}
            helperText={emailErrorText}
          />

          <Button
            fullWidth
            variant="contained"
            sx={{
              marginTop: "5%",
            }}
            onClick={async () => {
              if (firstName == "") {
                setFirstNameError(true);
                setfirstNameErrorText("Please provide the first name");
              } else if (lastname == "") {
                setLastNameError(true);
                setlastnameErrorText("Please provide the last name");
              } else if (email == "") {
                setEmailError(true);
                setEmailErrorText("Please provide the email address");
              } else {
                await updateProfile();
              }
            }}
          >
            Update Details
          </Button>
        </Box>
      </Drawer>

      <Modal
        open={loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CircularProgress />
        </Box>
      </Modal>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={() => setOpenErrorAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export default OrderDetails;
