import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { Card, Grid, Modal } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  GoogleMap,
  LoadScript,
  MarkerF,
  useLoadScript,
  DistanceMatrixService,
} from "@react-google-maps/api";

import MenuItem from "@mui/material/MenuItem";
import "./create.css";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import GoogleMapComponent from './GoogleMapComponent';

import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from "@mui/icons-material/Inventory";
import SummarizeIcon from "@mui/icons-material/Summarize";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Drawer from "@mui/material/Drawer";

import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import MyLocationRoundedIcon from "@mui/icons-material/MyLocationRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CancelIcon from "@mui/icons-material/Cancel";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import dateFormat from "dateformat";
import moment from "moment";
import { v4 as uuid } from "uuid";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import PointConfetti from "../Animation/PointConfetti";
import Geocode from "react-geocode";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Autocomplete from "react-google-autocomplete";



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "background.paper",
  border: "0.5px solid #000",
  boxShadow: 24,
  p: 4,
};

function CreateOrderMobile(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const maxWidth = "md";

  const [senderName, setSenderName] = useState("");
  const [senderAddressLine1, setSenderAddressLine1] = useState("");
  const [senderAddressLine2, setSenderAddressLine2] = useState("");
  const [sendermobileNumber, setSendermobileNumber] = useState("");
  const [senderLandmark, setSenderLandmark] = useState("");
  const [senderCity, setSenderCity] = useState("");
  const [senderState, setSenderState] = useState("");
  const [senderPincode, setSenderPincode] = useState("");
  const [senderHouseAddress, setSenderHouseAddress] = useState("");

  const [invoiceNumber, setInvoiceNumber] = useState("");

  const [receiverName, setReceiverName] = useState("");
  const [receiverAddressLine1, setReceiverAddressLine1] = useState("");
  const [receiverAddressLine2, setReceiverAddressLine2] = useState("");
  const [receivermobileNumber, setReceivermobileNumber] = useState("");
  const [receiverLandmark, setRecevierLandmark] = useState("");
  const [receiverCity, setRecevierCity] = useState("");
  const [receiverState, setRecevierState] = useState("");
  const [receiverPincode, setRecevierPincode] = useState("");
  const [receiverHouseAddress, setRecevierHouseAddress] = useState("");

  const [description, setDescription] = useState("");
  const [isFragile, setIsFragile] = useState(false);
  const [goodsValue, setGoodsValue] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [mobile, setMobile] = useState("");

  const [activeStep, setActiveStep] = useState(0);

  const [openFromAddress, setOpenFromAddress] = useState(false);
  const [openToAddress, setOpenToAddress] = useState(false);

  const [time, setTime] = useState("");

  const [packageSize, setPackageSize] = useState("");
  const [smallBorder, setSmallBorder] = useState("0.5px solid #DDDDDD");
  const [mediumBorder, setMediumBorder] = useState("0.5px solid #DDDDDD");
  const [largeBorder, setLargeBorder] = useState("0.5px solid #DDDDDD");
  const [bulkyBorder, setBulkyBorder] = useState("0.5px solid #DDDDDD");

  const [selectedDate, setSelectedDate] = useState("");
  const [date1Border, setDate1Border] = useState("0.5px solid #DDDDDD");
  const [date2Border, setDate2Border] = useState("0.5px solid #DDDDDD");
  const [date3Border, setDate3Border] = useState("0.5px solid #DDDDDD");
  const [date4Border, setDate4Border] = useState("0.5px solid #DDDDDD");

  const [loading, setLoading] = useState(false);

  const [pickupCoords, setPickupCoords] = useState("");
  const [deliveryCoords, setDeliveryCoords] = useState("");

  const [userAddress, setUserAddress] = useState([]);

  const [senderError, setSenderError] = useState(false);
  const [senderErrorText, setSenderErrorText] = useState("");
  const [receiverError, setReceiverError] = useState(false);
  const [receiverErrorTex, setReceiverErrorText] = useState("");

  const [packageSizeError, setPackageSizeError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [descriptionErrorText, setDescriptionErrorText] = useState("");
  const [goodsValueError, setGoodsValueError] = useState(false);
  const [goodsValueErrorText, setGoodsValueErrorText] = useState("");
  const [schPickError, setSchPickError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [amountPaid, setAmountPaid] = useState(0);

  const [btnDisabled, setBtnDisabled] = useState(false);

  const [distanceOriginLat, setDistanceOriginLat] = useState(0);
  const [distanceOriginLng, setDistanceOriginLng] = useState(0);
  const [distanceDestLat, setDistanceDestLat] = useState(12.9889077);
  const [distanceDestLng, setDistanceDestLng] = useState(77.6869043);

  const [totalDistance, setTotalDistance] = useState("");
  const [openDrawerCoupons, setOpenDrawerCoupons] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [showCelebrate, setShowCelebrate] = useState("none");

  const [allSizes, setAllSizes] = useState([]);
  const [ssID, setssID] = useState(0);
  const [allCoupons, setAllCoupons] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [showUper, setShowUper] = useState("none");
  const [showPaymentTypeError, setShowPaymentTypeError] = useState("none");

  const [completeAddress, setCompleteAdress] = useState("");
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [isLoad, setIsLoad] = useState(false);
  const [apiLoaded, setIsApiLoaded] = useState(false);


  useEffect(() => {
    (async () => {
      await position();
      await getData();
      await verifyToken();
      await getAddress();
      await getCoupons();



    })();
  }, []);



  const getData = async () => {
    const userDetails = localStorage.getItem("userDetails");
    const parseData = await JSON.parse(userDetails);
    const parseJdata = await JSON.parse(parseData.jdata);
    setSenderName(parseData.firstname + " " + parseData.lastname);
    setMobile(parseData.mobile);
    setSendermobileNumber(parseData.mobile);
  };

  const verifyToken = async () => {
    const userToken = localStorage.getItem("token");
    console.log(userToken);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/verifyToken",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    const responseJson = await response.json();
    if (responseJson.success == true) {
      console.log("authenticated");
    } else {
      navigate("/express/user-login");
    }
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#2287D9",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#2287D9",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#2287D9",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#2287D9",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <HomeIcon />,
      2: <InventoryIcon />,
      3: <SummarizeIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const date = new Date();

  const date2 = moment().add(1, "days");
  const date3 = moment().add(2, "days");
  const date4 = moment().add(3, "days");
  console.log(date2._d, date3._d, date4._d);

  const createOrder = async () => {
    setBtnDisabled(true);
    setLoading(true);
    const unique_id = uuid();
    const small_id = unique_id.slice(0, 8);
    const userToken = localStorage.getItem("token");
    const pickupCoordinates = pickupCoords.split(",");
    const deliveryCoordinates = deliveryCoords.split(",");

    const response = await fetch("https://ndd.logicarts.in/pickup/create", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${userToken}`,
      },

      body: JSON.stringify({
        pID: "",
        pickupdate: date.toISOString(),
        jdata: {
          orderID: `EPL${small_id}`,
          senderName: senderName,
          senderHouseAddress: senderHouseAddress,
          senderAddressLine1: senderAddressLine1,
          senderAddressLine2: senderAddressLine2,
          senderLandmark: senderLandmark,
          senderCity: senderCity,
          senderState: senderState,
          senderPincode: senderPincode,
          sendermobileNumber: sendermobileNumber,
          senderLat: Number(pickupCoordinates[0]),
          senderLng: Number(pickupCoordinates[1]),
          senderNote: isFragile == true ? "Fragile item, handle with care" : "",
          invoiceNumber: invoiceNumber,

          receiverName: receiverName,
          receiverHouseAddress: receiverHouseAddress,
          receiverAddressLine1: receiverAddressLine1,
          receiverAddressLine2: receiverAddressLine2,
          receiverLandmark: receiverLandmark,
          receiverCity: receiverCity,
          receiverState: receiverState,
          receiverPincode: receiverPincode,
          receivermobileNumber: receivermobileNumber,
          receiverLat: Number(deliveryCoordinates[0]),
          receiverLng: Number(deliveryCoordinates[1]),
          receiverNote:
            isFragile == true ? "Fragile item, handle with care" : "",

          paymentType: paymentType,
          goodsValue: goodsValue,

          Description: description,
          actualWeight: packageSize,
          mobile: mobile,
          isFragile: `${isFragile}`,

          deliveryAent: {},
          is_deliveryAgent: "0",

          paymentStatus: "pending",
          scheduleTime: time,
          scheduleDate: selectedDate,
          couponCode: couponCode,

          totalDistance: totalDistance,
          cxAmountPaid: amountPaid,
          images: [],
          amountToPay: `${amountPaid}00`,
          status: "Cx Amount Not Ready",
        },
      }),
    });

    const responseJson = await response.json();
    console.log(responseJson.data);
    if (responseJson.success == true) {
      setBtnDisabled(false);
      setLoading(false);
      if (paymentType == "Online") {
        navigate("/express/payment", {
          state: {
            paymentID: responseJson.data.id,
            pID: responseJson.data.pID,
            pickupdate: date.toISOString(),
            orderID: `EPL${small_id}`,
            senderName: senderName,
            senderHouseAddress: senderHouseAddress,
            senderAddressLine1: senderAddressLine1,
            senderAddressLine2: senderAddressLine2,
            senderLandmark: senderLandmark,
            senderCity: senderCity,
            senderState: senderState,
            senderPincode: senderPincode,
            sendermobileNumber: sendermobileNumber,
            senderLat: Number(pickupCoordinates[0]),
            senderLng: Number(pickupCoordinates[1]),
            senderNote:
              isFragile == true ? "Fragile item, handle with care" : "",
            invoiceNumber: invoiceNumber,

            receiverName: receiverName,
            receiverHouseAddress: receiverHouseAddress,
            receiverAddressLine1: receiverAddressLine1,
            receiverAddressLine2: receiverAddressLine2,
            receiverLandmark: receiverLandmark,
            receiverCity: receiverCity,
            receiverState: receiverState,
            receiverPincode: receiverPincode,
            receivermobileNumber: receivermobileNumber,
            receiverLat: Number(deliveryCoordinates[0]),
            receiverLng: Number(deliveryCoordinates[1]),
            receiverNote:
              isFragile == true ? "Fragile item, handle with care" : "",

            paymentType: paymentType,
            goodsValue: goodsValue,

            Description: description,
            actualWeight: packageSize,
            mobile: mobile,
            isFragile: `${isFragile}`,

            couponCode: couponCode,

            deliveryAent: {},
            is_deliveryAgent: "0",

            paymentStatus: "pending",
            scheduleTime: time,
            scheduleDate: selectedDate,
            cxAmountPaid: amountPaid,
            totalDistance: totalDistance,
            images: [],
            amountToPay: `${amountPaid}00`,
            status: "Cx Amount Not Ready",
          },
        });
      } else {
        navigate("/express/order-success");
      }
    } else {
      setErrorMsg("Error in creating order.");
      setOpenErrorAlert(true);
      setBtnDisabled(false);
      setLoading(false);
    }
  };

  const onGo = () => {
    navigate("/express/map-address-mobile", {
      state: {
        type: 'to Address',
      },
    });
  }

  const getAddress = async () => {
    const userToken = localStorage.getItem("token");
    const userDetails = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDetails);
    console.log(parseData.mobile);
    const response = await fetch("https://ndd.logicarts.in/pickup/getAddress", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${userToken}`,
      },

      body: JSON.stringify({
        cxID: `${parseData.cxID}`,
      }),
    });

    const responseJson = await response.json();
    const filterData = await responseJson.data.filter(
      (data) => data.isactive === true
    );
    const revereseData = filterData.reverse();
    setUserAddress(revereseData);

    if (!localStorage.getItem("isFromMap")) {
      setReceiverName("");
      setReceiverAddressLine1("");
      setReceivermobileNumber("");
      setDeliveryCoords("");
      setRecevierHouseAddress("");
      setRecevierLandmark("");
      setRecevierCity("");
      setRecevierState("");
      setRecevierPincode("");
    } else {
      setReceiverName(JSON.parse(revereseData[0].jdata).Name);
      setReceiverAddressLine1(
        `${JSON.parse(revereseData[0].jdata).Building},${JSON.parse(revereseData[0].jdata).Address
        }`
      );
      setReceivermobileNumber(JSON.parse(revereseData[0].jdata).Mobile);
      setDeliveryCoords(JSON.parse(revereseData[0].jdata).Coordinates);
      setRecevierHouseAddress(JSON.parse(revereseData[0].jdata).Building);
      setRecevierLandmark(JSON.parse(revereseData[0].jdata).Landmark);
      setRecevierCity(JSON.parse(revereseData[0].jdata).City);
      setRecevierState(JSON.parse(revereseData[0].jdata).State);
      setRecevierPincode(JSON.parse(revereseData[0].jdata).Pincode);
      await getDestination(JSON.parse(revereseData[0].jdata).Coordinates);
    }
  };



  const getOrigin = async (coords) => {
    const originCoordinates = coords.split(",");
    console.log(originCoordinates);
    setDistanceOriginLat(Number(originCoordinates[0]));
    setDistanceOriginLng(Number(originCoordinates[1]));
  };

  const getDestination = async (coords) => {
    const destinationCoordinates = coords.split(",");
    setDistanceDestLat(Number(destinationCoordinates[0]));
    setDistanceDestLng(Number(destinationCoordinates[1]));
  };

  const steps = ["Address", "Package", "Summary"];

  const handleOpenDrawer = () => {
    setOpenFromAddress(true);
  };

  const vertical = "top";
  const horizontal = "center";

  const stepperUI = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <Box>
            <Card
              sx={{
                width: "90%",
                display: "block",
                margin: "auto",
                boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.15)",
                borderRadius: "8px",
                padding: "3%",
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  fontWeight: "700",
                  textTransform: "uppercase",
                }}
              >
                Address Details
              </Typography>
              <Box
                sx={{
                  border: ".5px solid #C6C6C6",
                }}
              />

              <Box
                sx={{
                  marginTop: "7%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "#646262",
                      }}
                    >
                      Pickup Location
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      onClick={() => {
                        localStorage.removeItem("searchLat");
                        localStorage.removeItem("searchLng");
                        navigate("/express/map-address-mobile", {
                          state: {
                            type: "from Address",
                          },
                        });
                      }}
                    >
                      {senderAddressLine1 !== "" ? "Change" : "Add"}
                    </Button>
                  </Box>
                </Box>

                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    marginTop: "3%",
                  }}
                >
                  {senderAddressLine1 == "" ? (
                    <TextField
                      fullWidth
                      placeholder="Where to pickup?"
                      sx={{
                        border: "0.5px solid #DDDDDD",
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                        borderRadius: "4px",
                      }}
                      error={senderError}
                      helperText={senderErrorText}
                    />
                  ) : (
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        marginTop: "3%",
                      }}
                    >
                      <Typography>
                        <LocationOnIcon />
                      </Typography>
                      <Typography>{senderAddressLine1}</Typography>
                    </Stack>
                  )}
                </Stack>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "5%",
                  }}
                >
                  <Box>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "#646262",
                      }}
                    >
                      Delivery Location
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      onClick={() => {
                        setReceiverError(false);
                        setReceiverErrorText("");
                        localStorage.removeItem("searchLat");
                        localStorage.removeItem("searchLng");
                        navigate("/express/map-address-mobile", {
                          state: {
                            type: "to Address",
                          },
                        });
                      }}
                    >
                      {receiverAddressLine1 !== "" ? "Change" : "Add"}
                    </Button>
                  </Box>
                </Box>

                <Stack
                  sx={{
                    marginTop: "3%",
                  }}
                >
                  {receiverAddressLine1 == "" ? (
                    <Box
                      sx={{
                        marginTop: "5%",
                        padding: "3%",
                      }}
                    >
                      <GoogleMapComponent {...props} onGo={onGo} />
                    </Box>
                  ) : (
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        marginTop: "3%",
                      }}
                    >
                      <Typography>
                        <LocationOnIcon />
                      </Typography>
                      <Typography>{receiverAddressLine1}</Typography>
                    </Stack>
                  )}

                </Stack>
              </Box>
            </Card>
            <Box
              sx={{
                marginTop: "3%",
                marginBottom: "30%",
              }}
            >
              <Card
                sx={{
                  width: "90%",
                  display: "block",
                  margin: "auto",
                  boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.15)",
                  borderRadius: "8px",
                  padding: "3%",
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    fontWeight: "700",
                    textTransform: "uppercase",
                  }}
                >
                  Saved Address
                </Typography>
                <Box
                  sx={{
                    border: ".5px solid #C6C6C6",
                  }}
                />
                {userAddress.length == 0 ? (
                  <>
                    <Box
                      sx={{
                        padding: "5%",
                      }}
                    >
                      <img
                        src={require("./assests/images/On the way-rafiki.png")}
                        style={{
                          width: "50%",
                          margin: "auto",
                          display: "block",
                        }}
                      />
                      <Typography
                        sx={{
                          textAlign: "center",
                          marginTop: "3%",
                        }}
                      >
                        No Address Found! Please add your address
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    {userAddress.map((fromAddress, index) => (
                      <Box key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "10%",
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <Box>
                              <Typography>
                                <HomeRoundedIcon />
                              </Typography>
                            </Box>

                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                }}
                                gutterBottom
                              >
                                {JSON.parse(fromAddress.jdata).Name}
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack>
                            {/* <Button
                          onClick={() => deleteFromAddress(fromAddress.cxAdID)}
                        >
                          <DeleteIcon />
                        </Button> */}
                            {/* <Button
                          sx={{
                            marginTop : "-5%"
                          }}
                          onClick={() => {
                            localStorage.removeItem("searchLat");
                            localStorage.removeItem("searchLng");
                            navigate("/express/update-address-mobile", {
                              state: {
                                type: "from Address",
                                jdata: fromAddress.jdata,
                                cxAdID: fromAddress.cxAdID,
                              },
                            });
                          }}
                        >
                          <EditIcon />
                        </Button> */}
                          </Stack>
                        </Box>

                        <Typography>
                          {fromAddress.Address == ""
                            ? "Please save your address details"
                            : ` ${JSON.parse(fromAddress.jdata).Building}, ${JSON.parse(fromAddress.jdata).Address
                            }`}
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Button
                            fullWidth
                            sx={{
                              marginTop: "3%",
                            }}
                            onClick={async () => {
                              setSenderAddressLine1(
                                `${JSON.parse(fromAddress.jdata).Building},${JSON.parse(fromAddress.jdata).Address
                                }`
                              );
                              setSendermobileNumber(
                                JSON.parse(fromAddress.jdata).Mobile
                              );
                              setSenderName(JSON.parse(fromAddress.jdata).Name);
                              setPickupCoords(
                                JSON.parse(fromAddress.jdata).Coordinates
                              );
                              setSenderHouseAddress(
                                JSON.parse(fromAddress.jdata).Building
                              );
                              setSenderPincode(
                                JSON.parse(fromAddress.jdata).Pincode
                              );
                              setSenderCity(JSON.parse(fromAddress.jdata).City);
                              setSenderState(
                                JSON.parse(fromAddress.jdata).State
                              );
                              setSenderLandmark(
                                JSON.parse(fromAddress.jdata).Landmark
                              );
                              await getOrigin(
                                JSON.parse(fromAddress.jdata).Coordinates
                              );
                              setOpenFromAddress(false);
                            }}
                          >
                            As Pickup
                          </Button>
                          <Button
                            fullWidth
                            sx={{
                              marginTop: "3%",
                            }}
                            onClick={async () => {
                              setReceiverName(
                                JSON.parse(fromAddress.jdata).Name
                              );
                              setReceiverAddressLine1(
                                `${JSON.parse(fromAddress.jdata).Building},${JSON.parse(fromAddress.jdata).Address
                                }`
                              );
                              setReceivermobileNumber(
                                JSON.parse(fromAddress.jdata).Mobile
                              );
                              setDeliveryCoords(
                                JSON.parse(fromAddress.jdata).Coordinates
                              );
                              setRecevierHouseAddress(
                                JSON.parse(fromAddress.jdata).Building
                              );
                              setRecevierLandmark(
                                JSON.parse(fromAddress.jdata).Landmark
                              );
                              setRecevierCity(
                                JSON.parse(fromAddress.jdata).City
                              );
                              setRecevierState(
                                JSON.parse(fromAddress.jdata).State
                              );
                              setRecevierPincode(
                                JSON.parse(fromAddress.jdata).Pincode
                              );
                              await getDestination(
                                JSON.parse(fromAddress.jdata).Coordinates
                              );
                              setOpenToAddress(false);
                            }}
                          >
                            As Delivery
                          </Button>
                        </Box>

                        <Box
                          sx={{
                            border: ".5px solid #C6C6C6",
                          }}
                        />
                      </Box>
                    ))}
                  </>
                )}
              </Card>
            </Box>

            <Button
              variant="contained"
              sx={{
                backgroundColor: "#669AFF",
                position: "fixed",
                bottom: 0,
                right: 0,
                left: 0,
              }}
              onClick={async () => {
                if (senderAddressLine1 == "") {
                  setSenderError(true);
                  setSenderErrorText("Please provider the details");
                } else if (receiverAddressLine1 == "") {
                  setReceiverError(true);
                  setReceiverErrorText("Please provider the details");
                } else {
                  await shipmentSizeDropDown();
                  setActiveStep(1);
                }
              }}
            >
              Next
            </Button>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Card
              sx={{
                width: "90%",
                display: "block",
                margin: "auto",
                boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.15)",
                borderRadius: "8px",
                padding: "3%",
                marginBottom: "5%",
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  fontWeight: "700",
                  textTransform: "uppercase",
                }}
              >
                Package Details
              </Typography>
              <Box
                sx={{
                  border: ".5px solid #C6C6C6",
                }}
              />
              <Typography
                gutterBottom
                sx={{
                  marginTop: "5%",
                }}
              >
                Choose Package Size
              </Typography>

              <Grid
                container
                spacing={2}
                sx={{
                  marginBottom: "2%",
                }}
              >
                <Grid item xs={6}>
                  <Box>
                    <Card
                      sx={{
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                        border: smallBorder,
                        width: "100%",
                      }}
                      onClick={async () => {
                        setPackageSize(
                          `${allSizes[3].description} ${allSizes[3].sWeight}-${allSizes[3].eWeight} Kgs`
                        );
                        setSmallBorder("1px solid #2287D9");
                        setMediumBorder("0.5px solid #DDDDDD");
                        setLargeBorder("0.5px solid #DDDDDD");
                        setBulkyBorder("0.5px solid #DDDDDD");
                        setssID(allSizes[3].ssID);
                        await ShipmentsizeNpricing(0, allSizes[3].ssID);
                      }}
                    >
                      <img
                        src={allSizes[3].sizeURL}
                        style={{
                          display: "block",
                          margin: "auto",
                          width: "100%",
                        }}
                      />
                    </Card>
                    <Typography
                      sx={{
                        color: "#9A9A9A",
                        fontSize: "0.8rem",
                        textAlign: "center",
                      }}
                    >
                      {allSizes[3].description} ( {allSizes[3].sWeight} -{" "}
                      {allSizes[3].eWeight} kgs )
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <Card
                      sx={{
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                        border: mediumBorder,
                      }}
                      onClick={async () => {
                        setPackageSize(
                          `${allSizes[2].description} ${allSizes[2].sWeight}-${allSizes[2].eWeight}Kgs`
                        );
                        setSmallBorder("0.5px solid #DDDDDD");
                        setMediumBorder("1px solid #2287D9");
                        setLargeBorder("0.5px solid #DDDDDD");
                        setBulkyBorder("0.5px solid #DDDDDD");
                        setssID(allSizes[2].ssID);
                        await ShipmentsizeNpricing(0, allSizes[2].ssID);
                      }}
                    >
                      <img
                        src={allSizes[2].sizeURL}
                        style={{
                          display: "block",
                          margin: "auto",
                          width: "100%",
                        }}
                      />
                    </Card>
                    <Typography
                      sx={{
                        color: "#9A9A9A",
                        fontSize: "0.8rem",
                        textAlign: "center",
                      }}
                    >
                      {allSizes[2].description} ( {allSizes[2].sWeight} -{" "}
                      {allSizes[2].eWeight} kgs )
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  marginBottom: "2%",
                }}
              >
                <Grid item xs={6}>
                  <Box>
                    <Card
                      sx={{
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                        padding: "10%",
                        border: largeBorder,
                      }}
                      onClick={async () => {
                        setPackageSize(
                          `${allSizes[1].description} ${allSizes[1].sWeight}-${allSizes[1].eWeight}Kgs`
                        );
                        setSmallBorder("0.5px solid #DDDDDD");
                        setMediumBorder("0.5px solid #DDDDDD");
                        setLargeBorder("1px solid #2287D9");
                        setBulkyBorder("0.5px solid #DDDDDD");
                        setssID(allSizes[1].ssID);
                        await ShipmentsizeNpricing(0, allSizes[1].ssID);
                      }}
                    >
                      <img
                        src={allSizes[1].sizeURL}
                        style={{
                          display: "block",
                          margin: "auto",
                          width: "100%",
                        }}
                      />
                    </Card>
                    <Typography
                      sx={{
                        color: "#9A9A9A",
                        fontSize: "0.8rem",
                        textAlign: "center",
                      }}
                    >
                      {allSizes[1].description} ( {allSizes[1].sWeight} -{" "}
                      {allSizes[1].eWeight} kgs )
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <Card
                      sx={{
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                        padding: "10%",
                        border: bulkyBorder,
                      }}
                      onClick={async () => {
                        setPackageSize(
                          `${allSizes[0].description} ${allSizes[0].sWeight}-${allSizes[0].eWeight}Kgs`
                        );
                        setSmallBorder("0.5px solid #DDDDDD");
                        setMediumBorder("0.5px solid #DDDDDD");
                        setLargeBorder("0.5px solid #DDDDDD");
                        setBulkyBorder("1px solid #2287D9");
                        setssID(allSizes[0].ssID);
                        await ShipmentsizeNpricing(0, allSizes[0].ssID);
                      }}
                    >
                      <img
                        src={allSizes[0].sizeURL}
                        style={{
                          display: "block",
                          margin: "auto",
                          width: "100%",
                        }}
                      />
                    </Card>
                    <Typography
                      sx={{
                        color: "#9A9A9A",
                        fontSize: "0.8rem",
                        textAlign: "center",
                      }}
                    >
                      {allSizes[0].description} ( {allSizes[0].sWeight} -{" "}
                      {allSizes[0].eWeight} kgs )
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                  color: "red",
                  display: packageSizeError === true ? "block" : "none",
                }}
              >
                Please Choose the Package size
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  marginTop: "5%",
                }}
              >
                Package Contents
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter Item Details"
                sx={{
                  border: "0.5px solid #DDDDDD",
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                  borderRadius: "4px",
                }}
                onChange={(e) => {
                  setDescriptionError(false);
                  setDescriptionErrorText("");
                  setDescription(e.target.value);
                }}
                error={descriptionError}
                helperText={descriptionErrorText}
              />

              <Typography
                gutterBottom
                sx={{
                  marginTop: "3%",
                }}
              >
                Package Value
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter Item Value"
                sx={{
                  border: "0.5px solid #DDDDDD",
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                  borderRadius: "4px",
                }}
                onChange={(e) => {
                  setGoodsValueError(false);
                  setGoodsValueErrorText("");
                  setGoodsValue(e.target.value);
                }}
                error={goodsValueError}
                helperText={goodsValueErrorText}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="IS FRAGILE"
                sx={{
                  marginBottom: "2%",
                  marginTop: "1%",
                }}
                onChange={(event) => setIsFragile(event.target.checked)}
              />
              <Typography
                gutterBottom
                sx={{
                  marginTop: "3%",
                  fontWeight: "600",
                }}
              >
                Schedule Delivery Date
              </Typography>
              <Box
                sx={{
                  border: ".5px solid #C6C6C6",
                }}
              />
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  marginTop: "2%",
                }}
              >
                <Card
                  sx={{
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                    padding: "3%",
                    border: date1Border,
                  }}
                  onClick={() => {
                    setSelectedDate(dateFormat(date, "dd mmm yyyy"));
                    setDate1Border("1px solid #2287D9");
                    setDate2Border("0.5px solid #DDDDDD");
                    setDate3Border("0.5px solid #DDDDDD");
                    setDate4Border("0.5px solid #DDDDDD");
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "2rem",
                      color: "#9A9A9A",
                      textAlign: "center",
                    }}
                  >
                    {dateFormat(date, "dd")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#9A9A9A",
                      textAlign: "center",
                    }}
                  >
                    {dateFormat(date, "mmm yyyy")}
                  </Typography>
                </Card>
                <Card
                  sx={{
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                    padding: "3%",
                    border: date2Border,
                  }}
                  onClick={() => {
                    setSelectedDate(dateFormat(date2._d, "dd mmm yyyy"));
                    setDate1Border("0.5px solid #DDDDDD");
                    setDate2Border("1px solid #2287D9");
                    setDate3Border("0.5px solid #DDDDDD");
                    setDate4Border("0.5px solid #DDDDDD");
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "2rem",
                      color: "#9A9A9A",
                      textAlign: "center",
                    }}
                  >
                    {dateFormat(date2._d, "dd")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#9A9A9A",
                      textAlign: "center",
                    }}
                  >
                    {dateFormat(date2._d, "mmm yyyy")}
                  </Typography>
                </Card>
                <Card
                  sx={{
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                    padding: "3%",
                    border: date3Border,
                  }}
                  onClick={() => {
                    setSelectedDate(dateFormat(date3._d, "dd mmm yyyy"));
                    setDate1Border("0.5px solid #DDDDDD");
                    setDate2Border("0.5px solid #DDDDDD");
                    setDate3Border("1px solid #2287D9");
                    setDate4Border("0.5px solid #DDDDDD");
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "2rem",
                      color: "#9A9A9A",
                      textAlign: "center",
                    }}
                  >
                    {dateFormat(date3._d, "dd")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#9A9A9A",
                      textAlign: "center",
                    }}
                  >
                    {dateFormat(date3._d, "mmm yyyy")}
                  </Typography>
                </Card>
                <Card
                  sx={{
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
                    padding: "3%",
                    border: date4Border,
                  }}
                  onClick={() => {
                    setSelectedDate(dateFormat(date4._d, "dd mmm yyyy"));
                    setDate1Border("0.5px solid #DDDDDD");
                    setDate2Border("0.5px solid #DDDDDD");
                    setDate3Border("0.5px solid #DDDDDD");
                    setDate4Border("1px solid #2287D9");
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "2rem",
                      color: "#9A9A9A",
                      textAlign: "center",
                    }}
                  >
                    {dateFormat(date4._d, "dd")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#9A9A9A",
                      textAlign: "center",
                    }}
                  >
                    {dateFormat(date4._d, "mmm yyyy")}
                  </Typography>
                </Card>
              </Stack>
              <Typography
                sx={{
                  marginTop: "2%",
                  display: schPickError === true ? "block" : "none",
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Please provide the time.
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  marginTop: "3%",
                }}
              >
                Time
              </Typography>
              <FormControl fullWidth error={timeError}>
                <InputLabel id="demo-simple-select-label">Time</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={time}
                  label="Age"
                  onChange={handleChangeTime}
                >
                  <MenuItem value={"08:00 AM - 10:00 AM"}>
                    08:00 AM - 10:00 AM
                  </MenuItem>
                  <MenuItem value={"10:00 AM - 12:00 PM"}>
                    10:00 AM - 12:00 PM
                  </MenuItem>
                  <MenuItem value={"12:00 PM - 02:00 PM"}>
                    12:00 PM - 02:00 PM
                  </MenuItem>
                </Select>
              </FormControl>
            </Card>
            <Box
              sx={{
                marginBottom: "30%",
              }}
            >
              <Card
                sx={{
                  width: "90%",
                  display: "block",
                  margin: "auto",
                  boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.15)",
                  borderRadius: "8px",
                  padding: "3%",
                  marginBottom: "5%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        marginTop: "3%",
                      }}
                    >
                      Apply Coupons
                    </Typography>
                  </Box>
                  <Box>
                    <Button onClick={() => setOpenDrawerCoupons(true)}>
                      Select
                    </Button>
                  </Box>
                </Box>
              </Card>
              <Card
                sx={{
                  width: "90%",
                  display: "block",
                  margin: "auto",
                  boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.15)",
                  borderRadius: "8px",
                  padding: "3%",
                  marginBottom: "5%",
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    textTransform: "uppercase",
                  }}
                >
                  Select payment type
                </Typography>

                <Box
                  sx={{
                    border: ".5px solid #C6C6C6",
                  }}
                />

                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Online"
                      control={<Radio />}
                      label="Online (UPI / Credit Card/ Debit Card)"
                      onChange={(e) => {
                        setPackageSizeError("none");
                        setPaymentType(e.target.value);
                      }}
                    />
                    <FormControlLabel
                      value="COD"
                      control={<Radio />}
                      label="Cash On Pickup/Delivery"
                      onChange={(e) => {
                        setPackageSizeError("none");
                        setPaymentType(e.target.value);
                      }}
                    />
                  </RadioGroup>
                </FormControl>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                    color: "red",
                    display: showPaymentTypeError,
                  }}
                >
                  Please select the payment type
                </Typography>
              </Card>
            </Box>

            <Box
              sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: "#fff",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#d5d5d57d",
                  width: "100%",
                  padding: "1%",
                  display: showUper,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    marginLeft: "2%",
                  }}
                >
                  {" "}
                  <span
                    style={{
                      color: "#31AE1D",
                    }}
                  >
                    Who woo !
                  </span>{" "}
                  coupon {couponCode} Applied
                </Typography>
              </Box>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  padding: "5%",
                }}
              >
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: "1.2rem",
                    fontWeight: "600",
                  }}
                >
                  Total : ₹{amountPaid}
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "#669AFF",
                  }}
                  onClick={() => {
                    if (packageSize === "") {
                      setPackageSizeError(true);
                    } else if (description === "") {
                      setDescriptionError(true);
                      setDescriptionErrorText(
                        "Please provide the name of item"
                      );
                    } else if (goodsValue == "") {
                      setGoodsValueError(true);
                      setGoodsValueErrorText("provide the value of item");
                    } else if (selectedDate == "") {
                      setSchPickError(true);
                    } else if (time == "") {
                      setTimeError(true);
                    } else if (paymentType == "") {
                      setShowPaymentTypeError("block");
                    } else {
                      setActiveStep(2);
                    }
                  }}
                >
                  Proceed
                </Button>
              </Stack>
            </Box>
          </Box>
        );
      case 2:
        return (
          <Box
            sx={{
              padding: "3%",
              marginBottom: "15%",
            }}
          >
            <Card
              sx={{
                display: "block",
                margin: "auto",
                boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.15)",
                borderRadius: "8px",
                padding: "5%",
                marginBottom: "3%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  ADDRESS DETAILS
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    color: "#14AFE0",
                  }}
                  onClick={() => setActiveStep(0)}
                >
                  Update
                </Typography>
              </Box>

              <Box
                sx={{
                  border: ".5px solid #C6C6C6",
                }}
              />
              <Box>
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  <TimelineItem
                    sx={{
                      minHeight: "50px",
                    }}
                  >
                    <TimelineSeparator>
                      <TimelineDot color="success"></TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        sx={{
                          fontWeight: "600",
                        }}
                      >
                        Pickup Address
                      </Typography>
                      <Typography>{senderAddressLine1}</Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "grey",
                        }}
                      >
                        {senderName} | {sendermobileNumber}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem
                    sx={{
                      minHeight: "0px",
                    }}
                  >
                    <TimelineSeparator>
                      <TimelineDot color="error"></TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        sx={{
                          fontWeight: "600",
                        }}
                      >
                        Deilvery Address
                      </Typography>
                      <Typography>{receiverAddressLine1}</Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "grey",
                          marginBottom: "6%",
                        }}
                      >
                        {receiverName} | {receivermobileNumber}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </Box>
            </Card>
            <Card
              sx={{
                display: "block",
                margin: "auto",
                boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.15)",
                borderRadius: "8px",
                padding: "5%",
                marginBottom: "3%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  PACKAGE DETAILS
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    color: "#14AFE0",
                  }}
                  onClick={() => setActiveStep(1)}
                >
                  Update
                </Typography>
              </Box>
              <Box
                sx={{
                  border: ".5px solid #C6C6C6",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "3%",
                }}
              >
                <Box>
                  <Typography
                    gutterBottom
                    sx={{
                      color: "#646262",
                      marginTop: "3%",
                    }}
                  >
                    Package Size :
                  </Typography>
                  <Typography
                    gutterBottom
                    sx={{
                      color: "#646262",
                      marginTop: "3%",
                    }}
                  >
                    Package Contents :
                  </Typography>
                  <Typography
                    gutterBottom
                    sx={{
                      color: "#646262",
                      marginTop: "3%",
                    }}
                  >
                    Package Value :
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    gutterBottom
                    sx={{
                      color: "#646262",
                      marginTop: "3%",
                    }}
                  >
                    {packageSize}
                  </Typography>
                  <Typography
                    gutterBottom
                    sx={{
                      color: "#646262",
                      marginTop: "3%",
                    }}
                  >
                    {description}
                  </Typography>
                  <Typography
                    gutterBottom
                    sx={{
                      color: "#646262",
                      marginTop: "3%",
                    }}
                  >
                    {`INR ${goodsValue}`}
                  </Typography>
                </Box>
              </Box>

              <Typography
                sx={{
                  color: "#F45555",
                  textAlign: "center",
                  fontSize: "0.7rem",
                  marginTop: "3%",
                  textTransform: "uppercase",
                  visibility: isFragile === true ? "visible" : "hidden",
                }}
              >
                Package contents IS Fragile
              </Typography>
            </Card>

            <Card
              sx={{
                display: "block",
                margin: "auto",
                boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.15)",
                borderRadius: "8px",
                padding: "5%",
                marginBottom: "3%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  PICKUP SCHEDULE
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    color: "#14AFE0",
                  }}
                  onClick={() => setActiveStep(1)}
                >
                  Update
                </Typography>
              </Box>
              <Box
                sx={{
                  border: ".5px solid #C6C6C6",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "3%",
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    color: "#646262",
                    marginTop: "3%",
                    width: "50%",
                  }}
                >
                  Date and time :
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    color: "#646262",
                    marginTop: "3%",
                  }}
                >
                  {`${selectedDate} at ${time}`}
                </Typography>
              </Box>
            </Card>
            <FormControlLabel
              control={<Checkbox defaultChecked color="success" />}
              label="Get order updates on whatsapp"
            />

            <LoadingButton
              variant="contained"
              loading={loading}
              disabled={btnDisabled}
              sx={{
                backgroundColor: "#669AFF",
                position: "fixed",
                bottom: 0,
                right: 0,
                left: 0,
              }}
              onClick={() => {
                createOrder();
                localStorage.removeItem("isFromMap");
              }}
            >
              Place Order
            </LoadingButton>
          </Box>
        );

      default:
        break;
    }
  };

  const handleChangeTime = (event) => {
    setTime(event.target.value);
  };

  const deleteToAddress = async (id) => {
    const userDetails = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDetails);

    console.log(id);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/manageAddress",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          cxID: `${parseData.cxID}`,
          cxAdID: id,
          jdata: {
            Description: "",
            Address: "",
            Pincode: "",
            isDefault: "",
            categoryID: "9",
          },
        }),
      }
    );

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.success == true) {
      await getAddress();
    } else {
      setErrorMsg("Unable to delete your address please try after sometime.");
      setOpenErrorAlert(true);
    }
  };

  const shipmentSizeDropDown = async () => {
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/shipmentSizeDropDown",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          jdata: {},
        }),
      }
    );

    const responseJson = await response.json();
    if (responseJson.success == true) {
      setAllSizes(responseJson.data);
    } else {
      setErrorMsg("Unable to get the sizes");
      setOpenErrorAlert(true);
    }
  };

  const ShipmentsizeNpricing = async (code, id) => {
    const replaceKM = totalDistance.replace("km", "");
    console.log(replaceKM);
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/ShipmentsizeNpricing",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          jdata: {
            couponCode: code == 0 ? "" : code,
            distance: replaceKM,
            ssID: !id ? ssID : id,
            senderPincode: senderPincode,
            receiverPincode: receiverPincode,
          },
        }),
      }
    );

    const responseJson = await response.json();
    if (responseJson.success == true) {
      if (ssID == 1 || id == 1) {
        setAmountPaid(responseJson.data[0].price);
      } else if (ssID == 2 || id == 2) {
        setAmountPaid(responseJson.data[1].price);
      } else if (ssID == 3 || id == 3) {
        setAmountPaid(responseJson.data[2].price);
      } else {
        setAmountPaid(responseJson.data[3].price);
      }
    }
    console.log(responseJson);
  };

  const getCoupons = async () => {
    const response = await fetch("https://ndd.logicarts.in/pickup/coupons", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        jdata: {},
      }),
    });

    const responseJson = await response.json();
    if (responseJson.success == true) {
      setAllCoupons(responseJson.data);
    } else {
      setErrorMsg("unable to get coupons data");
      setOpenErrorAlert(true);
    }
  };

  Geocode.setApiKey("AIzaSyDJ6m7dujpOLQYOVDL6B5QJdE6cr0sEcuw");
  Geocode.setLanguage("en");
  Geocode.setRegion("in");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();

  const getAddressPosition = async (latitude, longitude) => {
    console.log("qwertyui");
    await Geocode.fromLatLng(`${latitude}`, `${longitude}`).then(
      async (response) => {
        const address = await response.results[0].formatted_address;
        setSenderAddressLine1(address);
        setPickupCoords(`${latitude},${longitude}`);
        const filterPostalCode =
          await response.results[0].address_components.filter(
            (value) => value.types[0] == "postal_code"
          );
        const filterCity = await response.results[0].address_components.filter(
          (value) => value.types[0] == "administrative_area_level_3"
        );
        const filterState = await response.results[0].address_components.filter(
          (value) => value.types[0] == "administrative_area_level_1"
        );
        setSenderPincode(filterPostalCode[0].long_name);
        setSenderCity(filterCity[0].long_name);
        setSenderState(filterState[0].long_name);
        await getOrigin(`${latitude},${longitude}`);
        setOpenLoadingModal(false);
      },
      (error) => {
        setOpenLoadingModal(false);
        console.error(error);
        setErrorMsg("Unable to fetch try again later");
        setOpenErrorAlert(true);
      }
    );
  };

  const position = async () => {
    setOpenLoadingModal(true);
    await navigator.geolocation.getCurrentPosition(
      async (position) => {
        await getAddressPosition(
          position.coords.latitude,
          position.coords.longitude
        );
      },
      async (err) => {
        if (err) {
          setOpenLoadingModal(false);
          setErrorMsg("Please enable your device location");
          setOpenErrorAlert(true);
        }
      }
    );
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          backgroundColor: "#669AFF",
          display: "flex",
          justifyContent: "space-between",
          padding: "3%",
        }}
      >
        <Typography
          sx={{
            marginTop: "2%",
          }}
          onClick={() => navigate("/express/order-history")}
        >
          <ArrowBackRoundedIcon
            sx={{
              color: "#FFFFFF",
            }}
          />
        </Typography>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: "600",
            marginTop: "1%",
            fontSize: "1.2rem",
          }}
        >
          Create Order
        </Typography>
        <Typography></Typography>
      </Box>
      <Box
        sx={{
          marginTop: "5%",
        }}
      >
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box
        sx={{
          marginTop: "5%",
        }}
      >
        {stepperUI(activeStep)}
      </Box>

      <Drawer
        anchor="bottom"
        open={openFromAddress}
        onClose={() => setOpenFromAddress(false)}
      >
        <Box
          sx={{
            padding: "5%",
          }}
        >
          {/* <Stack
            direction="row"
            spacing={1}
            sx={{
              marginTop: "3%",
            }}
            onClick={() => {
              localStorage.removeItem("searchLat");
              localStorage.removeItem("searchLng");
              navigate("/express/map-address-mobile", {
                state: {
                  type: "from Address",
                },
              });
            }}
          > */}
          {/* <Typography>
              <MyLocationRoundedIcon
                sx={{
                  color: "#2287D9",
                }}
              />
            </Typography>

            <Typography>Select location via map</Typography>
          </Stack> */}

          {/* <Typography
            sx={{
              marginTop: "5%",
              color: "#959595",
            }}
          >
            Saved Address
          </Typography> */}

          <Box>
            <Button fullWidth variant="contained">
              Edit Address
            </Button>
            <Button
              fullWidth
              variant="outlined"
              sx={{
                marginTop: "3%",
              }}
            >
              Dele Address
            </Button>
            {/* <Card
                    sx={{
                      padding: "3%",
                      marginTop: "2%",
                      borderRadius: "8px",
                      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      border: "0.5px solid silver",
                    }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        onClick={() => deleteFromAddress(fromAddress.cxAdID)}
                        sx={{
                          float: "right",
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                      <Button
                        sx={{
                          float: "right",
                        }}
                        onClick={() => {
                          localStorage.removeItem("searchLat");
                          localStorage.removeItem("searchLng");
                          navigate("/express/update-address-mobile", {
                            state: {
                              type: "from Address",
                              jdata: fromAddress.jdata,
                              cxAdID: fromAddress.cxAdID,
                            },
                          });
                        }}
                      >
                        <EditIcon />
                      </Button>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Box>
                        <Typography>
                          <HomeRoundedIcon />
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "600",
                          }}
                          gutterBottom
                        >
                          {JSON.parse(fromAddress.jdata).Name}
                        </Typography>
                        <Typography>
                          {fromAddress.Address == ""
                            ? "Please save your address details"
                            : ` ${JSON.parse(fromAddress.jdata).Building}, ${
                                JSON.parse(fromAddress.jdata).Address
                              }`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#767676",
                            marginTop: "2%",
                          }}
                        >
                          {JSON.parse(fromAddress.jdata).Mobile == ""
                            ? "No contact number"
                            : JSON.parse(fromAddress.jdata).Mobile}
                        </Typography>
                      </Box>
                    </Stack>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        marginTop: "3%",
                      }}
                      onClick={async () => {
                        setSenderAddressLine1(
                          `${JSON.parse(fromAddress.jdata).Building},${
                            JSON.parse(fromAddress.jdata).Address
                          }`
                        );
                        setSendermobileNumber(
                          JSON.parse(fromAddress.jdata).Mobile
                        );
                        setSenderName(JSON.parse(fromAddress.jdata).Name);
                        setPickupCoords(
                          JSON.parse(fromAddress.jdata).Coordinates
                        );
                        setSenderHouseAddress(
                          JSON.parse(fromAddress.jdata).Building
                        );
                        setSenderPincode(JSON.parse(fromAddress.jdata).Pincode);
                        setSenderCity(JSON.parse(fromAddress.jdata).City);
                        setSenderState(JSON.parse(fromAddress.jdata).State);
                        setSenderLandmark(
                          JSON.parse(fromAddress.jdata).Landmark
                        );
                        await getOrigin(
                          JSON.parse(fromAddress.jdata).Coordinates
                        );
                        setOpenFromAddress(false);
                      }}
                    >
                      Select Address
                    </Button>
                  </Card> */}
          </Box>
        </Box>
      </Drawer>

      <Drawer
        anchor="bottom"
        open={openToAddress}
        onClose={() => setOpenToAddress(false)}
      >
        <Box
          sx={{
            padding: "5%",
          }}
        >
          <Button
            sx={{
              float: "right",
            }}
            onClick={() => setOpenToAddress(false)}
          >
            <CancelIcon
              sx={{
                color: "grey",
              }}
            />
          </Button>
          <Typography
            sx={{
              fontWeight: "600",
              marginTop: "10%",
            }}
          >
            Search For Delivery Location
          </Typography>

          <TextField
            fullWidth
            style={{
              border: "0.5px solid #DDDDDD",
              borderRadius: "4px",
              marginTop: "3%",
            }}
            placeholder="Search your address"
            onClick={() =>
              navigate("/express/search-address-mobile", {
                state: {
                  type: "to Address",
                },
              })
            }
          />

          <Typography
            sx={{
              color: "gray",
              textAlign: "center",
              marginTop: "3%",
            }}
          >
            Or
          </Typography>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              marginTop: "3%",
            }}
            onClick={() => {
              localStorage.removeItem("searchLat");
              localStorage.removeItem("searchLng");
              navigate("/express/map-address-mobile", {
                state: {
                  type: "to Address",
                },
              });
            }}
          >
            <Typography>
              <MyLocationRoundedIcon
                sx={{
                  color: "#2287D9",
                }}
              />
            </Typography>

            <Typography>Select location via map</Typography>
          </Stack>

          <Typography
            sx={{
              marginTop: "5%",
              color: "#959595",
            }}
          >
            Saved Address
          </Typography>

          {userAddress.length == 0 ? (
            <Box
              sx={{
                marginTop: "10%",
                marginBottom: "10%",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Please Save Your Address
              </Typography>
            </Box>
          ) : (
            <>
              {userAddress.map((toAddress, index) => (
                <Box key={index}>
                  <Card
                    sx={{
                      padding: "3%",
                      marginTop: "2%",
                      borderRadius: "8px",
                      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      border: "0.5px solid silver",
                    }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        onClick={() => deleteToAddress(toAddress.cxAdID)}
                        sx={{
                          float: "right",
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                      <Button
                        sx={{
                          float: "right",
                        }}
                        onClick={() => {
                          localStorage.removeItem("searchLat");
                          localStorage.removeItem("searchLng");
                          navigate("/express/update-address-mobile", {
                            state: {
                              type: "to Address",
                              jdata: toAddress.jdata,
                              cxAdID: toAddress.cxAdID,
                            },
                          });
                        }}
                      >
                        <EditIcon />
                      </Button>
                    </Stack>

                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        marginTop: "3%",
                      }}
                    >
                      <Box>
                        <Typography>
                          <HomeRoundedIcon />
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "600",
                          }}
                          gutterBottom
                        >
                          {JSON.parse(toAddress.jdata).Name}
                        </Typography>
                        <Typography>
                          {!JSON.parse(toAddress.jdata).Address
                            ? "Please save your address details"
                            : ` ${JSON.parse(toAddress.jdata).Building}, ${JSON.parse(toAddress.jdata).Address
                            }`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#767676",
                            marginTop: "2%",
                          }}
                        >
                          {JSON.parse(toAddress.jdata).Mobile == ""
                            ? "No contact number"
                            : JSON.parse(toAddress.jdata).Mobile}
                        </Typography>
                      </Box>
                    </Stack>

                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        marginTop: "3%",
                      }}
                      onClick={async () => {
                        setReceiverName(JSON.parse(toAddress.jdata).Name);
                        setReceiverAddressLine1(
                          `${JSON.parse(toAddress.jdata).Building},${JSON.parse(toAddress.jdata).Address
                          }`
                        );
                        setReceivermobileNumber(
                          JSON.parse(toAddress.jdata).Mobile
                        );
                        setDeliveryCoords(
                          JSON.parse(toAddress.jdata).Coordinates
                        );
                        setRecevierHouseAddress(
                          JSON.parse(toAddress.jdata).Building
                        );
                        setRecevierLandmark(
                          JSON.parse(toAddress.jdata).Landmark
                        );
                        setRecevierCity(JSON.parse(toAddress.jdata).City);
                        setRecevierState(JSON.parse(toAddress.jdata).State);
                        setRecevierPincode(JSON.parse(toAddress.jdata).Pincode);
                        await getDestination(
                          JSON.parse(toAddress.jdata).Coordinates
                        );
                        setOpenToAddress(false);
                      }}
                    >
                      Select Address
                    </Button>
                  </Card>
                </Box>
              ))}
            </>
          )}
        </Box>
      </Drawer>

      <Box
        sx={{
          visibility: "hidden",
        }}
      >
        <LoadScript googleMapsApiKey="AIzaSyDJ6m7dujpOLQYOVDL6B5QJdE6cr0sEcuw">
          <DistanceMatrixService
            options={{
              destinations: [{ lat: distanceDestLat, lng: distanceDestLng }],
              origins: [{ lng: distanceOriginLng, lat: distanceOriginLat }],
              travelMode: "DRIVING",
            }}
            callback={(response) => {
              console.log(
                response.rows[0].elements[0].distance.text,
                "distance"
              );
              setTotalDistance(response.rows[0].elements[0].distance.text);
            }}
          />
        </LoadScript>
      </Box>

      <Drawer
        anchor="bottom"
        open={openDrawerCoupons}
        onClose={() => setOpenDrawerCoupons(false)}
      >
        <Box
          sx={{
            padding: "3%",
            marginBottom: "25%",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Coupons
          </Typography>
          {allCoupons.map((coupon) => (
            <Card
              sx={{
                borderRadius: "6px",
                backgroundColor: "#FFF",
                boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.15)",
                padding: "3%",
                marginTop: "3%",
              }}
            >
              <Stack direction="row" spacing={10}>
                <Box>
                  <Typography
                    sx={{
                      color: "#EB9C2C",
                      textTransform: "uppercase",
                    }}
                    gutterBottom
                  >
                    {coupon.couponCode}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: "600",
                    }}
                  >
                    {coupon.description}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    onClick={async () => {
                      setOpenDrawerCoupons(false);
                      setCouponCode(coupon.couponCode);
                      setShowCelebrate("block");
                      setOpenDialog(true);
                      await ShipmentsizeNpricing(coupon.couponCode);
                      setShowUper("block");
                      setTimeout(() => {
                        setShowCelebrate("none");
                        setOpenDialog(false);
                      }, 2500);
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              </Stack>
            </Card>
          ))}
        </Box>
        <Button
          variant="contained"
          fullWidth
          sx={{
            borderRadius: "0px",
            bottom: 0,
            left: 0,
            right: 0,
            position: "fixed",
          }}
          onClick={() => setOpenDrawerCoupons(false)}
        >
          Close
        </Button>
      </Drawer>

      <Dialog
        open={openDialog}
        maxWidth={maxWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        hideBackdrop={true}
      >
        <Box>
          <img
            src={require("./assests/images/13491-pop-new-year.gif")}
            style={{
              width: "50%",
              margin: "auto",
              display: "block",
            }}
          />
        </Box>
        <DialogContent
          sx={{
            marginTop: "-16%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              textAlign: "center",
            }}
            gutterBottom
          >
            {couponCode} Applied
          </Typography>

          <Typography
            sx={{
              fontSize: "0.8rem",
              color: "grey",
            }}
          >
            saved! That feels amazing right?
          </Typography>
        </DialogContent>
        <Button
          variant="contained"
          sx={{
            borderRadius: "0px",
          }}
          onClick={() => {
            setShowCelebrate("none");
            setOpenDialog(false);
          }}
        >
          OK
        </Button>
      </Dialog>

      <Box
        sx={{
          display: showCelebrate,
          position: "absolute",
          top: "60%",
        }}
      >
        <PointConfetti />
      </Box>

      <Modal
        open={openLoadingModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{ display: "flex", justifyContent: "center", padding: "5%" }}
          >
            <CircularProgress />
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              marginTop: "3%",
            }}
          >
            Getting Your Location
          </Typography>
        </Box>
      </Modal>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={() => setOpenErrorAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default CreateOrderMobile;
