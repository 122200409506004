import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Box, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Config from './../../configprod';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function UserLoginMobile() {
  const navigate = useNavigate();

  const [btnLoading, setBtnLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [mobileNumber, setMobileNumber] = useState("");
  const [open, setOpen] = useState(false);

  const [showCaptcha, setShowCaptcha] = useState("none");
  const [showPhone, setShowPhone] = useState("block");
  const [errorMsg, setErrorMsg] = useState("")

  const vertical = "top";
  const horizontal = "center";
  const maxWidth = "sm";

  const handleChange = (value) => {
    if (value.length != 10) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
      setMobileNumber(value);
    }
  };

  const getOTP = async (captchaToken) => {
    setBtnLoading(true);
    setBtnDisabled(true);

    const response = await fetch(`${Config.hostName}/pickup/getOTP`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({
      //   mobile: mobileNumber,
      //   captchaToken: '',
      // }),
      body: JSON.stringify({
        mobile: mobileNumber,
        captchaToken: captchaToken,
      }),
    });

    const responseJson = await response.json();
    if (responseJson.success == true) {
      setBtnDisabled(false);
      setBtnLoading(false);
      navigate("/express/user-otp", {
        state: {
          mobileNumber: mobileNumber,
        },
      });
    } else {
      setErrorMsg(responseJson.data)
      setOpen(true);
      setBtnDisabled(false);
      setBtnLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "5%",
          display: showPhone,
        }}
      >
        <img
          src={require("./assests/images/Expresslogonew.png")}
          style={{
            width: "100%",
            marginTop: "30%",
            marginBottom: "20%",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            color: "grey",
          }}
        >
          We will send you{" "}
          <strong
            style={{
              color: "black",
            }}
          >
            One Time Password
          </strong>{" "}
          on this mobile number
        </Typography>

        <Box
          sx={{
            marginTop: "25%",
          }}
        >
          <TextField
            fullWidth
            label="Enter Mobile Number"
            variant="standard"
            type="number"
            onChange={(e) => handleChange(e.target.value)}
          />
        </Box>

        <LoadingButton
          fullWidth
          disabled={btnDisabled}
          loading={btnLoading}
          variant="contained"
          sx={{
            marginTop: "15%",
          }}
          // onClick={() => {
          //   setShowCaptcha("block");
          //   setShowPhone("none");
          // }}

          onClick={() => getOTP('test')}
        >
          Get OTP
        </LoadingButton>
      </Box>

      <Box
        sx={{
          padding: "5%",
          display: showCaptcha,
        }}
      >
        <img
          src={require("./assests/images/Expresslogonew.png")}
          style={{
            width: "100%",
            marginTop: "30%",
          }}
        />
        <Typography
          sx={{
            marginTop: "5%",
            textAlign: "center",
          }}
        >
          Please verify that you are a human not a robot
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10%",
          }}
        >
          <ReCAPTCHA
            sitekey="6LcbsGwmAAAAAHwiEd2s4AVSrVc8OY9QFGu-BlZl"
            onChange={(value) => getOTP(value)}
          />
        </Box>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
         {errorMsg}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default UserLoginMobile;
