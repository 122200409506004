import React, { useEffect, useRef, useState } from "react";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AOS from "aos";
import "aos/dist/aos.css";
import "./assests/css/join.css";

function JoinUs() {
  const videoRef = useRef();
  const [stop, setStop] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Box>
      <a
        className="join-head"
        href="https://forms.gle/wv9Fr2PuL2DQmDJy6"
        target="_blank"
        gutterBottom
      >
        Join Our Team
      </a>

      <Box
        className="join-text-box"
        data-aos="zoom-in"
        data-aos-delay="5000"
        data-aos-duration="3000"
      >
        {/* <Typography className="join-text" gutterBottom>
          We are on a mission to help the world move things faster and smarter
          then even before. If you have ever been frustated by late deliveries,
          then come and help us fix it for everyone.
        </Typography>
        <Button
          variant="outlined"
          sx={{
            margin: "auto",
            display: "block",
            color: "black",
            borderColor: "black",
            cursor: "pointer",
          }}
        >
          Join Now
        </Button> */}
      </Box>

      <video
        loop
        autoPlay
        muted
        playsInline
        preload="metadata"
        style={{
          width: "100%",
        }}
      >
        <source
          src={require("./assests/videos/WhatsApp Video 2023-01-23 at 15.25.14.mp4#t=0.001")}
          type="video/mp4"
        />
      </video>
    </Box>
  );
}

export default JoinUs;
