import React from "react";
import {
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Container,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import "./css/footer.css";
import { Link, useNavigate } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function Footer() {
  const navigate = useNavigate();

  const handleClickScroll = (value) => {
    const element = document.getElementById(value);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box>
      <Box
        className="desktop-footer"
        sx={{
          backgroundColor: "black",
          color: "#fff",
          paddingTop: "5%",
          paddingBottom: "5%",
          paddingLeft: "2%",
          paddingRight: "2%",
        }}
      >
        <Grid
          container
          spacing={2}
          style={{
            justifyContent: "center",
          }}
        >
          <Grid item xs={4}>
            <img
              src={require("../../assets/logo/logicartsV2Dark.png")}
              style={{
                width: "50%",
              }}
            />
            <Typography
              sx={{
                marginTop: "2%",
              }}
            >
              Logicarts is an award-winning single aggregator platform
              efficiently solving major Logistics problems in south India. Our
              Mission is to connect SELLERS TO CONSUMERS ON TIME at an
              affordable cost.
            </Typography>
            <Typography
              sx={{
                marginTop: "2%",
              }}
            >
              <strong>Registered Address :</strong> Smart Ship Innovative Solutions Private
              Limited 512/10, Outer Ring Rd, Mahadevapura, Bengaluru, Karnataka
              560048 Bangalore, Karnataka 560048 India
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              sx={{
                paddingLeft: "50%",
                fontSize: "22px",
                fontWeight: 600,
              }}
            >
              Quick Links
            </Typography>
            <nav
              aria-label="secondary mailbox folders"
              style={{
                paddingLeft: "50%",
              }}
            >
              <List>
                <Link
                  to="/"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemText
                      primary="Home"
                      onClick={() => handleClickScroll("home")}
                      sx={{
                        cursor: "pointer",
                      }}
                      className="quick-links"
                    />
                  </ListItem>
                </Link>

                <Link
                  to="/services"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemText
                      primary="Services"
                      onClick={() => handleClickScroll("services")}
                      sx={{
                        cursor: "pointer",
                      }}
                      className="quick-links"
                    />
                  </ListItem>
                </Link>
                <ListItem disablePadding>
                  <ListItemText
                    primary="About Us"
                    onClick={() => navigate("/about-us")}
                    sx={{
                      cursor: "pointer",
                    }}
                    className="quick-links"
                  />
                </ListItem>
                <a
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfrpbGOo7Zo3aRto81VeNVXB0cqXytsDrLKIZ7JXvX0X1lmiA/viewform?usp=sf_link"
                  target="_blank"
                >
                  <ListItem disablePadding>
                    <ListItemText
                      primary="Become a Partner"
                      sx={{
                        cursor: "pointer",
                      }}
                      className="quick-links"
                    />
                  </ListItem>
                </a>
                {/* <ListItem disablePadding>
                  <ListItemText
                    primary="Contact Us"
                    onClick={() => handleClickScroll("contact-us")}
                    sx={{
                      cursor: "pointer",
                    }}
                    className="quick-links"
                  />
                </ListItem> */}
              </List>
            </nav>
          </Grid>
          <Grid item xs={4}>
            <Typography
              sx={{
                paddingLeft: "30%",
                fontSize: "22px",
                fontWeight: 600,
                marginBottom: "3%",
              }}
            >
              Contact Us
            </Typography>
            <Box
              sx={{
                paddingLeft: "30%",
              }}
            >
              <Box
                style={{
                  display: "flex",
                }}
                className="quick-links"
              >
                <a
                  href="mailto:info@logicarts.in"
                  target="_blank"
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  <Typography gutterBottom>
                    <EmailIcon />
                  </Typography>
                </a>
                <a
                  href="mailto:info@logicarts.in"
                  target="_blank"
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      marginLeft: "3px",
                    }}
                  >
                    info@logicarts.in
                  </Typography>
                </a>
              </Box>

              <Box
                style={{
                  display: "flex",
                }}
                className="quick-links"
              >
                <a
                  href="//api.whatsapp.com/send?phone=919606100542&text=Hi Logicarts Team, Have an enquiry on my order"
                  target="_blank"
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  <Typography gutterBottom>
                    <WhatsAppIcon />
                  </Typography>
                </a>
                <a
                  href="//api.whatsapp.com/send?phone=919606100542&text=Hi Logicarts Team, Have an enquiry on my order"
                  target="_blank"
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      marginLeft: "3px",
                    }}
                  >
                    Whatsapp Us
                  </Typography>
                </a>
              </Box>

              <Box
                style={{
                  display: "flex",
                }}
                className="quick-links"
              >
                <a
                  className="quick-links"
                  href="tel:+919606100542"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <Typography gutterBottom>
                    <LocalPhoneIcon />
                  </Typography>
                </a>
                <a
                  className="quick-links"
                  href="tel:+919606100542"
                  style={{
                    color: "inherit",
                  }}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      marginLeft: "3px",
                    }}
                  >
                    +91 96061 00542
                  </Typography>
                </a>
              </Box>

              <Typography
                sx={{
                  paddingTop: "5%",
                  fontSize: "22px",
                  fontWeight: 600,
                }}
              >
                Follow Us
              </Typography>
              <Box>
                <a
                  href="https://www.facebook.com/logicarts.in"
                  target="_blank"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <FacebookIcon
                    sx={{
                      fontSize: "2rem",
                    }}
                    className="quick-links"
                  />
                </a>

                <a
                  href="https://www.instagram.com/logicarts.in/"
                  target="_blank"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <InstagramIcon
                    sx={{
                      fontSize: "2rem",
                    }}
                    className="quick-links"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/logicarts/"
                  target="_blank"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <LinkedInIcon
                    sx={{
                      fontSize: "2rem",
                    }}
                    className="quick-links"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCKphZChT8Wrh0gpVvFuDhVQ"
                  target="_blank"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <YouTubeIcon
                    sx={{
                      fontSize: "2rem",
                    }}
                    className="quick-links"
                  />
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        className="mobile-footer"
        sx={{
          backgroundColor: "black",
          color: "#fff",
          paddingTop: "5%",
          paddingBottom: "5%",
          paddingLeft: "2%",
          paddingRight: "2%",
        }}
      >
        <Grid
          container
          spacing={2}
          style={{
            justifyContent: "center",
          }}
        >
          <Grid item xs={12}>
            <img
              src={require("../../assets/logo/logicartsV2Dark.png")}
              style={{
                width: "30%",
                display: "block",
                margin: "auto",
              }}
            />
            <Typography
              sx={{
                marginTop: "2%",
                textAlign: "center",
                padding: "5%",
              }}
            >
              Logicarts is an award-winning single aggregator platform
              efficiently solving major Logistics problems in south India. Our
              Mission is to connect SELLERS TO CONSUMERS ON TIME at an
              affordable cost.
            </Typography>
            <Typography
              sx={{
                marginTop: "2%",
                textAlign: "center",
                padding: "5%",
              }}
            >
             <strong>Registered Address :</strong> Smart Ship Innovative Solutions Private
              Limited 512/10, Outer Ring Rd, Mahadevapura, Bengaluru, Karnataka
              560048 Bangalore, Karnataka 560048 India
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Quick Links
            </Typography>
            <nav aria-label="secondary mailbox folders">
              <List>
                <Link
                  to="/"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemText
                      primary="Home"
                      sx={{
                        textAlign: "center",
                      }}
                      onClick={() => handleClickScroll("home")}
                    />
                  </ListItem>
                </Link>
                <Link
                  to="/services"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemText
                      primary="Services"
                      sx={{
                        textAlign: "center",
                      }}
                      onClick={() => handleClickScroll("services")}
                    />
                  </ListItem>
                </Link>
                <ListItem disablePadding>
                  <ListItemText
                    primary="About Us"
                    sx={{
                      textAlign: "center",
                    }}
                    onClick={() => navigate("/about-us")}
                  />
                </ListItem>
                <a
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfrpbGOo7Zo3aRto81VeNVXB0cqXytsDrLKIZ7JXvX0X1lmiA/viewform?usp=sf_link"
                  target="_blank"
                >
                  <ListItem disablePadding>
                    <ListItemText
                      primary="Become a Partner"
                      sx={{
                        textAlign: "center",
                      }}
                    />
                  </ListItem>
                </a>
                {/* <ListItem disablePadding>
                  <ListItemText
                    primary="Contact Us"
                    sx={{
                      textAlign: "center",
                    }}
                    onClick={() => handleClickScroll("contact-us")}
                  />
                </ListItem> */}
              </List>
            </nav>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "5%",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 600,
                textAlign: "center",
                marginBottom: "3%",
              }}
            >
              Contact Us
            </Typography>

            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                }}
                className="quick-links"
              >
                <a
                  href="mailto:info@logicarts.in"
                  target="_blank"
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  <Typography gutterBottom>
                    <EmailIcon />
                  </Typography>
                </a>
                <a
                  href="mailto:info@logicarts.in"
                  target="_blank"
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      marginLeft: "3px",
                    }}
                  >
                    info@logicarts.in
                  </Typography>
                </a>
              </Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                className="quick-links"
              >
                <a
                  href="//api.whatsapp.com/send?phone=919606100542&text=Hi Logicarts Team, Have an enquiry on my order"
                  target="_blank"
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  <Typography gutterBottom>
                    <WhatsAppIcon />
                  </Typography>
                </a>
                <a
                  href="//api.whatsapp.com/send?phone=919606100542&text=Hi Logicarts Team, Have an enquiry on my order"
                  target="_blank"
                  style={{
                    color: "inherit",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      marginLeft: "3px",
                    }}
                  >
                    Whatsapp Us
                  </Typography>
                </a>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                className="quick-links"
              >
                <a
                  className="quick-links"
                  href="tel:+919606100542"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <Typography gutterBottom>
                    <LocalPhoneIcon />
                  </Typography>
                </a>
                <a
                  className="quick-links"
                  href="tel:+919606100542"
                  style={{
                    color: "inherit",
                  }}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      marginLeft: "3px",
                    }}
                  >
                    +91 96061 00542
                  </Typography>
                </a>
              </Box>

              <Typography
                sx={{
                  paddingTop: "5%",
                  fontSize: "22px",
                  fontWeight: 600,
                }}
              >
                Follow Us
              </Typography>
              <Box>
                <a
                  href="https://www.facebook.com/logicarts.in"
                  target="_blank"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <FacebookIcon
                    sx={{
                      fontSize: "2rem",
                    }}
                    className="quick-links"
                  />
                </a>

                <a
                  href="https://www.instagram.com/logicarts.in/"
                  target="_blank"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <InstagramIcon
                    sx={{
                      fontSize: "2rem",
                    }}
                    className="quick-links"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/logicarts/"
                  target="_blank"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <LinkedInIcon
                    sx={{
                      fontSize: "2rem",
                    }}
                    className="quick-links"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCKphZChT8Wrh0gpVvFuDhVQ"
                  target="_blank"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <YouTubeIcon
                    sx={{
                      fontSize: "2rem",
                    }}
                    className="quick-links"
                  />
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Footer;
