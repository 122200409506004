import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AOS from "aos";
import "aos/dist/aos.css";
import "./assests/css/section2.css";
import AI from "./assests/images/aaI-01.svg";

function Section2() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Box
      sx={{
        paddingTop: "25%",
      }}
    >
      <Grid container>
        <Grid item xs={7}>
          <div class="cube-container">
            <div class="cube consumed">
              <div class="side top"></div>
              <div class="side front"></div>
              <div class="side back"></div>
              <div class="side left"></div>
              <div class="side right"></div>
              <div class="side bottom"></div>
            </div>

            <div class="cube consumer">
              <div class="side top"></div>
              <div class="side front"></div>
              <div class="side back"></div>
              <div class="side left"></div>
              <div class="side right"></div>
              <div class="side bottom"></div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={5}
          sx={{
            zIndex: 10000,
          }}
        >
          <Typography
            className="ai-head"
            sx={{
              marginTop: "15%",
            }}
          >
            AI THAT AUTOMATES
          </Typography>
          <Typography className="ai-text" gutterBottom>
            Our advanced optics and computer vision create ground truth data.
          </Typography>
          <Typography className="ai-text ai-text-second" gutterBottom>
            Say goodbye to hand scanning, misloads, and lost packages. Say hello
            to field-proven 99.9% performance.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          paddingTop: "20%",
        }}
      >
        <Grid item xs={7}>
          <img
            // src={require("./assests/images/tablet.png")}
            src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1674474086944_table.webp"
            style={{
              width: "140%",
              marginLeft: "-35%",
            }}
            data-aos="fade-right"
            data-aos-delay="100"
            data-aos-duration="1000"
          />
        </Grid>
        <Grid
          item
          xs={5}
          sx={{
            marginTop: "50%",
          }}
        >
          <img
            // src={require("./assests/images/MockUp6.png")}
            src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1674474159356_smartphone.webp"
            style={{
              width: "150%",
            }}
            data-aos="fade-left"
            data-aos-delay="100"
            data-aos-duration="1000"
          />
        </Grid>
      </Grid>
      {/* <div className="place-cube">
        <div class="scene">
          <div class="cube">
            <div class="cube-face  cube-face-front"></div>
            <div class="cube-face  cube-face-back"></div>
            <div class="cube-face  cube-face-left"></div>
            <div class="cube-face  cube-face-right"></div>
            <div class="cube-face  cube-face-top"></div>
            <div class="cube-face  cube-face-bottom"></div>
          </div>
          <div class="cube" id="cube2">
            <div class="cube-face  cube-face-front"></div>
            <div class="cube-face  cube-face-back"></div>
            <div class="cube-face  cube-face-left"></div>
            <div class="cube-face  cube-face-right"></div>
            <div class="cube-face  cube-face-top"></div>
            <div class="cube-face  cube-face-bottom"></div>
          </div>
        </div>
      </div>
      <div className="place-cube-1">
        <div class="scene">
          <div class="cube">
            <div class="cube-face  cube-face-front"></div>
            <div class="cube-face  cube-face-back"></div>
            <div class="cube-face  cube-face-left"></div>
            <div class="cube-face  cube-face-right"></div>
            <div class="cube-face  cube-face-top"></div>
            <div class="cube-face  cube-face-bottom"></div>
          </div>
          <div class="cube" id="cube4">
            <div class="cube-face  cube-face-front"></div>
            <div class="cube-face  cube-face-back"></div>
            <div class="cube-face  cube-face-left"></div>
            <div class="cube-face  cube-face-right"></div>
            <div class="cube-face  cube-face-top"></div>
            <div class="cube-face  cube-face-bottom"></div>
          </div>
        </div>
      </div> */}
    </Box>
  );
}

export default Section2;
