import React, { Component } from "react";
import Autocomplete from "react-google-autocomplete";

class GoogleMapComponent extends Component {
  handlePlaceSelected = (place) => {
    console.log(place);
    localStorage.setItem("searchLat", place.geometry.location.lat());
    localStorage.setItem("searchLng", place.geometry.location.lng());
    localStorage.setItem("searchAddress", place.formatted_address);
    localStorage.setItem("refresh", true);
    this.props.onGo();
  };

  render() {
    return (
        <Autocomplete
        apiKey="AIzaSyDJ6m7dujpOLQYOVDL6B5QJdE6cr0sEcuw"
        style={{
          width: "95%",
          height: "40px",
          border: "0.5px solid #DDDDDD",
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
          borderRadius: "4px",
          paddingLeft: "2%",
        }}
        onPlaceSelected={this.handlePlaceSelected}
        placeholder="Search your address"
        options={{
          types: ["(regions)"],
          componentRestrictions: { country: "in" },
        }}
      />
    );
  }
}

export default GoogleMapComponent;
