import React, { useCallback, useState, useEffect } from "react";
import { Button, Typography, Box, Card, Stack, Drawer } from "@mui/material";
import useRazorpay from "react-razorpay";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate, useLocation } from "react-router-dom";
import "./payment.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Celebrate from "./assests/123026-celebrations-begin.gif";
import PointConfetti from "../Animation/PointConfetti";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Payment() {
  const navigate = useNavigate();
  const location = useLocation();
  const maxWidth = "md";

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showOrderConfirm, setShowOrderConfirm] = useState(false);
  const [open, setOpen] = useState(false);

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const vertical = "top";
  const horizontal = "center";


  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, []);

  const getData = async () => {
    const userDetails = localStorage.getItem("userDetails");
    const parseData = await JSON.parse(userDetails);
    const parseJdata = await JSON.parse(parseData.jdata);
    setName(parseData.firstname + " " + parseData.lastname);
    setMobile(parseData.mobile);
    setEmail(parseJdata.email);
    console.log(parseData);
  };

  const Razorpay = useRazorpay();

  const handlePayment = useCallback(async () => {
    const options = {
      key: "rzp_live_FkMwaM2QQXnydI",
      amount: "100",
      currency: "INR",
      name: "Logicarts",
      description: "Logicarts pickup create order payment transaction",
      image:
        "https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1681457671979_logo_blue.png",
      order_id: location.state.paymentID,
      handler: async (res) => {
        console.log(res);
        if (res) {
          const response = await fetch(
            "https://ndd.logicarts.in/pickup/payment/success",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                "x-access-token": localStorage.getItem("token"),
              },

              body: JSON.stringify({
                pID: location.state.pID,
                jdata: {
                  orderID: location.state.orderID,
                  senderName: location.state.senderName,
                  senderHouseAddress: location.state.senderHouseAddress,
                  senderAddressLine1: location.state.senderAddressLine1,
                  senderAddressLine2: location.state.senderAddressLine2,
                  senderLandmark: location.state.senderLandmark,
                  senderCity: location.state.senderCity,
                  senderState: location.state.senderState,
                  senderPincode: location.state.senderPincode,
                  sendermobileNumber: location.state.sendermobileNumber,
                  senderLat: location.state.senderLat,
                  senderLng: location.state.senderLng,
                  senderNote: location.state.senderNote,
                  receiverName: location.state.receiverName,
                  receiverHouseAddress: location.state.receiverHouseAddress,
                  receiverAddressLine1: location.state.receiverAddressLine1,
                  receiverAddressLine2: location.state.receiverAddressLine2,
                  receiverLandmark: location.state.receiverLandmark,
                  receiverCity: location.state.receiverCity,
                  receiverState: location.state.receiverState,
                  receiverPincode: location.state.receiverPincode,
                  receiverMobileNumber: location.state.receivermobileNumber,
                  receiverLat: location.state.receiverLat,
                  receiverLng: location.state.receiverLng,
                  receiverNote: location.state.receiverNote,
                  paymentType: location.state.paymentType,
                  goodsValue: Number(location.state.goodsValue),
                  Description: location.state.Description,
                  actualWeight: location.state.actualWeight,
                  isFragile: location.state.isFragile,
                  mobile: location.state.mobile,
                  amount: "200",
                  deliveryAent: {},
                  is_deliveryAgent: "0",
                  couponCode: location.state.couponCode,
                  cxAmountPaid: location.state.cxAmountPaid,
                  totalDistance: location.state.totalDistance,

                  images: [],
                  amountToPay: location.state.amountToPay,
                  status: "Cx Payment Successful",
                },
              }),
            }
          );

          const responseJson = await response.json();
          console.log(responseJson);
          if (responseJson.success == true) {
            navigate("/express/order-success", {
              state: {
                pID: location.state.pID,
                orderID: location.state.orderID,
                senderName: location.state.senderName,
                senderHouseAddress: location.state.senderHouseAddress,
                senderAddressLine1: location.state.senderAddressLine1,
                senderAddressLine2: location.state.senderAddressLine2,
                senderLandmark: location.state.senderLandmark,
                senderCity: location.state.senderCity,
                senderState: location.state.senderState,
                senderPincode: location.state.senderPincode,
                sendermobileNumber: location.state.sendermobileNumber,
                senderLat: location.state.senderLat,
                senderLng: location.state.senderLng,
                senderNote: location.state.senderNote,
                receiverName: location.state.receiverName,
                receiverHouseAddress: location.state.receiverHouseAddress,
                receiverAddressLine1: location.state.receiverAddressLine1,
                receiverAddressLine2: location.state.receiverAddressLine2,
                receiverLandmark: location.state.receiverLandmark,
                receiverCity: location.state.receiverCity,
                receiverState: location.state.receiverState,
                receiverPincode: location.state.receiverPincode,
                receiverMobileNumber: location.state.receivermobileNumber,
                receiverLat: location.state.receiverLat,
                receiverLng: location.state.receiverLng,
                receiverNote: location.state.receiverNote,
                paymentType: location.state.paymentType,
                goodsValue: Number(location.state.goodsValue),
                Description: location.state.Description,
                actualWeight: location.state.actualWeight,
                isFragile: location.state.isFragile,
                mobile: location.state.mobile,
                amount: "200",
                deliveryAent: {},
                is_deliveryAgent: "0",
                couponCode: location.state.couponCode,
                cxAmountPaid: location.state.cxAmountPaid,
                totalDistance: location.state.totalDistance,

                images: [],
                amountToPay: location.state.amountToPay,
                status: "Cx Order Cancelled",
              },
            });
          } else {
            setErrorMsg("Unable to update the payment status");
            setOpenErrorAlert(true)
            navigate("/express/order-history");
          }
        } else {
          const response = await fetch(
            "https://ndd.logicarts.in/pickup/payment/failed",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                "x-access-token": localStorage.getItem("token"),
              },

              body: JSON.stringify({
                pID: location.state.pID,
                jdata: {
                  orderID: location.state.orderID,
                  senderName: location.state.senderName,
                  senderHouseAddress: location.state.senderHouseAddress,
                  senderAddressLine1: location.state.senderAddressLine1,
                  senderAddressLine2: location.state.senderAddressLine2,
                  senderLandmark: location.state.senderLandmark,
                  senderCity: location.state.senderCity,
                  senderState: location.state.senderState,
                  senderPincode: location.state.senderPincode,
                  sendermobileNumber: location.state.sendermobileNumber,
                  senderLat: location.state.senderLat,
                  senderLng: location.state.senderLng,
                  senderNote: location.state.senderNote,
                  receiverName: location.state.receiverName,
                  receiverHouseAddress: location.state.receiverHouseAddress,
                  receiverAddressLine1: location.state.receiverAddressLine1,
                  receiverAddressLine2: location.state.receiverAddressLine2,
                  receiverLandmark: location.state.receiverLandmark,
                  receiverCity: location.state.receiverCity,
                  receiverState: location.state.receiverState,
                  receiverPincode: location.state.receiverPincode,
                  receiverMobileNumber: location.state.receivermobileNumber,
                  receiverLat: location.state.receiverLat,
                  receiverLng: location.state.receiverLng,
                  receiverNote: location.state.receiverNote,
                  paymentType: location.state.paymentType,
                  goodsValue: Number(location.state.goodsValue),
                  Description: location.state.Description,
                  actualWeight: location.state.actualWeight,
                  isFragile: location.state.isFragile,
                  mobile: location.state.mobile,
                  amount: "200",
                  deliveryAent: {},
                  is_deliveryAgent: "0",
                  couponCode: location.state.couponCode,

                  images: [],
                  amountToPay: location.state.amountToPay,
                  status: "Payment Failed",
                },
              }),
            }
          );

          const responseJson = await response.json();
          console.log(responseJson);
          if (responseJson.success == true) {
            navigate("/express/order-history");
          } else {
            setErrorMsg("Unable to update the payment status");
            setOpenErrorAlert(true)
            navigate("/express/order-history");
          }
        }
      },
      prefill: {
        name: name,
        email: email,
        contact: mobile,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);

  const handleAnimation = () => {
    return;
  };

  const paymentSuccess = async () => {
    const response = await fetch(
      "https://ndd.logicarts.in/pickup/payment/success",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },

        body: JSON.stringify({
          pID: location.state.pID,
          jdata: {
            orderID: location.state.orderID,
            senderName: location.state.senderName,
            senderHouseAddress: location.state.senderHouseAddress,
            senderAddressLine1: location.state.senderAddressLine1,
            senderAddressLine2: location.state.senderAddressLine2,
            senderLandmark: location.state.senderLandmark,
            senderCity: location.state.senderCity,
            senderState: location.state.senderState,
            senderPincode: location.state.senderPincode,
            sendermobileNumber: location.state.sendermobileNumber,
            senderLat: location.state.senderLat,
            senderLng: location.state.senderLng,
            senderNote: location.state.senderNote,
            receiverName: location.state.receiverName,
            receiverHouseAddress: location.state.receiverHouseAddress,
            receiverAddressLine1: location.state.receiverAddressLine1,
            receiverAddressLine2: location.state.receiverAddressLine2,
            receiverLandmark: location.state.receiverLandmark,
            receiverCity: location.state.receiverCity,
            receiverState: location.state.receiverState,
            receiverPincode: location.state.receiverPincode,
            receiverMobileNumber: location.state.receivermobileNumber,
            receiverLat: location.state.receiverLat,
            receiverLng: location.state.receiverLng,
            receiverNote: location.state.receiverNote,
            paymentType: location.state.paymentType,
            goodsValue: Number(location.state.goodsValue),
            Description: location.state.Description,
            actualWeight: location.state.actualWeight,
            isFragile: location.state.isFragile,
            mobile: location.state.mobile,
            amount: "200",
            deliveryAent: {},
            is_deliveryAgent: "0",
            couponCode: location.state.couponCode,

            images: [],
            amountToPay: location.state.amountToPay,
            status: "Cx Payment Successful",
          },
        }),
      }
    );
  };

  return (
    <>
      <div className="desktop-payment">
        <img
          src={require("./assests/E-Wallet-amico.png")}
          style={{
            width: "30%",
            display: "block",
            margin: "auto",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            marginBottom: "3%",
          }}
        >
          Click below to proceed to payment
        </Typography>
        <Button
          variant="contained"
          onClick={handlePayment}
          sx={{
            display: "block",
            margin: "auto",
          }}
        >
          Proceed To Pay
        </Button>
      </div>
      <div className="mobile-payment">
        <Box
          sx={{
            backgroundColor: "#669AFF",
            display: "flex",
            justifyContent: "space-between",
            padding: "3%",
          }}
        >
          <Typography
            sx={{
              marginTop: "2%",
            }}
            onClick={() => navigate("/express/order-history")}
          >
            <ArrowBackRoundedIcon
              sx={{
                color: "#FFFFFF",
              }}
            />
          </Typography>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontWeight: "600",
              marginTop: "1%",
              fontSize: "1.2rem",
            }}
          >
            Payment
          </Typography>
          <Typography></Typography>
        </Box>
        <Box
          sx={{
            padding: "5%",
            marginTop: "10%",
          }}
        >
          <img
            src={require("../CreateOrder/assests/images/logo_new1.png")}
            style={{
              width: "60%",
              display: "block",
              margin: "auto",
            }}
          />

          <img
            src={require("./assests/E-Wallet-amico.png")}
            style={{
              width: "100%",
              marginTop: "5%",
            }}
          />

          <Typography
            sx={{
              textAlign: "center",
              marginTop: "5%",
            }}
          >
            Great! Please click on proceed to pay to complete your transation.
          </Typography>
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Button
              fullWidth
              variant="contained"
              onClick={handlePayment}
              sx={{
                margin: "auto",
                display: "block",
                borderRadius: "0px",
              }}
            >
              Proceed To Pay.
            </Button>
          </Box>
        </Box>

        <Dialog
          open={openDialog}
          maxWidth={maxWidth}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          hideBackdrop={true}
        >
          <Box>
            <img
              src={require("./assests/13491-pop-new-year.gif")}
              style={{
                width: "50%",
                margin: "auto",
                display: "block",
              }}
            />
          </Box>
          <DialogContent
            sx={{
              marginTop: "-16%",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                textAlign: "center",
              }}
              gutterBottom
            >
              FIRSTORDER Applied
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "1.8rem",
              }}
            >
              ₹100
            </Typography>
            <Typography
              sx={{
                fontSize: "0.8rem",
                color: "grey",
              }}
            >
              saved! That feels amazing right?
            </Typography>
          </DialogContent>
          <Button
            variant="contained"
            sx={{
              borderRadius: "0px",
            }}
            onClick={() => setOpenDialog(false)}
          >
            OK
          </Button>
        </Dialog>

        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
        >
          <Alert
            onClose={() => setOpen(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Successful Transaction!
          </Alert>
        </Snackbar>

        <Snackbar
          open={openErrorAlert}
          autoHideDuration={6000}
          onClose={() => setOpenErrorAlert(false)}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert
            onClose={() => setOpenErrorAlert(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMsg}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default Payment;
