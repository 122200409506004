import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import "./css/triangle.css";
import AOS from "aos";
import "aos/dist/aos.css";

function VideoSectionSecond() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Box
        sx={{
          margin: "auto",
        }}
      >
        {/* <Typography className="video-first-header" sx={{
          
        }}>
          Large scale operations with
        </Typography> */}
        <Typography className="video-first-header-below">
          Secure, Reliable and On-Time Delivery
        </Typography>

        <video
          loop
          autoPlay
          muted
          playsInline
          preload="metadata"
          style={{
            width: "100%",
            position: "absolute",
            boxShadow : "none",
            border: "1px solid #fafafc",
            outline: "none",
            paddingTop : "3%"
            // clipPath : "inset(1px 1px)"
          }}
          
        >
          <source
            src={require("./Video/WhatsApp Video 2023-01-23 at 15.31.44.mp4#t=0.001")}
            type="video/mp4"
          />
        </video>
        <div
          className="text-1"
          data-aos="fade-up"
          data-aos-delay="350"
          data-aos-duration="3000"
        >
          <p>MANUFACTURER</p>
          <p
          className="down-manu"
            
          >
            /E-COMMERCE
          </p>
        </div>

        <div
          className="text-2"
          data-aos="fade-up"
          data-aos-delay="350"
          data-aos-duration="3000"
        >
          <p>FC HUB</p>
        </div>
        <div
          className="text-3"
          data-aos="fade-up"
          data-aos-delay="350"
          data-aos-duration="3000"
        >
          <p>LAST MILE</p>
          <p
          className="last-down"
            
          >
            HUB
          </p>
        </div>
        <div
          className="text-4"
          data-aos="fade-up"
          data-aos-delay="390"
          data-aos-duration="3000"
        >
          <p>D2C</p>
        </div>

        <div
          className="text-5"
          data-aos="fade-up"
          data-aos-delay="390"
          data-aos-duration="3000"
        >
          <p>RETAIL (B2B)</p>
        </div>
      </Box>
      {/* <Box>
        <img
          src={require("./Video/Screenshot 2022-12-29 at 12.13.27 PM.png")}
          style={{
            width: "100%",
          }}
        />
      </Box> */}

      {/* <Box
          backgroundColor: "#000",
          padding : "5%",
          marginTop : "-4%"
        }}
      >
        <Typography sx={{
          color: "#fff",
          fontSize: "3rem",
          fontWeight: 600,
          textAlign: "center",
          marginBottom : "5%"
        }}>
        One Solution For All Your Needs
        </Typography>
        <Grid container>
          <Grid item xs={3}>
            <img
              src={require("./assests/images/Ic_Services (1).png")}
              style={{
                margin: "auto",
                display: "block",
              }}
            />

            <Typography
              sx={{
                color: "#fff",
                fontSize: "20px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              End-2-End Integrated Solution
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                textAlign: "center",
              }}
            >
              Pick Up goods from the seller
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <img src={require("./assests/images/Ic_Services (2).png")} style={{
                margin: "auto",
                display: "block",
              }} />
            <Typography
              sx={{
                color: "#fff",
                fontSize: "20px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Mid Mile
            </Typography>
            <Typography
              sx={{
                color: "#fff",

                textAlign: "center",
              }}
            >
              Pick Up goods from the seller
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <img src={require("./assests/images/Ic_Services (3).png")} style={{
                margin: "auto",
                display: "block",
              }} />

            <Typography
              sx={{
                color: "#fff",
                fontSize: "20px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Last Mile
            </Typography>
            <Typography
              sx={{
                color: "#fff",

                textAlign: "center",
              }}
            >
              Pick Up goods from the seller
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <img src={require("./assests/images/Ic_Services (5).png")} style={{
                margin: "auto",
                display: "block",
              }} />

            <Typography
              sx={{
                color: "#fff",
                fontSize: "20px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Warehousing
            </Typography>
            <Typography
              sx={{
                color: "#fff",

                textAlign: "center",
              }}
            >
              Pick Up goods from the seller
            </Typography>
          </Grid>
        </Grid>
      </Box> */}

      {/* <Box className="black-box-mobile">
        <Typography
          sx={{
            color: "#fff",
            fontSize: "30px",
            textAlign: "center",
          }}
        >
          One Solution For All Your Needs
        </Typography>

        <Grid
          container
          sx={{
            alignItems: "center",
            textAlign: "center",
            marginTop: "40px",
          }}
        >
          <Grid item xs={12}>
            <img src={require("./Video/dd 1.png")} />
            <Typography
              sx={{
                color: "#fff",
                fontSize: "24px",
                fontWeight: 600,
              }}
            >
              First Mile
            </Typography>
            <Typography
              sx={{
                color: "#929292",
                fontSize: "24px",
                fontWeight: 400,
              }}
            >
              Pickup goods from the seller
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img src={require("./Video/dd 1.png")} />
            <Typography
              sx={{
                color: "#fff",
                fontSize: "24px",
                fontWeight: 600,
              }}
            >
              Last Mile
            </Typography>
            <Typography
              sx={{
                color: "#929292",
                fontSize: "24px",
                fontWeight: 400,
              }}
            >
              Pickup goods from the seller
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img src={require("./Video/dd 1.png")} />
            <Typography
              sx={{
                color: "#fff",
                fontSize: "24px",
                fontWeight: 600,
              }}
            >
              Mid Mile
            </Typography>
            <Typography
              sx={{
                color: "#929292",
                fontSize: "24px",
                fontWeight: 400,
              }}
            >
              Pickup goods from the seller
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img src={require("./Video/dd 1.png")} />
            <Typography
              sx={{
                color: "#fff",
                fontSize: "24px",
                fontWeight: 600,
              }}
            >
              Part Loads
            </Typography>
            <Typography
              sx={{
                color: "#929292",
                fontSize: "24px",
                fontWeight: 400,
              }}
            >
              Pickup goods from the seller
            </Typography>
          </Grid>
        </Grid>
      </Box> */}
    </>
  );
}

export default VideoSectionSecond;
