import React, { useState, useEffect } from "react";
import axios from 'axios';
import {
  Typography,
  Grid,
  Button,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip
} from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import vehicle from './assets/vehicle.svg';
import PdfIcon from './assets/pdfIcon.png';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import './vendorService.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';


const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#1b8fd2',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

const Home = () => {
  const [searchResult, setSearchResult] = useState(null);
  const [vehicleNumberError, setVehicleNumberError] = useState(false);
  const [vehicleNumberTextError, setVehicleNumberTextError] = useState('Vehicle Number not founnd.')
  const [vehicleSizeData, setVehicleSizeData] = useState([]);
  const [categories, setCategories] = useState([
    { name: 'registration', label: 'Registration', attachments: [] },
    { name: 'insurance', label: 'Insurance', attachments: [] },
    { name: 'puc', label: 'PUC', attachments: [] },
    { name: 'statePermit', label: 'State Permit', attachments: [] },
    { name: 'nationalPermit', label: 'National Permit', attachments: [] },
    { name: 'fitnessCertificate', label: 'Fitness Certificate', attachments: [] },
  ]);
  const [vendorServiceData, setVendorServiceData] = useState({
    vehicleSizeId: '',
    vehicleSizeIdError: false,
    vehicleNumber: '',
    vehicleNumberError: false,
    vendorName: '',
    vendorNameError: false,
    vendorMobile: '',
    vendorMobileError: false,

    registrationExp: '',
    registrationExpError: false,
    insuranceExp: '',
    insuranceExpError: false,
    statePermitExp: '',
    statePermitExpError: false,
    nationalPermitExp: '',
    nationalPermitExpError: false,
    pucExp: '',
    pucExpError: false,
    fitnessCertificateExp: '',
    fitnessCertificateError: false,
  });
  const [currentCategory, setCurrentCategory] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState({});





  const handleClose = () => {
    setOpen(false);
  };

  const handlePreviewOpen = (category) => {
    console.log(category);
    setCurrentCategory(category);
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setCurrentCategory(null);
  };




  const handleSubmitForm = async () => {
    const fieldsToCheck = ['vehicleSizeId', 'vehicleNumber', 'vendorName', 'vendorMobile', 'registrationExp', 'insuranceExp', 'statePermitExp', 'nationalPermitExp', 'fitnessCertificateExp', 'pucExp'];
    const lastServiceFileUploaded = categories.find(e => e.name === 'lastService' && e.attachments.length > 0);

    // Check for empty required fields
    const hasError = fieldsToCheck.some(field => !vendorServiceData[field]);
    if (hasError) {
      const firstErrorField = fieldsToCheck.find(field => !vendorServiceData[field]);
      setVendorServiceData(prevData => ({
        ...prevData,
        [`${firstErrorField}Error`]: true,
      }));
      return; // Stop form submission if there are errors
    }

    // Check for missing files (excluding lastService)
    let hasFileError = false;
    let hasFileName = '';
    categories.forEach(e => {
      if (e.attachments.length === 0) {
        hasFileError = true;
        hasFileName = e.label;
      }
    });

    if (hasFileError) {
      toast.error(`Please select at least 1 file in: ${hasFileName}`);
      return; // Stop form submission if there are file errors
    }


    // Prepare form data for submission
    let data = new FormData();
    data.append('vehicleId', searchResult.vehicleId);
    data.append('vehicleNumber', vendorServiceData.vehicleNumber);
    data.append('name', vendorServiceData.vendorName);
    data.append('mobile', vendorServiceData.vendorMobile);
    data.append('registrationExp', vendorServiceData.registrationExp);
    data.append('insuranceExp', vendorServiceData.insuranceExp);
    data.append('statePermitExp', vendorServiceData.statePermitExp);
    data.append('nationalPermitExp', vendorServiceData.nationalPermitExp);
    data.append('fitnessCertificateExp', vendorServiceData.fitnessCertificateExp);
    data.append('pucExp', vendorServiceData.pucExp);


    categories.forEach(e => {
      if (e.attachments.length > 0) {
        e.attachments.forEach(attachment => {
          data.append(`${e.name}Doc`, attachment);
        });
      }
    });

    // Submit form data
    try {
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/vendorService/create`,
        data: data
      };

      const response = await axios.request(config);

      // Reset form and show success message
      setOpen(true);
      setCategories([
        { name: 'registration', label: 'Registration', attachments: [] },
        { name: 'insurance', label: 'Insurance', attachments: [] },
        { name: 'puc', label: 'PUC', attachments: [] },
        { name: 'statePermit', label: 'State Permit', attachments: [] },
        { name: 'nationalPermit', label: 'National Permit', attachments: [] },
        { name: 'fitnessCertificate', label: 'Fitness Certificate', attachments: [] },
      ]);
      setVendorServiceData({
        vehicleSizeId: '',
        vehicleSizeIdError: false,
        vehicleNumber: '',
        vehicleNumberError: false,
        vendorName: '',
        vendorNameError: false,
        vendorMobile: '',
        vendorMobileError: false,
        registrationExp: '',
        registrationExpError: false,
        insuranceExp: '',
        insuranceExpError: false,
        statePermitExp: '',
        statePermitExpError: false,
        nationalPermitExp: '',
        nationalPermitExpError: false,
        pucExp: '',
        pucExpError: false,
        fitnessCertificateExp: '',
        fitnessCertificateError: false,
      });
      setSearchResult(null);
      toast.success('Thank you for contacting us.');
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };


  const handleAddMoreFiles = async (event) => {
    const categoryData = currentCategory;
    const files = event.target.files;
    const maxSizeBytes = 1048576; // 1MB in bytes
    const attachmentsUpload = categoryData.attachments
    const existingFileNames = attachmentsUpload.map((file) => file.name);
    const totalFiles = attachmentsUpload.length + files.length;
    console.log(totalFiles);
    if (totalFiles > 5) {
      // Take only the first 10 files
      const filesToAdd = Array.from(files).slice(0, 5 - attachmentsUpload.length);

      for (let i = 0; i < filesToAdd.length; i++) {
        const file = filesToAdd[i];
        // Check if the file with the same name already exists
        if (!existingFileNames.includes(file.name)) {
          // Check if the file size is within the limit
          if (file.size <= maxSizeBytes) {
            await attachmentsUpload.push(file);
            setCategories((prevCategories) =>
              prevCategories.map((category) =>
                category.name === categoryData.name
                  ? { ...category, attachments: attachmentsUpload }
                  : category
              )
            );
            // Update the current category state
            setCurrentCategory({
              ...categoryData,
              attachments: attachmentsUpload,
            });
          } else {
            // Show an error message for files exceeding the size limit
            toast.error(`File "${file.name}" exceeds the maximum size limit of 1MB and will not be uploaded.`);
          }
        } else {
          // Show an error message for duplicate files
          toast.error(`File "${file.name}" has already been selected.`);
        }
      }
      // Show a message indicating that only the first 10 files were selected
      toast.error("You can only select up to 5 files. Only the first 5 files were selected.");
    } else {
      // If total files are within the limit, add them normally
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // Check if the file with the same name already exists
        if (!existingFileNames.includes(file.name)) {
          // Check if the file size is within the limit
          if (file.size <= maxSizeBytes) {
            await attachmentsUpload.push(file);
            setCategories((prevCategories) =>
              prevCategories.map((category) =>
                category.name === categoryData.name
                  ? { ...category, attachments: attachmentsUpload }
                  : category
              )
            );
            // Update the current category state
            setCurrentCategory({
              ...categoryData,
              attachments: attachmentsUpload,
            });
          } else {
            // Show an error message for files exceeding the size limit
            toast.error(`File "${file.name}" exceeds the maximum size limit of 1MB and will not be uploaded.`);
          }
        } else {
          // Show an error message for duplicate files
          toast.error(`File "${file.name}" has already been selected.`);
        }
      }
    }
    event.target.value = "";
  }

  const handleFileChange = (event, categoryName) => {
    const files = event.target.files;
    const maxSizeBytes = 1048576; // 1MB in bytes

    if (files.length > 5) {
      toast.error(
        `You can select maximum 5 files`
      );
    }

    const filesToAdd = Array.from(files).slice(0, 5);
    let filesToUpload = [];
    for (let i = 0; i < filesToAdd.length; i++) {
      const file = filesToAdd[i];
      if (file.size <= maxSizeBytes) {
        filesToUpload.push(file);
      } else {
        // Show an error message for files exceeding the size limit
        toast.error(
          `File "${file.name}" exceeds the maximum size limit of 1MB and will not be uploaded.`
        );
      }
    }

    setCategories((prevCategories) =>
      prevCategories.map((category) =>
        category.name === categoryName
          ? { ...category, attachments: filesToUpload }
          : category
      )
    );
    event.target.value = "";
  };

  const handleDeleteImage = (index, categoryData) => {
    // Filter out the attachment at the given index
    const updatedAttachments = categoryData.attachments.filter((_, key) => key !== index);

    // Update the categories state
    setCategories(prevCategories =>
      prevCategories.map(category =>
        category.name === categoryData.name
          ? { ...category, attachments: updatedAttachments }
          : category
      )
    );

    // Update the current category state
    setCurrentCategory({
      ...categoryData,
      attachments: updatedAttachments,
    });
  };




  const handleChangeVendorServiceData = (e, name) => {
    const { value } = e.target;

    // Check if the value is empty and update the error state accordingly
    if (value === '') {
      setVendorServiceData((prevData) => ({
        ...prevData,
        [`${name}Error`]: true,
      }));
    } else {
      setVendorServiceData((prevData) => ({
        ...prevData,
        [`${name}Error`]: false,
      }));
    }

    if (name == 'vendorMobile') {
      if (/^\d{0,10}$/.test(value)) {
        setVendorServiceData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setVendorServiceData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setVehicleNumberError(false)
    const formData = new FormData(e.target);
    const vehicleNumber = formData.get('vehcileNumber');
    setVehicleNumberTextError('Vehicle Number not founnd.')
    const url = `${process.env.REACT_APP_BASE_URL}/vendorService/searchVehicle?vehicleNumber=${vehicleNumber}`;
    axios.get(url)
      .then((response) => {
        if (response.data == null) {
          setSearchResult(null);
          setVehicleNumberError(true)
        } else {
          setSearchResult(response.data);
          setVendorServiceData({
            vehicleSizeId: response.data.vehicleSize?.vehicleSizeId,
            vehicleSizeIdError: false,
            vehicleNumber: response.data.vehicleNumber,
            vehicleNumberError: false,
            vendorName: response.data.vendor?.vendorName,
            vendorNameError: false,
            vendorMobile: response.data.vendor?.vendorPhoneNumber.replace(/\D/g, '').slice(0, 10),
            vendorMobileError: false,
          })
        }

      })
      .catch(error => {
        setSearchResult(null);
        setVehicleNumberError(true)
        setVehicleNumberTextError(error.response.data.error)
      });
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const getVehicleSize = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/vendorService/getVehicleSize`;
    axios.get(url)
      .then((response) => {
        setVehicleSizeData(response.data.vehicleSizes)
      })
      .catch(error => {

      });
  }

  useEffect(() => {
    getVehicleSize();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <ToastContainer style={{ marginTop: '5vh' }} />
        <Grid
          container
        >

          <Grid
            container
            spacing={1}
            className="mainHeader"
          >
            <Grid className="hide" item xs={2}></Grid>

            <Grid item md={6} xs={12} style={{ marginRight: '-51px', paddingRight: '20px' }}>
              <Grid container className="mobileHeading" spacing={1} >
                <Grid item md={12} xs={12} mb={1} >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "38px",
                      lineHeight: "46px",
                      color: "#1b8fd2",
                    }}
                  >
                    Vendor Services
                  </Typography>
                </Grid>

                <Grid item md={12} xs={12} mb={1} >
                  <form onSubmit={(e) => handleSearch(e)}>
                    <Grid container spacing={1}>
                      <Grid item md={8} xs={12}>
                        <TextField
                          fullWidth
                          error={vehicleNumberError}
                          label="Enter your Vehicle Number"
                          variant="outlined"
                          size="small"
                          required
                          name="vehcileNumber"
                          helperText={vehicleNumberError == true ? vehicleNumberTextError : ""}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="medium"
                          className="searchButton"
                          startIcon={<SearchIcon />}
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                {searchResult != null &&
                  <Grid mt={3} item md={12} xs={12}>
                    <Grid container spacing={1} >

                      <Grid mt={3} item md={12} xs={12}>
                        <Typography variant="h6">
                          <b>  Search Results</b>
                        </Typography>
                      </Grid>

                      <Grid item md={5} xs={12}>
                        <TextField
                          id="outlined-disabled-1"
                          label="Vehicle Number"
                          value={vendorServiceData.vehicleNumber}
                          name="vehicleNumber"
                          onChange={(e) => handleChangeVendorServiceData(e, 'vehicleNumber')}
                          size="small"
                          className="textField"
                          required
                          disabled
                        />
                      </Grid>
                      <Grid item md={5} xs={12}>
                        <TextField
                          id="outlined-disabled-2"
                          label="Vendor Name"
                          value={vendorServiceData.vendorName}
                          name="vendorName"
                          disabled
                          size="small"
                          className="textField"
                          error={vendorServiceData.vendorNameError}
                          onChange={(e) => handleChangeVendorServiceData(e, 'vendorName')}
                          required
                        />
                      </Grid>
                      <Grid item md={5} mt={1} xs={12}>
                        <FormControl fullWidth size="small" required>
                          <InputLabel id="vehicle-size-label">Vehicle Size</InputLabel>
                          <Select
                            labelId="vehicle-size-label"
                            id="vehicle-size"
                            value={vendorServiceData.vehicleSizeId}
                            label="Vehicle Size"
                            disabled={true}
                            error={vendorServiceData.vehicleSizeIdError}
                            onChange={(e) => handleChangeVendorServiceData(e, 'vehicleSizeId')}
                            name="vehicleSizeId"
                            className="textField"
                          >
                            {vehicleSizeData.map((e) => (
                              <MenuItem value={e.vehicleSizeId}>{e.vehicleSize}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={5} mt={1} xs={12}>
                        <TextField
                          id="outlined-disabled-4"
                          label="Vendor Contact Number"
                          value={vendorServiceData.vendorMobile}
                          name="vendorMobile"
                          disabled
                          size="small"
                          className="textField"
                          error={vendorServiceData.vendorMobileError}
                          onChange={(e) => handleChangeVendorServiceData(e, 'vendorMobile')}
                          required
                        />

                      </Grid>
                    </Grid>
                  </Grid>
                }

              </Grid>
            </Grid>

            <Grid className="hide imgVehicle" item md={3} xs={12}>
              <div style={{ width: '26vh', marginTop: '-15px' }}>
                <img
                  src={vehicle}
                  alt="Vehicle"
                  style={{ width: '100%' }}
                />
              </div>
            </Grid>


            <Grid className="hide" item md={1} xs={12}></Grid>

          </Grid>
          {searchResult != null &&
            <Grid
              container
              className="docForm"
              mt={3}
            >
              <Grid className="hide" item xs={1}></Grid>


              <Grid item md={10} xs={12} >
                <Grid container spacing={1} mt={2} className="formContainer" >
                  <Grid item md={1} className="hide" xs={11}></Grid>
                  <Grid item md={11} xs={11} >
                    <Typography variant="h6">
                      <b> Upload Documents*</b>
                    </Typography>
                  </Grid>
                  <Grid item md={1} className="hide" xs={12}></Grid>

                  {categories.map((category, index) => (
                    <>
                      <Grid item xs={12} md={3} sx={{ textAlign: 'left' }} key={index}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                          <p> {category.label}{category.name == 'lastService' ? '' : '*'}</p>
                          <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            startIcon={category.attachments.length > 0 ? <VisibilityIcon /> : <CloudUploadIcon />}
                            sx={{
                              width: '100%',
                              position: 'relative',
                              textAlign: 'center',
                              padding: '8px'
                            }}
                            onClick={() => {
                              if (category.attachments.length > 0) {
                                handlePreviewOpen(category);
                              }
                            }}
                          >
                            {category.attachments.length > 0 ? `${category.attachments.length} file(s) uploaded` : 'Upload file'}
                            {category.attachments.length == 0 ?
                              <VisuallyHiddenInput
                                type="file"
                                multiple
                                accept=".pdf, .jpeg, .jpg, .png"
                                onChange={(e) => handleFileChange(e, category.name)}
                              /> : ''
                            }
                          </Button>
                          <Box sx={{ width: '100%', marginTop: 3 }}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={vendorServiceData[`${category.name}ExpError`]}
                              onChange={(e) => handleChangeVendorServiceData(e, `${category.name}Exp`)}
                              label={`${category.label} Expiry Date${category.name == 'lastService' ? '' : '*'}`}
                              size="small"
                              type="date"
                              disabled={category.attachments.length > 0 ? false : true}
                              value={vendorServiceData[`${category.name}Exp`]}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      {index === 2 && (
                        <>
                          <Grid item md={2} className="hide" xs={12}></Grid>
                          <Grid item md={1} className="hide" xs={12}></Grid>
                        </>
                      )}
                    </>
                  ))}

                  <Grid item xs={12} md={11} mt={3} mb={5} style={{ textAlign: 'right' }}>
                    <Button variant="contained" size="large" onClick={handleSubmitForm}>
                      Submit
                    </Button>
                  </Grid>

                </Grid>


              </Grid>



            </Grid>
          }
        </Grid>
        <br />
        <br />
        <br />
        <br />

        <Dialog
          open={previewOpen}
          onClose={handlePreviewClose}
          maxWidth="md"
          fullWidth
          sx={{
            '& .MuiDialog-paper': {
              position: 'relative',
              borderRadius: '8px',
              padding: '0',
              overflow: 'hidden',
            },
          }}
        >
          <DialogTitle>
            <b>Uploaded Files for {currentCategory?.label}</b>
            <br /><span style={{ fontSize: '12px', color: 'red' }}>*Maximum 5 files can be uploaded</span>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handlePreviewClose}
              aria-label="close"
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              padding: '24px',
              textAlign: 'center',
              backgroundColor: '#ffffff',
            }}
          >
            <Grid container spacing={2}>
              {currentCategory?.attachments.map((file, index) => (
                <Grid item xs={6} sm={2} md={2} key={index}>
                  <div style={{ position: 'relative', width: '133px', height: '10vh' }}>
                    <Tooltip title={file.name}>
                      <a
                        size="small"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={URL.createObjectURL(file)}
                      >
                        <img
                          src={file.name.endsWith('.pdf') ? PdfIcon : URL.createObjectURL(file)}
                          alt={`Preview ${index}`}
                          className={file.name.endsWith('.pdf') ? 'previewPdf' : 'previewImage'}
                        />
                      </a>
                    </Tooltip>
                    <IconButton
                      edge="end"
                      color="error"

                      aria-label="delete"
                      sx={{ position: 'absolute', right: 0, top: 0 }}
                      style={{ padding: '0px', right: '3px', top: '0px', background: 'black', color: 'white' }}
                      onClick={() => { handleDeleteImage(index, currentCategory); }}
                    >
                      <CloseIcon style={{ padding: '4px' }} />
                    </IconButton>
                  </div>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              padding: '16px 24px',
              justifyContent: 'center',
              backgroundColor: '#ffffff',
            }}
          >
            <Button
              component="label"
              variant="contained"
              color="primary"
              startIcon={<CloudUploadIcon />}
            >
              Upload More
              <VisuallyHiddenInput
                type="file"
                multiple
                accept=".pdf, .jpeg, .jpg, .png"
                onChange={(e) => handleAddMoreFiles(e)}
              />
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="sm"
          fullWidth
          sx={{
            '& .MuiDialog-paper': {
              position: 'relative',
              borderRadius: '8px',
              padding: '0',
              overflow: 'hidden',
            },
          }}
        >
          <div
            style={{
              position: 'absolute',
              inset: 0,
              backgroundColor: 'rgba(31, 41, 55, 0.6)',
              backdropFilter: 'blur(4px)',
              zIndex: -1,
            }}
          ></div>

          <DialogContent
            sx={{
              padding: '24px',
              textAlign: 'center',
              backgroundColor: '#ffffff',
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="inline size-28 shrink-0 text-success"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ width: '48px', height: '48px', color: '#4caf50' }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <Typography
              variant="h6"
              sx={{
                marginTop: '16px',
                fontSize: '24px',
                color: '#374151',
              }}
            >
              Success Message
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginTop: '8px',
                color: '#6b7280',
              }}
            >
              Thank you for contacting us. Our representative will get back to you.
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              padding: '16px 24px',
              justifyContent: 'center',
              backgroundColor: '#ffffff',
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              color="success"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Footer />
      </ThemeProvider>
    </>
  );
};

export default Home;
