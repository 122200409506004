import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  Stack,
  Drawer,
  Button,
  TextField,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import BusinessIcon from "@mui/icons-material/Business";
import { useNavigate } from "react-router-dom";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SavedAddress() {
  const navigate = useNavigate();

  const [allAddress, setAllAddress] = useState([]);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const vertical = "top";
  const horizontal = "center";

  useEffect(() => {
    (async () => {
      await getAddress();
    })();
  }, []);

  const getAddress = async () => {
    const userToken = localStorage.getItem("token");
    const userDetails = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDetails);
    console.log(parseData.mobile);
    const response = await fetch("https://ndd.logicarts.in/pickup/getAddress", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${userToken}`,
      },

      body: JSON.stringify({
        cxID: `${parseData.cxID}`,
      }),
    });

    const responseJson = await response.json();
    const filterData = await responseJson.data.filter(
      (data) => data.isactive === true
    );
    const revereseData = filterData.reverse();
    console.log(revereseData);
    setAllAddress(revereseData);
  };

  const deleteToAddress = async (id) => {
    const userDetails = localStorage.getItem("userDetails");
    const parseData = JSON.parse(userDetails);

    console.log(id);

    const response = await fetch(
      "https://ndd.logicarts.in/pickup/manageAddress",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          cxID: `${parseData.cxID}`,
          cxAdID: id,
          jdata: {
            Description: "",
            Address: "",
            Pincode: "",
            isDefault: "",
            categoryID: "9",
          },
        }),
      }
    );

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.success == true) {
      await getAddress();
    } else {
      setErrorMsg("Unable to delete your address please try after sometime.");
      setOpenErrorAlert(true)
    }
  };

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: "#669AFF",
          display: "flex",
          justifyContent: "space-between",
          padding: "3%",
        }}
      >
        <Typography
          sx={{
            marginTop: "2%",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackRoundedIcon
            sx={{
              color: "#FFFFFF",
            }}
          />
        </Typography>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: "600",
            marginTop: "1%",
            fontSize: "1.2rem",
          }}
        >
          Manage Address
        </Typography>
        <Typography></Typography>
      </Box>
      <Box
        sx={{
          marginTop: "5%",
          padding: " 3%",
        }}
      >
        {allAddress.map((address) => (
          <Card
            sx={{
              padding: "3%",
              marginBottom: "3%",
              borderRadius: "8px",
              backgroundColor: "#FFF",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
          >
            <Stack direction="row" spacing={2}>
              <Typography>
                <BusinessIcon />
              </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                }}
              >
                {JSON.parse(address.jdata).Name}
              </Typography>
            </Stack>
            <Typography
              sx={{
                color: "grey",
                fontSize: "0.9rem",
              }}
              gutterBottom
            >
              {JSON.parse(address.jdata).Address}
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => {
                  localStorage.removeItem("searchLat");
                  localStorage.removeItem("searchLng");
                  navigate("/express/update-address-mobile", {
                    state: {
                      type: "to Address",
                      jdata: address.jdata,
                      cxAdID: address.cxAdID,
                    },
                  });
                }}
              >
                Edit
              </Button>
              <Button onClick={() => deleteToAddress(address.cxAdID)}>
                Delete
              </Button>
            </Stack>
          </Card>
        ))}
      </Box>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={() => setOpenErrorAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export default SavedAddress;
