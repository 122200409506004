import React from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from 'react-confetti'


const PointConfetti = (passedProps) => {
  const { width, height } = useWindowSize();
  return (
    <Confetti
      width={width}
      height={height}
      recycle={true}
    />
  );
};

export default PointConfetti;
