import React, { useState } from 'react'
import Banner from './Banner';
import { Typography, Container, Box, Button, Tabs, Tab, Grid, } from '@mui/material';
import Header from '../Header/Header';
import MediaSlider from './MediaSlider';
import Footer from '../Footer/Footer';

// State for managing active tab

const Events = () => {
  const [tabValue, setTabValue] = useState(0);
  return (
    <>
      <Header />
      <Banner />
      <Typography variant="h4" component="h2" sx={{ marginTop: '20px', fontWeight: 'bold', fontSize: '2rem', marginLeft: '20px' }}>
        Explore Events
      </Typography>

      <MediaSlider />
      <Footer />

    </>
  )
}

export default Events 